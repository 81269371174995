import React from 'react'
import Navbar from '../Navbar/Navbar'
import { Container, Grid, GridItem, Flex } from '@chakra-ui/react'

export default function CommunityLayout({ main }) {
    return (
        <div>
            <Container maxW="container.xl">
                <Navbar />
                <Flex direction="column" alignItems="center" w="100%">
                    {main}
                </Flex>
            </Container>
        </div>
    )
}
