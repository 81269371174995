import React, { useState, useEffect } from 'react'
import {
    ComposedChart,
    Area,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'
import { add, differenceInCalendarDays, format } from 'date-fns'
import { getWeekNum } from './Utils/helperFunctions'
import ChartCard from './Utils/ChartCard'
import CustomTooltip from './Utils/CustomTooltip'
import theme from '../../ChakraDesign/theme'

export default function WeeklyEfficiency({
    startDate,
    endDate,
    completedTasks,
    isNewUser,
    isPdf,
    mobile,
}) {
    const [filteredData, setFilteredData] = useState([])
    const colors = [theme.colors.pink[500]]

    useEffect(() => {
        if (isNewUser) {
            const dummyData = [
                {
                    date: startDate,
                    label: `${format(startDate, 'MMM d')}`,
                    weeklyAverage: 9,
                },
                {
                    date: add(startDate, { weeks: 1 }),
                    label: `${format(add(startDate, { weeks: 1 }), 'MMM d')}`,
                    weeklyAverage: 9,
                },
                {
                    date: add(startDate, { weeks: 2 }),
                    label: `${format(add(startDate, { weeks: 2 }), 'MMM d')}`,
                    weeklyAverage: 11,
                },
                {
                    date: add(startDate, { weeks: 3 }),
                    label: `${format(add(startDate, { weeks: 3 }), 'MMM d')}`,
                    weeklyAverage: 11,
                },
                {
                    date: add(startDate, { weeks: 4 }),
                    label: `${format(add(startDate, { weeks: 4 }), 'MMM d')}`,
                    weeklyAverage: 12,
                },
                {
                    date: add(startDate, { weeks: 5 }),
                    label: `${format(add(startDate, { weeks: 5 }), 'MMM d')}`,
                    weeklyAverage: 14,
                },
                {
                    date: add(startDate, { weeks: 6 }),
                    label: `${format(add(startDate, { weeks: 6 }), 'MMM d')}`,
                    weeklyAverage: 14,
                },
                {
                    date: add(startDate, { weeks: 7 }),
                    label: `${format(add(startDate, { weeks: 7 }), 'MMM d')}`,
                    weeklyAverage: 15,
                },
                {
                    date: add(startDate, { weeks: 8 }),
                    label: `${format(add(startDate, { weeks: 8 }), 'MMM d')}`,
                    weeklyAverage: 13,
                },
            ]
            setFilteredData(dummyData)
        } else {
            //get whole number for total weeks in selected date range
            const numWeeks = Math.ceil(
                (differenceInCalendarDays(endDate, startDate) + 1) / 7
            )

            //initialize data array
            let data = Array(numWeeks).fill({})
            data = data.map((week, index) => {
                const date = add(startDate, { weeks: index })
                return {
                    date,
                    label: `${format(date, 'MMM d')}`,
                    weeklyCompletedTasks: 0,
                    totalCompletedTasks: 0,
                    weeklyAverage: 0,
                }
            })

            //fill data array by week number
            completedTasks.forEach((task) => {
                const weekNumber = getWeekNum(
                    new Date(task.completionDate),
                    startDate
                )
                if (data[weekNumber] !== undefined) {
                    data[weekNumber].weeklyCompletedTasks++
                }
            })

            //calculate and store weekly average in data array
            let totalCompletedTasks = 0
            data = data.map((d, i) => {
                totalCompletedTasks += d.weeklyCompletedTasks
                return {
                    ...d,
                    weeklyAverage: Math.round(totalCompletedTasks / (i + 1)),
                }
            })

            setFilteredData(data)
        }
    }, [startDate, endDate, completedTasks, isNewUser])

    const stats = [
        {
            name: 'Average tasks completed per week',
            value:
                filteredData[0] &&
                filteredData[filteredData.length - 1].weeklyAverage,
            color: colors[0],
        },
    ]

    const unitLabels = [
        {
            singular: 'task completed per week',
            plural: 'tasks completed per week',
        },
    ]

    return (
        <ChartCard
            title="Overall Weekly Efficiency"
            description="Weekly average of tasks completed"
            stats={stats}
            isPdf={isPdf}
        >
            <ResponsiveContainer width={mobile ? '125%' : '105%'} height={320}>
                {filteredData.length > 0 && (
                    <ComposedChart
                        data={filteredData}
                        margin={{
                            top: 5,
                            right: mobile ? 110 : 85,
                            bottom: 50,
                            left: mobile && -40,
                        }}
                    >
                        <Tooltip
                            content={
                                <CustomTooltip
                                    endDate={endDate}
                                    colors={colors}
                                    unitLabels={unitLabels}
                                    dateLabelType={'dynamic'}
                                />
                            }
                        />
                        <CartesianGrid stroke={theme.colors.grey[200]} />
                        <Line
                            type="monotone"
                            dataKey="weeklyAverage"
                            stroke={colors[0]}
                            animationDuration={!isPdf && 3000}
                            strokeWidth={3}
                            dot={false}
                        />
                        <Area
                            type="monotone"
                            dataKey="weeklyAverage"
                            fill={colors[0]}
                            animationDuration={!isPdf && 3000}
                            stroke="none"
                            fillOpacity=".5"
                        />
                        <XAxis
                            dataKey="label"
                            interval={{ base: 0, sm: 1 }}
                            angle={25}
                            scale="point"
                            dy={10}
                        ></XAxis>
                        <YAxis />
                    </ComposedChart>
                )}
            </ResponsiveContainer>
        </ChartCard>
    )
}
