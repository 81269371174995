import React, { useState, useContext } from 'react'
import {
    Box,
    FormControl,
    FormLabel,
    Menu,
    MenuButton,
    MenuList,
    useToast,
    MenuItem,
    Button,
    Text,
    Circle,
} from '@chakra-ui/react'
import { analytics } from '../Util/api'
import { UserContext } from '../Contexts/UserContext'
import { TasksContext } from '../Contexts/TasksContext'
import { CopyIcon, CarrotIcon, CircleCheckIcon } from '../ChakraDesign/Icons'
import OOModal from '../SharedComponents/OOModal'
import ToastyBoi from '../SharedComponents/ToastyBoi'

const PERMISSIONS = [
    {
        text: 'Off',
        value: 'off',
        description: 'Only you can see this task, unless previously shared',
    },
    {
        text: 'Viewer',
        value: 'viewer',
        description: 'Anyone with this link can view but not edit the task',
    },
    {
        text: 'Co-owner',
        value: 'coOwner',
        description: 'Anyone with this link can edit and complete the task',
    },
]

const PERMISSION_MAP = {
    off: 0,
    viewer: 1,
    coOwner: 2,
}

export default function SharingModal({
    taskId,
    isOpen,
    onClose,
    urgency,
    shareLinkPermissionType,
}) {
    const { user } = useContext(UserContext)
    const { updateTask } = useContext(TasksContext)

    const toast = useToast()
    const linkyBoi = `${process.env.REACT_APP_URL}/tasks/${taskId}`

    const [selectedIndex, setSelectedIndex] = useState(
        PERMISSION_MAP[shareLinkPermissionType] || 0
    )

    const handlePermissionClick = (index) => {
        setSelectedIndex(index)
        updateTask(
            taskId,
            {
                shareLinkPermissionType: PERMISSIONS[index].value,
                isShared: index === 0 ? false : true,
            },
            urgency
        )
        analytics.trigger({ name: 'Task Shared', user })
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(linkyBoi)
        toast({
            duration: 3000,
            render: () => (
                <ToastyBoi
                    message="The link has been copied"
                    icon={<CircleCheckIcon fill="white" />}
                    backgroundColor="blue.500"
                />
            ),
        })
    }
    return (
        <OOModal
            title="Share this task"
            isOpen={isOpen}
            onClose={onClose}
            secondaryButton={
                selectedIndex !== 0 && {
                    onClick: copyToClipboard,
                    text: (
                        <>
                            <CopyIcon mt="6px" ml="-2px" />
                            <span>Copy Link</span>
                        </>
                    ),
                }
            }
        >
            <Box>
                <FormControl
                    id="share-type"
                    display="flex"
                    flexDirection="column"
                    align="center"
                >
                    <FormLabel fontSize="sm">Select Share Type</FormLabel>
                    <Menu>
                        {({ isOpen }) => (
                            <>
                                <MenuButton
                                    as={Button}
                                    colorScheme="blue"
                                    w="90%"
                                    m="auto"
                                    rightIcon={
                                        <CarrotIcon
                                            transform={
                                                isOpen && 'rotate(180deg)'
                                            }
                                        />
                                    }
                                >
                                    {PERMISSIONS[selectedIndex].text}{' '}
                                </MenuButton>
                                <MenuList>
                                    {PERMISSIONS.map((p, i) => (
                                        <MenuItem
                                            key={i}
                                            onClick={() =>
                                                handlePermissionClick(i)
                                            }
                                        >
                                            {p.text} ({p.description})
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </>
                        )}
                    </Menu>
                    <Text mt="16px" fontSize="14px">
                        {PERMISSIONS[selectedIndex].description}
                    </Text>
                </FormControl>
            </Box>
        </OOModal>
    )
}
