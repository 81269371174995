import React, { useState, useContext } from 'react'
import {
    Button,
    Box,
    Text,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Input,
    useToast,
    FormControl,
    FormLabel,
    CircularProgress,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Select,
} from '@chakra-ui/react'
import { apiCall } from '../Util/api'
import ToastyBoi from '../SharedComponents/ToastyBoi'
import { Formik, Form, Field } from 'formik'
import {
    LeftArrowIcon,
    PlusIcon,
    SharingIcon,
    CircleCheckIcon,
} from '../ChakraDesign/Icons'
import { UserContext } from '../Contexts/UserContext'

export default function AddASubscriber({ subscribers, setSubscribers }) {
    const { user } = useContext(UserContext)
    const [step, setStep] = useState(1)
    const [isOpen, setIsOpen] = useState(false)
    const [userType, setUserType] = useState('')
    const [userNotFound, setUserNotFound] = useState(false)
    const [userAlreadyExists, setUserAlreadyExists] = useState(false)

    const toast = useToast()

    const handleStepBack = () => {
        setStep(1)
        setUserType('')
        setUserNotFound(false)
        setUserAlreadyExists(false)
    }

    const handleUserTypeClick = (type) => {
        setUserType(type)
        setStep(2)
    }

    const handleClose = () => {
        setStep(1)
        setUserType('')
        setUserNotFound(false)
        setUserAlreadyExists(false)
        setIsOpen(false)
    }

    const handleStripeMembership = async (tier, name, customerId) => {
        let stripeKey = ''
        if (tier === 'free') {
            handleClose()
            toast({
                duration: 3000,
                render: () => (
                    <ToastyBoi
                        message={'Successfully invited ' + name}
                        icon={<CircleCheckIcon fill="white" />}
                        backgroundColor="blue.500"
                    />
                ),
            })
        } else {
            if (tier === 'personal') {
                stripeKey = process.env.REACT_APP_IND_PRICE_KEY
            } else if (tier === 'professional') {
                stripeKey = process.env.REACT_APP_PRO_PRICE_KEY
            }
            const data = await apiCall(
                'POST',
                '/stripe/create-checkout-session',
                {
                    lookup_key: stripeKey,
                    stripeCustomerId: customerId,
                }
            )
            window.location.href = data.url
        }
    }

    const handleSubmit = async (values) => {
        const tier = values.subscription.toLowerCase()
        let invitedUser = {}

        if (userType === 'createNew') {
            try {
                invitedUser = await apiCall(
                    `POST`,
                    `/users/${user._id}/teamMembers/invite/new`,
                    { ...values }
                )
                if (invitedUser) {
                    handleStripeMembership(
                        tier,
                        values.name.split(' ')[0],
                        invitedUser.stripeCustomerId
                    )

                    const newSubscribers = [...subscribers]
                    newSubscribers.push(invitedUser)
                    setSubscribers(newSubscribers)
                } else {
                    setUserAlreadyExists(true)
                }
            } catch (error) {
                console.log(error)
            }
        } else if (userType === 'addExisting') {
            try {
                invitedUser = await apiCall(
                    `POST`,
                    `/users/${user._id}/teamMembers/invite/existing`,
                    { ...values }
                )
                if (invitedUser) {
                    handleStripeMembership(
                        tier,
                        values.name.split(' ')[0],
                        invitedUser.stripeCustomerId
                    )

                    const newSubscribers = [...subscribers]
                    newSubscribers.push(invitedUser)
                    setSubscribers(newSubscribers)
                } else {
                    setUserNotFound(true)
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            console.log('user type not recognized')
        }
    }

    const Step1 = () => (
        <>
            <ModalHeader fontSize="lg">Add a subscriber</ModalHeader>
            <ModalCloseButton marginTop="5px" />
            <ModalBody>
                <Text>
                    Have someone on your team who would love Office Otter? Add
                    them here! Adding a subscriber will put you in charge of
                    their subscription.
                </Text>
                <Flex justifyContent="space-around" mt="32px" mb="16px">
                    <Button
                        variant="grey"
                        size="md"
                        leftIcon={<SharingIcon />}
                        onClick={() => handleUserTypeClick('createNew')}
                    >
                        Create new user
                    </Button>
                    <Button
                        variant="grey"
                        size="md"
                        leftIcon={<PlusIcon />}
                        onClick={() => handleUserTypeClick('addExisting')}
                    >
                        Add existing user
                    </Button>
                </Flex>
            </ModalBody>
        </>
    )

    const Step2 = () => (
        <>
            <Flex w="100%" align="center" justify="start" pt="16px" pl="16px">
                <LeftArrowIcon
                    cursor="pointer"
                    _hover={{ color: 'blue.500' }}
                    onClick={() => handleStepBack()}
                />
                <Text ml="24px" fontSize="lg" fontWeight="bold">
                    {userType === 'createNew'
                        ? 'Create New User'
                        : 'Add Existing User'}
                </Text>
                <ModalCloseButton marginTop="5px" />
            </Flex>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    message: '',
                    subscription: 'Free',
                }}
                onSubmit={async (values, actions) => {
                    await handleSubmit(values)
                }}
            >
                {({ values, handleChange, isSubmitting }) => (
                    <>
                        <ModalBody mb="16px">
                            <Form id="addSubscriberForm">
                                <FormControl id="name" isRequired>
                                    <FormLabel>Name</FormLabel>
                                    <Input
                                        type="text"
                                        size="lg"
                                        variant="filled"
                                        placeholder="Your team member's name!"
                                        onChange={handleChange}
                                        value={values.name}
                                        name="name"
                                    />
                                </FormControl>
                                <FormControl id="email" isRequired>
                                    <FormLabel mt="16px">
                                        Email address
                                    </FormLabel>
                                    <Input
                                        size="lg"
                                        variant="filled"
                                        placeholder="Your team member's email!"
                                        type="email"
                                        onChange={handleChange}
                                        value={values.email}
                                        name="email"
                                    />
                                    {userAlreadyExists && (
                                        <Text
                                            pl="16px"
                                            color="red.500"
                                            mt="4px"
                                            mb="-8px"
                                        >
                                            * There is already a user with this
                                            email
                                        </Text>
                                    )}
                                    {userNotFound && (
                                        <Text
                                            pl="16px"
                                            color="red.500"
                                            mt="4px"
                                            mb="-8px"
                                        >
                                            * There is not a user associated
                                            with this email
                                        </Text>
                                    )}
                                </FormControl>
                                <FormControl id="subscription" isRequired>
                                    <FormLabel fontSize="small" mt="16px">
                                        Subscription Tier
                                    </FormLabel>
                                    <Select
                                        marginTop="8px"
                                        name="subscription"
                                        variant="filled"
                                        value={values.subscription}
                                        onChange={handleChange}
                                    >
                                        <option value="free">Free</option>
                                        <option value="personal">
                                            Personal
                                        </option>
                                        <option value="professional">
                                            Professional
                                        </option>
                                    </Select>
                                </FormControl>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                type="submit"
                                form="addSubscriberForm"
                                colorScheme="blue"
                                variant="solid"
                                size="md"
                            >
                                {isSubmitting ? (
                                    <CircularProgress
                                        isIndeterminate
                                        size="24px"
                                    />
                                ) : (
                                    'Submit'
                                )}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Formik>
        </>
    )

    return (
        <Box
            width="100%"
            paddingLeft="8px !important"
            paddingRight="8px !important"
            marginTop="8px"
        >
            <Button
                onClick={() => setIsOpen(true)}
                variant="grey"
                size="md"
                mt="8px"
            >
                add a subscriber
            </Button>
            <Modal isOpen={isOpen} onClose={handleClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    {step === 1 && <Step1 />}
                    {step === 2 && <Step2 />}
                </ModalContent>
            </Modal>
        </Box>
    )
}
