import React, { useContext, useState, useEffect } from 'react'
import {
    Flex,
    Center,
    Avatar,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Text,
    RadioGroup,
    Radio,
    Checkbox,
    Box,
    FormLabel,
    Button,
} from '@chakra-ui/react'
import { Logo } from './Logo'
import {
    TasksIcon,
    ProfileIcon,
    SignOutIcon,
    TemplateIcon,
    CreditCardIcon,
    ListIcon,
    ClipboardIcon,
    LoopIcon,
    FilesIcon,
    ReportsIcon,
    CommunityIcon,
} from '../ChakraDesign/Icons'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { UserContext } from '../Contexts/UserContext'
import { analytics, setTokenHeader } from '../Util/api'
import MissedNotifications from './MissedNotifications'
import { sub, add } from 'date-fns'
import DatePicker from '../SharedComponents/DatePicker'
import OOModal from '../SharedComponents/OOModal'
import { URGENCIES } from '../Util/urgencies'
import AllRecurrencesModal from '../Recurrence/AllRecurrencesModal'
import FilesModal from '../Files/FilesModal'
import { PaidPopUpContext } from '../Contexts/PaidPopupContext'
import RequestATopicModal from '../Community/RequestATopicModal'

export default function Navbar({
    mobileMenu,
    searchInput,
    setIsTemplatesOpen,
    setIsSetupGuideOpen,
}) {
    const { user, setUser } = useContext(UserContext)
    const location = useLocation()
    const navigate = useNavigate()
    const [showWrapUpModal, setShowWrapUpModal] = useState(false)
    const [showRequestATopic, setShowRequestATopic] = useState(false)
    const [isAllRecurrenceModalOpen, setIsAllRecurrenceModalOpen] =
        useState(false)
    const [isFilesModalOpen, setIsFilesModalOpen] = useState(false)
    const TODAY = new Date()
    const { setPaidPopup } = useContext(PaidPopUpContext)
    const handleSignOut = () => {
        localStorage.removeItem('jwtToken')
        localStorage.removeItem('zenMode')
        localStorage.removeItem('hasSeenIntegrations')
        setUser(null)
        setTokenHeader(null)
        navigate(`/signin`)
    }

    useEffect(() => {
        if (window.location.href.indexOf('upgrade') > -1) {
            setPaidPopup({
                show: true,
                reason: 'Upgrade your plan to get the most out of Office Otter.',
                hideButtons: false,
            })
        }
    }, [])

    const WrapUpModal = () => {
        const [selectedSections, setSelectedSections] = useState([
            true,
            true,
            false,
            false,
            true,
        ])
        const [sortBy, setSortby] = useState('urgency')
        const [startDate, setStartDate] = useState(sub(TODAY, { weeks: 1 }))
        const [endDate, setEndDate] = useState(add(TODAY, { hours: 1 }))

        return (
            <OOModal
                isOpen={showWrapUpModal}
                onClose={() => setShowWrapUpModal(false)}
                onSubmit={() => {
                    analytics.trigger({
                        name: 'List Exported',
                        user,
                    })
                    window.open(
                        `/wrapup?${sortBy}&startDate=${startDate}&endDate=${endDate}&sections=${selectedSections}`,
                        '_blank'
                    )
                }}
                title="Select settings for export"
            >
                <Text fontSize="lg">
                    To print your list or save as a PDF, select the options
                    below and click Submit.
                </Text>
                <hr
                    style={{
                        height: 1,
                        backgroundColor: '#E5ECF6',
                        border: '1px solid #E5ECF6',
                        width: '100%',
                        borderRadius: 16,
                        marginBottom: 16,
                        marginTop: 16,
                    }}
                />
                <Text
                    fontSize="lg"
                    fontWeight="500"
                    marginTop="16px"
                    marginBottom="8px"
                >
                    Sections to export
                </Text>
                <Box>
                    {[...URGENCIES, { emoji: '✅', text: 'Completed' }].map(
                        (u, i) => (
                            <Flex alignItems="center" ml="24px">
                                <Checkbox
                                    marginRight="16px"
                                    isChecked={selectedSections[i]}
                                    onChange={() => {
                                        console.log(selectedSections)
                                        let newSections = [...selectedSections]
                                        newSections[i] = !newSections[i]
                                        setSelectedSections(newSections)
                                    }}
                                    size="lg"
                                />
                                <Text mr="4px" fontSize="20px">
                                    {u.emoji}
                                </Text>
                                <Text>{u.text}</Text>
                            </Flex>
                        )
                    )}
                </Box>
                <Flex pl="48px">
                    <Box marginLeft="16px">
                        <FormLabel mb="0px" fontSize="14px">
                            Start
                        </FormLabel>
                        <DatePicker
                            date={startDate}
                            setDate={(d) => {
                                setStartDate(d)
                            }}
                        />
                    </Box>
                    <Box marginLeft="16px">
                        <FormLabel mb="0px" fontSize="14px">
                            {' '}
                            End
                        </FormLabel>
                        <DatePicker
                            date={endDate}
                            setDate={(d) => {
                                setEndDate(d)
                            }}
                        />
                    </Box>
                </Flex>
                <Text
                    fontSize="lg"
                    fontWeight="500"
                    mt="16px"
                    marginBottom="8px"
                >
                    Sort list by
                </Text>

                <Flex alignItems="center" ml="24px">
                    <RadioGroup
                        onChange={setSortby}
                        value={sortBy}
                        display="flex"
                        flexDir="column"
                    >
                        <Radio value="urgency" size="lg">
                            <Text ml="8px">Urgency</Text>
                        </Radio>
                        <Radio value="label" size="lg">
                            <Text ml="8px">Label</Text>
                        </Radio>
                    </RadioGroup>
                </Flex>
            </OOModal>
        )
    }

    return (
        <Flex justifyContent="center" w="100%" mb="16px">
            <Flex
                h="72px"
                w="100%"
                maxW="container.xl"
                justifyContent="space-between"
                pl="4px"
            >
                <Center>
                    <Logo />
                </Center>
                {location.pathname === '/tasks' && (
                    <Center display={{ base: 'none', md: 'flex' }}>
                        {searchInput}
                    </Center>
                )}
                <Center
                    display={{ base: 'flex', md: 'none' }}
                    alignSelf="center"
                >
                    {mobileMenu}
                </Center>
                {location.pathname === '/community' && (
                    <Center ml="16px">
                        <Button
                            variant="nav-button"
                            size="lg"
                            onClick={() => setShowRequestATopic(true)}
                        >
                            Request a topic
                        </Button>
                    </Center>
                )}
                <Flex ml="auto" display={{ base: 'none', md: 'flex' }}>
                    <Center>
                        {!process.env.REACT_APP_PROFESSIONAL_SUBSCRIPTION_IDs.split(
                            ' '
                        ).includes(user.stripeProductId) && (
                            <Button
                                colorScheme="blue"
                                onClick={() =>
                                    setPaidPopup({
                                        show: true,
                                        reason: 'Upgrade your plan to get the most out of Office Otter.',
                                        hideButtons: false,
                                    })
                                }
                            >
                                Upgrade
                            </Button>
                        )}
                    </Center>
                    <Center ml="16px">
                        <Link to="/community">
                            <Button
                                variant="nav-button"
                                size="lg"
                                leftIcon={<CommunityIcon />}
                                color={
                                    window.location.pathname === '/community' &&
                                    'blue.500'
                                }
                            >
                                Community
                            </Button>
                        </Link>
                    </Center>
                    <Center marginLeft="16px" className="hover">
                        <MissedNotifications user={user} />
                    </Center>
                </Flex>
                <Center ml="16px">
                    <Menu>
                        <MenuButton>
                            <Avatar
                                name={user.name}
                                bg="blue.500"
                                fontWeight="normal"
                                color="white"
                                src={user.profilePhotoUrl}
                            />
                        </MenuButton>
                        <MenuList>
                            <Link to="/userprofile/info">
                                <MenuItem icon={<ProfileIcon />}>
                                    Profile
                                </MenuItem>
                            </Link>
                            {!location.pathname.includes('/userprofile') && (
                                <Link to="/userprofile/subscription">
                                    <MenuItem
                                        icon={
                                            <CreditCardIcon
                                                style={{
                                                    marginLeft: 1,
                                                    height: 22,
                                                    width: 22,
                                                }}
                                            />
                                        }
                                    >
                                        Subscription
                                    </MenuItem>
                                </Link>
                            )}
                            <Link to="/tasks">
                                <MenuItem icon={<TasksIcon />}>Tasks</MenuItem>
                            </Link>
                            <Link to="/tasks/completed">
                                <MenuItem icon={<ReportsIcon />}>
                                    Reports
                                </MenuItem>
                            </Link>
                            {location.pathname === '/tasks' && (
                                <MenuItem
                                    icon={
                                        <TemplateIcon
                                            style={{
                                                width: 30,
                                                height: 30,
                                                marginRight: -6,
                                            }}
                                        />
                                    }
                                    onClick={() => setIsTemplatesOpen(true)}
                                >
                                    Templates
                                </MenuItem>
                            )}

                            <MenuItem
                                icon={<LoopIcon />}
                                onClick={() =>
                                    setIsAllRecurrenceModalOpen(true)
                                }
                            >
                                Recurrences
                            </MenuItem>
                            <MenuItem
                                icon={<FilesIcon />}
                                onClick={() => setIsFilesModalOpen(true)}
                            >
                                Files
                            </MenuItem>
                            <Link to="/community">
                                <MenuItem icon={<CommunityIcon />}>
                                    Community
                                </MenuItem>
                            </Link>
                            <MenuItem
                                icon={<ListIcon />}
                                onClick={() => setShowWrapUpModal(true)}
                            >
                                Export List
                            </MenuItem>
                            {location.pathname === '/tasks' && (
                                <MenuItem
                                    icon={<ClipboardIcon />}
                                    onClick={() => setIsSetupGuideOpen(true)}
                                >
                                    Setup Guide
                                </MenuItem>
                            )}

                            <MenuItem
                                icon={<SignOutIcon />}
                                onClick={handleSignOut}
                            >
                                Sign Out
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Center>
            </Flex>
            {showWrapUpModal && <WrapUpModal />}
            {showRequestATopic && (
                <RequestATopicModal
                    open={showRequestATopic}
                    setOpen={setShowRequestATopic}
                />
            )}
            {isAllRecurrenceModalOpen && (
                <AllRecurrencesModal
                    isOpen={isAllRecurrenceModalOpen}
                    setIsOpen={() => setIsAllRecurrenceModalOpen(false)}
                />
            )}
            {isFilesModalOpen && (
                <FilesModal
                    isOpen={isFilesModalOpen}
                    setIsOpen={() => setIsFilesModalOpen(false)}
                />
            )}
        </Flex>
    )
}
