import React, { useEffect } from 'react'
import FileSaver from 'file-saver'
import { Flex, Button, CircularProgress } from '@chakra-ui/react'
import { apiCall, analytics } from '../Util/api'
import { DownloadIcon } from '../ChakraDesign/Icons'
import { format, set } from 'date-fns'
import DatePicker from '../SharedComponents/DatePicker'

export default function ReportsActionBar({
    user,
    isNewUser,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
}) {
    const handleSetStartDate = (selectedDate) => {
        if (selectedDate < endDate) {
            setStartDate(selectedDate)
        } else {
            alert('Please select a date that is before the end date')
        }
    }

    const handleSetEndDate = (selectedDate) => {
        if (selectedDate > startDate) {
            setEndDate(selectedDate)
        } else {
            alert('Please select a date that is after the start date')
        }
    }

    return (
        <Flex align="center" direction={{ base: 'column', md: 'row' }}>
            <Flex align="center" style={{ opacity: isNewUser && 0.5 }}>
                <div
                    style={{
                        marginRight: 8,
                        padding: 4,
                    }}
                >
                    <DatePicker
                        date={startDate}
                        setDate={handleSetStartDate}
                        isDisabled={isNewUser}
                    />
                </div>
                <div
                    style={{
                        marginLeft: 8,
                    }}
                >
                    <DatePicker
                        date={endDate}
                        setDate={handleSetEndDate}
                        isDisabled={isNewUser}
                    />
                </div>
            </Flex>
        </Flex>
    )
}
