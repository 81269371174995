import React, { useState } from 'react'
import { USE_CASES } from '../constants'
import HeaderText from '../HeaderText'
import SubHeaderText from '../SubHeaderText'
import UseCaseCard from '../UseCaseCard'
import { Flex, Box, Image, Button, ModalFooter } from '@chakra-ui/react'

export default function Step1({ step, setStep, updateUserInfo }) {
    const [useCase, setUseCase] = useState(null)

    const handleUseCaseClick = (title, index) => {
        setUseCase(index)
        updateUserInfo({ use: title })
    }

    return (
        <>
            {useCase === null && (
                <>
                    <HeaderText>
                        Tell us, what are you looking to use Office Otter for?
                    </HeaderText>
                    <SubHeaderText>
                        This will help customize your experience.
                    </SubHeaderText>
                    <Flex justify="center">
                        <Flex
                            flexWrap="wrap"
                            maxW="420px"
                            justify="space-around"
                            mb="32px"
                            mt="32px"
                        >
                            {USE_CASES.map((i, index) => (
                                <UseCaseCard
                                    id={`useCase${index}`}
                                    title={i.title}
                                    image={i.image}
                                    onClick={() =>
                                        handleUseCaseClick(i.title, index)
                                    }
                                />
                            ))}
                        </Flex>
                    </Flex>
                </>
            )}
            {useCase !== null && (
                <Flex justify="center">
                    <Box style={{ width: '400px' }}>
                        <HeaderText>You're in the right place.</HeaderText>
                        <Image src={USE_CASES[useCase].followUpImg}></Image>
                        <SubHeaderText>
                            {USE_CASES[useCase].followUpMessage}
                        </SubHeaderText>
                    </Box>
                </Flex>
            )}
            <ModalFooter>
                <Button onClick={() => setStep(step + 1)} color="primary">
                    {useCase === null ? 'Skip' : 'Next'}
                </Button>
            </ModalFooter>
        </>
    )
}
