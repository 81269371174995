import React from 'react'
import { Box } from '@chakra-ui/react'
import theme from '../ChakraDesign/theme'
import PDFLayout from './Layouts/PDFLayout'
import WordCloud from './Charts/WordCloud'
import CreatedVsCompleted from './Charts/CreatedVsCompleted'
import ChecklistProductivity from './Charts/ChecklistProductivity'
import LabelsDistribution from './Charts/LabelsDistribution'
import ProductivityTrends from './Charts/ProductivityTrends'
import ResponseTimes from './Charts/ResponseTimes'
import WeeklyEfficiency from './Charts/WeeklyEfficiency'

export function Reports({
    isNewUser,
    startDate,
    endDate,
    tasks,
    completedTasks,
    completedTodos,
    labels,
    mobile,
}) {
    return (
        <Box
            style={{
                marginTop: 32,
                overflowY: 'auto',
            }}
        >
            <CreatedVsCompleted
                startDate={startDate}
                endDate={endDate}
                tasks={tasks}
                completedTasks={completedTasks}
                isNewUser={isNewUser}
                mobile={mobile}
            />
            <ChecklistProductivity
                startDate={startDate}
                endDate={endDate}
                completedTasks={completedTasks}
                completedTodos={completedTodos}
                isNewUser={isNewUser}
                mobile={mobile}
            />
            <LabelsDistribution
                startDate={startDate}
                endDate={endDate}
                completedTasks={completedTasks}
                labels={labels}
                isNewUser={isNewUser}
                mobile={mobile}
            />
            <WordCloud tasks={tasks} isNewUser={isNewUser} />
            <ProductivityTrends
                tasks={tasks}
                completedTasks={completedTasks}
                isNewUser={isNewUser}
            />
            <ResponseTimes
                completedTasks={completedTasks}
                isNewUser={isNewUser}
            />
            <WeeklyEfficiency
                startDate={startDate}
                endDate={endDate}
                completedTasks={completedTasks}
                isNewUser={isNewUser}
                mobile={mobile}
            />
        </Box>
    )
}

export function ReportsPDF({
    user,
    startDate,
    endDate,
    tasks,
    completedTasks,
    completedTodos,
    labels,
}) {
    const info = {
        user: user,
        startDate: startDate,
        endDate: endDate,
    }

    return (
        <Box w="816px" backgroundColor={theme.colors.white}>
            <Box id="page1">
                <PDFLayout info={info}>
                    <CreatedVsCompleted
                        startDate={startDate}
                        endDate={endDate}
                        tasks={tasks}
                        completedTasks={completedTasks}
                        isPdf={true}
                    />
                    <WordCloud tasks={tasks} isPdf={true} />
                </PDFLayout>
            </Box>
            <Box id="page2">
                <PDFLayout info={info}>
                    <ChecklistProductivity
                        startDate={startDate}
                        endDate={endDate}
                        completedTasks={completedTasks}
                        completedTodos={completedTodos}
                        isPdf={true}
                    />
                    <ProductivityTrends
                        tasks={tasks}
                        completedTasks={completedTasks}
                        isPdf={true}
                    />
                </PDFLayout>
            </Box>
            <Box id="page3">
                <PDFLayout info={info}>
                    <LabelsDistribution
                        startDate={startDate}
                        endDate={endDate}
                        completedTasks={completedTasks}
                        labels={labels}
                        isPdf={true}
                    />
                </PDFLayout>
            </Box>
            <Box id="page4">
                <PDFLayout info={info}>
                    <ResponseTimes
                        completedTasks={completedTasks}
                        isPdf={true}
                    />
                </PDFLayout>
            </Box>
            <Box id="page5">
                <PDFLayout info={info}>
                    <WeeklyEfficiency
                        startDate={startDate}
                        endDate={endDate}
                        completedTasks={completedTasks}
                        isPdf={true}
                    />
                </PDFLayout>
            </Box>
        </Box>
    )
}
