import React, { useState, useContext } from 'react'
import {
    Button,
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Input,
    useToast,
    FormControl,
    FormLabel,
    CircularProgress,
} from '@chakra-ui/react'
import { analytics } from '../Util/api'
import ToastyBoi from '../SharedComponents/ToastyBoi'
import { Formik, Form } from 'formik'
import { CircleCheckIcon } from '../ChakraDesign/Icons'
import { UserContext } from '../Contexts/UserContext'

export default function RequestATopicModal({ open, setOpen }) {
    const { user } = useContext(UserContext)
    const [isOpen, setIsOpen] = useState(open)

    const toast = useToast()

    const sendTopicRequest = async (values) => {
        try {
            analytics.trigger({
                name: values,
                user,
            })
            toast({
                duration: 3000,
                render: () => (
                    <ToastyBoi
                        message={'Successfully sent'}
                        icon={<CircleCheckIcon fill="white" />}
                        backgroundColor="blue.500"
                    ></ToastyBoi>
                ),
            })
        } catch (error) {
            console.log(error)
        }
        setOpen(false)
    }

    return (
        isOpen && (
            <Modal isOpen={isOpen} onClose={() => setOpen(false)} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <Formik
                        initialValues={{ topic: '' }}
                        onSubmit={async (values, actions) => {
                            await sendTopicRequest(values)
                        }}
                    >
                        {({ values, handleChange, isSubmitting }) => (
                            <>
                                <ModalHeader fontSize="lg">
                                    Have a topic you would like to discuss?
                                </ModalHeader>
                                <ModalCloseButton marginTop="5px" />
                                <ModalBody>
                                    <Form
                                        style={{ width: '100%' }}
                                        id="topicform"
                                    >
                                        <FormControl isRequired>
                                            <Input
                                                type="text"
                                                size="lg"
                                                variant="filled"
                                                placeholder="A topic you would like to discuss..."
                                                onChange={handleChange}
                                                value={values.topic}
                                                name="topic"
                                                autoFocus
                                            />
                                        </FormControl>
                                    </Form>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        type="submit"
                                        form="topicform"
                                        colorScheme="blue"
                                        variant="solid"
                                        size="md"
                                    >
                                        {isSubmitting ? (
                                            <CircularProgress
                                                isIndeterminate
                                                size="24px"
                                            />
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </ModalFooter>
                            </>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        )
    )
}
