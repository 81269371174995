import React from 'react'

export default function Support() {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 48,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'left',
                    flexDirection: 'column',
                    maxWidth: 500,
                }}
            >
                <p>
                    <span style={{ fontWeight: 400 }}>
                        OFFICE OTTER SUPPORT
                    </span>
                </p>
                <p>&nbsp;</p>
                <p>
                    <span style={{ fontWeight: 400 }}>
                        Last updated August 18, 2022
                    </span>
                </p>
                <p style={{ marginTop: 32, textAlign: 'center' }}>
                    For any questions regarding pricing or account information
                    please contact:
                </p>
                <p style={{ fontWeight: 'bold' }}>shayanne@officeotter.com</p>
                <p style={{ marginTop: 32 }}>
                    For technical support please contact:
                </p>
                <p style={{ fontWeight: 'bold' }}>jordan@officeotter.com</p>
            </div>
        </div>
    )
}
