import React, { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Box, Button, Flex, Text, Tooltip } from '@chakra-ui/react'
import {
    PencilIcon,
    TrashIcon,
    SharingIcon,
    DownloadIcon,
} from '../ChakraDesign/Icons'
import RenameFileDialogue from './RenameFileDialogue'
import DeleteFileDialogue from './DeleteFileDialogue'
import ShareFileDialogue from './ShareFileDialogue'
import format from 'date-fns/format'
import { analytics } from '../Util/api'

export default function FileCard({
    file,
    index,
    sortBy,
    updateFile,
    deleteFile,
    disableDrag,
    user,
}) {
    const [isChangeNameDialogueOpen, setIsChangeNameDialogueOpen] =
        useState(false)
    const [isDeleteDialogueOpen, setIsDeleteDialogueOpen] = useState(false)
    const [isShareDialogueOpen, setIsShareDialogueOpen] = useState(false)

    const fileCardStyle = (isDragging, draggableStyle) => {
        let transform = draggableStyle.transform

        if (transform) {
            transform =
                'translate(0px' +
                transform.slice(transform.indexOf(','), transform.length)
        }
        return {
            ...draggableStyle,
            transform: transform,
            cursor: 'pointer',
            marginTop: '16px',
            padding: '16px',
            borderRadius: '8px',
            backgroundColor: 'gray.200',
        }
    }

    return (
        <Draggable
            key={file._id}
            draggableId={file._id}
            index={index}
            // isDragDisabled={sortBy !== 'dnd' || disableDrag}
        >
            {(provided, snapshot) => (
                <Box
                    w="100%"
                    _hover={{
                        boxShadow: '0 8px 16px 0 rgba(56, 96, 165, 0.15)',
                        transition: '0.3s',
                    }}
                    _active={{
                        cursor: 'grabbing',
                    }}
                    backgroundColor="colors.white"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={fileCardStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                >
                    <Flex justifyContent="space-between">
                        <Flex
                            alignItems="center"
                            maxWidth="80%"
                            onClick={() => {
                                window.open(file.url, '_blank')
                                analytics.trigger({
                                    name: 'File Downloaded',
                                    user,
                                })
                            }}
                        >
                            {file.name}
                            <Text fontSize="12" color="grey.900" ml="8px">
                                {format(new Date(file.createdDate), 'MMM d')}
                            </Text>
                        </Flex>
                        <Flex>
                            <Tooltip label="Download File">
                                <Button
                                    variant="icon-button"
                                    onClick={() => {
                                        window.open(file.url, '_blank')
                                        analytics.trigger({
                                            name: 'File Downloaded',
                                            user,
                                        })
                                    }}
                                >
                                    <DownloadIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip label="Get a link to share file">
                                <Button
                                    variant="icon-button"
                                    onClick={() => setIsShareDialogueOpen(true)}
                                >
                                    <SharingIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip label="Edit name">
                                <Button
                                    variant="icon-button"
                                    onClick={() =>
                                        setIsChangeNameDialogueOpen(true)
                                    }
                                >
                                    <PencilIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip label="Delete file">
                                <Button
                                    variant="icon-button"
                                    onClick={() =>
                                        setIsDeleteDialogueOpen(true)
                                    }
                                >
                                    <TrashIcon />
                                </Button>
                            </Tooltip>
                        </Flex>
                    </Flex>
                    {isChangeNameDialogueOpen && (
                        <RenameFileDialogue
                            isOpen={isChangeNameDialogueOpen}
                            onClose={() => setIsChangeNameDialogueOpen(false)}
                            updateFile={updateFile}
                            fileId={file._id}
                            fileName={file.name}
                        />
                    )}
                    {isDeleteDialogueOpen && (
                        <DeleteFileDialogue
                            isOpen={isDeleteDialogueOpen}
                            onClose={() => setIsDeleteDialogueOpen(false)}
                            deleteFile={deleteFile}
                            fileId={file._id}
                        />
                    )}
                    {isShareDialogueOpen && (
                        <ShareFileDialogue
                            isOpen={isShareDialogueOpen}
                            onClose={() => setIsShareDialogueOpen(false)}
                            fileLink={file.url}
                            fileName={file.name}
                        />
                    )}
                </Box>
            )}
        </Draggable>
    )
}
