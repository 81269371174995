import React, { useState, useEffect, useContext } from 'react'
import {
    Button,
    Box,
    Modal,
    ModalContent,
    Flex,
    Text,
    Container,
} from '@chakra-ui/react'
import { analytics, apiCall } from '../Util/api'
import { UserContext } from '../Contexts/UserContext'
import { LabelsContext } from '../Contexts/LabelsContext'
import RecurrenceContainer from './RecurrenceContainer'

export default function AllRecurrencesModal({ isOpen, setIsOpen }) {
    const { user } = useContext(UserContext)
    const { getUsersLabels } = useContext(LabelsContext)

    useEffect(() => {
        getUsersLabels()
        analytics.trigger({ name: 'All Recurrences Viewed', user })
    }, [])

    return (
        <Modal isOpen={isOpen} size="full" onClose={() => setIsOpen(false)}>
            <ModalContent p={4}>
                <Container maxW="container.xl">
                    <Box>
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            pl="16px"
                            pt="4px"
                        >
                            <Text fontSize="xl" fontWeight="600">
                                Recurring tasks
                            </Text>
                            <Box>
                                <Button
                                    onClick={() => {
                                        setIsOpen(false)
                                    }}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Flex>
                    </Box>
                    <Box padding="32px">
                        <RecurrenceContainer user={user} />
                    </Box>
                </Container>
            </ModalContent>
        </Modal>
    )
}
