import React, { useState } from 'react'
import { Input, Flex } from '@chakra-ui/react'
import OOModal from '../SharedComponents/OOModal'

export default function DeleteFileDialogue({
    isOpen,
    onClose,
    deleteFile,
    fileId,
}) {
    const handleSubmit = () => {
        deleteFile(fileId)
        onClose()
    }

    return (
        <OOModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title="Are you sure you want to delete?"
        ></OOModal>
    )
}
