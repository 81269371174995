import React, { useState, useEffect, useContext } from 'react'
import {
    Box,
    Center,
    FormLabel,
    FormControl,
    Text,
    VStack,
    Flex,
    Button,
} from '@chakra-ui/react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { apiCall } from '../Util/api'
import TaskCard from './TaskCard/TaskCard'
import { Link } from 'react-router-dom'
import { PaidPopUpContext } from '../Contexts/PaidPopupContext'
import { sub, add, isBefore, isAfter } from 'date-fns'
import DatePicker from '../SharedComponents/DatePicker'
import csvDownload from 'json-to-csv-export'
import { UserContext } from '../Contexts/UserContext'
import { TasksContext } from '../Contexts/TasksContext'
import { getTotalMinutesFromTimeLog } from './TimeKeepingModal'
import { format, differenceInCalendarDays } from 'date-fns'

export default function CompletedSections({ urgency, numCompletedTasks }) {
    const TODAY = new Date()

    // context variables
    const { user } = useContext(UserContext)
    const { setPaidPopup } = useContext(PaidPopUpContext)
    const { tasks, setTasks, setNumCompletedTasks } = useContext(TasksContext)

    // state variables
    const [completedTasks, setCompletedTasks] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [startDate, setStartDate] = useState(sub(TODAY, { weeks: 1 }))
    const [endDate, setEndDate] = useState(add(TODAY, { hours: 1 }))
    const [showSubmit, setShowSubmit] = useState(false)

    useEffect(() => {
        getCompletedTasks()
    }, [])

    useEffect(() => {
        if (!isLoading) {
            console.log(TODAY - new Date(startDate))
            if (
                user.stripeProductId === '' &&
                differenceInCalendarDays(TODAY, new Date(startDate)) > 7
            ) {
                setStartDate(sub(TODAY, { days: 7 }))
                setPaidPopup({
                    show: true,
                    reason: 'Oops! It looks like you hit a limit on your free plan 😢. You can continue using your free account. 🎉 Upgrade to go back further than one week.',
                    hideButtons: false,
                })
            } else if (
                process.env.REACT_APP_PERSONAL_SUBSCRIPTION_IDs.split(
                    ' '
                ).includes(user.stripeProductId) &&
                differenceInCalendarDays(TODAY, new Date(startDate)) > 14
            ) {
                setStartDate(sub(TODAY, { days: 14 }))
                setPaidPopup({
                    show: true,
                    reason: 'Oops! It looks like you hit a limit on your free plan 😢. You can continue using your free account. 🎉 Upgrade to go back further than two weeks.',
                    hideButtons: false,
                })
            }
        }
    }, [startDate])

    const getCompletedTasks = async () => {
        setIsLoading(true)

        try {
            const completedTasks = await apiCall(
                'get',
                `/users/${user._id}/tasks/completed/range?startDate=${startDate}&endDate=${endDate}&subscription=${user.stripeProductId}`
            )

            setCompletedTasks(completedTasks)
        } catch (error) {
            alert(error)
        }

        setIsLoading(false)
    }

    //updates task and updates section state to have updated task
    const updateTask = async (taskId, taskData) => {
        try {
            //update task
            const newTask = await apiCall(
                'PUT',
                `/users/${user._id}/tasks/${taskId}`,
                taskData
            )

            //if we set a due date on a completed task
            if (taskData.due || taskData.isCompleted === false) {
                //remove
                setCompletedTasks(
                    [...completedTasks].filter((t) => t._id !== newTask._id)
                )
                //add
                let newTasks = [...tasks]
                newTasks.unshift(newTask)
                setTasks(newTasks)
                setNumCompletedTasks(numCompletedTasks - 1)
            } else {
                //no due date change
                let updatedSection = [...completedTasks].map((task) => {
                    if (task._id === taskId) {
                        return newTask
                    } else {
                        return task
                    }
                })
                setCompletedTasks(updatedSection)
            }
        } catch (error) {
            console.log(error)
            alert(
                'Unable to update task. Error message: ' +
                    JSON.stringify(error.data.error.message)
            )
        }
    }

    const handleDownloadCSV = () => {
        // Description
        // Checklist
        // Notes
        // Labels
        // Creation Date
        // Completion Date
        // Time Spent
        let tasksInDateRange = completedTasks.filter(
            (t) =>
                isAfter(new Date(t.completionDate), startDate) &&
                isBefore(new Date(t.completionDate), endDate)
        )

        let cleanedTasks = tasksInDateRange.map((t) => {
            return {
                //strip html
                description: t.description.replace(/<\/?[^>]+(>|$)/g, ''),
                //combine notes together
                notes: t.notes.join(' '),
                //only grab checklist item name
                checklist: t.checklist
                    .map((item, i) => i + 1 + '. ' + item.name)
                    .join(' '),
                //only grab label name
                labels: t.labels.map((l) => l.name).join(', '),
                creationDate: format(new Date(t.createdDate), 'MM-dd-yyyy'),
                completionDate: format(
                    new Date(t.completionDate),
                    'MM-dd-yyyy'
                ),
                timeSpent: getTotalMinutesFromTimeLog(t.timeLog),
            }
        })

        csvDownload(
            cleanedTasks,
            user.name.replace(/\s/g, '') +
                'OfficeOtterCompletedTasks' +
                format(
                    new Date(startDate),
                    'MM-dd-yyyy' + '-' + format(new Date(endDate), 'MM-dd-yyyy')
                ) +
                '.csv'
        )
    }

    return (
        <Box>
            <Flex
                width="100%"
                alignItems="center"
                boxShadow="base"
                padding="16px"
                borderRadius="16px"
            >
                <Flex
                    align="center"
                    width="100%"
                    justifyContent="space-between"
                >
                    <FormControl
                        flexDirection="row"
                        display="flex"
                        marginRight="16px"
                        width="100%"
                    >
                        <Box marginRight="16px">
                            <FormLabel>Start date</FormLabel>
                            <DatePicker
                                date={startDate}
                                setDate={(d) => {
                                    setStartDate(d)
                                    setShowSubmit(true)
                                }}
                            />
                        </Box>
                        <Box>
                            <FormLabel> End date</FormLabel>
                            <DatePicker
                                date={endDate}
                                setDate={(d) => {
                                    setEndDate(d)
                                    setShowSubmit(true)
                                }}
                            />
                        </Box>
                        {showSubmit && (
                            <Button
                                colorScheme="blue"
                                size="md"
                                mt="auto"
                                ml="8px"
                                onClick={() => {
                                    getCompletedTasks()
                                    setShowSubmit(false)
                                }}
                            >
                                submit
                            </Button>
                        )}
                    </FormControl>
                    <Flex
                        flexDirection="row"
                        alignItems="start"
                        height="72px"
                        marginTop="-5px"
                    >
                        <Button
                            size="md"
                            width="148px"
                            onClick={handleDownloadCSV}
                        >
                            export to CSV
                        </Button>
                        <Link
                            target="_blank"
                            to={`/reports?ispdf&startDate=${startDate}&endDate=${endDate}&fromCompleted`}
                        >
                            <Button
                                size="md"
                                width="148px"
                                marginLeft="8px"
                                colorScheme="blue"
                            >
                                see reports
                            </Button>
                        </Link>
                    </Flex>
                </Flex>
            </Flex>
            <Box
                height="calc(100vh - 252px)"
                overflow="auto"
                style={{
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    marginLeft: -8,
                    marginRight: -8,
                }}
            >
                {isLoading ? (
                    <Center height="128px">
                        <Center
                            height="52px"
                            width="52px"
                            shadow="base"
                            fontSize="xl"
                            padding="8px"
                            borderRadius="8px"
                        >
                            🦦
                        </Center>
                        <Text
                            fontSize="xl"
                            fontWeight="bold"
                            letterSpacing="-0.75px"
                            display={{ base: 'none', lg: 'flex' }}
                            ml="8px"
                        >
                            loading ...
                        </Text>
                    </Center>
                ) : (
                    <>
                        <DragDropContext>
                            <Droppable droppableId="completed-section">
                                {() => (
                                    <VStack id="tasks-list">
                                        {completedTasks.map((t, i) => (
                                            <TaskCard
                                                task={t}
                                                index={i}
                                                key={t._id}
                                                urgency={urgency}
                                                disableDrag={true}
                                                cards={completedTasks}
                                                setCards={setCompletedTasks}
                                                updateCompletedTask={updateTask}
                                            />
                                        ))}
                                    </VStack>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                )}
            </Box>
        </Box>
    )
}
