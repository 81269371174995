import theme from '../../ChakraDesign/theme'
import React, { useState, useEffect } from 'react'
import { getDay } from 'date-fns'
import ChartCard from './Utils/ChartCard'

export default function ProductivityTrends({
    tasks,
    completedTasks,
    isNewUser,
    isPdf,
}) {
    const [mostProductiveDay, setMostProductiveDay] = useState(null)
    const [busiestDay, setBusiestDay] = useState(null)

    useEffect(() => {
        if (isNewUser) {
            setMostProductiveDay('Wednesday')
            setBusiestDay('Monday')
        } else {
            const week = [
                { day: 'Sunday', numCompTasks: 0, numCreatedTasks: 0 },
                { day: 'Monday', numCompTasks: 0, numCreatedTasks: 0 },
                { day: 'Tuesday', numCompTasks: 0, numCreatedTasks: 0 },
                { day: 'Wednesday', numCompTasks: 0, numCreatedTasks: 0 },
                { day: 'Thursday', numCompTasks: 0, numCreatedTasks: 0 },
                { day: 'Friday', numCompTasks: 0, numCreatedTasks: 0 },
                { day: 'Saturday', numCompTasks: 0, numCreatedTasks: 0 },
            ]
            completedTasks.forEach((task) => {
                week[getDay(new Date(task.completionDate))].numCompTasks++
            })
            tasks.forEach((task) => {
                week[getDay(new Date(task.createdDate))].numCreatedTasks++
            })

            const completedByDay = week.map((day) => day.numCompTasks)
            const createdByDay = week.map((day) => day.numCreatedTasks)

            setMostProductiveDay(
                week[completedByDay.indexOf(Math.max(...completedByDay))].day
            )
            setBusiestDay(
                week[createdByDay.indexOf(Math.max(...createdByDay))].day
            )
        }
    }, [tasks, completedTasks, isNewUser])

    const stats = [
        {
            name: 'Day with the most completed tasks',
            value: mostProductiveDay,
            color: theme.colors.teal[500],
        },
        {
            name: 'Day with the most created tasks',
            value: busiestDay,
            color: theme.colors.teal[500],
        },
    ]

    return <ChartCard title="Productivity Trends" stats={stats} isPdf={isPdf} />
}
