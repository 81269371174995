import React, { useState, useEffect } from 'react'
import OOModal from '../SharedComponents/OOModal'
import { FormLabel, Flex, Text, Button } from '@chakra-ui/react'
import { apiCall } from '../Util/api'
import { CircleCheckIcon } from '../ChakraDesign/Icons'
import { useToast } from '@chakra-ui/react'
import ToastyBoi from '../SharedComponents/ToastyBoi'

export default function EditSubscriberModal({
    user,
    subscriberToEdit,
    setSubscribers,
    title,
    isOpen,
    onClose,
    handleSubmit,
    setEditSubscriberModal,
}) {
    const toaster = useToast()
    const [subscription, setSubscription] = useState('')

    useEffect(() => {
        getSubscription()
    }, [])

    const getSubscription = () => {
        if (
            subscriberToEdit.stripeProductId ===
            process.env.REACT_APP_PERSONAL_SUBSCRIPTION_IDs
        ) {
            setSubscription('Personal')
        } else if (
            subscriberToEdit.stripeProductId ===
            process.env.REACT_APP_PROFESSIONAL_SUBSCRIPTION_IDs
        ) {
            setSubscription('Professional')
        } else {
            setSubscription('Free')
        }
    }

    const manageSubscription = async () => {
        const data = await apiCall('POST', '/stripe/create-portal-session', {
            stripeCustomerId: subscriberToEdit.stripeCustomerId,
        })
        window.open(data.url, '_blank').focus()
    }

    const handleUpgradeClick = async (v) => {
        console.log(v)
        const data = await apiCall('POST', '/stripe/create-checkout-session', {
            lookup_key: v,
            stripeCustomerId: subscriberToEdit.stripeCustomerId,
        })
        window.location.href = data.url
    }

    const removeUserFromTeam = async () => {
        const newSubscribers = await apiCall(
            `DELETE`,
            `/users/${user._id}/teamMembers/${subscriberToEdit._id}`
        )

        setSubscribers(newSubscribers)
        setEditSubscriberModal(false)
        toaster({
            status: 'success',
            duration: 3000,
            isClosable: true,
            render: () => (
                <ToastyBoi
                    message={`${subscriberToEdit.name} removed from team`}
                    icon={<CircleCheckIcon fill="white" />}
                    backgroundColor="blue.500"
                />
            ),
        })
    }

    return (
        <OOModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title={title}
        >
            <FormLabel mt="8px" fontWeight="bold">
                User Information
            </FormLabel>
            <Flex direction="column" pl="16px">
                <Flex>
                    <Text fontWeight="bold">Full Name:</Text>
                    <Text ml="8px">{subscriberToEdit.name}</Text>
                </Flex>
                <Flex>
                    <Text fontWeight="bold">Email:</Text>
                    <Text ml="8px">{subscriberToEdit.email}</Text>
                </Flex>
                <Flex>
                    <Text fontWeight="bold">Subscription Type:</Text>
                    <Text ml="8px">{subscription}</Text>
                </Flex>
            </Flex>
            <FormLabel mt="8px" fontWeight="bold">
                Manage
            </FormLabel>
            <Flex direction="column" p="0px 16px">
                <Button mt="8px" onClick={() => manageSubscription()}>
                    {`Manage ${
                        subscriberToEdit.name.split(' ')[0]
                    }'s Subscription`}
                </Button>
                <Button mt="8px" onClick={() => removeUserFromTeam()}>
                    {`Remove ${
                        subscriberToEdit.name.split(' ')[0]
                    } from subscribers`}
                </Button>
            </Flex>
            {subscription !== 'Professional' && (
                <>
                    <FormLabel mt="8px" fontWeight="bold">
                        Upgrades
                    </FormLabel>
                    <Flex direction="column" p="0px 16px">
                        {subscription === 'Free' && (
                            <Button
                                colorScheme="blue"
                                onClick={() =>
                                    handleUpgradeClick(
                                        process.env.REACT_APP_IND_PRICE_KEY
                                    )
                                }
                            >
                                upgrade to personal
                            </Button>
                        )}
                        {(subscription === 'Free' ||
                            subscription === 'Personal') && (
                            <Button
                                colorScheme="blue"
                                mt="8px"
                                onClick={() =>
                                    handleUpgradeClick(
                                        process.env.REACT_APP_PRO_PRICE_KEY
                                    )
                                }
                            >
                                upgrade to professional
                            </Button>
                        )}
                    </Flex>
                </>
            )}
        </OOModal>
    )
}
