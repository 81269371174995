import React, { useState } from 'react'
import { Flex } from '@chakra-ui/react'
import IntegrationsHelper from '../../Tasks/IntegrationsHelper'

export default function Integrations({ user }) {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <Flex mt="48px" w="100%">
            <IntegrationsHelper
                user={user}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                isProfile={true}
            />
        </Flex>
    )
}
