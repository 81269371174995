import React, { useState } from 'react'
import { Switch, FormControl, FormLabel, useToast } from '@chakra-ui/react'
import { analytics, apiCall } from '../Util/api'
import OOModal from '../SharedComponents/OOModal'
import RecurrenceForm, { blankForm } from '../Tasks/TaskCard/RecurrenceForm'
import ToastyBoi from '../SharedComponents/ToastyBoi'
import { CircleCheckIcon } from '../ChakraDesign/Icons'

export default function RecurrenceModal({
    user,
    isOpen,
    onClose,
    recurrence,
    recurringTasks,
    setRecurringTasks,
}) {
    const toast = useToast()
    const [addTasksOnDay, setAddtasksOnDay] = useState(recurrence.daysInAdvance)
    const [rrule, setRrule] = useState({
        ...blankForm,
    })

    const [removeExistingRecurrences, setRemoveExistingRecurrences] =
        useState(false)

    const handleSubmit = async () => {
        //if the task is recurring, make recurrence calculations
        let ruleParams = { ...rrule }

        for (let key in ruleParams) {
            if (ruleParams[key] === '' || ruleParams[key] === null) {
                delete ruleParams[key]
            }
        }

        if (ruleParams.freq === 2 && ruleParams.byweekday.length === 0) {
            alert('Please select at least one day of the week')
            return
        }

        try {
            await apiCall(
                'PUT',
                `/users/${user._id}/recurrence/${recurrence._id}?removeExistingRecurrences=${removeExistingRecurrences}`,
                {
                    rruleParams: ruleParams,
                    daysInAdvance: Number(addTasksOnDay),
                }
            )

            toast({
                duration: 3000,
                render: () => (
                    <ToastyBoi
                        message={'Recurrence updated.'}
                        icon={<CircleCheckIcon fill="white" />}
                        backgroundColor="blue.500"
                    ></ToastyBoi>
                ),
            })
        } catch (error) {
            alert(JSON.stringify(error))
        }
    }

    const handleRemoveRecurrence = async () => {
        try {
            await apiCall(
                'DELETE',
                `/users/${user._id}/recurrence/${recurrence._id}`
            )

            setRecurringTasks(
                [...recurringTasks].filter((t, i) => t._id != recurrence._id)
            )

            toast({
                duration: 3000,
                render: () => (
                    <ToastyBoi
                        message={'Recurrence removed.'}
                        icon={<CircleCheckIcon fill="white" />}
                        backgroundColor="blue.500"
                    ></ToastyBoi>
                ),
            })
        } catch (error) {}
    }

    return (
        <OOModal
            title="Edit a recurrence"
            onSubmit={handleSubmit}
            secondaryButton={
                recurrence._id && {
                    onClick: handleRemoveRecurrence,
                    text: 'end recurrence',
                }
            }
            isOpen={isOpen}
            onClose={onClose}
        >
            {Boolean(recurrence._id) && (
                <RecurrenceForm
                    recurrenceId={recurrence._id}
                    userId={user._id}
                    date={new Date(recurrence.rruleParams.dtstart)}
                    rrule={rrule}
                    setRrule={setRrule}
                    addTasksOnDay={addTasksOnDay}
                    setAddtasksOnDay={setAddtasksOnDay}
                />
            )}
            {Boolean(recurrence._id) && (
                <FormControl
                    display="flex"
                    alignItems="center"
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setRemoveExistingRecurrences(true)
                    }}
                    mt="16px"
                >
                    <FormLabel mb="0" fontSize="sm" fontWeight="bold">
                        Replace existing recurrences?
                    </FormLabel>
                    <Switch isChecked={removeExistingRecurrences} mr="16px" />
                </FormControl>
            )}
        </OOModal>
    )
}
