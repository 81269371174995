import { differenceInCalendarDays, startOfWeek, endOfWeek } from 'date-fns'
const TODAY = new Date()

export const getWeekNum = (completionDate, startDate) => {
    return (
        Math.ceil(
            (differenceInCalendarDays(completionDate, startDate) + 1) / 7
        ) - 1
    )
}

export const isThisWeek = (date) => {
    // Get start and end of current week
    const weekStart = startOfWeek(TODAY)
    const weekEnd = endOfWeek(TODAY)

    // Return true if passed in date is in the current week
    if (date >= weekStart && date <= weekEnd) {
        return true
    } else {
        return false
    }
}

export const getNumWeeks = (startDate, endDate) => {
    return Math.ceil((differenceInCalendarDays(endDate, startDate) + 1) / 7)
}
