export const URGENCIES = [
    {
        emoji: '🤷‍♀️',
        text: 'Pending',
    },
    {
        emoji: '🏃‍♀️',
        text: 'Now',
    },
    {
        emoji: '🚶‍♀️',
        text: 'Soon',
    },
    {
        emoji: '🥱',
        text: 'Later',
    },
]
