import React from 'react'
import { Text } from '@chakra-ui/react'

export default function EmptyInboxBoi() {
    const prompt = (
        <Text fontSize="md" color="grey.900" fontWeight="bold">
            Looks like your inbox is empty!
            <br />
            <br />
            Copy your personal link above and send it to your executive or
            co-workers.
            <br />
            From this link they can submit personalized tasks directly to you,
            pending your approval.
            <br />
            <br />
            Want a different personal link? You can customize it in your inbox
            settings!
        </Text>
    )

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '10vh',
            }}
        >
            {prompt}
        </div>
    )
}
