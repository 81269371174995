import React, { useState, useEffect } from 'react'
import TagCloud from 'react-tag-cloud'
import randomColor from 'randomcolor'
import { memo } from 'react'
import ChartCard from './Utils/ChartCard'
import theme from '../../ChakraDesign/theme'

function WordCloud({ tasks, isNewUser, isPdf }) {
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        let wordCloudData = []

        if (isNewUser) {
            wordCloudData = [
                { value: 'checklist' },
                { value: 'employee' },
                { value: 'interview' },
                { value: 'first' },
                { value: 'start' },
                { value: 'due' },
                { value: 'offboarding' },
                { value: 'scheduling' },
                { value: 'submit' },
                { value: 'expenses' },
                { value: 'onboarding' },
                { value: 'overview' },
                { value: 'new' },
                { value: 'mailing' },
                { value: 'shipping' },
                { value: 'packages' },
                { value: 'hire' },
                { value: 'name' },
                { value: 'date' },
                { value: 'protocol' },
            ]
        } else {
            tasks.forEach((t) => {
                const words = t.description.replace(/<[^>]*>?/gm, '').split(' ')
                words.forEach((word) => {
                    if (word.length > 4) {
                        if (
                            wordCloudData
                                .map((datum) => datum.value)
                                .includes(word.toLowerCase())
                        ) {
                            wordCloudData[
                                wordCloudData
                                    .map((datum) => datum.value)
                                    .indexOf(word.toLowerCase())
                            ].count++
                        } else {
                            wordCloudData.push({
                                value: word.toLowerCase(),
                                count: 1,
                            })
                        }
                    }
                })
            })
            wordCloudData.sort((a, b) => b.count - a.count)
            wordCloudData.splice(20)
        }
        wordCloudData.forEach((datum, index) => {
            if (index === 0) {
                wordCloudData[index].fontSize = 65
            } else if (index < 5) {
                wordCloudData[index].fontSize = 35
            } else if (index < 10) {
                wordCloudData[index].fontSize = 30
            } else if (index < 15) {
                wordCloudData[index].fontSize = 20
            } else if (index < 20) {
                wordCloudData[index].fontSize = 15
            } else {
                wordCloudData[index].fontSize = 10
            }
        })
        setFilteredData(wordCloudData)
    }, [tasks])

    return (
        <ChartCard
            title="Keyword Highlights"
            description="Most frequently used keywords among tasks completed"
            isPdf={isPdf}
        >
            {filteredData.length > 0 && (
                <TagCloud
                    style={{
                        fontFamily: theme.fonts.body,
                        fontWeight: 'bold',
                        fontSize: 28,
                        color: () =>
                            randomColor({
                                hue: theme.colors.grey[900],
                            }),
                        padding: 8,
                        width: '90%',
                        height: isPdf ? 280 : 300,
                    }}
                >
                    {filteredData.map((datum) => (
                        <span style={{ fontSize: datum.fontSize }}>
                            {datum.value}
                        </span>
                    ))}
                </TagCloud>
            )}
        </ChartCard>
    )
}

export default memo(WordCloud)
