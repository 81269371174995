import React, { useEffect, useContext } from 'react'
import { analytics } from '../Util/api'
import { UserContext } from '../Contexts/UserContext'
import { TasksContext } from '../Contexts/TasksContext'
import { LabelsContext } from '../Contexts/LabelsContext'
import { Box, Flex, Text, VStack } from '@chakra-ui/react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import TaskCard from './TaskCard/TaskCard'

export default function LabelsTaskList({ urgency }) {
    const { user } = useContext(UserContext)
    const { filteredLabels } = useContext(LabelsContext)
    const { tasks, setTasks } = useContext(TasksContext)

    useEffect(() => {
        analytics.trigger({ name: 'Labels Section Viewed', user })
    }, [])

    return (
        <Box
            height="calc(100vh - 136px)"
            overflow="auto"
            style={{
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 8,
                marginLeft: -8,
                marginRight: -8,
            }}
        >
            <DragDropContext>
                <Droppable droppableId="all-tasks">
                    {() => (
                        <VStack id="tasks-list">
                            {filteredLabels.map((label, i) => (
                                <Box width="100%">
                                    <Box
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Flex
                                            alignItems="center"
                                            marginRight="16px"
                                        >
                                            <Text
                                                color="blue.500"
                                                fontSize="md"
                                                fontWeight="bold"
                                                whiteSpace="nowrap"
                                                marginLeft="8px"
                                            >
                                                {label.name}
                                            </Text>
                                        </Flex>
                                        <hr
                                            style={{
                                                height: 1,
                                                backgroundColor: '#E5ECF6',
                                                border: '1px solid #E5ECF6',
                                                width: '100%',
                                                borderRadius: 16,
                                            }}
                                        />
                                    </Box>
                                    {tasks.map(
                                        (t, i) =>
                                            t.labels
                                                .map((label) => label.name)
                                                .includes(label.name) && (
                                                <TaskCard
                                                    task={t}
                                                    index={i}
                                                    key={t._id}
                                                    cards={tasks}
                                                    urgency={urgency}
                                                    disableDrag={true}
                                                    setCards={setTasks}
                                                />
                                            )
                                    )}
                                </Box>
                            ))}
                            )
                        </VStack>
                    )}
                </Droppable>
            </DragDropContext>
        </Box>
    )
}
