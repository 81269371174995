import React, { useState, useEffect, useContext } from 'react'
import { apiCall } from '../Util/api'
import { UserContext } from '../Contexts/UserContext'
import { LabelsContext } from '../Contexts/LabelsContext'
import RecurringTasksList from '../Recurrence/ReccurenceList'

export default function RecurrenceContainer() {
    // context variables
    const { user } = useContext(UserContext)
    const { getUsersLabels } = useContext(LabelsContext)

    // state variables
    const [recurringTasks, setRecurringTasks] = useState([])

    useEffect(() => {
        getRecurrences()
    }, [])

    const getRecurrences = async () => {
        let allRecurrences = await apiCall(
            'get',
            `/users/${user._id}/recurrence`
        )

        setRecurringTasks(allRecurrences)
    }

    const updateRecurringTask = async (id, data) => {
        try {
            let newRecurringTasks = [...recurringTasks]
            const updatedRecurrence = await apiCall(
                `PUT`,
                `/users/${user._id}/recurrence/${id}`,
                data
            )

            getUsersLabels()

            newRecurringTasks = newRecurringTasks.map((recurrence) => {
                if (recurrence._id === updatedRecurrence._id) {
                    return updatedRecurrence
                } else {
                    return recurrence
                }
            })
            setRecurringTasks(newRecurringTasks)
        } catch (error) {
            console.log(error)
            return false
        }
    }

    return (
        <RecurringTasksList
            user={user}
            recurringTasks={recurringTasks}
            setRecurringTasks={setRecurringTasks}
            updateRecurringTask={updateRecurringTask}
        />
    )
}
