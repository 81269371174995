import React from 'react'
import Navbar from '../../Navbar/Navbar'
import { Container, Grid, GridItem, Flex } from '@chakra-ui/react'

export default function ReportsLayout({ mobileMenu, main, isPdf }) {
    return (
        <div>
            <Container maxW="container.xl">
                {!isPdf && <Navbar mobileMenu={mobileMenu} />}
                <Flex direction="column" alignItems="center">
                    {main}
                </Flex>
            </Container>
        </div>
    )
}
