import React, { useState } from 'react'
import { Input, Kbd, Flex, Button } from '@chakra-ui/react'
import { AddTaskIcon } from '../ChakraDesign/Icons'

export default function CreateTemplateInput({ createTemplate }) {
    const [description, setDescription] = useState('')

    const submit = () => {
        createTemplate({ description })
        setDescription('')
    }
    return (
        <Flex w="100%" align="center">
            <AddTaskIcon color="grey.900" ml="-1px" mr="9px" />
            <Input
                placeholder="Type a template description here"
                size="lg"
                maxW="424px"
                height="32px"
                name="description"
                variant="unstyled"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onKeyDown={(e) => e.keyCode === 13 && submit()}
                autoComplete="off"
            />
            {description.length > 0 && (
                <Button colorScheme="blue" size="md" ml="16px" onClick={submit}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        submit{' '}
                        <Kbd color="black" ml="4px" borderColor="grey.800">
                            enter
                        </Kbd>
                    </span>
                </Button>
            )}
        </Flex>
    )
}
