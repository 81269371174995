import React, { useEffect, useState } from 'react'
import { Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'
import { differenceInHours } from 'date-fns/esm'
import ChartCard from './Utils/ChartCard'
import CustomTooltip from './Utils/CustomTooltip'
import theme from '../../ChakraDesign/theme'
import { Box } from '@chakra-ui/react'

export default function ResponseTimes({ completedTasks, isNewUser, isPdf }) {
    const colors = [
        theme.colors.blue[500],
        theme.colors.teal[500],
        theme.colors.grey[900],
        theme.colors.pink[500],
    ]
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        if (isNewUser) {
            const dummyData = [
                { value: 37, index: 0, tag: 'within 24 hours' },
                { value: 22, index: 1, tag: 'within 3 days' },
                { value: 12, index: 2, tag: 'within 1 week' },
                { value: 24, index: 3, tag: 'in over a week' },
            ]
            setFilteredData(dummyData)
        } else {
            if (completedTasks.length > 0) {
                let data = [
                    { value: 0, index: 0, tag: 'within 24 hours' },
                    { value: 0, index: 1, tag: 'within 3 days' },
                    { value: 0, index: 2, tag: 'within 1 week' },
                    { value: 0, index: 3, tag: 'in over a week' },
                ]
                completedTasks.forEach((t) => {
                    const hours = differenceInHours(
                        new Date(t.completionDate),
                        new Date(t.createdDate)
                    )
                    if (hours < 24) {
                        data[0].value++
                    } else if (hours < 72) {
                        data[1].value++
                    } else if (hours < 168) {
                        data[2].value++
                    } else {
                        data[3].value++
                    }
                })

                data.forEach((datum) => {
                    datum.value = Math.round(
                        (datum.value / completedTasks.length) * 100
                    )
                })

                setFilteredData(data)
            }
        }
    }, [completedTasks, isNewUser])

    const Circle = ({ color }) => (
        <Box
            style={{
                height: 16,
                width: 16,
                padding: 4,
                backgroundColor: color,
                borderRadius: 8,
            }}
        />
    )

    const stats = [
        {
            name:
                filteredData[0] &&
                `${filteredData[0].value}% completed within 24 hours`,
            value: <Circle color={colors[0]} />,
        },
        {
            name:
                filteredData[1] &&
                `${filteredData[1].value}% completed within 3 days`,
            value: <Circle color={colors[1]} />,
        },
        {
            name:
                filteredData[2] &&
                `${filteredData[2].value}% completed within 7 days`,
            value: <Circle color={colors[2]} />,
        },
        {
            name:
                filteredData[3] &&
                `${filteredData[3].value}% completed in over a week`,
            value: <Circle color={colors[3]} />,
        },
    ]

    const unitLabels = [
        {
            singular: 'of tasks completed',
            plural: 'of tasks completed',
        },
    ]

    return (
        <ChartCard
            title="Response Times"
            description="Time measured from task creation until task completion"
            stats={stats}
            isPdf={isPdf}
        >
            <ResponsiveContainer height={320} width="100%">
                <PieChart width={800} height={800}>
                    <Pie
                        dataKey="value"
                        isAnimationActive={!isPdf}
                        data={filteredData}
                        outerRadius={140}
                        cx="50%"
                        cy="45%"
                    >
                        {filteredData.map((datum, index) => (
                            <Cell
                                key={index}
                                fill={colors[index]}
                                fillOpacity={0.9}
                            />
                        ))}
                    </Pie>
                    <Tooltip
                        content={
                            <CustomTooltip
                                colors={colors}
                                unitLabels={unitLabels}
                                dateLabelType={'none'}
                                isPercentage={true}
                            />
                        }
                    />
                </PieChart>
            </ResponsiveContainer>
        </ChartCard>
    )
}
