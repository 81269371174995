import React from 'react'
import { Flex, Button, Text, Tooltip, useToast } from '@chakra-ui/react'
import { CopyIcon, CircleCheckIcon } from '../ChakraDesign/Icons'
import ToastyBoi from '../SharedComponents/ToastyBoi'

export default function InboxHeader({ vanityLink }) {
    const toast = useToast()
    return (
        <Flex
            justify="space-between"
            w="100%"
            align={{ base: 'start', sm: 'center' }}
            direction={{ base: 'column', sm: 'row' }}
        >
            <Flex justify="start">
                <Text fontSize="48px" display={{ base: 'none', md: 'flex' }}>
                    {'📥'}
                </Text>
                <Flex direction="column" justify="center" ml="16px">
                    <Flex>
                        <Text
                            fontSize="32px"
                            mr="8px"
                            display={{ sm: 'flex', md: 'none' }}
                        >
                            {'📥'}
                        </Text>
                        <Text
                            fontSize="24px"
                            fontWeight="bold"
                            mt={{ base: '4px', md: '0px' }}
                            lineHeight={{
                                base: '48px',
                                md: '36px',
                            }}
                        >
                            Inbox
                        </Text>
                    </Flex>
                    <Flex>
                        <Text>{`https://inbox.officeotter.com/${vanityLink}`}</Text>
                        <Tooltip label="Copy link" shouldWrapChildren>
                            <CopyIcon
                                ml="8px"
                                mt="2px"
                                cursor="pointer"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `${process.env.REACT_APP_INBOX_URL}/${vanityLink}`
                                    )
                                    // notify the user
                                    toast({
                                        duration: 3000,
                                        render: () => (
                                            <ToastyBoi
                                                message={'Link copied'}
                                                icon={
                                                    <CircleCheckIcon fill="white" />
                                                }
                                                backgroundColor="blue.500"
                                            />
                                        ),
                                    })
                                }}
                            />
                        </Tooltip>
                    </Flex>
                </Flex>
            </Flex>
            <Button
                size="lg"
                alignSelf={{ base: 'end', sm: '' }}
                onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_URL}/userprofile/inbox`)
                }
            >
                inbox settings
            </Button>
        </Flex>
    )
}
