import React, { useContext } from 'react'
import HeaderText from '../HeaderText'
import {
    Box,
    Flex,
    Image,
    Button,
    ModalFooter,
    Text,
    Checkbox,
} from '@chakra-ui/react'
import { UserContext } from '../../Contexts/UserContext'
import SubHeaderText from '../SubHeaderText'
import { TasksContext } from '../../Contexts/TasksContext'

export default function Step2({ step, setStep }) {
    const { user } = useContext(UserContext)
    const { createTask, tasks } = useContext(TasksContext)

    return (
        <>
            <Flex
                flexDir="column"
                width="100%"
                justfiyContent="center"
                alignItems="center"
            >
                <Box pl="24px" pr="24px">
                    <HeaderText>
                        Check your phone! You’ll have a message from us. Text
                        back something you need to get done today.
                    </HeaderText>
                    <SubHeaderText>
                        {tasks.length === 0
                            ? `Not seeing a text? Check your email and send a task back
                        to tasks@officeotter.com.`
                            : `
                        Nice, you now have the power of Office Otter in your pocket!`}
                    </SubHeaderText>
                </Box>
                {tasks.length === 0 && (
                    <Box maxWidth="560px" mt="16px">
                        <Flex width="100%" justifyContent="center">
                            <Image
                                src={
                                    'https://office-otter-production.s3.us-east-2.amazonaws.com/0514a030-5fb7-4cd3-af2f-a3029082a6eecomms%202.png'
                                }
                                height="auto"
                                width="auto"
                                maxHeight="300px"
                            ></Image>
                        </Flex>
                    </Box>
                )}
                {tasks.length > 0 && (
                    <Flex w="100%" justifyContent="center" pl="16px" pr="16px">
                        <Flex
                            p="24px"
                            width="90%"
                            alignItems="center"
                            justifyContent="space-between"
                            boxShadow="base"
                            borderRadius="8px"
                            mt="16px"
                        >
                            <Checkbox size="lg" isChecked={false} />
                            <Text
                                fontSize="lg"
                                fontWeight="400"
                                style={{
                                    textAlign: 'center',
                                    marginLeft: 16,
                                    marginRight: 32,
                                    overflowWrap: 'break-word',
                                    marginRight: 'auto',
                                }}
                            >
                                {tasks[0].description}
                            </Text>
                        </Flex>
                    </Flex>
                )}
            </Flex>
            <ModalFooter>
                {tasks.length === 0 ? (
                    <Button
                        onClick={async () => {
                            await createTask(
                                {
                                    description:
                                        '↔️ Connect Office Otter to your messaging platforms',
                                },
                                1
                            )
                            setStep(step + 1)
                        }}
                        color="primary"
                    >
                        Skip
                    </Button>
                ) : (
                    <Button onClick={() => setStep(step + 1)} color="primary">
                        Next
                    </Button>
                )}
            </ModalFooter>
        </>
    )
}
