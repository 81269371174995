import React from 'react'
import { VStack } from '@chakra-ui/react'
import LeftNavButton from '../SharedComponents/LeftNavButton'

export default function TemplatesNavLeft({
    sections,
    selectedSection,
    setSelectedSection,
}) {
    return (
        <VStack w="100%" justifyItems="center">
            {sections.map((section, index) => (
                <LeftNavButton
                    key={index}
                    left={section.left}
                    text={section.text}
                    right={section.right}
                    selected={selectedSection === index}
                    handleClick={() => setSelectedSection(index)}
                />
            ))}
        </VStack>
    )
}
