import React, { useState, useContext } from 'react'
import bell from '../../sounds/bell.mp3'
import ding from '../../sounds/ding.mp3'
import pop from '../../sounds/pop.mp3'
import waterDrop from '../../sounds/waterDrop.mp3'
import HeaderText from '../HeaderText'
import { UserContext } from '../../Contexts/UserContext'
import {
    Flex,
    Box,
    Button,
    RadioGroup,
    Radio,
    CircularProgress,
} from '@chakra-ui/react'
import { apiCall, analytics } from '../../Util/api'
import checkoff from '../checkoff.png'

export default function Step4({ step, setStep }) {
    const { user, setUser } = useContext(UserContext)
    const [isSubmitting, setIsSubmitting] = useState(false)
    // sound vars
    const sounds = ['off', 'bell', 'ding', 'pop', 'waterDrop']
    const [completeSound, setCompleteSound] = useState(user.completeSound)

    // play sound when clicked
    const playSound = async (completeSound) => {
        switch (completeSound) {
            case 'bell':
                await new Audio(bell).play()
                break

            case 'ding':
                await new Audio(ding).play()
                break

            case 'pop':
                await new Audio(pop).play()
                break

            case 'waterDrop':
                await new Audio(waterDrop).play()
                break

            default:
                break
        }
    }

    // submit handler
    const submit = async () => {
        setIsSubmitting(true)
        try {
            const newUser = await apiCall('PUT', `/users/${user._id}`, {
                completeSound: completeSound,
                isWalkthroughComplete: true,
            })
            setUser(newUser)
        } catch (error) {
            console.log(error)
        }

        const userInfo = await apiCall('POST', `/users/${user._id}/walkthrough`)
        setUser(userInfo)

        analytics.trigger({
            name: 'Walkthrough Completed',
            user: userInfo,
        })
        setStep(step + 1)
        setIsSubmitting(false)
    }

    return (
        <Flex direction="column" width="100%" p="24px">
            <HeaderText>
                And just for fun, select your customized completion sound.
            </HeaderText>
            <Flex justify="center" mt="24px" mb="24px">
                <Box>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            style={{
                                width: '240px',
                                marginRight: 8,
                                marginTop: 8,
                            }}
                            src={checkoff}
                        />
                    </div>
                </Box>
                <RadioGroup
                    value={completeSound}
                    onChange={(sound) => {
                        playSound(sound)
                        setCompleteSound(sound)
                    }}
                >
                    <Flex direction="column" align="start" ml="24px">
                        {sounds.map((sound) => (
                            <Radio size="lg" mt="8px" mb="8px" value={sound}>
                                {sound === 'waterDrop' ? 'water drop' : sound}
                            </Radio>
                        ))}
                    </Flex>
                </RadioGroup>
            </Flex>
            <Button
                size="md"
                alignSelf="end"
                colorScheme="blue"
                onClick={submit}
            >
                {isSubmitting ? (
                    <CircularProgress isIndeterminate size="16px" />
                ) : (
                    'Finish'
                )}
            </Button>
        </Flex>
    )
}
