export const USE_CASES = [
    {
        image: 'https://office-otter-production.s3.us-east-2.amazonaws.com/ce2ee403-d362-4864-864a-098b6357ad6aWalkthrough%20-%20communication%20integrations.png',
        title: 'Keep your tasks organized',
        followUpImg:
            'https://office-otter-production.s3.us-east-2.amazonaws.com/0514a030-5fb7-4cd3-af2f-a3029082a6eecomms%202.png',
        followUpMessage:
            'Office Otter integrates seamlessly with Slack, Email, Text, and Chrome. If you want support, we created demos and guides just for you!',
    },
    {
        image: 'https://office-otter-production.s3.us-east-2.amazonaws.com/c813ef76-d385-438e-96d3-e7dd13cfcc5fWalkthrough%20Community.png',
        title: 'Connect with a community of Admins',
        followUpImg:
            'https://office-otter-production.s3.us-east-2.amazonaws.com/f1b784b2-7619-4831-a90a-280988c6c946community%202.png',
        followUpMessage:
            'Learn, support, be empowered through our community events every week.',
    },
    {
        image: 'https://office-otter-production.s3.us-east-2.amazonaws.com/2fb5c2ae-2fe4-4f82-93d2-48b3bd9484b8Walkthrough%20to%20do%20list.png',
        title: 'School and Homework',
        followUpImg:
            'https://office-otter-production.s3.us-east-2.amazonaws.com/720b06be-3131-44d9-945c-7c460a960342walkthrough%20-%20general%20task%20management.png',
        followUpMessage:
            'Office Otter reduces your mental load and helps you stay organized.',
    },
    {
        image: 'https://office-otter-production.s3.us-east-2.amazonaws.com/a2106e21-81f5-4870-986c-b78d9f218401walkthrough%20-%20reports.png',
        title: 'Performance Reports to prove my value',
        followUpImg:
            'https://office-otter-production.s3.us-east-2.amazonaws.com/652d9b18-ae4a-4cff-bbe4-cbd37c7cdea8walkthrough%20reports.png',
        followUpMessage:
            'Office Otter automatically tracks dozens of performance metrics for your next raise or promotion.',
    },
]

export const BENEFITS = [
    {
        image: '📲',
        title: 'Texts',
    },
    {
        image: '📫',
        title: 'Emails',
    },
    {
        image: (
            <img
                style={{ marginTop: 4, marginBottom: 16 }}
                width="48px"
                src="https://office-otter-production.s3.us-east-2.amazonaws.com/6d171b12-7ffe-42a2-9020-64ceea46f06bSlack-logo.jpg"
            />
        ),
        title: 'Slack Messages',
    },

    {
        image: (
            <img
                style={{ marginTop: 4, marginBottom: 16 }}
                width="48px"
                src="https://office-otter-production.s3.us-east-2.amazonaws.com/9727deb9-9e9a-4dab-8bae-03b2703a7d23teamslogo.png"
            />
        ),
        title: 'Teams messages',
    },
]
