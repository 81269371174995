import React, { useContext } from 'react'
import { apiCall } from '../Util/api'
import { UserContext } from '../Contexts/UserContext'
import { TasksContext } from '../Contexts/TasksContext'
import { LabelsContext } from '../Contexts/LabelsContext'
import { CircleCheckIcon } from '../ChakraDesign/Icons'
import { Box, VStack, useToast } from '@chakra-ui/react'
import ToastyBoi from '../SharedComponents/ToastyBoi'
import InboxCard from './InboxCard'
import EmptyInboxBoi from './EmptyInboxBoi'

export default function InboxList({ inbox, setInbox }) {
    const toast = useToast()

    // context
    const { user } = useContext(UserContext)
    const { createTask } = useContext(TasksContext)
    const { getUsersLabels } = useContext(LabelsContext)

    // update inbox functions
    const acceptTask = async (task) => {
        // grab task data
        const taskData = {
            description: task.description,
            checklist: task.checklist,
            notes: task.notes,
            due: task.due,
            files: task.files,
            requester: task.requester,
            requestedTask: task._id,
            urgency: 1,
        }

        // update status of inbox task in the db
        apiCall(`PUT`, `/requesters/${user._id}/tasks/${task._id}`, {
            status: 'accepted',
        })

        // update state to filter out accepted task
        let newInbox = [...inbox]
        newInbox = newInbox.filter((t) => t._id !== task._id)
        setInbox(newInbox)

        // create the task and update labels
        await createTask(taskData)
        getUsersLabels()

        // notify the user
        toast({
            duration: 3000,
            render: () => (
                <ToastyBoi
                    message={'Task moved into 🏃‍♀️ Now'}
                    icon={<CircleCheckIcon fill="white" />}
                    backgroundColor="blue.500"
                />
            ),
        })
    }

    const denyTask = (taskId) => {
        // update db
        apiCall(`PUT`, `/requesters/${user._id}/tasks/${taskId}`, {
            status: 'denied',
        })

        // update state
        let newInbox = [...inbox]
        newInbox = newInbox.filter((t) => t._id !== taskId)
        setInbox(newInbox)

        // notify user
        toast({
            duration: 3000,
            render: () => (
                <ToastyBoi
                    message={'Task denied'}
                    icon={<CircleCheckIcon fill="white" />}
                    backgroundColor="blue.500"
                />
            ),
        })
    }
    return (
        <Box
            pl="8px"
            pr="8px"
            pb="8px"
            ml="-8px"
            mr="-8px"
            maxH="75vh"
            overflow="auto"
        >
            <VStack align="start">
                {inbox?.map((task, index) => (
                    <InboxCard
                        key={task._id}
                        task={task}
                        acceptTask={acceptTask}
                        denyTask={denyTask}
                    />
                ))}
                {inbox.length === 0 && <EmptyInboxBoi />}
            </VStack>
        </Box>
    )
}
