import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../Contexts/UserContext'
import { apiCall, analytics } from '../Util/api'
import { format } from 'date-fns'
import { Box, Text, Flex, Button, Checkbox, VStack } from '@chakra-ui/react'
import theme from '../ChakraDesign/theme'
import logo from '../Navbar/logo.png'
import { getHoursMinutesFormattedFromTimeLog } from '../Tasks/TimeKeepingModal'
import { URGENCIES } from '../Util/urgencies'
const TODAY = new Date()

export default function WrapUp() {
    const { user } = useContext(UserContext)

    // grab dates form url
    const startDate = new Date(
        decodeURI(window.location.search.split('&')[1].split('=')[1])
    )
    const endDate = new Date(
        decodeURI(window.location.search.split('&')[2].split('=')[1])
    )
    const [completedTasks, setCompletedTasks] = useState([])
    const [labels, setLabels] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    //grab sort by urgency or label from url
    const sortBy = window.location.search.split('?')[1].split('&')[0]
    //grab what urgencies are selected from url
    const selectedSections = window.location.search
        .split('&')[3]
        .split('=')[1]
        .split(',')
        .map((item) => (item === 'true' ? true : false))

    console.log(selectedSections)
    useEffect(() => {
        getCompletedTasks()
        analytics.trigger({ name: 'Wrap Up Viewed', user })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCompletedTasks = async () => {
        //get all tasks from designated sections
        setIsLoading(true)
        let tasks = []
        try {
            const uncompletedTasks = await apiCall(
                'get',
                `/users/${user._id}/tasks`
            )
            const filteredUncompleted = uncompletedTasks.filter(
                (t) => selectedSections[t.urgency] === true
            )

            tasks.push(...filteredUncompleted)

            if (selectedSections[4]) {
                const completedTasks = await apiCall(
                    'get',
                    `/users/${user._id}/tasks/completed/range?startDate=${startDate}&endDate=${endDate}&subscription=${user.stripeProductId}`
                )
                tasks.push(...completedTasks)
            }

            setCompletedTasks(tasks)
            setLabels([
                ...new Set(
                    tasks
                        .map((t) => t.labels)
                        .flat()
                        .map((l) => l.name)
                ),
            ])
        } catch (error) {
            alert(error)
        }

        setIsLoading(false)
    }

    const WrapUpTaskCard = ({ task }) => {
        const timeLog = getHoursMinutesFormattedFromTimeLog(
            task.timeLog
        ).replace(/\s/g, '')
        return (
            <Box
                w="720px"
                marginBottom="16px"
                border="1px solid #b1bdd1"
                borderRadius="16px"
                padding="16px"
            >
                <Box w="100%" minHeight="48px">
                    <Flex alignItems="flex-start">
                        <Checkbox
                            marginRight="16px"
                            isChecked={task.isCompleted}
                            size="lg"
                        />
                        <Text fontSize="18px" marginTop="-2px">
                            {task.description.replace(/<\/?[^>]+(>|$)/g, '')}
                        </Text>
                    </Flex>
                    <Box paddingLeft="36px" paddingRight="8px">
                        <Box mt="8px">
                            <Text fontSize="md" textColor="grey.900">
                                Notes
                            </Text>
                            <hr
                                style={{
                                    borderTopWidth: '2px',
                                    height: '2px',
                                    borderRadius: '16px',
                                    borderColor: '#edf1f7', //light.400
                                    marginTop: '2px',
                                    marginBottom: '4px',
                                }}
                            />
                            <Text>
                                {task.notes
                                    .join('')
                                    ?.replace(/<\/?[^>]+(>|$)/g, '')}
                            </Text>
                        </Box>
                        <Box mt="8px">
                            <Text fontSize="md" textColor="grey.900">
                                Checklist
                            </Text>
                            <hr
                                style={{
                                    borderTopWidth: '2px',
                                    height: '2px',
                                    borderRadius: '16px',
                                    borderColor: '#edf1f7', //light.400
                                    marginTop: '2px',
                                    marginBottom: '4px',
                                }}
                            />
                            {task.checklist.map((item) => (
                                <Flex
                                    justifyContent="flex-start"
                                    w="100%"
                                    alignItems="center"
                                >
                                    <Checkbox
                                        marginRight="16px"
                                        isChecked={item.complete}
                                        size="lg"
                                    />
                                    <Box w="100%">
                                        <Text>{item.name}</Text>
                                    </Box>
                                </Flex>
                            ))}
                        </Box>
                    </Box>
                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        mt="16px"
                    >
                        <Flex alignItems="center">
                            <Button
                                height="40px"
                                display="flex"
                                alignItems="center"
                                fontSize="md"
                                color="blue.500"
                                justifyContent="flex-start"
                            >
                                <span
                                    style={{
                                        paddingRight: '12px',
                                        fontSize: '20px',
                                    }}
                                >
                                    {task.isCompleted
                                        ? '✅'
                                        : URGENCIES[task.urgency].emoji}
                                </span>
                                {task.isCompleted
                                    ? 'completed'
                                    : URGENCIES[task.urgency].text}

                                <Text ml="4px" fontWeight="500" fontSize="md">
                                    {task.isCompleted &&
                                        format(
                                            new Date(task.completionDate),
                                            'MMM d'
                                        )}
                                </Text>
                            </Button>
                        </Flex>
                        <Box>
                            <Flex flexWrap="wrap">
                                {task.labels.map(
                                    (label, index) =>
                                        label.name != '⌛' && (
                                            <Button
                                                variant="chip-blue"
                                                key={index}
                                                mt="2px"
                                                mb="2px"
                                            >
                                                {label.name}
                                            </Button>
                                        )
                                )}
                                {timeLog !== '0:00' && (
                                    <Button
                                        variant="chip-grey"
                                        mt="2px"
                                        mb="2px"
                                    >
                                        ⌛️
                                        {timeLog}
                                    </Button>
                                )}
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        )
    }

    const SortByLabel = () => {
        return (
            <VStack>
                {labels.map((label, i) => (
                    <Box width="100%">
                        <Box
                            style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            marginBottom="8px"
                        >
                            <Flex alignItems="center" marginRight="16px">
                                <Text
                                    color="blue.500"
                                    fontSize="md"
                                    fontWeight="bold"
                                    whiteSpace="nowrap"
                                    marginLeft="8px"
                                >
                                    {label}
                                </Text>
                            </Flex>
                            <hr
                                style={{
                                    height: 1,
                                    backgroundColor: '#E5ECF6',
                                    border: '1px solid #E5ECF6',
                                    width: '100%',
                                    borderRadius: 16,
                                }}
                            />
                        </Box>
                        {completedTasks.map(
                            (t, i) =>
                                t.labels.map((l) => l.name).includes(label) && (
                                    <WrapUpTaskCard task={t} />
                                )
                        )}
                    </Box>
                ))}
                <Box
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    marginBottom="8px"
                >
                    <Flex alignItems="center" marginRight="16px">
                        <Text
                            color="blue.500"
                            fontSize="md"
                            fontWeight="bold"
                            whiteSpace="nowrap"
                            marginLeft="8px"
                        >
                            no label
                        </Text>
                    </Flex>
                    <hr
                        style={{
                            height: 1,
                            backgroundColor: '#E5ECF6',
                            border: '1px solid #E5ECF6',
                            width: '100%',
                            borderRadius: 16,
                        }}
                    />
                </Box>
                {completedTasks.map(
                    (t, i) =>
                        t.labels.length === 0 && <WrapUpTaskCard task={t} />
                )}
            </VStack>
        )
    }

    const SortByUrgency = () => {
        console.log('hello')
        return (
            <VStack>
                {[...URGENCIES, { emoji: '✅', text: 'Completed' }].map(
                    (urgency, index) =>
                        selectedSections[index] && (
                            <Box width="100%">
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    marginBottom="8px"
                                >
                                    <Flex
                                        alignItems="center"
                                        marginRight="16px"
                                    >
                                        <Text>{urgency.emoji}</Text>
                                        <Text
                                            color="blue.500"
                                            fontSize="md"
                                            fontWeight="bold"
                                            whiteSpace="nowrap"
                                            marginLeft="8px"
                                        >
                                            {urgency.text}
                                        </Text>
                                    </Flex>
                                    <hr
                                        style={{
                                            height: 1,
                                            backgroundColor: '#E5ECF6',
                                            border: '1px solid #E5ECF6',
                                            width: '100%',
                                            borderRadius: 16,
                                        }}
                                    />
                                </Box>
                                {completedTasks.map(
                                    (t, i) =>
                                        t.urgency === index &&
                                        !t.isCompleted && (
                                            <WrapUpTaskCard task={t} />
                                        )
                                )}
                                {completedTasks.map(
                                    (t, i) =>
                                        index === 4 &&
                                        t.isCompleted && (
                                            <WrapUpTaskCard task={t} />
                                        )
                                )}
                            </Box>
                        )
                )}
            </VStack>
        )
    }

    return (
        <Flex flexDirection="column" alignItems="center">
            <Box width="768px" padding="0px 24px 0px 24px">
                <Flex justify="space-between" mb="16px">
                    <Flex alignItems="center">
                        <Flex
                            alignSelf="center"
                            maxW="48px"
                            maxH="48px"
                            ml="-8px"
                        >
                            <img src={logo} style={{ maxWidth: '100%' }} />
                        </Flex>
                        <Text
                            display="flex"
                            fontFamily={theme.fonts.heading}
                            fontSize="30px"
                            fontWeight="bold"
                            letterSpacing="-0.75px"
                            pl="8px"
                            alignSelf="center"
                        >
                            office otter
                        </Text>
                    </Flex>
                    <Flex justify="flex-end" alignItems="end" flexDir="column">
                        <Flex
                            fontSize="24px"
                            fontWeight="bold"
                            alignItems="center"
                        >
                            {user.name}
                        </Flex>
                        <Text
                            color={theme.colors.grey[900]}
                            fontSize="16px"
                            fontWeight="bold"
                        >
                            {format(startDate, 'MMM d y')} -{' '}
                            {format(endDate, 'MMM d y')}
                        </Text>
                    </Flex>
                </Flex>
                {!isLoading && (
                    <Flex justify="start" align="start" direction="column">
                        {sortBy === 'label' && <SortByLabel />}
                        {sortBy === 'urgency' && <SortByUrgency />}
                    </Flex>
                )}
            </Box>
        </Flex>
    )
}
