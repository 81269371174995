import React, { useContext } from 'react'
import { UserContext } from '../Contexts/UserContext'
import { Flex, Text, Progress, Radio } from '@chakra-ui/react'
import { apiCall, analytics } from '../Util/api'
import { isPaidUser } from '../Util/permissions'
import { PaidPopUpContext } from '../Contexts/PaidPopupContext'

export default function Poll({ description, options, _id, posts, setPosts }) {
    const { user } = useContext(UserContext)
    const { setPaidPopup } = useContext(PaidPopUpContext)

    const voteForPollOption = async (optionId) => {
        if (isPaidUser(user)) {
            const newOption = await apiCall(
                'PUT',
                `/users/${user._id}/post/${_id}/option/${optionId}`,
                {
                    votes: options
                        .filter((o) => o._id === optionId)[0]
                        .votes.includes(user._id)
                        ? options
                              .filter((o) => o._id === optionId)[0]
                              .votes.filter((l) => l !== user._id)
                        : [
                              ...options.filter((o) => o._id === optionId)[0]
                                  .votes,
                              user._id,
                          ],
                }
            )

            analytics.trigger({
                name: 'Poll Voted On',
                user,
            })

            console.log(newOption)
            const newPosts = posts.map((p) =>
                p._id === _id
                    ? {
                          ...p,
                          options: options.map((o) =>
                              o._id === optionId ? newOption : o
                          ),
                      }
                    : p
            )
            setPosts(newPosts)
        } else {
            setPaidPopup({
                show: true,
                reason: 'Oops! It looks like you hit a limit on your free plan 😢. Upgrade to vote on a poll.',
            })
        }
    }

    return (
        <Flex flexDir="column" w="100%">
            <Text fontSize="30px" fontWeight="bold">
                {description}
            </Text>
            <Flex flexDir="column" p="16px" width="100%">
                {/* if any poll option votes contain the user id, show results, otherwise show choices */}
                {options.map((option) => (
                    <Flex
                        mt="16px"
                        p="16px"
                        backgroundColor="gray.100"
                        borderRadius="16px"
                        justifyContent="space-between"
                        width="100%"
                        flexDir="row"
                        key={option._id}
                    >
                        <Flex
                            justifyContent="space-between"
                            w="100%"
                            alignItems="start"
                            flexDir="column"
                            p="0px 16px"
                        >
                            {options
                                .map((o) => o.votes)
                                .flat()
                                .includes(user._id) ? (
                                <Flex
                                    backgroundColor="gray.100"
                                    borderRadius="16px"
                                    justifyContent="space-between"
                                    width="100%"
                                    flexDir="column"
                                >
                                    <Text>{option.description}</Text>
                                    <Flex
                                        width="100%"
                                        alignItems="center"
                                        marginTop="0px !important"
                                        justifyContent="space-between"
                                    >
                                        <Progress
                                            value={
                                                option.votes.length > 0
                                                    ? (option.votes.length /
                                                          options.reduce(
                                                              (
                                                                  prevValu,
                                                                  currValu
                                                              ) =>
                                                                  currValu.votes
                                                                      .length +
                                                                  prevValu,
                                                              0
                                                          )) *
                                                      100
                                                    : 1
                                            }
                                            colorScheme="blue"
                                            borderRadius="16px"
                                            height="8px"
                                            width="100%"
                                            marginRight="16px"
                                            sx={{
                                                '& > div:first-child': {
                                                    transitionProperty: 'width',
                                                },
                                            }}
                                        />
                                        <Text
                                            fontSize="small"
                                            color="grey.900"
                                            fontWeight="bold"
                                            marginTop="0px"
                                        >
                                            {
                                                (
                                                    (option.votes.length /
                                                        options.reduce(
                                                            (
                                                                prevValu,
                                                                currValu
                                                            ) =>
                                                                currValu.votes
                                                                    .length +
                                                                prevValu,
                                                            0
                                                        )) *
                                                    100
                                                )
                                                    .toString()
                                                    .split('.')[0]
                                            }
                                            %
                                        </Text>
                                    </Flex>
                                </Flex>
                            ) : (
                                <Flex width="100%" flexDir="row">
                                    <Radio
                                        borderColor="grey.500"
                                        size="lg"
                                        onChange={() =>
                                            voteForPollOption(option._id)
                                        }
                                        mr="16px"
                                    ></Radio>
                                    <Text>{option.description}</Text>
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    )
}
