import React from 'react'
import { Button, Flex, Box, Tooltip, Text } from '@chakra-ui/react'

export default function LeftNavButton({
    selected,
    left,
    text,
    right,
    handleClick,
    badge,
    isZenMode,
}) {
    return (
        <Button
            variant="left-nav-button"
            bg={`${selected && 'blue.50'}`}
            textColor={`${selected && 'blue.500'}`}
            onClick={handleClick}
            maxWidth={isZenMode ? '56px' : 'none'}
        >
            <>
                <Box
                    position="absolute"
                    top="0px"
                    right="0px"
                    color={selected ? 'blue.500' : 'grey.900'}
                    h="18px"
                    minW="18px"
                    borderRadius="9px"
                    fontSize="12px"
                    p="0px 4px"
                    pt="1px"
                    alignItems="center"
                    justifyContent="center"
                    display={isZenMode ? 'flex' : { base: 'flex', lg: 'none' }}
                >
                    {right}
                </Box>
                <Flex
                    justifyContent={isZenMode ? 'center' : 'space-between'}
                    width="100%"
                >
                    <Tooltip label={isZenMode && text}>
                        <Box fontSize="22px" alignSelf="center">
                            {left}
                        </Box>
                    </Tooltip>
                    {!isZenMode && (
                        <Box
                            mr="auto"
                            ml={`${left.length > 0 && '16px'}`}
                            alignSelf="center"
                            display={{ base: 'none', lg: 'block' }}
                        >
                            {' '}
                            {text}
                        </Box>
                    )}
                    {!isZenMode && (
                        <Box
                            textColor={selected ? 'blue.500' : 'grey.900'}
                            alignSelf="center"
                            display={{ base: 'none', lg: 'block' }}
                        >
                            {right}
                            {badge && (
                                <div
                                    className="badge"
                                    style={{
                                        marginTop: '8px',
                                        marginRight: '8px',
                                    }}
                                />
                            )}
                        </Box>
                    )}
                </Flex>
            </>
        </Button>
    )
}
