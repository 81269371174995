import React from 'react'
import { Box } from '@chakra-ui/react'

export default function UseCaseCard({ id, image, title, onClick }) {
    return (
        <Box>
            <div
                style={{
                    backgroundColor: 'white',
                    height: 188,
                    width: 188,
                    borderRadius: 16,
                    margin: 8,
                    boxShadow: '0 8px 16px 0 rgba(56, 96, 165, 0.2)',
                }}
                id={id}
                className="hover"
                onClick={onClick}
            >
                <div
                    style={{
                        height: 188,
                        width: 188,
                        padding: 12,
                        backgroundSize: 'cover',
                        alignItems: 'end',
                        display: 'flex',
                        borderRadius: 16,
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.95)), url(${image})`,
                    }}
                >
                    <div
                        style={{
                            textAlign: 'left',
                            color: 'white',
                            fontWeight: '600',
                            paddingLeft: 8,
                            paddingBottom: 8,
                            lineHeight: 1.2,
                            textShadow: '2px 4px 4px rgba(0, 0, 0, 0.9)',
                            fontSize: '19px',
                        }}
                    >
                        {title}
                    </div>
                </div>
            </div>
        </Box>
    )
}
