import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../Contexts/UserContext'
import { LabelsContext } from '../Contexts/LabelsContext'
import { apiCall, analytics } from '../Util/api'
import { Flex, Center, Box } from '@chakra-ui/react'
import TasksLayout from '../Layouts/TasksLayout'
import TemplatesList from './TemplatesList'
import TemplatesNavLeft from './TemplatesNavLeft'
import TemplatesNavMobile from './TemplatesNavMobile'
import CreateTemplateInput from './CreateTemplateInput'

export default function TemplatesContainer({ setModalOpen }) {
    // context variables
    const { user } = useContext(UserContext)
    const { getUsersLabels } = useContext(LabelsContext)

    // state variables
    const [myTemplates, setMyTemplates] = useState([])
    const [discoverTemplates, setDiscoverTemplates] = useState([])
    const [selectedSection, setSelectedSection] = useState(0)

    // non-state variables
    const sections = [
        {
            left: '📝',
            text: 'My Templates',
            mobileText: 'My Templates',
            right: myTemplates.length,
        },
        {
            left: '🔎',
            text: 'Discover Templates',
            mobileText: 'Discover',
            right: discoverTemplates.length,
        },
    ]

    useEffect(() => {
        getUsersLabels()
        getMyTemplates()
        getDiscoverTemplates()
        analytics.trigger({ name: 'Templates Modal Viewed', user })
    }, [])

    const getMyTemplates = async () => {
        const templates = await apiCall(`GET`, `/users/${user._id}/templates`)
        templates.sort((a, b) => a.position - b.position)

        // update state and return fetched templates
        setMyTemplates(templates)
        return templates
    }

    const getDiscoverTemplates = async () => {
        const templates = await apiCall(
            `GET`,
            `/users/${user._id}/templates/fromOfficeOtter`
        )
        templates.sort((a, b) => a.position - b.position)

        // update state and return fetched templates
        setDiscoverTemplates(templates)
        return templates
    }

    const createTemplate = async (data) => {
        try {
            if (myTemplates.length === 0) {
                data.position = 1000
            } else {
                data.position = myTemplates[0].position - 1
            }

            const newTemplate = await apiCall(
                `POST`,
                `/users/${user._id}/templates`,
                data
            )
            newTemplate.openUp = true

            const newTemplates = [...myTemplates]
            newTemplates.unshift(newTemplate)

            setMyTemplates(newTemplates)

            return newTemplate
        } catch (error) {
            console.log(error)
        }
    }

    const updateTemplate = async (id, data) => {
        try {
            let newTemplates = [...myTemplates]
            const updatedTemplate = await apiCall(
                `PUT`,
                `/users/${user._id}/templates/${id}`,
                data
            )

            getUsersLabels()

            newTemplates = newTemplates.map((template) => {
                if (template._id === updatedTemplate._id) {
                    return updatedTemplate
                } else {
                    return template
                }
            })
            setMyTemplates(newTemplates)
        } catch (error) {
            console.log(error)
            return false
        }
    }

    const deleteTemplate = async (id) => {
        try {
            let newTemplates = [...myTemplates]
            await apiCall(`DELETE`, `/users/${user._id}/templates/${id}`)

            newTemplates = newTemplates.filter(
                (template) => template._id !== id
            )
            setMyTemplates(newTemplates)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <TasksLayout
            isTemplateModal
            left={
                <Box w="100%" mt={{ base: '', md: '40px', lg: '32px' }}>
                    <TemplatesNavLeft
                        sections={sections}
                        selectedSection={selectedSection}
                        setSelectedSection={setSelectedSection}
                    />
                </Box>
            }
            main={
                <Box w="100%" mt={{ base: '', md: '40px' }}>
                    <Center
                        display={{ base: 'flex', md: 'none' }}
                        alignSelf="center"
                    >
                        <TemplatesNavMobile
                            sections={sections}
                            selectedSection={selectedSection}
                            setSelectedSection={setSelectedSection}
                        />
                    </Center>
                    {selectedSection === 0 && (
                        <Flex
                            w="100%"
                            align="center"
                            pl="16px"
                            mt="11px"
                            justify="space-between"
                        >
                            <CreateTemplateInput
                                createTemplate={createTemplate}
                            />
                        </Flex>
                    )}
                    <TemplatesList
                        user={user}
                        myTemplates={myTemplates}
                        setModalOpen={setModalOpen}
                        getMyTemplates={getMyTemplates}
                        setMyTemplates={setMyTemplates}
                        createTemplate={createTemplate}
                        updateTemplate={updateTemplate}
                        deleteTemplate={deleteTemplate}
                        selectedSection={selectedSection}
                        discoverTemplates={discoverTemplates}
                    />
                </Box>
            }
        />
    )
}
