import React, { useContext } from 'react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Flex,
    Button,
    Tooltip,
} from '@chakra-ui/react'
import {
    sortTasksByDragAndDrop,
    sortTasksByCreationDate,
    sortTasksByDueDate,
} from './sortUtils'
import { analytics } from '../Util/api'
import { UserContext } from '../Contexts/UserContext'
import { TasksContext } from '../Contexts/TasksContext'
import { FiltersIcon, CloseIcon } from '../ChakraDesign/Icons'

const SORT_BY_FILTERS = [
    {
        name: 'drag and drop',
        value: 'dnd',
    },
    {
        name: 'label',
        value: 'label',
    },
    {
        name: 'due date (soonest first)',
        value: 'dueDateNewestFirst',
    },
    {
        name: 'due date (furthest first)',
        value: 'dueDateOldestFirst',
    },
    {
        name: 'creation date (newest first)',
        value: 'creationDateNewestFirst',
    },
    {
        name: 'creation date (oldest first)',
        value: 'creationDateOldestFirst',
    },
]

export default function TasksFilteringAndSorting() {
    const { user } = useContext(UserContext)
    const { tasks, setTasks, sortBy, setSortBy } = useContext(TasksContext)

    const handleSetSortBy = (sortType) => {
        analytics.trigger({
            name: 'Tasks Sorted',
            user,
            properties: {
                type: sortType,
            },
        })
        setSortBy(sortType)
        let list = [...tasks]
        let sortedList = []

        switch (sortType) {
            case 'dnd':
                sortedList = sortTasksByDragAndDrop(list)

                break
            case 'label':
                sortedList = list

                break
            case 'dueDateNewestFirst':
                sortedList = sortTasksByDueDate(list, 'newestFirst')

                break
            case 'dueDateOldestFirst':
                sortedList = sortTasksByDueDate(list, 'oldestFirst')

                break
            case 'creationDateNewestFirst':
                sortedList = sortTasksByCreationDate(list, 'newestFirst')

                break
            case 'creationDateOldestFirst':
                sortedList = sortTasksByCreationDate(list, 'oldestFirst')

                break

            default:
                break
        }

        setTasks(sortedList)
    }

    const SortByButton = React.forwardRef(({ children, ...rest }, ref) => (
        <span ref={ref} {...rest}>
            <Button
                rightIcon={<FiltersIcon />}
                height="32px"
                display="flex"
                alignItems="center"
                fontSize="sm"
                color="black"
                justifyContent="space-between"
            >
                {children}
            </Button>
        </span>
    ))
    return (
        <Flex
            id="sort and filter"
            justifyContent="flex-end"
            marginLeft={{ base: '0px', sm: '8px' }}
            width={{ base: '100%', sm: 'unset' }}
            marginTop={{ base: '16px', sm: '0px' }}
        >
            <Button
                variant="chip-grey"
                rightIcon={<CloseIcon height="16px" width="16px" />}
                onClick={() => handleSetSortBy('dnd')}
                display={{ base: 'none', md: 'flex' }}
            >
                {SORT_BY_FILTERS.filter((f) => f.value === sortBy)[0]?.name}
            </Button>
            <Menu isLazy>
                <Tooltip label="Sort tasks by a parameter">
                    <MenuButton as={SortByButton}>Sort by</MenuButton>
                </Tooltip>
                <MenuList>
                    {SORT_BY_FILTERS.map((f, i) => (
                        <MenuItem
                            onClick={() => handleSetSortBy(f.value)}
                            value={f.value}
                            key={i}
                        >
                            {f.name}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Flex>
    )
}
