import React from 'react'
import UploadButton from '../Tasks/TaskCard/UploadButton'
import { Tooltip, Button } from '@chakra-ui/react'

export default function CreateFileInput({ createFile }) {
    return (
        <UploadButton
            addFile={createFile}
            onUploadSuccess={() => console.log('successful upload')}
        >
            {/* <Tooltip label="Click to upload file"> */}
            <Button colorScheme="blue">Click to upload file</Button>
            {/* </Tooltip> */}
        </UploadButton>
    )
}
