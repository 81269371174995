import React, { useEffect, useState, useContext } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { apiCall } from '../Util/api'
import { LabelsContext } from '../Contexts/LabelsContext'
import {
    Box,
    Flex,
    Tooltip,
    Text,
    Button,
    IconButton,
    Spacer,
    Progress,
    VStack,
} from '@chakra-ui/react'
import {
    CarrotIcon,
    LabelIcon,
    AttachmentSlantIcon,
    LoopIcon,
} from '../ChakraDesign/Icons'
import AttachmentsList from '../Tasks/TaskCard/AttachmentsList'
import Description from '../Tasks/TaskCard/Description'
import Checklist from '../Tasks/TaskCard/Checklist'
import Notes from '../Tasks/TaskCard/Notes'
import UploadButton from '../Tasks/TaskCard/UploadButton'
import LabelModal from '../Tasks/LabelModal'
import RecurrenceModal from './RecurrenceModal'

export default function RecurrenceCard({
    user,
    index,
    cards,
    setCards,
    recurrence,
    updateRecurringTask,
    recurringTasks,
    setRecurringTasks,
}) {
    // state variables
    const [expanded, setExpanded] = useState(false)
    const [description, setDescription] = useState(recurrence.description)
    const [recurrenceLabels, setRecurrenceLabels] = useState(recurrence.labels)
    const [notes, setNotes] = useState(recurrence.notes.join('\n\n'))
    const [checklist, setChecklist] = useState(
        [...recurrence.checklist].sort((a, b) => a.position - b.position)
    )
    const [progress, setProgress] = useState([0, checklist.length])
    const [attachments, setAttachments] = useState(recurrence.files)
    const [isRecurrenceModalOpen, setIsRecurrenceModalOpen] = useState(false)

    useEffect(() => {
        setProgress([0, checklist.length])
    }, [checklist])

    useEffect(() => {
        if (recurrence.openUp) {
            setExpanded(true)
        }
    }, [recurrence])

    const recurrenceCardStyle = (draggableStyle) => {
        let transform = draggableStyle.transform

        if (transform) {
            transform =
                'translate(0px' +
                transform.slice(transform.indexOf(','), transform.length)
        }
        return {
            ...draggableStyle,
            transform: transform,
            cursor: expanded && 'pointer',
            marginTop: '16px',
            padding: '16px',
            borderRadius: '8px',
            backgroundColor: 'gray.200',
        }
    }

    const CustomTooltip = ({ label, children }) => (
        <Tooltip label={label}>
            <ForwardRefWrapper>{children}</ForwardRefWrapper>
        </Tooltip>
    )

    const ForwardRefWrapper = React.forwardRef(({ children, ...rest }, ref) => (
        <span ref={ref} {...rest}>
            {children}
        </span>
    ))

    const handleUpdate = async (newData, field) => {
        switch (field) {
            case 'description':
                setDescription(newData.description)
                break

            case 'checklist':
                break
            case 'notes':
                setNotes(newData.notes)
                break
            default:
                alert('not a valid update field')
                break
        }
        updateRecurringTask(recurrence._id, newData)
    }

    // API's to pass to checklist component
    const createItem = async ({ name }) => {
        const newChecklist = [...checklist]
        const newItem = {
            name,
            position:
                newChecklist.length === 0
                    ? 1000
                    : newChecklist[newChecklist.length - 1].position + 10,
        }
        newChecklist.push(newItem)
        setChecklist(newChecklist)

        try {
            const createdItem = await apiCall(
                `POST`,
                `/users/${user._id}/checklist/${recurrence._id}?recurrence=true`,
                newItem
            )
            newChecklist[newChecklist.length - 1] = createdItem
            setChecklist(newChecklist)
            updateRecurringTask(recurrence._id, { checklist: newChecklist })
        } catch (error) {
            console.log(error)
        }
    }

    const updateItem = async (itemId, updates) => {
        try {
            const updatedItem = await apiCall(
                `PUT`,
                `/users/${user._id}/checklist/${recurrence._id}/${itemId}?recurrence=true`,
                updates
            )
            return updatedItem
        } catch (error) {
            console.log(error)
        }
    }

    const deleteItem = (itemId) => {
        const newChecklist = checklist.filter((item) => item._id !== itemId)
        setChecklist(newChecklist)
        updateRecurringTask(recurrence._id, { checklist: newChecklist })

        try {
            apiCall(
                `DELETE`,
                `/users/${user._id}/checklist/${recurrence._id}/${itemId}?recurrence=true`
            )
        } catch (error) {
            console.log(error)
        }
    }

    // API to delete attachment
    const deleteAttachment = (fileId) => {
        let newAttachments = [...attachments].filter(
            (attachment) => attachment._id !== fileId
        )
        setAttachments(newAttachments)
        updateRecurringTask(recurrence._id, { files: newAttachments })

        try {
            apiCall(
                `DELETE`,
                `/users/${user._id}/tasks/${recurrence._id}/files/${fileId}?recurrence="true"`
            )
        } catch (error) {
            console.log(error)
        }
    }

    const handleUploadSuccess = (newFile) => {
        let newAttachments = [...attachments]
        newAttachments.push(newFile)

        updateRecurringTask(recurrence._id, { files: newAttachments })
        setAttachments(newAttachments)
    }

    useEffect(() => {
        setRecurrenceLabels([...recurrence.labels])
    }, [recurrence.labels])

    const [isLabelsModalOpen, setIsLabelsModalOpen] = useState(false)

    const addFile = async (formData) => {
        const response = await apiCall(
            `POST`,
            `/users/${user._id}/files?taskId=${recurrence._id}&recurrence=true`,
            formData
        )
        return response
    }

    const updateRecurrenceLabels = (newLabels) => {
        updateRecurringTask(recurrence._id, { labels: newLabels })
    }

    return (
        <>
            <Draggable
                key={recurrence._id}
                draggableId={recurrence._id}
                index={index}
                isDragDisabled={expanded}
            >
                {(provided) => (
                    <Box
                        w="100%"
                        _hover={{
                            boxShadow: '0 8px 16px 0 rgba(56, 96, 165, 0.15)',
                            transition: '0.3s',
                        }}
                        _active={{
                            cursor: 'grabbing',
                        }}
                        boxShadow={`${
                            expanded && '0 8px 16px 0 rgba(56, 96, 165, 0.15)'
                        }`}
                        backgroundColor="colors.white"
                        onClick={() => !expanded && setExpanded(true)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={recurrenceCardStyle(
                            provided.draggableProps.style
                        )}
                    >
                        <Flex
                            justifyContent={{
                                base: 'start',
                                md: 'space-between',
                            }}
                            w="100%"
                            minHeight="48px"
                        >
                            <Box
                                pr="16px"
                                ml="8px"
                                alignSelf="center"
                                mt={expanded && '12px'}
                                w={{
                                    base: '90%',
                                    md: !expanded ? '50%' : '100%',
                                }}
                            >
                                <VStack
                                    flexDirection={expanded ? 'row' : 'column'}
                                >
                                    <Flex w="100%">
                                        <Description
                                            expanded={expanded}
                                            description={description}
                                            handleUpdate={handleUpdate}
                                            key={recurrence._id}
                                        />
                                    </Flex>
                                    {!expanded && progress[1] > 0 && (
                                        <Flex
                                            w="100%"
                                            alignItems="center"
                                            mt="0px !important"
                                        >
                                            <Progress
                                                h="8px"
                                                w="100%"
                                                mr="16px"
                                                ml="16px"
                                                value={0}
                                                colorScheme="blue"
                                                borderRadius="16px"
                                                mt="0px"
                                            />
                                            <Text
                                                fontSize="small"
                                                fontWeight="bold"
                                                color="grey.900"
                                                mt="0px"
                                            >
                                                {progress[0]}/{progress[1]}
                                            </Text>
                                        </Flex>
                                    )}
                                </VStack>
                            </Box>
                            {!expanded && (
                                <>
                                    <Box
                                        mr="auto"
                                        alignSelf="center"
                                        display={{ base: 'none', md: 'flex' }}
                                        w={{
                                            md: '178px',
                                        }}
                                        flexWrap="wrap"
                                    >
                                        {recurrenceLabels.map((label, i) => (
                                            <Button
                                                variant="chip-colored"
                                                background={
                                                    label.color === ''
                                                        ? 'blue.500'
                                                        : label.color
                                                }
                                                key={i}
                                                mt={'2px'}
                                                mb={'2px'}
                                                onClick={() =>
                                                    setIsLabelsModalOpen(true)
                                                }
                                            >
                                                {label.name}
                                            </Button>
                                        ))}
                                    </Box>
                                </>
                            )}
                            {expanded && (
                                <IconButton
                                    icon={<CarrotIcon />}
                                    onClick={() => {
                                        setExpanded(false)
                                        recurrence.openUp = false
                                    }}
                                    size="lg"
                                    borderRadius="xl"
                                    backgroundColor="transparent"
                                    color="grey.900"
                                    alignSelf="flex-start"
                                />
                            )}
                        </Flex>
                        {expanded && (
                            <Box>
                                <Box ml="24px" mr="32px" mt="8px">
                                    <Notes
                                        notes={notes}
                                        handleUpdate={handleUpdate}
                                    />
                                    <Spacer h="8px" />
                                    <Checklist
                                        user={user}
                                        items={checklist}
                                        setItems={setChecklist}
                                        expanded={expanded}
                                        progress={progress}
                                        createItem={createItem}
                                        updateItem={updateItem}
                                        deleteItem={deleteItem}
                                        cantComplete={true}
                                    />
                                </Box>
                                <AttachmentsList
                                    section={0}
                                    attachments={attachments}
                                    deleteAttachment={deleteAttachment}
                                />
                                <Flex justify="space-between" mt="24px">
                                    <Flex align="center">
                                        <Box ml="16px">
                                            {recurrenceLabels.map(
                                                (label, i) => (
                                                    <Button
                                                        variant="chip-colored"
                                                        background={
                                                            label.color === ''
                                                                ? 'blue.500'
                                                                : label.color
                                                        }
                                                        key={i}
                                                        mt={'2px'}
                                                        mb={'2px'}
                                                        onClick={() =>
                                                            setIsLabelsModalOpen(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        {label.name}
                                                    </Button>
                                                )
                                            )}
                                        </Box>
                                    </Flex>
                                    <Flex
                                        align="center"
                                        display={{ base: 'none', md: 'flex' }}
                                    >
                                        <CustomTooltip label="Edit recurrence">
                                            <Button
                                                variant="icon-button-text"
                                                onClick={() =>
                                                    setIsRecurrenceModalOpen(
                                                        true
                                                    )
                                                }
                                                leftIcon={<LoopIcon />}
                                                maxW="none"
                                            >
                                                Recurrence
                                            </Button>
                                        </CustomTooltip>
                                        <CustomTooltip label="Add and remove labels">
                                            <Button
                                                variant="icon-button-text"
                                                onClick={() =>
                                                    setIsLabelsModalOpen(true)
                                                }
                                                leftIcon={<LabelIcon />}
                                            >
                                                Label
                                            </Button>
                                        </CustomTooltip>
                                        <UploadButton
                                            addFile={addFile}
                                            onUploadSuccess={
                                                handleUploadSuccess
                                            }
                                        >
                                            <CustomTooltip label="Add attachment">
                                                <Button
                                                    variant="icon-button-text"
                                                    leftIcon={
                                                        <AttachmentSlantIcon />
                                                    }
                                                >
                                                    Attachment
                                                </Button>
                                            </CustomTooltip>
                                        </UploadButton>
                                    </Flex>
                                </Flex>
                            </Box>
                        )}
                    </Box>
                )}
            </Draggable>
            {isLabelsModalOpen && (
                <LabelModal
                    cards={cards}
                    setCards={setCards}
                    isOpen={isLabelsModalOpen}
                    selectedLabels={recurrenceLabels}
                    setSelectedLabels={setRecurrenceLabels}
                    updateCardLabels={updateRecurrenceLabels}
                    onClose={() => setIsLabelsModalOpen(false)}
                />
            )}
            {isRecurrenceModalOpen && (
                <RecurrenceModal
                    user={user}
                    isOpen={isRecurrenceModalOpen}
                    onClose={() => setIsRecurrenceModalOpen(false)}
                    recurrence={recurrence}
                    recurringTasks={recurringTasks}
                    setRecurringTasks={setRecurringTasks}
                />
            )}
        </>
    )
}
