import React, { useState } from 'react'
import {
    Box,
    Flex,
    Text,
    Image,
    Modal,
    Button,
    Checkbox,
    ModalFooter,
    ModalOverlay,
    ModalContent,
} from '@chakra-ui/react'
import { apiCall } from '../Util/api'
import { AVATARS } from './avatars/avatars'
import Avatar from './avatars/Avatar'
import superWoman from './superWoman.svg'
import superWoman2 from './superWoman2.svg'

export default function CommunityFirstTimePopup({
    isOpen,
    showPopup,
    setIsOpen,
    setShowPopup,
    user,
}) {
    const [selectedAvatar, setSelectedAvatar] = useState(null)
    const [step, setStep] = useState(1)

    return (
        <>
            <link
                as="image"
                rel="preload"
                href="https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/622234b51a88cbf13d268d77_undraw_Group_chat_re_frmo.png"
            />
            <Modal isOpen={isOpen} size="xl" w="600px">
                <ModalOverlay />
                <ModalContent
                    maxW="800px"
                    style={{
                        display: 'flex',
                        justifyContent: 'start',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                    }}
                >
                    <Box mt="32px" mb="16px" pl="16px" pr="16px">
                        <Text
                            fontSize="xl"
                            style={{
                                textAlign: 'center',
                                fontWeight: 600,
                            }}
                        >
                            {step < 3 &&
                                'Welcome to the Office Otter Community'}
                            {step === 3 && 'Select your avatar to get started'}
                        </Text>
                        {step < 3 && (
                            <Flex justifyContent="center" mt="8px">
                                <Box
                                    height="320px"
                                    width="320px"
                                    backgroundSize="320px"
                                    backgroundImage={`url(${
                                        step === 1 ? superWoman : superWoman2
                                    })`}
                                    backgroundRepeat={'no-repeat'}
                                    borderRadius="50%"
                                ></Box>
                            </Flex>
                        )}
                        <Text fontSize="24px" textAlign="center" p="0px 32px">
                            {step === 1 && (
                                <>
                                    We're thrilled to have you in our online
                                    community built just for admins, the
                                    <b> Superheroes </b>
                                    of the office!{' '}
                                </>
                            )}
                            {step === 2 &&
                                `This is a safe space where you can anonymously
                            dialogue, share your experiences, and connect with
                            like-minded administrative professionals. `}
                        </Text>
                    </Box>
                    {step === 3 && (
                        <Flex
                            w="100%"
                            flexWrap="wrap"
                            justifyContent="center"
                            mb="32px"
                            pr={{ sm: '8px', md: '128px' }}
                            pl={{ sm: '8px', md: '128px' }}
                        >
                            {AVATARS.map((a) =>
                                selectedAvatar === null ? (
                                    <Avatar
                                        size="96px"
                                        onClick={() => {
                                            apiCall(
                                                'PUT',
                                                `/users/${user._id}`,
                                                {
                                                    avatarAnimal: a.name,
                                                }
                                            )
                                            setSelectedAvatar(a.name)
                                        }}
                                        img={a.img}
                                        _hover={{
                                            cursor: 'pointer',
                                            outline: '5px solid #fab6b2',
                                            outlineOffset: -2,
                                        }}
                                    ></Avatar>
                                ) : (
                                    a.name === selectedAvatar && (
                                        <Avatar
                                            size="96px"
                                            img={a.img}
                                        ></Avatar>
                                    )
                                )
                            )}
                        </Flex>
                    )}

                    <ModalFooter>
                        <Flex w="100%" justify="flex-end" mr="-16px">
                            {step < 3 && (
                                <Button
                                    colorScheme="blue"
                                    onClick={() => {
                                        setStep(step + 1)
                                    }}
                                >
                                    Next
                                </Button>
                            )}
                            {step === 3 && (
                                <Button
                                    colorScheme="blue"
                                    onClick={() => {
                                        setShowPopup(false)
                                        setIsOpen(false)
                                    }}
                                    disabled={
                                        selectedAvatar === null ? true : false
                                    }
                                >
                                    Let's go!
                                </Button>
                            )}
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
