import React, { useState, useContext } from 'react'
import { apiCall } from '../Util/api'
import { UserContext } from '../Contexts/UserContext'
import { Modal, ModalContent, ModalOverlay, Flex } from '@chakra-ui/react'
import useLocalStorage from '../Hooks/UseLocalStorage'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'

export default function WalkthroughContainer() {
    const [step, setStep] = useLocalStorage('walkthroughStep', 1)
    const { user, setUser } = useContext(UserContext)
    const [open] = useState(true)

    const updateUserInfo = async (newValues) => {
        try {
            const newUserData = await apiCall(
                'PUT',
                `/users/${user._id}`,
                newValues
            )
            setUser(newUserData)
        } catch (error) {
            console.log(error)
        }
    }

    const Dot = ({ isLarge }) => (
        <div
            style={{
                height: isLarge ? 12 : 6,
                width: isLarge ? 12 : 6,
                backgroundColor: isLarge ? '#0a58ce' : '#8f9bb3',
                borderRadius: '50%',
                margin: 8,
            }}
        ></div>
    )

    return (
        step < 5 && (
            <>
                <link
                    as="image"
                    rel="preload"
                    href="https://office-otter-production.s3.us-east-2.amazonaws.com/ce2ee403-d362-4864-864a-098b6357ad6aWalkthrough%20-%20communication%20integrations.png"
                />
                <link
                    as="image"
                    rel="preload"
                    href="https://office-otter-production.s3.us-east-2.amazonaws.com/c813ef76-d385-438e-96d3-e7dd13cfcc5fWalkthrough%20Community.png"
                />
                <link
                    as="image"
                    rel="preload"
                    href="https://office-otter-production.s3.us-east-2.amazonaws.com/a2106e21-81f5-4870-986c-b78d9f218401walkthrough%20-%20reports.png"
                />
                <link
                    as="image"
                    rel="preload"
                    href="https://office-otter-production.s3.us-east-2.amazonaws.com/2fb5c2ae-2fe4-4f82-93d2-48b3bd9484b8Walkthrough%20to%20do%20list.png"
                />
                <link
                    as="image"
                    rel="preload"
                    href="https://office-otter-production.s3.us-east-2.amazonaws.com/f1b784b2-7619-4831-a90a-280988c6c946community%202.png"
                />
                <link
                    as="image"
                    rel="preload"
                    href="https://office-otter-production.s3.us-east-2.amazonaws.com/0514a030-5fb7-4cd3-af2f-a3029082a6eecomms%202.png"
                />
                <link
                    as="image"
                    rel="preload"
                    href="https://office-otter-production.s3.us-east-2.amazonaws.com/652d9b18-ae4a-4cff-bbe4-cbd37c7cdea8walkthrough%20reports.png"
                />
                <link
                    as="image"
                    rel="preload"
                    href="https://office-otter-production.s3.us-east-2.amazonaws.com/720b06be-3131-44d9-945c-7c460a960342walkthrough%20-%20general%20task%20management.png"
                />{' '}
                <Modal isOpen={open} size="xl">
                    <ModalOverlay />
                    <ModalContent display="flex" justifyContent="start">
                        {step > 0 && step < 5 && (
                            <Flex
                                align="center"
                                justify="center"
                                mt="24px"
                                mb="8px"
                            >
                                <Dot isLarge={true} />
                                <Dot isLarge={step > 1} />
                                <Dot isLarge={step > 2} />
                                <Dot isLarge={step > 3} />
                            </Flex>
                        )}
                        {step === 1 && (
                            <Step1
                                step={step}
                                setStep={setStep}
                                updateUserInfo={updateUserInfo}
                            />
                        )}
                        {step === 2 && (
                            <Step2
                                step={step}
                                setStep={setStep}
                                updateUserInfo={updateUserInfo}
                            />
                        )}
                        {step === 3 && (
                            <Step3
                                step={step}
                                setStep={setStep}
                                updateUserInfo={updateUserInfo}
                            />
                        )}
                        {step === 4 && <Step4 step={step} setStep={setStep} />}
                    </ModalContent>
                </Modal>
            </>
        )
    )
}
