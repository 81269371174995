import { Link, Image, Img } from '@chakra-ui/react'

const baseObject = {
    header: '',
    intro: [''],
    stepsHeaer: '',
    steps: [
        {
            emoji: '1️⃣',
            text: '',
        },
    ],
    videoUrl: '',
}

const CopyLink = ({ url, text }) => (
    <Link
        alignSelf="center"
        mt="8px"
        mb="8px"
        color="blue.500"
        textDecor="underline"
        target="_blank"
        rel="noopener noreferrer"
        _hover={{ cursor: 'pointer' }}
        href={url}
    >
        {text}
    </Link>
)

export const gettingStartedCopy = {
    card: {
        emoji: '☀️',
        title: 'Your first day',
        subtitle: 'So you just signed up for Office Otter; now what?',
    },
    header: 'Office Otter Getting Started Guide',
    intro: [
        'You just signed up for Office Otter. Now what?',
        'This guide will walk you through the basics of our most popular features. Follow the steps below to get your to-do list centralized and organized in minutes!!',
    ],
    stepsHeaer: 'Get Started',
    steps: [
        {
            emoji: '1️⃣',
            text: 'Transfer your to-do list for this week from whatever method you have been using to Office Otter (e.g. sticky notes, pen & paper, etc.)',
        },
        {
            emoji: '2️⃣',
            text: 'Create your first task by typing over the grayed out text at the top of your screen that says “Type a task here”',
        },
        {
            emoji: '3️⃣',
            text: 'Enhances detail to your task by adding subtasks, and clicking into the grayed out text that says “Add a checklist item here and press Enter.”',
        },
        {
            emoji: '4️⃣',
            text: 'Prioritize your task by clicking on the clock icon in the bottom right of your task to add a due date and time.',
        },
        {
            emoji: '5️⃣',
            text: 'Organize your task by clicking on the label icon in the bottom right of your task to add a label.',
        },
        {
            emoji: '6️⃣',
            text: (
                <span>
                    If you want a more detailed step-by-step guide, check out
                    our{' '}
                    <CopyLink
                        url="https://www.officeotter.com/resources/getting-started-guide"
                        text="Getting Started blog post"
                    />{' '}
                </span>
            ),
        },
    ],
    videoUrl: 'https://www.loom.com/embed/a8d2ce62ca0e411db1cce0d66aee86e6',
}

export const desktopShortcutcopy = {
    card: {
        emoji: '🦦',
        title: 'Desktop Shortcut',
        subtitle: "Add Office Otter to your computer's Desktop or Dock",
    },
    header: 'Office Otter Desktop Shortcut Setup Guide',
    intro: [
        'Our world moves so fast that sometimes two clicks vs. four can make all the difference. That’s why we created the Office Otter Desktop Shortcut. With this shortcut, you can access your Office Otter dashboard from your computer desktop with a single click.',
    ],
    stepsHeaer: 'How to set up your Office Otter Desktop Shortcut',
    steps: [
        {
            emoji: '1️⃣',
            text: 'Open Office Otter in Google Chrome, and shrink your screen so your desktop is visible.',
        },
        {
            emoji: '2️⃣',
            text: 'Click and drag the lock icon from the URL bar to your desktop.',
        },
        {
            emoji: '3️⃣',
            text: (
                <span>
                    To get the{' '}
                    <CopyLink
                        url="https://office-otter-develop.s3.us-east-2.amazonaws.com/otter.ico"
                        text="Otter icon"
                    />{' '}
                    to appear on your desktop shortcut, follow the steps in our{' '}
                    <CopyLink
                        url="https://www.officeotter.com/resources/office-otter-desktop-shortcut-setup-guide"
                        text="Desktop Shortcut blog"
                    />{' '}
                    for the operating system you’re using.
                </span>
            ),
        },
    ],
    videoUrl: 'https://www.loom.com/embed/d7928169b6bb4ab8a54bc67a0e56418a',
}

export const emailIntegrationCopy = {
    card: {
        emoji: '📫',
        title: 'Email a task',
        subtitle: 'Forward emails to tasks@officeotter.com',
    },
    header: 'How to Use the Office Otter Email Integration',
    intro: [
        'Welcome to the Office Otter Email Integration Guide! This Integration is perfect for those emails you need to follow up on later but don’t want to clutter up your inbox. It’s easy to send them to Office Otter, then archive, delete or file them, knowing the important tasks are already on your to-do list. ',
    ],
    stepsHeader:
        'Follow the simple steps below to set up the email integration!',
    steps: [
        {
            emoji: '1️⃣',
            text: 'To start setting up our Email integration, you can check your inbox for an email from us.',
        },
        {
            emoji: '2️⃣',
            text: (
                <span>
                    Respond to the email or send tasks by emailing{' '}
                    <CopyLink
                        url="tasks@officeotter.com"
                        text="tasks@officeotter.com"
                    />
                    {'.'}
                </span>
            ),
        },
        {
            emoji: '3️⃣',
            text: 'The entire email message will be added to your Now section in Office Otter.',
        },
        {
            emoji: '4️⃣',
            text: (
                <span>
                    If you want a more detailed step-by-step guide, check out
                    our{' '}
                    <CopyLink
                        url="https://www.officeotter.com/resources/how-to-use-the-office-otter-email-integration"
                        text="Email Integration blog post"
                    />{' '}
                    .{' '}
                </span>
            ),
        },
    ],
    videoUrl: 'https://www.loom.com/embed/f9ff5c56f0fa467bb665766d9f79e763',
}

export const chromeIntegrationCopy = {
    card: {
        emoji: (
            <img
                style={{ margin: 16, marginBottom: 12 }}
                width="48px"
                src="https://office-otter-production.s3.us-east-2.amazonaws.com/2f7f2536-e813-4f3b-a0d1-dc0c825729a0chrome.png"
            />
        ),
        title: 'Chrome Plugin',
        subtitle: 'Highlight text from any website & turn it into a task',
    },
    header: 'Office Otter Chrome Plugin Feature Guide',
    intro: [
        `Are you one of those people with so many tabs open in
                        your browser that you can’t read the words on the tabs?
                        One of the hallmarks of administrative jobs is that
                        tasks and inputs are constantly coming in from all
                        directions.`,
        `                        We built the Office Otter Google Chrome Plugin so you
                        can send tasks to your list from any website without
                        leaving the page you’re on (including all those other
                        platforms your company uses that don’t integrate with
                        each other). The plugin allows you to save what you need
                        and close the tab.`,
    ],
    stepsHeader: 'Installing the Chrome Plugin',
    steps: [
        {
            emoji: '1️⃣',
            text: (
                <span>
                    Follow{' '}
                    <CopyLink
                        url="https://chrome.google.com/webstore/detail/office-otter-chrome-exten/cnfbcpcddokhioeecbjbmdibjfkhpcpp?hl=en"
                        text="this link to the Office Otter Chrome Plugin"
                    />{' '}
                    in the Chrome Store.
                </span>
            ),
        },
        {
            emoji: '2️⃣',
            text: (
                <span>
                    Select <b>Add to Chrome </b>and follow prompts to complete
                    the setup.
                </span>
            ),
        },
        {
            emoji: '3️⃣',
            text: `You can now highlight any text from any website in
                        Chrome, right click, and create a task for your Office
                        Otter list.`,
        },
        {
            emoji: '4️⃣',
            text: (
                <span>
                    You can also click on the 🦦 icon in your Chrome extension
                    shortcuts menu to create a task and assign an urgency.
                </span>
            ),
        },

        {
            emoji: '5️⃣',
            text: (
                <span>
                    For a more detailed step-by-step, check out our{' '}
                    <CopyLink
                        url="https://www.officeotter.com/resources/office-otter-chrome-plugin-feature-guide"
                        text="Chrome Plugin blog post"
                    />{' '}
                    .{' '}
                </span>
            ),
        },
    ],
    videoUrl: 'https://www.loom.com/embed/fc17dffad0004e55a0c6936590477adf',
}

export const slackIntegrationCopy = {
    card: {
        emoji: (
            <img
                style={{ margin: 16, marginBottom: 12 }}
                width="48px"
                src="https://office-otter-production.s3.us-east-2.amazonaws.com/6d171b12-7ffe-42a2-9020-64ceea46f06bSlack-logo.jpg"
            />
        ),
        title: 'Slack a task',
        subtitle: 'Send messages in 2 clicks right from Slack',
    },
    header: 'How to Install and Use the Office Otter Slack Integration',
    intro: [
        'Do you find yourself getting pinged throughout the work day (and often outside of work hours) with requests on Slack? While many of the requests may seem simple, keeping track of and following up on them can be overwhelming. You may have a constant fear of missing something.',
        'Enter the Office Otter Slack integration! Using this integration, you can quickly turn a message into a task on your to-do list without leaving Slack. Follow the steps below to set up and start using this amazing tool.',
    ],
    stepsHeader: 'Install the Office Otter Slack Integration',
    steps: [
        {
            emoji: '1️⃣',
            text: 'Make sure that your Office Otter account email and your Slack account email are the same (you can check this in your Profile).',
        },
        {
            emoji: '2️⃣',
            text: (
                <span>
                    Click the button below to connect to your Slack account.{' '}
                    <CopyLink
                        text={
                            <Image
                                alt="Add to Slack"
                                src="https://platform.slack-edge.com/img/add_to_slack.png"
                                srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                            />
                        }
                        url="https://slack.com/oauth/v2/authorize?scope=chat%3Awrite%2Ccommands%2Cusers%3Aread%2Cusers%3Aread.email%2C+im%3Ahistory&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbnN0YWxsT3B0aW9ucyI6eyJzY29wZXMiOlsiY2hhdDp3cml0ZSIsImNvbW1hbmRzIiwidXNlcnM6cmVhZCIsInVzZXJzOnJlYWQuZW1haWwsIGltOmhpc3RvcnkiXX0sIm5vdyI6IjIwMjAtMDgtMjdUMjE6Mjc6MTIuOTU0WiIsImlhdCI6MTU5ODU2MzYzMn0.5-JW49iy6WrwETvxcgJ-G_D2Cxkp-_QkqwHvDYoFEHc&client_id=1042364295380.1077558574930"
                    />
                </span>
            ),
        },
        {
            emoji: '3️⃣',
            text: 'Any Slack message from a channel, individual or group can be converted to a task without notifying anyone by clicking on the “Create a task” Office Otter action in More Actions.',
        },
        {
            emoji: '4️⃣',
            text: (
                <span>
                    If you want a more detailed step-by-step guide, check out
                    our{' '}
                    <CopyLink
                        url="https://www.officeotter.com/resources/how-to-install-and-use-the-office-otter-slack-integration"
                        text="Slack Integration blog post."
                    />{' '}
                    .{' '}
                </span>
            ),
        },
    ],
    videoUrl: 'https://www.loom.com/embed/9c54d3e5d7be4dffa3df241ca08a0dce',
}

export const inboxIntegrationCopy = {
    card: {
        emoji: '📥',
        title: 'Inbox || receive tasks',
        subtitle:
            'Share your custom Inbox link with others to let them add tasks to your Office Otter list',
    },
    header: 'How to Save Time With Office Otter Inbox',
    intro: [
        'Communication tools are the main way you receive requests from others. This is why Office Otter has integrations for Email, Slack, Text, Chrome and more. But what if you could allow your trusted colleagues to put their requests directly onto your Office Otter list, pending your approval? ',
        'Your custom Inbox link does just that! By sharing your custom Inbox link with trusted teammates, they can add requests directly to where you work: your Office Otter list. ',
        'Assistants and operations roles can share their unique Inbox link with other assistants in the company, adjacent team members from HR or People Ops on the onboarding team, direct reports, and the Execuitves they suppot.',
        'It all starts with your unique link.',
    ],
    stepsHeader: 'Finding, Customizing and Copying Your Inbox Link',
    steps: [
        {
            emoji: '1️⃣',
            text: (
                <span>
                    Click on the Inbox section (under Completed) to access your{' '}
                    <b>Inbox Settings</b>. You can also access{' '}
                    <b>Inbox Settings</b> from your Profile.
                </span>
            ),
        },
        {
            emoji: '2️⃣',
            text: (
                <span>
                    Customize your Inbox link and click <b>Save Changes</b>.
                </span>
            ),
        },
        {
            emoji: '3️⃣',
            text: 'Copy your custom Inbox link and share it with whomever you would like!',
        },
        {
            emoji: '4️⃣',
            text: (
                <span>
                    If you want a more detailed step-by-step guide, check out
                    our{' '}
                    <CopyLink
                        url="https://www.officeotter.com/resources/how-to-save-time-with-office-otter-inbox"
                        text="blog post"
                    />
                    .{' '}
                </span>
            ),
        },
    ],
    videoUrl: 'https://www.loom.com/embed/fc17dffad0004e55a0c6936590477adf',
}

export const textIntegrationCopy = (sendFromNumber) => ({
    card: {
        emoji: '📲',
        title: 'Text a task',
        subtitle: `Text ${sendFromNumber} to send tasks from anywhere`,
    },
    header: "Texting Office Otter for when you're on the go",
    intro: [],
    stepsHeader: 'Steps',
    steps: [
        {
            emoji: '1️⃣',
            text: 'Check your phone for a welcome text from us.',
        },
        {
            emoji: '2️⃣',
            text: (
                <span>Save our number {sendFromNumber} in your contacts.</span>
            ),
        },
        {
            emoji: '3️⃣',
            text: `Text our phone number to add a task to your Now section.`,
        },
        {
            emoji: '4️⃣',
            text: (
                <span>
                    You can also use Siri to send in a task. Just say, "Hey Siri
                    text Office Otter".
                </span>
            ),
        },
    ],
    videoUrl: 'https://www.loom.com/embed/8a50324c36a443ef9b14f511dbb488be',
})

export const privacyAndSecurityCopy = {
    card: {
        emoji: '🔒',
        title: 'Privacy & Security',
        subtitle:
            'Your data is safe and we will never sell your data. Open to learn more. ',
    },
    header: 'Office Otter Privacy and Security Guide',
    intro: [
        `We respect your privacy and the security of your data. That’s why we’ve included multiple layers of encryption for every facet of your Office Otter account.`,
    ],
    stepsHeader: 'Our security in depth',
    steps: [
        {
            emoji: '🔐',
            text: `Your password is stored on a secure server, encrypted using a Blowfish cypher, hashed, and salted. This means it is as safe as it could possibly be with modern technology.`,
        },
        {
            emoji: '🙋‍♀️',
            text: 'Your account & tasks are accessible ONLY to you',
        },
        {
            emoji: '🔑',
            text: `The Office Otter application is:
encrypted with 256-bit HTTPS encryption, stored on servers with automated certificate management (ACM) and SSL,
supported by TLS encryption for all network traffic, and data is secured at rest using automated encrypted storage volumes.`,
        },
        {
            emoji: '🖥',
            text: `Your data is also secure with our Slack Integration. While the shortcut will be available to your whole workspace, no one will be able to access your task list or add tasks to it.
Office Otter will be able to: view
messages and other content in direct messages that only Office Otter has been added to,
view names & email addresses of people in your workspace, send messages as office_otter, 
and add shortcuts and/or slash commands that other people can use.
`,
        },
    ],
    videoUrl: '',
}

export const mobileAppIntegrationCopy = {
    card: {
        emoji: '📱',
        title: 'Mobile App',
        subtitle: 'For quick access to your list right from your phone',
    },
    header: 'Office Otter Mobile App Guide',
    intro: [``],
    stepsHeader:
        'To access Office Otter from your iPhone or Android, follow the instructions below.',
    steps: [
        {
            emoji: '1️⃣',
            text: `Pull up app.officeotter.com in your phone's browser.`,
        },
        {
            emoji: '2️⃣',
            text: (
                <span>
                    On Safari click on the share button. On Chrome click on the
                    three vertical dots on the top right corner.{' '}
                </span>
            ),
        },
        {
            emoji: '3️⃣',
            text: `Click on the "Add to Home Screen" button. `,
        },
    ],
    videoUrl: '',
}

export const microsoftOutlookCopy = {
    card: {
        emoji: (
            <img
                width="56px"
                style={{ marginTop: 12, marginBottom: 12 }}
                src="https://office-otter-develop.s3.us-east-2.amazonaws.com/fa1f2b16-1ceb-49c5-864b-7e5c7d9da8a8Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png"
            />
        ),
        title: 'Microsoft Outlook',
        subtitle: 'Manage your task list from within Outlook',
    },
    header: 'How to integrate Office Otter with Outlook',
    intro: [``],
    stepsHeader: "Let's get started!",
    steps: [
        {
            emoji: '1️⃣',
            text: (
                <span>
                    In a browser, navigate to{' '}
                    <CopyLink
                        text="outlook.office.com"
                        url="https://outlook.office.com"
                    />
                    .
                </span>
            ),
        },
        {
            emoji: '2️⃣',
            text: `Add the Office Otter app from the Outlook app store.`,
        },
        {
            emoji: '3️⃣',
            text: `Once added, the Office Otter icon will appear on the left side toolbar with other 
                    installed apps. Click on the icon to manage your list from within Outlook.`,
        },
        {
            emoji: '4️⃣',
            text: (
                <span>
                    For a visual and more detailed step-by-step guide, check out
                    our{' '}
                    <CopyLink
                        text="Outlook Integration blog post"
                        url="https://www.officeotter.com/resources/how-to-install-and-use-the-office-otter-outlook-app"
                    />
                    .
                </span>
            ),
        },
    ],
    videoUrl: '',
}

export const microsoftTeamsCopy = {
    card: {
        emoji: (
            <img
                width="72px"
                src="https://office-otter-production.s3.us-east-2.amazonaws.com/f48f4bbd-2065-4ee0-aec9-5d0d3e212a2cdownload.png"
            />
        ),
        title: 'Microsoft Teams',
        subtitle: 'Send Teams messages to Office Otter',
    },
    header: 'How to integrate Office Otter with Microsoft Teams',
    intro: [``],
    stepsHeader: "Let's get started!",
    steps: [
        {
            emoji: '1️⃣',
            text: `Install Office Otter from the Teams app library.`,
        },
        {
            emoji: '2️⃣',
            text: `Select any Teams message from a channel, individual or group to convert 
                    it to a task. Then, click on the “Create Task in Office Otter” action in 
                    More Actions. Sending a message to Office Otter will not notify anyone in 
                    your organization. `,
        },
        {
            emoji: '3️⃣',
            text: `Once you’ve selected to Create a Task, the entire message will be copied, 
                    edits can be made within Teams, then click Submit to send to your Office Otter list.`,
        },
        {
            emoji: '4️⃣',
            text: (
                <span>
                    For a visual and further detailed step-by-step guide, check
                    out our{' '}
                    <CopyLink
                        text="Teams Integration blog post"
                        url="https://www.officeotter.com/resources/how-to-install-and-use-the-office-otter-teams-app"
                    />
                    .
                </span>
            ),
        },
    ],
    videoUrl: '',
}
