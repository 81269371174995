import React, { useState, useEffect, useContext } from 'react'
import {
    Button,
    Box,
    Modal,
    ModalContent,
    Flex,
    Text,
    Tooltip,
} from '@chakra-ui/react'
import { analytics } from '../Util/api'
import useLocalStorage from '../Hooks/UseLocalStorage'
import {
    gettingStartedCopy,
    privacyAndSecurityCopy,
    desktopShortcutcopy,
    emailIntegrationCopy,
    slackIntegrationCopy,
    inboxIntegrationCopy,
    chromeIntegrationCopy,
    textIntegrationCopy,
    mobileAppIntegrationCopy,
    microsoftTeamsCopy,
    microsoftOutlookCopy,
} from '../Copy'
import SetupGuideLayout from '../Layouts/SetupGuideLayout'
import { InfoIcon } from '../ChakraDesign/Icons'
import IntegrationCard from './IntegrationCard'
import { UserContext } from '../Contexts/UserContext'

export default function IntegrationsHelper({
    isOpen,
    setIsOpen,
    isZenMode,
    isProfile,
}) {
    const { user } = useContext(UserContext)

    const [hasSeenIntegrations, setHasSeenIntegrations] = useLocalStorage(
        'hasSeenIntegrations',
        false
    )
    const [hideIntegrations, setHideIntegrations] = useLocalStorage(
        'hideIntegrations',
        false
    )

    const [selectedIntegration, setSelectedIntegration] = useState('')
    const sendFromNumber = user.phone.startsWith('+44')
        ? process.env.REACT_APP_UKnumber
        : process.env.REACT_APP_USnumber

    const copies = {
        'Your first day': gettingStartedCopy,
        'Desktop Shortcut': desktopShortcutcopy,
        'Inbox || receive tasks': inboxIntegrationCopy,
        'Mobile App': mobileAppIntegrationCopy,
        'Privacy & Security': privacyAndSecurityCopy,
        'Text a task': textIntegrationCopy(sendFromNumber),
        'Slack a task': slackIntegrationCopy,
        'Email a task': emailIntegrationCopy,
        'Chrome Plugin': chromeIntegrationCopy,
        'Microsoft Teams': microsoftTeamsCopy,
        'Microsoft Outlook': microsoftOutlookCopy,
    }

    const INTEGRATIONS_SMOL = [
        {
            emoji: '📲',
            title: 'Text a task',
            subtitle: `Text ${
                user.phone.startsWith('+44')
                    ? process.env.REACT_APP_UKnumber
                    : process.env.REACT_APP_USnumber
            }`,
            isIntegrated: user.isTextIntegrated,
        },
        {
            emoji: '📫',
            title: 'Email a task',
            subtitle: 'tasks@officeotter.com',
            isIntegrated: user.isEmailIntegrated,
        },
        {
            emoji: (
                <img
                    style={{ marginTop: 2, marginBottom: 4 }}
                    width="16px"
                    src="https://office-otter-production.s3.us-east-2.amazonaws.com/6d171b12-7ffe-42a2-9020-64ceea46f06bSlack-logo.jpg"
                />
            ),
            title: 'Slack a task',
            subtitle: 'Slack a task',
            isIntegrated: user.isSlackIntegrated,
        },
        {
            emoji: (
                <img
                    style={{ marginTop: 2, marginBottom: 4 }}
                    width="16px"
                    src="https://office-otter-production.s3.us-east-2.amazonaws.com/9727deb9-9e9a-4dab-8bae-03b2703a7d23teamslogo.png"
                />
            ),
            title: 'Teams Integration',
            subtitle: 'Teams Integration',
            isIntegrated: user.isMicrosoftIntegrated,
        },
        {
            emoji: (
                <img
                    style={{ marginTop: 2, marginBottom: 4 }}
                    width="16px"
                    src="https://office-otter-develop.s3.us-east-2.amazonaws.com/fa1f2b16-1ceb-49c5-864b-7e5c7d9da8a8Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png"
                />
            ),
            title: 'Outlook Integration',
            subtitle: 'Outlook Integration',
            isIntegrated: user.isMicrosoftIntegrated,
        },
        {
            emoji: (
                <img
                    style={{ marginTop: 2, marginBottom: 4 }}
                    width="16px"
                    src="https://office-otter-production.s3.us-east-2.amazonaws.com/2f7f2536-e813-4f3b-a0d1-dc0c825729a0chrome.png"
                />
            ),
            title: 'Chrome Plugin',
            subtitle: 'Chrome Plugin',
            isIntegrated: user.isChromeExtIntegrated,
        },
        {
            emoji: '📥',
            title: 'Inbox',
            subtitle: 'inbox.officeotter.com',
            isIntegrated: user.isInboxIntegrated,
        },
    ]

    useEffect(() => {
        if (isOpen === true) {
            analytics.trigger({
                name: 'Integrations Helper Clicked',
                user,
                properties: {
                    type: selectedIntegration,
                },
            })
            setHasSeenIntegrations(true)
        }
    }, [isOpen])

    useEffect(() => {
        if (selectedIntegration !== '') {
            // grab innermost modal container and scroll it
            const elements = document.getElementsByClassName(
                'chakra-modal__content-container'
            )
            elements[elements.length - 1]?.scrollTo({
                top: 0,
                behavior: 'smooth',
            })

            analytics.trigger({
                name: 'Setup Tile Clicked',
                user,
                properties: {
                    type: selectedIntegration,
                },
            })
        }
    }, [selectedIntegration])

    const SmolIntegrationsList = () => (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    maxWidth: 560,
                    justifyContent: 'space-around',
                }}
            >
                {INTEGRATIONS_SMOL.map((i, index) => (
                    <Flex
                        width="100%"
                        marginTop="8px"
                        flexDir="column"
                        alignItems="center"
                        key={index}
                    >
                        <Flex
                            size="small"
                            onClick={() => {
                                setIsOpen(true)
                                let integration
                                switch (index) {
                                    case 0:
                                        integration =
                                            textIntegrationCopy(sendFromNumber)
                                                .card.title
                                        break
                                    case 1:
                                        integration =
                                            emailIntegrationCopy.card.title
                                        break
                                    case 2:
                                        integration =
                                            slackIntegrationCopy.card.title
                                        break
                                    case 3:
                                        integration =
                                            microsoftTeamsCopy.card.title
                                        break
                                    case 4:
                                        integration =
                                            microsoftOutlookCopy.card.title
                                        break
                                    case 5:
                                        integration =
                                            chromeIntegrationCopy.card.title
                                        break
                                    case 6:
                                        integration =
                                            inboxIntegrationCopy.card.title
                                        break

                                    default:
                                        break
                                }
                                console.log(integration)
                                setSelectedIntegration(integration)
                            }}
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                            className="hover"
                        >
                            <Flex>
                                <Text>{i.emoji}</Text>
                                <Text
                                    marginRight="auto"
                                    marginLeft="10px"
                                    fontSize="small"
                                >
                                    {i.subtitle}
                                </Text>
                            </Flex>
                            <Tooltip
                                label={`${
                                    i.isIntegrated
                                        ? 'You have created a task from this integration.'
                                        : 'You have not created a task from this integration.'
                                }`}
                            >
                                <Text marginRight="12px">
                                    {i.isIntegrated ? '✅' : '❌'}
                                </Text>
                            </Tooltip>
                        </Flex>
                    </Flex>
                ))}
            </div>
        </div>
    )

    const IntegrationsList = () => (
        <Box
            marginTop="16px !important"
            paddingLeft="8px !important"
            paddingRight="8px !important"
            display={isProfile ? {} : { base: 'none', lg: 'block' }}
        >
            <Flex alignItems="center">
                <Text fontSize="14px" fontWeight="bold">
                    Turn your conversations into tasks{' '}
                </Text>
                {!isProfile && (
                    <Tooltip label="Hide integrations list">
                        <Box
                            cursor="pointer"
                            ml="4px"
                            onClick={() => setHideIntegrations(true)}
                        >
                            <InfoIcon h="12px" w="12px" />
                        </Box>
                    </Tooltip>
                )}
            </Flex>
            <SmolIntegrationsList />
            <Button
                onClick={() => setIsOpen(true)}
                colorScheme="blue"
                width="100%"
                mt="8px"
            >
                Setup Guide
                {hasSeenIntegrations != true && <div className="badge"></div>}
            </Button>
        </Box>
    )
    return (
        <>
            {isProfile && <IntegrationsList />}
            {!isProfile && !isZenMode && !hideIntegrations && (
                <IntegrationsList />
            )}
            {isOpen && (
                <Modal
                    isOpen={isOpen}
                    size="full"
                    onClose={() => setIsOpen(false)}
                >
                    <ModalContent>
                        <SetupGuideLayout
                            header={
                                <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                    pl="16px"
                                    pt="4px"
                                >
                                    <Text fontSize="xl" fontWeight="600">
                                        Getting started with office otter
                                    </Text>
                                    <Box>
                                        {selectedIntegration !== '' && (
                                            <Button
                                                onClick={() => {
                                                    setSelectedIntegration('')
                                                }}
                                                mr="16px"
                                            >
                                                Back
                                            </Button>
                                        )}
                                        <Button
                                            onClick={() => {
                                                setIsOpen(false)
                                            }}
                                        >
                                            Close
                                        </Button>
                                    </Box>
                                </Flex>
                            }
                            main={
                                <Box padding="32px">
                                    <Box>
                                        {selectedIntegration !== '' ? (
                                            <Box>
                                                <Text fontSize="24px" mb="16px">
                                                    {
                                                        copies[
                                                            selectedIntegration
                                                        ].header
                                                    }
                                                </Text>
                                                <Flex flexWrap="wrap-reverse">
                                                    <Flex
                                                        width={{
                                                            base: '100%',
                                                            md: '100%',
                                                        }}
                                                        flexDir="column"
                                                    >
                                                        {copies[
                                                            selectedIntegration
                                                        ].intro.map((p) => (
                                                            <span>
                                                                {p}
                                                                <br />
                                                                <br />
                                                            </span>
                                                        ))}
                                                        {copies[
                                                            selectedIntegration
                                                        ].videoUrl && (
                                                            <Flex
                                                                justifyContent="center"
                                                                width={{
                                                                    base: '100%',
                                                                    md: '100%',
                                                                }}
                                                                height="420px"
                                                            >
                                                                <Flex
                                                                    padding="8px"
                                                                    borderRadius="16px"
                                                                    boxShadow="0 2px 4px 0 rgba(56, 96, 165, 0.2)"
                                                                    flexDir="column"
                                                                    maxHeight="420px"
                                                                    width="100%"
                                                                >
                                                                    <iframe
                                                                        src={
                                                                            copies[
                                                                                selectedIntegration
                                                                            ]
                                                                                .videoUrl
                                                                        }
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            borderRadius:
                                                                                '16px',
                                                                        }}
                                                                        allowFullScreen={
                                                                            true
                                                                        }
                                                                    ></iframe>
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                        <br />

                                                        <Text
                                                            fontSize="20px"
                                                            mb="16px"
                                                        >
                                                            {
                                                                copies[
                                                                    selectedIntegration
                                                                ].stepsHeader
                                                            }
                                                        </Text>

                                                        {copies[
                                                            selectedIntegration
                                                        ].steps.map((s) => (
                                                            <>
                                                                <Flex>
                                                                    <Box marginRight="8px">
                                                                        {
                                                                            s.emoji
                                                                        }
                                                                    </Box>
                                                                    <Box>
                                                                        {s.text}
                                                                    </Box>
                                                                </Flex>
                                                                <br />
                                                            </>
                                                        ))}
                                                    </Flex>
                                                </Flex>
                                            </Box>
                                        ) : (
                                            <Text fontSize="24px" mb="16px">
                                                Select a guide to begin
                                            </Text>
                                        )}

                                        <Flex
                                            mt="16px"
                                            flexWrap="wrap"
                                            justifyContent={{
                                                base: 'center',
                                                lg: 'start',
                                            }}
                                        >
                                            <Text
                                                fontSize="20px"
                                                mb="16px"
                                                alignSelf="flex-start"
                                                mr="auto"
                                                fontWeight="500"
                                                color="gray.500"
                                            >
                                                General
                                            </Text>
                                            <hr
                                                style={{
                                                    height: 1,
                                                    backgroundColor: '#E5ECF6',
                                                    border: '1px solid #E5ECF6',
                                                    width: '100%',
                                                    borderRadius: 16,
                                                    marginBottom: 8,
                                                }}
                                            />
                                            {Object.keys(copies).map(
                                                (i, index) => {
                                                    return (
                                                        index < 5 && (
                                                            <IntegrationCard
                                                                emoji={
                                                                    copies[i]
                                                                        .card
                                                                        .emoji
                                                                }
                                                                title={
                                                                    copies[i]
                                                                        .card
                                                                        .title
                                                                }
                                                                subtitle={
                                                                    copies[i]
                                                                        .card
                                                                        .subtitle
                                                                }
                                                                onClick={() => {
                                                                    setSelectedIntegration(
                                                                        copies[
                                                                            i
                                                                        ].card
                                                                            .title
                                                                    )
                                                                }}
                                                                style={{
                                                                    marginRight:
                                                                        '16px',
                                                                    marginBottom:
                                                                        '16px',
                                                                }}
                                                            />
                                                        )
                                                    )
                                                }
                                            )}
                                        </Flex>
                                        <Flex
                                            mt="16px"
                                            flexWrap="wrap"
                                            justifyContent={{
                                                base: 'center',
                                                lg: 'start',
                                            }}
                                        >
                                            <Text
                                                fontSize="20px"
                                                mb="16px"
                                                alignSelf="flex-start"
                                                mr="auto"
                                                fontWeight="500"
                                                color="grey.500"
                                            >
                                                Integration setup
                                            </Text>
                                            <hr
                                                style={{
                                                    height: 1,
                                                    backgroundColor: '#E5ECF6',
                                                    border: '1px solid #E5ECF6',
                                                    width: '100%',
                                                    borderRadius: 16,
                                                    marginBottom: 8,
                                                }}
                                            />
                                            {Object.keys(copies).map(
                                                (i, index) => {
                                                    return (
                                                        index > 4 && (
                                                            <IntegrationCard
                                                                emoji={
                                                                    copies[i]
                                                                        .card
                                                                        .emoji
                                                                }
                                                                title={
                                                                    copies[i]
                                                                        .card
                                                                        .title
                                                                }
                                                                subtitle={
                                                                    copies[i]
                                                                        .card
                                                                        .subtitle
                                                                }
                                                                onClick={() =>
                                                                    setSelectedIntegration(
                                                                        copies[
                                                                            i
                                                                        ].card
                                                                            .title
                                                                    )
                                                                }
                                                                style={{
                                                                    marginRight:
                                                                        '16px',
                                                                    marginBottom:
                                                                        '16px',
                                                                }}
                                                            />
                                                        )
                                                    )
                                                }
                                            )}
                                        </Flex>
                                        <Flex
                                            mt="16px"
                                            flexWrap="wrap"
                                            justifyContent={{
                                                base: 'center',
                                                lg: 'start',
                                            }}
                                        >
                                            <Text
                                                fontSize="20px"
                                                mb="16px"
                                                alignSelf="flex-start"
                                                mr="auto"
                                                fontWeight="500"
                                            >
                                                👀 Don't see what you're looking
                                                for? Chat us on the bottom
                                                right, we want to help!
                                            </Text>
                                        </Flex>
                                    </Box>
                                </Box>
                            }
                        />
                    </ModalContent>
                </Modal>
            )}
        </>
    )
}
