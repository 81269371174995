import { startOfDay, add } from 'date-fns'

const emailPics = [
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/6296647db91cbdd6eeb75789_undraw_Mobile_inbox_re_ciwq.png',
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/6272c9badddf2c7a03bc1886_undraw_Inbox_cleanup_re_jcbh.png',
]
const productivityPics = [
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/627d13c843dc351cf74d9950_undraw_In_progress_re_m1l6.png',
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/6222320ccb4cc57ef753a719_undraw_Checklist__re_2w7v.png',
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/620eaf43448e67200de47e6d_undraw_Data_trends_re_2cdy.png',
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/61f839c0fb9ce5261cc816b1_undraw_task_list_6x9d.svg',
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/5fca98e2b9550a0f0b28f4aa_undraw_multitasking_hqg3.png',
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/5fcab968467301b9dd21ecd5_undraw_investment_data_yfvt.png',
]
const textPics = [
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/627d577655516a5783fb21e5_undraw_Messaging_app_re_aytg.png',
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/5fa5b1fba18867915b89e2ee_undraw_push_notifications_im0o.png',
]

const collabPics = [
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/6272cbb31d2cbce83a90e0e0_undraw_Connection_re_lcud.png',
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/6255e5c7a511c864e95420a1_undraw_Interview_re_e5jn.png',
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/62222ffed47387006af864fc_undraw_Statistics_re_kox4.png',
]

const checklistPics = [
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/625d75637a776848899963ca_undraw_Pie_chart_re_bgs8.png',
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/61f839c0fb9ce5261cc816b1_undraw_task_list_6x9d.svg',
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/5fa5b56b716542213844c624_undraw_Setup_wizard_re_nday.png',
]

const overwhelmedPics = [
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/5fa5b56b716542213844c624_undraw_Setup_wizard_re_nday.png',
]

const monthPics = [
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/5fa5b2f78d86744247d9442c_undraw_online_calendar_kvu2.png',
]

const moneyPics = [
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/62a250c1ef0f57711304ebe1_undraw_Transfer_money_re_6o1h.png',
]

const mailPics = [
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/5fa5b62e45a845db0faeed56_undraw_message_sent_1030.png',
]

const nonWorkPics = [
    'https://uploads-ssl.webflow.com/5f4fe62894914e85f9cc9af5/623898fc2348a468fe1822a4_undraw_cup_of_tea_6nqg.png',
]

const prompts = [
    // 'Have you set a deadline for your recently created tasks?',
    { text: 'What are your goals for this week?', picType: 'productivity' },
    { text: 'What are today’s top 2 priorities?', picType: 'productivity' },
    {
        text: 'How can you help a co-worker this week?',
        picType: 'collab',
    },
    {
        text: 'What do you hope to complete by the end of today?',
        picType: 'productivity',
    },
    {
        text: 'What do you hope to complete by the end of the week?',
        picType: 'productivity',
    },
    { text: 'What are your top 3 goals for the month?', picType: 'month' },
    {
        text: 'What are your top 2 tasks for the week?',
        picType: 'productivity',
    },
    { text: 'What do you HAVE to get done today?', picType: 'productivity' },
    { text: 'Are there any emails that you need to send?', picType: 'email' },
    {
        text: 'What needs to get done by the end of the work week?',
        picType: 'month',
    },
    { text: 'Have a big project? Let’s break it down!', picType: 'checklist' },
    { text: 'What calls do you need to make today?', picType: 'productivity' },
    {
        text: 'What calls do you need to make this week?',
        picType: 'productivity',
    },
    { text: 'What texts do you need to send out today?', picType: 'text' },
    { text: 'What texts do you need to send out this week?', picType: 'text' },
    { text: 'What emails do you need to reply to today?', picType: 'email' },
    {
        text: 'What emails do you need to reply to this week?',
        picType: 'email',
    },
    { text: 'What emails do you need to send out today?', picType: 'email' },
    {
        text: 'What emails do you need to send out this week?',
        picType: 'email',
    },
    {
        text: 'What upcoming events need to be prepped/planned?',
        picType: 'productivity',
    },
    { text: 'Have you checked your email recently?', picType: 'email' },
    // 'Set a recurring reminder to stay on top of your inbox!',
    {
        text: 'What reminders do you need to be set each week?',
        picType: 'month',
    },
    {
        text: 'What project are you currently working on? Let’s organize it with a checklist!',
        picType: 'checklist',
    },
    {
        text: 'Do you have any deadlines that need to be set?',
        picType: 'month',
    },
    {
        text: 'Who do you need to coordinate with this week?',
        picType: 'collab',
    },
    {
        text: 'What needs to get done before your next meeting?',
        picType: 'productivity',
    },
    {
        text: 'Do you have any upcoming tasks you want to take note of?',
        picType: 'productivity',
    },
    {
        text: 'Have a task in mind? Text it to Office Otter and Otto will create the task for you!',
        picType: 'text',
    },
    {
        text: 'Have you created a task recently? Let’s set up your weekly goals in Office Otter!',
        picType: 'month',
    },
    {
        text: 'It’s been awhile. You otter start keeping track of you tasks!',
        picType: 'productivity',
    },
    { text: 'What bills need to be paid?', picType: 'money' },
    { text: 'What mail needs to be processed?', picType: 'mail' },
    { text: 'Who do you need to follow up with this week?', picType: 'collab' },
    {
        text: 'Do any of your tasks need to be updated or modified?',
        picType: 'productivity',
    },
    { text: 'What meetings do you have this week?', picType: 'month' },
    { text: 'What meetings do you have this month?', picType: 'month' },
    { text: 'What meetings do you have today?', picType: 'month' },
    {
        text: 'Do you have any articles you need to read this week? Text the link to Officer Otter and we’ll make it a task!',
        picType: 'text',
    },
    {
        text: 'Need to create a task on the go? Text it to Officer Otter!',
        picType: 'text',
    },
    { text: 'How can you mix up your routine today?', picType: 'productivity' },
    { text: 'It’s been awhile. Let’s set some goals!', picType: 'month' },
    {
        text: 'What are 2 ways you can improve your work efficiency this week?',
        picType: 'productivity',
    },
    {
        text: 'What are 2 ways you and your coworkers can improve your teamwork this month?',
        picType: 'collab',
    },
    {
        text: 'Is your current project too big for one task? Let’s break it down into more manageable smaller tasks!',
        picType: 'checklist',
    },
    {
        text: 'Overbooked? Who can you reach out to for a helping hand today?',
        picType: 'overwhelmed',
    },
    {
        text: 'Overwhelmed? Let’s prioritize what needs to get done first!',
        picType: 'overwhelmed',
    },
    {
        text: 'What is something non work-related that you would like to get done today?',
        picType: 'nonwork',
    },
    {
        text: 'What are 3 non work-related tasks you would like to get done this week?',
        picType: 'nonwork',
    },
    {
        text: 'What are 3 non work-related tasks you would like to get done this month?',
        picType: 'nonwork',
    },
    {
        text: 'What needs to get done around the house this weekend?',
        picType: 'nonwork',
    },
    {
        text: "You're on a roll!",
        picType: 'productivity',
    },
    {
        text: 'Enjoy the rest of your day!',
        picType: 'nonwork',
    },
    {
        text: 'Outstanding work today!',
        picType: 'productivity',
    },
    {
        text: 'Treat yourself!',
        picType: 'productivity',
    },
    {
        text: 'Extraordinary work!',
        picType: 'productivity',
    },
    {
        text: "You're unstoppable!",
        picType: 'productivity',
    },
    {
        text: 'Keep up the good work!',
        picType: 'productivity',
    },
]

export const getRandomPrompt = () => {
    return prompts[Math.floor(Math.random() * prompts.length)].text
}

export const getPrompt = (index) => {
    return prompts[index].text
}

export const getSmartPrompt = (urgency, today, numCompletedToday) => {
    let options = []
    if (numCompletedToday >= 5) {
        options = [51, 52, 53, 54, 55, 56, 57]
    } else {
        const TOD =
            today < add(startOfDay(today), { hours: 12 })
                ? 'morning'
                : 'evening'
        switch (urgency) {
            case 0:
                if (TOD === 'morning') {
                    options = [3, 23, 24, 25, 33, 43, 45]
                } else {
                    options = [3, 23, 25, 28, 33, 34, 39, 43, 45, 50]
                }
                break
            case 1:
                if (TOD === 'morning') {
                    options = [2, 4, 8, 12, 14, 16, 18, 21, 22, 31, 40, 46, 47]
                } else {
                    options = [8, 9, 12, 14, 16, 18, 27, 28, 31, 39, 46]
                }
                break
            case 2:
                if (TOD === 'morning') {
                    options = [
                        1, 5, 7, 11, 13, 15, 17, 19, 20, 22, 26, 29, 32, 35, 37,
                        38, 42, 44, 48,
                    ]
                } else {
                    options = [
                        5, 7, 9, 11, 13, 15, 17, 19, 20, 28, 29, 32, 35, 38, 39,
                        42, 44, 48,
                    ]
                }
                break
            case 3:
                if (TOD === 'morning') {
                    options = [6, 10, 23, 24, 36, 41, 43, 45, 49]
                } else {
                    options = [6, 10, 23, 28, 30, 36, 39, 41, 43, 45, 49, 50]
                }
                break
            default:
                options = [2, 4, 8, 12, 14, 16, 18, 21, 22, 31, 40, 46, 47]
                break
        }
    }

    const option = options[Math.floor(Math.random() * options.length)]
    const prompt = prompts[option - 1]

    let pic
    switch (prompt.picType) {
        case 'email':
            pic = emailPics[Math.floor(Math.random() * emailPics.length)]
            break
        case 'text':
            pic = textPics[Math.floor(Math.random() * textPics.length)]
            break
        case 'productivity':
            pic =
                productivityPics[
                    Math.floor(Math.random() * productivityPics.length)
                ]
            break
        case 'collab':
            pic = collabPics[Math.floor(Math.random() * collabPics.length)]
            break
        case 'checklist':
            pic =
                checklistPics[Math.floor(Math.random() * checklistPics.length)]
            break
        case 'overwhelmed':
            pic =
                overwhelmedPics[
                    Math.floor(Math.random() * overwhelmedPics.length)
                ]
            break
        case 'month':
            pic = monthPics[Math.floor(Math.random() * monthPics.length)]
            break
        case 'money':
            pic = moneyPics[Math.floor(Math.random() * moneyPics.length)]
            break
        case 'mail':
            pic = mailPics[Math.floor(Math.random() * mailPics.length)]
            break
        case 'nonwork':
            pic = nonWorkPics[Math.floor(Math.random() * nonWorkPics.length)]
            break
        default:
            pic =
                productivityPics[
                    Math.floor(Math.random() * productivityPics.length)
                ]
            break
    }
    return { text: prompt.text, pic: pic }
}

export const checkPromptTypes = () => {
    prompts.forEach((prompt, index) => {
        if (!prompt.text) {
            console.log('no text at: ' + index)
        } else {
            console.log('check')
        }
        if (!prompt.picType) {
            console.log('no pic type at: ' + index)
        } else {
            if (
                !(
                    prompt.picType === 'email' ||
                    prompt.picType === 'text' ||
                    prompt.picType === 'productivity' ||
                    prompt.picType === 'collab' ||
                    prompt.picType === 'checklist' ||
                    prompt.picType === 'overwhelmed' ||
                    prompt.picType === 'month' ||
                    prompt.picType === 'money' ||
                    prompt.picType === 'mail' ||
                    prompt.picType === 'nonwork'
                )
            ) {
                console.log('invalid pic type at: ' + index)
            } else {
                console.log('check')
            }
        }
    })
}
