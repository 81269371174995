import React, { useState, useContext } from 'react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Box,
    Flex,
    Button,
    Tooltip,
} from '@chakra-ui/react'
import {
    AddTaskIcon,
    TemplateIcon,
    LabelIcon,
    AttachmentIcon,
    AttachmentSlantIcon,
    KebabIcon,
    TrashIcon,
} from '../../ChakraDesign/Icons'
import { apiCall } from '../../Util/api'
import UploadButton from '../../Tasks/TaskCard/UploadButton'
import LabelModal from '../../Tasks/LabelModal'

export default function TemplateActionBar({
    userId,
    cards,
    setCards,
    section,
    templateLabels,
    setTemplateLabels,
    templateId,
    updateTemplate,
    deleteTemplate,
    handleUploadSuccess,
    actionButtonText,
    actionButtonFunction,
}) {
    const [isLabelsModalOpen, setIsLabelsModalOpen] = useState(false)

    const addFile = async (formData) => {
        const response = await apiCall(
            `POST`,
            `/users/${userId}/files?taskId=${templateId}&template=true`,
            formData
        )
        return response
    }

    const updateTemplateLabels = (newLabels) => {
        updateTemplate(templateId, { labels: newLabels })
    }

    const ForwardRefWrapper = React.forwardRef(({ children, ...rest }, ref) => (
        <span ref={ref} {...rest}>
            {children}
        </span>
    ))

    const CustomTooltip = ({ label, children }) => (
        <Tooltip label={label}>
            <ForwardRefWrapper>{children}</ForwardRefWrapper>
        </Tooltip>
    )

    const MoreActionsButton = React.forwardRef(({ children, ...rest }, ref) => (
        <span ref={ref} {...rest}>
            <Button variant="more-actions" leftIcon={<KebabIcon />}>
                {children}
            </Button>
        </span>
    ))

    return (
        <Flex justify="space-between" mt="24px">
            <Flex align="center">
                <CustomTooltip label={actionButtonText}>
                    <Button
                        size="lg"
                        alignItems="center"
                        pr="30px"
                        backgroundColor="blue.50"
                        color="blue.500"
                        onClick={(e) => actionButtonFunction(e)}
                    >
                        <TemplateIcon mt="2px" mr="2px" />
                        {actionButtonText}
                    </Button>
                </CustomTooltip>
                <Box ml="16px">
                    {templateLabels?.map((label) => (
                        <Button
                            variant="chip-colored"
                            background={
                                label.color === '' ? 'blue.500' : label.color
                            }
                            onClick={() => setIsLabelsModalOpen(true)}
                        >
                            {label.name}
                        </Button>
                    ))}
                </Box>
            </Flex>
            {section === 0 && (
                <Flex align="center">
                    <Flex align="center" display={{ base: 'none', md: 'flex' }}>
                        <CustomTooltip label="Add and remove labels">
                            <Button
                                variant="icon-button-text"
                                onClick={() => setIsLabelsModalOpen(true)}
                                leftIcon={<LabelIcon />}
                            >
                                Label
                            </Button>
                        </CustomTooltip>
                        <UploadButton
                            addFile={addFile}
                            onUploadSuccess={handleUploadSuccess}
                        >
                            <CustomTooltip label="Add attachment">
                                <Button
                                    variant="icon-button-text"
                                    leftIcon={<AttachmentSlantIcon />}
                                >
                                    Attachment
                                </Button>
                            </CustomTooltip>
                        </UploadButton>
                    </Flex>
                    <Menu isLazy>
                        <CustomTooltip label="More actions">
                            <MenuButton as={MoreActionsButton}>More</MenuButton>
                        </CustomTooltip>
                        <MenuList>
                            <MenuItem
                                icon={<AddTaskIcon />}
                                onClick={(e) => actionButtonFunction(e)}
                            >
                                Add to Task List
                            </MenuItem>
                            <MenuItem
                                icon={<LabelIcon />}
                                onClick={() => setIsLabelsModalOpen(true)}
                            >
                                Add Label
                            </MenuItem>
                            <UploadButton
                                addFile={addFile}
                                onUploadSuccess={handleUploadSuccess}
                            >
                                <MenuItem
                                    icon={<AttachmentIcon />}
                                    closeOnSelect={false}
                                >
                                    Add attachment
                                </MenuItem>
                            </UploadButton>
                            <MenuItem
                                icon={<TrashIcon />}
                                onClick={() => deleteTemplate(templateId)}
                            >
                                Delete
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            )}
            {isLabelsModalOpen && (
                <LabelModal
                    cards={cards}
                    setCards={setCards}
                    isOpen={isLabelsModalOpen}
                    selectedLabels={templateLabels}
                    setSelectedLabels={setTemplateLabels}
                    updateCardLabels={updateTemplateLabels}
                    onClose={() => setIsLabelsModalOpen(false)}
                />
            )}
        </Flex>
    )
}
