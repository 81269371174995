import React from 'react'
import { Box, Grid, Spacer, Flex } from '@chakra-ui/react'
import { ColorModeSwitcher } from './ColorModeSwitcher'
import Colors from './Colors'
import Buttons from './Buttons'
import Typography from './Typography'
import Displays from './Displays'
import Inputs from './Inputs'
import Icons from './Icons'

export default function Overview() {
    return (
        <Box>
            <Grid p={16}>
                {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
                <Flex>
                    <Colors />
                    <Spacer />
                    <Typography />
                    <Spacer />
                    <Buttons />
                    <Spacer />
                    <Inputs />
                </Flex>
                <Flex>
                    <Displays />
                    <Spacer />
                    <Icons />
                </Flex>
            </Grid>
        </Box>
    )
}
