import React, { useState, useEffect, useContext } from 'react'
import {
    Box,
    Switch,
    FormControl,
    FormLabel,
    Text,
    useToast,
    Flex,
    Radio,
    RadioGroup,
} from '@chakra-ui/react'
import { setMinutes, setHours, setSeconds, getDate, getMonth } from 'date-fns'
import { covertTimeTo12forMenu, convertTime12to24 } from '../Util/timeUtils'
import { DatePickerWithDayOfWeek } from '../SharedComponents/DatePicker'
import { analytics, apiCall } from '../Util/api'
import OOModal from '../SharedComponents/OOModal'
import { getUrgencyBasedOnDueDate } from './sortUtils'
import { URGENCIES } from '../Util/urgencies'
import RecurrenceForm, { blankForm } from './TaskCard/RecurrenceForm'
import ToastyBoi from '../SharedComponents/ToastyBoi'
import { CircleCheckIcon } from '../ChakraDesign/Icons'
import { Link } from 'react-router-dom'
import { RRule } from 'rrule'
import AllRecurrencesModal from '../Recurrence/AllRecurrencesModal'
import { UserContext } from '../Contexts/UserContext'
import { TasksContext } from '../Contexts/TasksContext'

export default function DueDateModal({
    due,
    submit,
    isOpen,
    onClose,
    urgencyDescription,
    urgency,
    recurrenceId,
    taskId,
}) {
    const { user } = useContext(UserContext)
    const { updateTask } = useContext(TasksContext)

    //due date and recurrence dtstart
    const [date, setDate] = useState(
        due ? new Date(due) : new Date(new Date().setHours(10, 0, 0))
    )
    const [invalidTime, setInvalidTime] = useState(false)
    const [time, setTime] = useState(
        due ? covertTimeTo12forMenu(new Date(due)) : '10:00AM'
    )
    const toast = useToast()

    //recurrence form state
    const [showRecurrenceForm, setShowRecurrenceForm] = useState(
        Boolean(recurrenceId)
    )
    const [showForJustThisOrFutureOption, setShowForJustThisOrFutureOption] =
        useState(false)
    const [thisTaskOrFutureTasksToo, setThisTaskOrFutureTasksToo] =
        useState('this task')
    const [addTasksOnDay, setAddtasksOnDay] = useState(0)
    const [rrule, setRrule] = useState({
        ...blankForm,
        dtstart: new Date(date),
    })
    const [removeExistingRecurrences, setRemoveExistingRecurrences] =
        useState(false)
    const [isAllRecurrenceModalOpen, setIsAllRecurrenceModalOpen] =
        useState(false)

    //if the time changes, ask the user if they want to change the time for just the current task or also the recurring tasks
    //also need to update the dtstart time so the recurrence predictor show right days
    useEffect(() => {
        if (recurrenceId && time !== covertTimeTo12forMenu(new Date(due))) {
            setShowForJustThisOrFutureOption(true)
        }
        setRrule({
            ...rrule,
            dtstart: new Date(date),
        })
    }, [time])

    //if the date changes, need to update the recurrence dtstart, bymonthday, bymonth params because they're calculated off dtstart/due date
    useEffect(() => {
        if (recurrenceId && date.toISOString() != new Date(due).toISOString()) {
            setShowForJustThisOrFutureOption(true)
        }

        setRrule({
            ...rrule,
            dtstart: new Date(date),
            bymonthday:
                rrule.freq === RRule.MONTHLY
                    ? Number(getDate(new Date(date)))
                    : '',
            bymonth:
                rrule.freq === RRule.YEARLY
                    ? Number(getMonth(new Date(date))) + 1
                    : '',
        })
    }, [date])

    const handleSubmit = async () => {
        //if we're removing the existing recurrences we don't need to update, a new task will take it's place
        if (!removeExistingRecurrences) {
            submit({ due: date })
        }

        //if the task is recurring, make recurrence calculations
        if (showRecurrenceForm) {
            let ruleParams = { ...rrule, dtstart: date }
            for (let key in ruleParams) {
                if (ruleParams[key] === '' || ruleParams[key] === null) {
                    delete ruleParams[key]
                }
            }

            if (ruleParams.freq === 2 && ruleParams.byweekday.length === 0) {
                alert('Please select at least one day of the week')
                return
            }

            try {
                //were updating a recurrence but not changing the time
                if (recurrenceId && !showForJustThisOrFutureOption) {
                    await apiCall(
                        'PUT',
                        `/users/${user._id}/recurrence/${recurrenceId}?removeExistingRecurrences=${removeExistingRecurrences}`,
                        {
                            rruleParams: ruleParams,
                            daysInAdvance: Number(addTasksOnDay),
                        }
                    )
                } //if the time is being changed, and the user wants it to reflect current recurrences, we need to remove existing ones and re-populate
                else if (
                    recurrenceId &&
                    thisTaskOrFutureTasksToo === 'this task and future tasks'
                ) {
                    await apiCall(
                        'PUT',
                        `/users/${user._id}/recurrence/${recurrenceId}?removeExistingRecurrences=${removeExistingRecurrences}`,
                        {
                            rruleParams: ruleParams,
                            daysInAdvance: Number(addTasksOnDay),
                        }
                    )
                } else if (
                    recurrenceId &&
                    showForJustThisOrFutureOption &&
                    thisTaskOrFutureTasksToo === 'this task'
                ) {
                    //if showForJustThisOrFutureOption is true and is 'this task' dont update recurrence
                } else {
                    //if task is creating a new recurrence job
                    const recurrence = await apiCall(
                        'POST',
                        `/users/${user._id}/recurrence/${taskId}`,
                        {
                            rruleParams: ruleParams,
                            daysInAdvance: Number(addTasksOnDay),
                        }
                    )
                }

                toast({
                    duration: 3000,
                    render: () => (
                        <ToastyBoi
                            message={'Recurrence created.'}
                            icon={<CircleCheckIcon fill="white" />}
                            backgroundColor="blue.500"
                        ></ToastyBoi>
                    ),
                })
            } catch (error) {
                alert(JSON.stringify(error))
            }
        }
        analytics.trigger({ name: 'Due Date Assigned', user })
    }

    const handleRemove = () => {
        submit({ due: null, urgency })
        onClose()
    }

    const handleRemoveRecurrence = async () => {
        try {
            await apiCall(
                'DELETE',
                `/users/${user._id}/recurrence/${recurrenceId}`
            )
            updateTask(taskId, { recurrenceId: null }, urgency)
            setShowForJustThisOrFutureOption(false)
            setShowRecurrenceForm(false)
            toast({
                duration: 3000,
                render: () => (
                    <ToastyBoi
                        message={'Recurrence removed.'}
                        icon={<CircleCheckIcon fill="white" />}
                        backgroundColor="blue.500"
                    ></ToastyBoi>
                ),
            })
        } catch (error) {}
    }

    const handleSetTime = (time) => {
        const [hours, minutes] = convertTime12to24(time).split(':')
        let fullDate = setHours(new Date(date), hours)
        fullDate = setMinutes(fullDate, minutes)
        fullDate = setSeconds(fullDate, 0)
        setTime(time)
        setDate(fullDate)
    }

    const handleSetDate = (date) => {
        const [hours, minutes] = convertTime12to24(time).split(':')
        let fullDate = setHours(new Date(date), hours)
        fullDate = setMinutes(fullDate, minutes)
        fullDate = setSeconds(fullDate, 0)

        setDate(fullDate)
    }

    return (
        <OOModal
            title="Set a due date"
            onSubmit={handleSubmit}
            disableSubmit={invalidTime}
            secondaryButton={
                due && {
                    onClick: handleRemove,
                    text: 'remove due date',
                }
            }
            isOpen={isOpen}
            onClose={onClose}
        >
            <Flex>
                <DatePickerWithDayOfWeek
                    time={time}
                    setTime={handleSetTime}
                    invalidTime={invalidTime}
                    setInvalidTime={setInvalidTime}
                    date={new Date(date)}
                    setDate={handleSetDate}
                />
            </Flex>
            <Flex mt="4px">
                {urgencyDescription && (
                    <Flex>
                        <Text fontSize="sm" marginRight="3px">
                            This task will be moved to{' '}
                        </Text>
                        <Text
                            color="blue.500"
                            fontWeight="bold"
                            fontSize="sm"
                            marginRight="6px"
                        >
                            {URGENCIES[getUrgencyBasedOnDueDate(date)].emoji}
                        </Text>
                        <Text color="blue.500" fontWeight="bold" fontSize="sm">
                            {URGENCIES[getUrgencyBasedOnDueDate(date)].text}
                        </Text>
                    </Flex>
                )}
            </Flex>
            {showForJustThisOrFutureOption && recurrenceId && (
                <Box>
                    <FormLabel style={{ marginTop: 16 }} fontSize="sm">
                        Change date and/or time for
                    </FormLabel>

                    <RadioGroup
                        name="thisTaskOrFutureTasksToo"
                        value={thisTaskOrFutureTasksToo}
                        onChange={setThisTaskOrFutureTasksToo}
                    >
                        <Radio value="this task">This task</Radio>
                        <Box>
                            <Radio value="this task and future tasks">
                                This task and future tasks
                            </Radio>{' '}
                        </Box>
                    </RadioGroup>
                </Box>
            )}
            <Box mt="16px">
                <FormControl
                    display="flex"
                    alignItems="center"
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (Boolean(recurrenceId)) {
                            handleRemoveRecurrence()
                        }
                        setShowRecurrenceForm(!showRecurrenceForm)
                    }}
                    w="211px"
                >
                    <FormLabel mb="0">Make task repeat?</FormLabel>
                    <Switch isChecked={showRecurrenceForm} mr="16px" />
                </FormControl>
                {recurrenceId && (
                    <Flex
                        mt="16px"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box color="blue.500">
                            <Text
                                color="blue.500"
                                style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    setIsAllRecurrenceModalOpen(true)
                                }
                            >
                                view all recurring tasks
                            </Text>
                        </Box>
                        <Text
                            color="red.500"
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={handleRemoveRecurrence}
                        >
                            {' '}
                            end this recurrence
                        </Text>
                    </Flex>
                )}
            </Box>
            {showRecurrenceForm && (
                <Box mt="16px">
                    <RecurrenceForm
                        recurrenceId={recurrenceId}
                        userId={user._id}
                        date={date}
                        rrule={rrule}
                        setRrule={setRrule}
                        addTasksOnDay={addTasksOnDay}
                        setAddtasksOnDay={setAddtasksOnDay}
                    />
                    {Boolean(recurrenceId) && (
                        <FormControl
                            display="flex"
                            alignItems="center"
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setRemoveExistingRecurrences(true)
                            }}
                            mt="16px"
                        >
                            <FormLabel mb="0" fontSize="sm" fontWeight="bold">
                                Replace existing recurrences?
                            </FormLabel>
                            <Switch
                                isChecked={removeExistingRecurrences}
                                mr="16px"
                            />
                        </FormControl>
                    )}
                </Box>
            )}
            {isAllRecurrenceModalOpen && (
                <AllRecurrencesModal
                    isOpen={isAllRecurrenceModalOpen}
                    setIsOpen={() => setIsAllRecurrenceModalOpen(false)}
                />
            )}
        </OOModal>
    )
}
