import React, { useState, useContext } from 'react'
import {
    Button,
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Input,
    useToast,
    FormControl,
    FormLabel,
    CircularProgress,
} from '@chakra-ui/react'
import { apiCall } from '../Util/api'
import ToastyBoi from '../SharedComponents/ToastyBoi'
import { Formik, Form } from 'formik'
import { CircleCheckIcon } from '../ChakraDesign/Icons'
import { UserContext } from '../Contexts/UserContext'

export default function ReferAFriend() {
    const { user } = useContext(UserContext)
    const [isOpen, setIsOpen] = useState(false)
    const [name, setName] = useState('')

    const toast = useToast()

    const sendEmailInvitation = async (values) => {
        console.log(values)
        try {
            await apiCall(
                'POST',
                `/users/${user._id}/notifications/invite`,
                values
            )
            toast({
                duration: 3000,
                render: () => (
                    <ToastyBoi
                        message={'Successfully invited ' + name}
                        icon={<CircleCheckIcon fill="white" />}
                        backgroundColor="blue.500"
                    ></ToastyBoi>
                ),
            })
        } catch (error) {
            console.log(error)
        }
        setIsOpen(false)
    }

    return (
        <Box
            width="100%"
            paddingLeft="8px !important"
            paddingRight="8px !important"
            display={{ base: 'none', md: 'block' }}
        >
            <Button
                onClick={() => setIsOpen(true)}
                colorScheme="blue"
                size="md"
                mt="8px"
                width="100%"
            >
                refer a friend!
            </Button>
            {isOpen && (
                <Modal
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    size="xl"
                >
                    <ModalOverlay />
                    <ModalContent>
                        <Formik
                            initialValues={{ name: '', email: '', message: '' }}
                            onSubmit={async (values, actions) => {
                                await sendEmailInvitation(values)
                            }}
                        >
                            {({ values, handleChange, isSubmitting }) => (
                                <>
                                    <ModalHeader fontSize="lg">
                                        Know someone who will love Office Otter?
                                    </ModalHeader>
                                    <ModalCloseButton marginTop="5px" />
                                    <ModalBody>
                                        <Form
                                            style={{ width: '100%' }}
                                            id="referralform"
                                        >
                                            <FormControl id="name" isRequired>
                                                <FormLabel>Name</FormLabel>
                                                <Input
                                                    type="text"
                                                    size="lg"
                                                    variant="filled"
                                                    placeholder="Your friend's name!"
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    name="name"
                                                />
                                            </FormControl>
                                            <FormControl id="email" isRequired>
                                                <FormLabel mt="16px">
                                                    Email address
                                                </FormLabel>
                                                <Input
                                                    size="lg"
                                                    variant="filled"
                                                    placeholder="Type your friend's email!"
                                                    type="email"
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    name="email"
                                                />
                                            </FormControl>
                                            <FormControl id="email" isRequired>
                                                <FormLabel mt="16px">
                                                    Message to send with email
                                                    invite{' '}
                                                </FormLabel>
                                                <Input
                                                    type="text"
                                                    size="lg"
                                                    variant="filled"
                                                    placeholder="Why you think they'll love Office Otter..."
                                                    onChange={handleChange}
                                                    value={values.message}
                                                    name="message"
                                                />
                                            </FormControl>
                                        </Form>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button
                                            type="submit"
                                            form="referralform"
                                            colorScheme="blue"
                                            variant="solid"
                                            size="md"
                                        >
                                            {isSubmitting ? (
                                                <CircularProgress
                                                    isIndeterminate
                                                    size="24px"
                                                />
                                            ) : (
                                                'Send'
                                            )}
                                        </Button>
                                    </ModalFooter>
                                </>
                            )}
                        </Formik>
                    </ModalContent>
                </Modal>
            )}
        </Box>
    )
}
