import React, { useState, useEffect } from 'react'
import { Box, Button, Flex } from '@chakra-ui/react'
import Wheel from '@uiw/react-color-wheel'
import { hexToHsva } from '@uiw/color-convert'
import OOModal from '../SharedComponents/OOModal'

export default function ColorPicker({
    label,
    colors,
    isOpen,
    onClose,
    setting,
    setLabel,
    updateLabel,
}) {
    const [hsva, setHsva] = useState(hexToHsva(label.color || colors[0]))

    const handleColorWheelChange = (color) => {
        setHsva({ ...hsva, ...color.hsva })
        setLabel({ ...label, color: color.hex })
    }

    const handleColorSelection = (color) => {
        const newHsva = hexToHsva(color)

        setHsva(newHsva)
        setLabel({ ...label, color })
    }

    const handleSubmit = () => {
        setting === 'editColor' && updateLabel()
    }

    return (
        <OOModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title="Pick a Color"
        >
            <Flex direction="column" alignItems="start">
                <Button
                    variant="chip-colored"
                    mb="24px"
                    backgroundColor={
                        label.color !== '' ? label.color : colors[0]
                    }
                >
                    {label.name || 'example label'}
                </Button>
                <Flex width="100%">
                    <Flex
                        alignContent="start"
                        justifyContent="start"
                        flexWrap="wrap"
                        flexDir="column"
                        w="200px"
                        h="200px"
                    >
                        {colors.map((color) => (
                            <Box
                                m="8px"
                                bg={color}
                                h="32px"
                                w="32px"
                                cursor="pointer"
                                borderRadius="16px"
                                onClick={() => handleColorSelection(color)}
                            />
                        ))}
                    </Flex>
                    <Box>
                        <Wheel
                            color={hsva}
                            onChange={handleColorWheelChange}
                            style={{
                                cursor: 'pointer',
                                borderRadius: '50%',
                                boxShadow:
                                    '0px 0px 10px 8px rgba(56, 96, 165, 0.15)',
                            }}
                        />
                    </Box>
                </Flex>
            </Flex>
        </OOModal>
    )
}
