import React, { useState, useEffect, useContext } from 'react'
import { Flex, Text, Avatar, Button, Box } from '@chakra-ui/react'
import { apiCall, stripeAPI, analytics } from '../../Util/api'
import { UserContext } from '../../Contexts/UserContext'
import { useToast } from '@chakra-ui/react'
import { CircleCheckIcon } from '../../ChakraDesign/Icons'
import ToastyBoi from '../../SharedComponents/ToastyBoi'
import UploadButton from '../../Tasks/TaskCard/UploadButton'
import YourInfo from './YourInfo'
import CompanyInfo from './CompanyInfo'
import Customization from './Customization'
import Integrations from './Integrations'
import Notifications from './Notifications'
import Subscriptions from './Subscriptions'
import InboxSettings from './InboxSettings'

export default function FormContainer({ section }) {
    const toaster = useToast()
    const { user, setUser } = useContext(UserContext)

    const [totalCompleted, setTotalCompleted] = useState(0)
    const [billingAmount, setBillingAmount] = useState(0)
    const [billingInterval, setBillingInterval] = useState('month')
    const [profilePhoto, setProfilePhoto] = useState(null)
    const [profileText, setProfileText] = useState('')

    const submit = async (newValues) => {
        try {
            const newUserData = await apiCall(
                'PUT',
                `/users/${user._id}`,
                newValues
            )
            toaster({
                status: 'success',
                duration: 3000,
                isClosable: true,
                render: () => (
                    <ToastyBoi
                        message={'Settings successfully saved!'}
                        icon={<CircleCheckIcon fill="white" />}
                        backgroundColor="blue.500"
                    />
                ),
            })
            setUser(newUserData)
        } catch (error) {
            console.log(error)
        }
    }

    const getSubscriptionInfo = async () => {
        try {
            if (user.stripeProductId === '') {
                const numCompletedChecklistsTasks = await apiCall(
                    `GET`,
                    `/users/${user._id}/completed`
                )
                setTotalCompleted(numCompletedChecklistsTasks)
            } else {
                const customer = await stripeAPI(
                    `GET`,
                    `/customers/${user.stripeCustomerId}?expand[]=subscriptions`
                )

                const plan = customer.subscriptions.data[0]?.items.data[0].plan
                setBillingAmount(plan.amount / 100)
                setBillingInterval(plan.interval)
            }
        } catch (error) {}
    }

    const createProfilePic = async (formData) => {
        if (![...formData][0][1].type.includes('image')) {
            alert(
                'File must be an image. Accepted file types are jpeg, gif, png, svg, bmp, and ico'
            )
            throw formData
        } else {
            try {
                const newPictureFile = await apiCall(
                    `POST`,
                    `/users/${user._id}/files/create`,
                    formData
                )

                return newPictureFile
            } catch (error) {
                console.log(error)
            }
        }
    }

    const onNewProfilePic = (newFile) => {
        try {
            let updatedUser = { ...user }
            let newPicURL = ''

            if (newFile) {
                newPicURL = newFile.url
            }
            updatedUser.profilePhotoUrl = newPicURL
            setUser(updatedUser)
            setProfilePhoto(newPicURL)
            setProfileText('edit')

            apiCall(`PUT`, `/users/${user._id}`, {
                profilePhotoUrl: newPicURL,
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (user.profilePhotoUrl === '') {
            setProfileText('add')
        } else {
            setProfilePhoto(user.profilePhotoUrl)
            setProfileText('edit')
        }
        analytics.trigger({ name: 'User Profile Viewed', user })
        getSubscriptionInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Flex
            direction="column"
            justify="center"
            align="center"
            maxW="400px"
            ml={{ base: 'auto', md: 'auto' }}
            mr={{ base: 'auto', md: 'auto' }}
            pl={{ base: '2px', md: '' }}
            pr={{ base: '2px', md: '' }}
        >
            <Flex align="center" mt="16px">
                <Flex direction="column">
                    <Avatar
                        fontWeight="normal"
                        color="white"
                        cursor="pointer"
                        size="lg"
                        overflow="hidden"
                        src={profilePhoto}
                        onClick={() =>
                            document.getElementById('profile-icon').click()
                        }
                    >
                        <Box position="absolute">
                            <UploadButton
                                addFile={createProfilePic}
                                onUploadSuccess={onNewProfilePic}
                            >
                                <Button
                                    w="64px"
                                    borderRadius="0px"
                                    mt="56px"
                                    opacity="0.8"
                                    p="0px"
                                    alignItems="start"
                                    ref={(node) =>
                                        node && (node.id = 'profile-icon')
                                    }
                                >
                                    <Text
                                        color="black"
                                        mt="2px"
                                        decoration="underline"
                                    >
                                        {profileText}
                                    </Text>
                                </Button>
                            </UploadButton>
                        </Box>
                    </Avatar>
                </Flex>
                <Text ml="16px" fontSize="28px" fontWeight="bold">
                    {user.name}
                </Text>
            </Flex>
            {section === 0 && <YourInfo user={user} submit={submit} />}
            {section === 1 && <CompanyInfo user={user} submit={submit} />}
            {section === 2 && <InboxSettings user={user} submit={submit} />}
            {section === 3 && <Customization user={user} submit={submit} />}
            {section === 4 && <Integrations user={user} submit={submit} />}
            {section === 5 && <Notifications user={user} submit={submit} />}
            {section === 6 && (
                <Subscriptions
                    user={user}
                    totalCompleted={totalCompleted}
                    billingAmount={billingAmount}
                    billingInterval={billingInterval}
                />
            )}
        </Flex>
    )
}
