import React, { useContext, useState } from 'react'
import { Flex, Text, Box } from '@chakra-ui/react'
import { UserContext } from '../Contexts/UserContext'
import { apiCall } from '../Util/api'
import { AVATARS } from './avatars/avatars'
import Avatar from './avatars/Avatar'
import { isPaidUser } from '../Util/permissions'
import { PaidPopUpContext } from '../Contexts/PaidPopupContext'
import Document from '@tiptap/extension-document'
import { useEditor, EditorContent, Extension } from '@tiptap/react'
import Link from '@tiptap/extension-link'
import HardBreak from '@tiptap/extension-hard-break'
import { Text as ttText } from '@tiptap/extension-text'
import Paragraph from '@tiptap/extension-paragraph'
import { Placeholder } from '@tiptap/extension-placeholder'

export default function Comment({
    avatarAnimal,
    description,
    commentUserId,
    posts,
    setPosts,
    commentId,
    postId,
    comments,
}) {
    const { user } = useContext(UserContext)
    const [newComment, setNewComment] = useState('')
    const { setPaidPopup } = useContext(PaidPopUpContext)

    const createComment = async (desc) => {
        if (isPaidUser(user)) {
            const createdComment = await apiCall(
                'POST',
                `/users/${user._id}/post/${postId}/comment`,
                {
                    description: desc,
                    user: user._id,
                    parentCommentId: commentId,
                }
            )

            const newPosts = posts.map((p) =>
                p._id === postId
                    ? {
                          ...p,
                          comments: p.comments.map((c) =>
                              c._id === commentId
                                  ? {
                                        ...c,
                                        childComments: [
                                            ...c.childComments,
                                            createdComment,
                                        ],
                                    }
                                  : c
                          ),
                      }
                    : p
            )
            setPosts(newPosts)
            setNewComment('')

            return createdComment._id
        } else {
            setPaidPopup({
                show: true,
                reason: 'Oops! It looks like you hit a limit on your free plan 😢. Upgrade to comment on a post.',
            })
        }
    }

    return (
        <>
            <Flex
                mt="12px"
                background="gray.100"
                p="16px"
                borderRadius="16px"
                mb="16px"
            >
                <Avatar
                    size="48px"
                    img={AVATARS.filter((a) => a.name === avatarAnimal)[0]?.img}
                    style={{
                        outline:
                            user._id === commentUserId && '4px solid #fab6b2',
                        position: 'absolute',
                        marginLeft: 8,
                        marginTop: -44,
                    }}
                ></Avatar>
                <Flex flexDirection="column" w="100%">
                    <Text mt="16px">
                        <CommentForm
                            description={description}
                            editable={false}
                        />
                    </Text>
                    <Flex alignItems="center" w="100%" mt="8px">
                        <Flex alignItems="center" width="100%" ml="16px">
                            <Box w="100%">
                                <CommentForm
                                    description={newComment}
                                    handleUpdate={(desc) => createComment(desc)}
                                    editable
                                />
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            {comments?.map((c) => (
                <Flex
                    mt="4px"
                    background="gray.100"
                    p="16px"
                    borderRadius="16px"
                    mb="12px"
                    ml="32px"
                    key={c._id}
                >
                    <Avatar
                        size="30px"
                        img={
                            AVATARS.filter((a) => a.name === c.avatarAnimal)[0]
                                ?.img
                        }
                        style={{
                            outline:
                                user._id === commentUserId &&
                                '4px solid #fab6b2',
                            position: 'absolute',
                            marginLeft: 8,
                            marginTop: -36,
                        }}
                    ></Avatar>
                    <Flex flexDirection="column" w="100%">
                        <Text fontSize="14px" pt="4px">
                            <CommentForm
                                description={c.description}
                                editable={false}
                            />
                        </Text>
                    </Flex>
                </Flex>
            ))}
        </>
    )
}

export const CommentForm = ({ description, handleUpdate, editable }) => {
    let editor = useEditor({
        extensions: [
            Document,
            Paragraph,
            ttText,
            HardBreak,

            Link.configure({ HTMLAttributes: { class: 'description-link' } }),
            Placeholder.configure({
                // Use a placeholder:
                placeholder: 'Respond with a comment…',
                // Use different placeholders depending on the node type:
                // placeholder: ({ node }) => {
                //   if (node.type.name === 'heading') {
                //     return 'What’s the title?'
                //   }

                //   return 'Can you add some further context?'
                // },
            }),
        ],
        editable: editable,
        content: description,
        editorProps: {
            attributes: {
                class: 'office-otter-community-form',
            },
        },
        injectCSS: false,
    })

    return (
        <Box>
            <EditorContent
                onKeyDown={(e) => {
                    if (e.keyCode === 13 && !e.shiftKey) {
                        const html = editor.getHTML()
                        // send the content to an API here
                        if (html.length > 7) {
                            handleUpdate(html.replace(/(<p><\/p>\s*)+$/, ''))

                            editor.commands.clearContent(true)
                        }
                    }
                }}
                editor={editor}
                placeholder="Respond to comment"
            />
            {editor && editable && editor.getText()?.length > 5 && (
                <Box mt="2px" fontSize="10px">
                    <b> Shift + return </b> to add a new line
                </Box>
            )}
        </Box>
    )
}
