import React, { useState } from 'react'
import { Input, Flex } from '@chakra-ui/react'
import OOModal from '../SharedComponents/OOModal'

export default function RenameFileDialogue({
    isOpen,
    onClose,
    updateFile,
    fileName,
    fileId,
}) {
    const [name, setName] = useState(fileName)

    const handleSubmit = (e) => {
        if (name.length > 0) {
            updateFile(fileId, { name: name })
        }

        onClose()
    }

    return (
        <OOModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title="Edit file name"
        >
            <Flex direction="column">
                <Input
                    id="label-input"
                    mt="8px"
                    mb="8px"
                    value={name}
                    placeholder="Edit file name..."
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={(e) => {
                        e.stopPropagation()
                        e.keyCode === 13 && handleSubmit(e)
                    }}
                    autoComplete="off"
                    autoFocus
                />
            </Flex>
        </OOModal>
    )
}
