import React, { useState } from 'react'
import {
    Box,
    Flex,
    Text,
    Image,
    Modal,
    Button,
    Checkbox,
    ModalFooter,
    ModalOverlay,
    ModalContent,
} from '@chakra-ui/react'

export default function ReportsPopup({
    isOpen,
    showPopup,
    setIsOpen,
    setShowPopup,
}) {
    const [step, setStep] = useState(1)

    const Dot = ({ isLarge }) => (
        <Box
            style={{
                height: isLarge ? 12 : 6,
                width: isLarge ? 12 : 6,
                backgroundColor: isLarge ? '#0a58ce' : '#8f9bb3',
                borderRadius: '50%',
                margin: 8,
            }}
        />
    )

    const Step1 = () => (
        <Box mt="16px" mb="16px">
            <Text
                fontSize="xl"
                style={{
                    textAlign: 'center',
                    fontWeight: 600,
                }}
            >
                {' '}
                Welcome to Reports! 📊
            </Text>
            <div
                style={{
                    margin: '32px auto',
                    width: 560,
                    height: 190,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    alignItems: 'end',
                    display: 'flex',
                    borderRadius: 16,
                    backgroundImage:
                        'url("https://office-otter-develop.s3.us-east-2.amazonaws.com/abc9251f-b0da-4492-a2e1-b05714c9037dlabels.png")',
                }}
            />
            <Text mt="24px" fontSize="md" textAlign="center" p="0px 32px">
                Reports are a great way to quantify your performance and
                identify trends in your productivity. Print or download your
                Reports and bring them to your next performance review!
            </Text>
        </Box>
    )

    const Step2 = () => (
        <Box>
            <Text mt="24px" fontSize="lg" fontWeight="500" textAlign="center">
                To print your reports, right-click anywhere on the page and
                select <span style={{ fontWeight: 800 }}>Print...</span>
            </Text>
            <div
                style={{
                    margin: '32px auto',
                    width: 560,
                    height: 340,
                    backgroundSize: 'cover',
                    alignItems: 'end',
                    display: 'flex',
                    borderRadius: 16,
                    backgroundImage:
                        'url("https://office-otter-develop.s3.us-east-2.amazonaws.com/686fea46-ac85-487b-825a-2a692a77df30rightclick.png")',
                }}
            />
        </Box>
    )

    const Step3 = () => (
        <Box>
            <Text
                mt="24px"
                fontSize="lg"
                fontWeight="500"
                textAlign="center"
                p="16px"
            >
                In the destination input you can select your printer or choose{' '}
                <span style={{ fontWeight: 800 }}>Save as PDF</span> to download
            </Text>
            <div
                style={{
                    boxShadow: '0 8px 16px 0 rgba(56, 96, 165, 0.2)',
                    margin: '32px auto',
                    width: 700,
                    height: 340,
                    padding: 16,
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    alignItems: 'end',
                    display: 'flex',
                    borderRadius: 16,
                    backgroundImage:
                        'url("https://office-otter-develop.s3.us-east-2.amazonaws.com/f4fde334-6225-41db-a8bc-23bd03985db1saveaspdf.png")',
                }}
            />
            <Flex justify="center">
                <Checkbox
                    mb="8px"
                    isChecked={!showPopup}
                    onChange={() => setShowPopup(!showPopup)}
                >
                    Do not show message again
                </Checkbox>
            </Flex>
        </Box>
    )

    return (
        <>
            <link
                as="image"
                rel="preload"
                href="https://office-otter-develop.s3.us-east-2.amazonaws.com/686fea46-ac85-487b-825a-2a692a77df30rightclick.png"
            />
            <link
                as="image"
                rel="preload"
                href="https://office-otter-develop.s3.us-east-2.amazonaws.com/f4fde334-6225-41db-a8bc-23bd03985db1saveaspdf.png"
            />
            <link
                as="image"
                rel="preload"
                href="https://office-otter-develop.s3.us-east-2.amazonaws.com/abc9251f-b0da-4492-a2e1-b05714c9037dlabels.png"
            />

            <Modal isOpen={isOpen} size="xl" w="600px">
                <ModalOverlay />
                <ModalContent
                    maxW="800px"
                    style={{
                        display: 'flex',
                        justifyContent: 'start',
                        paddingTop: step < 8,
                        paddingLeft: '8px',
                        paddingRight: '8px',
                    }}
                >
                    <Flex mt="24px" align="center" justify="center">
                        <Dot isLarge={true} />
                        <Dot isLarge={step > 1} />
                        <Dot isLarge={step > 2} />
                    </Flex>

                    {step === 1 && <Step1 />}
                    {step === 2 && <Step2 />}
                    {step === 3 && <Step3 />}

                    <ModalFooter>
                        <Flex w="100%" justify="space-between">
                            {step > 1 && (
                                <Button
                                    id="progress-button"
                                    onClick={() => setStep(step - 1)}
                                >
                                    back
                                </Button>
                            )}
                            {step < 3 ? (
                                <Button
                                    ml="auto"
                                    id="progress-button"
                                    onClick={() => setStep(step + 1)}
                                >
                                    next
                                </Button>
                            ) : (
                                <Button
                                    colorScheme="blue"
                                    onClick={() => setIsOpen(false)}
                                >
                                    Got It!
                                </Button>
                            )}
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
