import React from 'react'
import { Center, Image, Text, Box } from '@chakra-ui/react'
import logo from './logo.png'
import { Link } from 'react-router-dom'

export const Logo = () => {
    return (
        <Link to="/tasks">
            <Center>
                <Center
                    height="52px"
                    width="52px"
                    shadow="base"
                    fontSize="xl"
                    padding="8px"
                    borderRadius="8px"
                >
                    🦦
                </Center>

                {/* <Image
                    boxSize="64px"
                    objectFit="cover"
                    src={logo}
                    alt="Office Otter"
                /> */}
                <Text
                    fontSize="xl"
                    fontWeight="bold"
                    letterSpacing="-0.75px"
                    display={{ base: 'none', lg: 'flex' }}
                    ml="8px"
                >
                    office otter
                </Text>
            </Center>
        </Link>
    )
}
