import React, { useState, useContext } from 'react'
import {
    Flex,
    Text,
    Button,
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    FormControl,
    FormLabel,
    FormHelperText,
    Input,
    CircularProgress,
} from '@chakra-ui/react'
import { UserContext } from '../Contexts/UserContext'
import { Formik, Form } from 'formik'
import { PhoneErrorMessage } from '../UserProfile/UserForms/YourInfo'
import PhoneInput from '../UserProfile/UserForms/PhoneInputs/PhoneInputComponent'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { apiCall } from '../Util/api'

export default function MoreInfoModal({ isOpen, name }) {
    const { user, setUser } = useContext(UserContext)

    const [phone, setPhone] = useState('')
    const [validPhone, setValidPhone] = useState(true)

    const handleClose = () => {
        setPhone('')
        setValidPhone(true)
    }

    const submitMoreInfo = async (values) => {
        const updatedUser = await apiCall(
            `PUT`,
            `/users/${user._id}/moreInfo`,
            {
                ...values,
                phone,
            }
        )
        setUser(updatedUser)
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent p="16px">
                <Text fontSize="lg" fontWeight="bold">
                    Tell us more about yourself
                </Text>
                <Formik
                    initialValues={{ name: name, phone: '', password: '' }}
                    onSubmit={async (values, actions) => {
                        if (isValidPhoneNumber(phone)) {
                            setValidPhone(true)
                            await submitMoreInfo(values)
                        } else {
                            setValidPhone(false)
                        }
                    }}
                >
                    {({ values, handleChange, isSubmitting }) => (
                        <>
                            <ModalBody>
                                <Form id="moreInfo">
                                    <FormControl id="name" isRequired>
                                        <FormLabel mt="16px">Name</FormLabel>
                                        <Input
                                            type="text"
                                            size="lg"
                                            variant="filled"
                                            placeholder="name"
                                            onChange={handleChange}
                                            value={values.name}
                                            name="name"
                                        />
                                    </FormControl>
                                    <FormControl id="password" isRequired>
                                        <FormLabel mt="16px">
                                            Password
                                        </FormLabel>
                                        <Input
                                            type="password"
                                            size="lg"
                                            variant="filled"
                                            placeholder="password"
                                            onChange={handleChange}
                                            value={values.password}
                                            name="password"
                                        />
                                    </FormControl>
                                    <FormControl id="phone" isRequired>
                                        <FormLabel mt="16px">Phone</FormLabel>
                                        <PhoneInput
                                            placeholder="Type your phone number..."
                                            value={phone}
                                            onChange={(num) => setPhone(num)}
                                        />
                                        {!validPhone && (
                                            <PhoneErrorMessage
                                                validPhone={validPhone}
                                            />
                                        )}
                                        <FormHelperText ml="16px">
                                            For our text integration
                                        </FormHelperText>
                                    </FormControl>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    colorScheme="blue"
                                    variant="solid"
                                    size="lg"
                                    width="100%"
                                    mt="8px"
                                    type="submit"
                                    form="moreInfo"
                                >
                                    {isSubmitting ? (
                                        <CircularProgress
                                            isIndeterminate
                                            size="32px"
                                        />
                                    ) : (
                                        'Update Info'
                                    )}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    )
}
