import React, { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { apiCall } from '../../Util/api'
import {
    Box,
    Flex,
    useToast,
    Tooltip,
    Text,
    Button,
    IconButton,
    Spacer,
    Progress,
    VStack,
    Icon,
    Collapse,
} from '@chakra-ui/react'
import {
    TemplateIcon,
    CarrotIcon,
    CircleCheckIcon,
} from '../../ChakraDesign/Icons'
import ToastyBoi from '../../SharedComponents/ToastyBoi'
import TemplateActionBar from './TemplateActionBar'
import AttachmentsList from '../../Tasks/TaskCard/AttachmentsList'
import Description from '../../Tasks/TaskCard/Description'
import Checklist from '../../Tasks/TaskCard/Checklist'
import Notes from '../../Tasks/TaskCard/Notes'
import LoadingModal from './LoadingModal'

export default function DiscoverTemplateCard({
    user,
    index,
    template,
    getMyTemplates,
    createTemplate,
}) {
    const toaster = useToast()

    const [expanded, setExpanded] = useState(false)
    const [description, setDescription] = useState(template.description)
    const [labels, setLabels] = useState(template.labels)
    const [notes, setNotes] = useState(template.notes.join('\n\n'))
    const [checklist, setChecklist] = useState(template.checklist)
    const [progress, setProgress] = useState([0, checklist.length])
    const [attachments, setAttachments] = useState(template.files)
    const [isLoading, setIsLoading] = useState(false)

    const templateCardStyle = (draggableStyle) => ({
        ...draggableStyle,
        cursor: 'pointer',
        marginTop: '16px',
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: 'gray.200',
    })

    const addToMyTemplates = async (event) => {
        event.stopPropagation()

        const templateData = {
            description,
            labels,
            notes,
            files: attachments,
        }

        try {
            setIsLoading(true)
            const newTemplate = await createTemplate(templateData)

            for await (let item of checklist) {
                const newTodo = {
                    name: item.name,
                    complete: false,
                    completionDate: null,
                    position: item.position,
                }
                await apiCall(
                    `POST`,
                    `/users/${user._id}/checklist/${newTemplate._id}?template=true`,
                    newTodo
                )
                await getMyTemplates()
            }
            setIsLoading(false)
            toaster({
                status: 'success',
                duration: 3000,
                isClosable: true,
                render: () => (
                    <ToastyBoi
                        message='Added to "My Templates" section'
                        backgroundColor="blue.500"
                        icon={<CircleCheckIcon fill="white" />}
                    />
                ),
            })
        } catch (error) {
            console.log(error)
        }
    }

    const ForwardRefWrapper = React.forwardRef(({ children, ...rest }, ref) => (
        <span ref={ref} {...rest}>
            {children}
        </span>
    ))

    const CustomTooltip = ({ label, children }) => (
        <Tooltip label={label}>
            <ForwardRefWrapper>{children}</ForwardRefWrapper>
        </Tooltip>
    )

    return (
        <>
            <Draggable
                key={template._id}
                draggableId={template._id}
                index={index}
                isDragDisabled={true}
            >
                {(provided) => (
                    <Box
                        w="100%"
                        _hover={{
                            boxShadow: '0 8px 16px 0 rgba(56, 96, 165, 0.15)',
                            transition: '0.3s',
                        }}
                        _active={{
                            cursor: 'grabbing',
                        }}
                        boxShadow={`${
                            expanded && '0 8px 16px 0 rgba(56, 96, 165, 0.15)'
                        }`}
                        backgroundColor="colors.white"
                        onClick={() => !expanded && setExpanded(true)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={templateCardStyle(provided.draggableProps.style)}
                    >
                        <Flex
                            justify={{ base: 'start', md: 'space-between' }}
                            w="100%"
                            minHeight="48px"
                        >
                            <CustomTooltip label="Add to My Templates">
                                <IconButton
                                    alignSelf="flex-start"
                                    ml="-10px"
                                    mr="-10px"
                                    mt="8px"
                                    variant="ghost"
                                    size="md"
                                    onClick={(e) => addToMyTemplates(e)}
                                    icon={
                                        <Icon
                                            as={TemplateIcon}
                                            mt="4px"
                                            ml="5px"
                                            w={7}
                                            h={7}
                                        />
                                    }
                                />
                            </CustomTooltip>
                            <Box
                                pr="16px"
                                ml="-4px"
                                alignSelf="center"
                                mt={expanded && '11px'}
                                w={{
                                    sm: '90%',
                                    md: !expanded ? '50%' : '100%',
                                }}
                            >
                                <VStack
                                    flexDirection={expanded ? 'row' : 'column'}
                                >
                                    <Box
                                        w="100%"
                                        mb={
                                            !expanded && progress[1] === 0
                                                ? '11px'
                                                : '10px'
                                        }
                                    >
                                        <Description
                                            expanded={expanded}
                                            description={description}
                                            key={template.description}
                                            disabled={true}
                                        />
                                    </Box>

                                    {!expanded && progress[1] > 0 && (
                                        <Flex
                                            w="100%"
                                            alignItems="center"
                                            mt="0px !important"
                                        >
                                            <Progress
                                                h="8px"
                                                w="100%"
                                                mr="16px"
                                                ml="16px"
                                                value={0}
                                                colorScheme="blue"
                                                borderRadius="16px"
                                                mt="0px"
                                            />
                                            <Text
                                                fontSize="small"
                                                fontWeight="bold"
                                                color="grey.900"
                                                mt="0px"
                                            >
                                                {progress[0]}/{progress[1]}
                                            </Text>
                                        </Flex>
                                    )}
                                </VStack>
                            </Box>
                            {!expanded && (
                                <Box
                                    mr="auto"
                                    alignSelf="center"
                                    display={{ base: 'none', md: 'flex' }}
                                    w={{
                                        md: '178px',
                                    }}
                                    flexWrap="wrap"
                                >
                                    {labels.map((label, index) => (
                                        <Button
                                            variant="chip-colored"
                                            key={index}
                                            mt={'2px'}
                                            mb={'2px'}
                                            background={
                                                label.color === ''
                                                    ? 'blue.500'
                                                    : label.color
                                            }
                                        >
                                            {label.name}
                                        </Button>
                                    ))}
                                </Box>
                            )}
                            {expanded && (
                                <IconButton
                                    icon={<CarrotIcon />}
                                    onClick={() => setExpanded(false)}
                                    size="lg"
                                    borderRadius="xl"
                                    backgroundColor="transparent"
                                    color="grey.900"
                                    alignSelf="flex-start"
                                />
                            )}
                        </Flex>
                        <Collapse in={expanded} unmountOnExit>
                            <Box ml="32px" mr="32px" mt="8px">
                                <Notes notes={notes} disabled={true} />
                                <Spacer h="8px" />
                                <Checklist
                                    user={user}
                                    items={checklist}
                                    setItems={setChecklist}
                                    expanded={expanded}
                                    progress={progress}
                                    cantComplete={true}
                                    disabled={true}
                                />
                            </Box>
                            <AttachmentsList
                                attachments={attachments}
                                cantDelete={true}
                            />
                            <TemplateActionBar
                                labels={labels}
                                section={1}
                                addToMyTemplates={addToMyTemplates}
                                actionButtonText={'Add to My Templates'}
                                actionButtonFunction={addToMyTemplates}
                            />
                        </Collapse>
                    </Box>
                )}
            </Draggable>
            {isLoading && (
                <LoadingModal
                    isOpen={isLoading}
                    message="Adding to My Templates..."
                />
            )}
        </>
    )
}
