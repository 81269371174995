import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../Contexts/UserContext'
import { apiCall, analytics } from '../Util/api'
import ReportsNavMobile from './ReportsNavMobile'
import ReportsLayout from './Layouts/ReportsLayout'
import ReportsActionBar from './ReportsActionBar'
import UpgradePrompt from './UpgradePrompt'
import { Reports, ReportsPDF } from './Reports'
import { sub, add, isBefore, isAfter } from 'date-fns'
import { Box, Text, Center } from '@chakra-ui/react'
import theme from '../ChakraDesign/theme'
import { PaidPopUpContext } from '../Contexts/PaidPopupContext'
import useLocalStorage from '../Hooks/UseLocalStorage'
import ReportsPopup from './ReportsPopup'

const TODAY = new Date()

export default function ReportsContainer() {
    const { user } = useContext(UserContext)
    const { setPaidPopup } = useContext(PaidPopUpContext)
    const [showPopup, setShowPopup] = useLocalStorage('showReportsPopup', true)
    const [isPopupOpen, setIsPopupOpen] = useState(showPopup)

    // state variables
    const [startDate, setStartDate] = useState(
        window.location.search.includes('pdf')
            ? new Date(
                  decodeURI(window.location.search.split('&')[1].split('=')[1])
              )
            : sub(TODAY, { weeks: 2 })
    )
    const [endDate, setEndDate] = useState(
        window.location.search.includes('pdf')
            ? new Date(
                  decodeURI(window.location.search.split('&')[2].split('=')[1])
              )
            : add(TODAY, { hours: 1 })
    )

    const [labels, setLabels] = useState([])
    const [tasks, setTasks] = useState([])
    const [completedTasks, setCompletedTasks] = useState([])
    const [completedTodos, setCompletedTodos] = useState([])
    const [tasksInDateRange, setTasksInDateRange] = useState([])
    const [isNewUser, setIsNewUser] = useState(false)
    const [completedTasksInRange, setCompletedTasksInRange] = useState([])
    const [completedTodosInRange, setCompletedTodosInRange] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [mobile, setMobile] = useState(window.innerWidth < 768)
    const [subscription, setSubscription] = useState(false)

    // non-state variables
    const isPdf = window.location.search.includes('pdf')

    const checkMobile = () => {
        if (mobile && window.innerWidth >= 768) {
            setMobile(false)
        } else if (!mobile && window.innerWidth < 768) {
            setMobile(true)
        }
    }

    window.addEventListener('resize', checkMobile)

    const getData = async () => {
        // make api call to get all labels and tasks
        const allLabels = await apiCall('GET', `/users/${user._id}/labels`)
        const allTasks = await apiCall('GET', `/users/${user._id}/tasks/all`)
        const allCompletedTasks = allTasks.filter((task) => task.isCompleted)

        // extract completed checklist items
        let completedTodos = []
        allTasks.forEach((task) => {
            if (task.checklist.length > 0) {
                task.checklist.forEach((todo) => {
                    if (todo !== null && todo.complete) {
                        completedTodos.push(todo)
                    }
                })
            }
        })

        // update state
        setLabels(allLabels)
        setTasks(allTasks)
        setCompletedTasks(allCompletedTasks)
        setCompletedTodos(completedTodos)
        setIsNewUser(allCompletedTasks.length < 10)
        setDataLoaded(true)
    }

    useEffect(() => {
        getData()
        analytics.trigger({ name: 'Reports Page Viewed', user })

        if (user.stripeProductId === '') {
            // setStartDate(sub(TODAY, { days: 7 }))
            setSubscription('free')
        } else if (
            process.env.REACT_APP_PERSONAL_SUBSCRIPTION_IDs.split(' ').includes(
                user.stripeProductId
            )
        ) {
            setSubscription('personal')
        } else if (
            process.env.REACT_APP_PROFESSIONAL_SUBSCRIPTION_IDs.split(
                ' '
            ).includes(user.stripeProductId)
        ) {
            setSubscription('professional')
        } else {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTasksInDateRange(
            tasks.filter(
                (t) =>
                    isAfter(new Date(t.createdDate), startDate) &&
                    isBefore(new Date(t.createdDate), endDate)
            )
        )

        setCompletedTasksInRange(
            completedTasks.filter(
                (t) =>
                    isAfter(new Date(t.completionDate), startDate) &&
                    isBefore(new Date(t.completionDate), endDate)
            )
        )

        setCompletedTodosInRange(
            completedTodos.filter(
                (t) =>
                    isAfter(new Date(t.completionDate), startDate) &&
                    isBefore(new Date(t.completionDate), endDate)
            )
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks, completedTasks, completedTodos, startDate, endDate])

    const handleSetStartDate = (date) => {
        const today = new Date()
        //check users subscription
        if (user.stripeProductId === '') {
            if (date > new Date().setDate(today.getDate() - 7)) {
                setStartDate(date)
            } else {
                setPaidPopup({
                    show: true,
                    reason: 'Oops! It looks like you hit a limit on your free plan 😢. You can continue using your free account. 🎉 Upgrade to go back further than one week in your reports.',
                    hideButtons: false,
                })
            }
        } else if (
            process.env.REACT_APP_PERSONAL_SUBSCRIPTION_IDs.split(' ').includes(
                user.stripeProductId
            )
        ) {
            if (date > new Date().setDate(today.getDate() - 30)) {
                setStartDate(date)
            } else {
                setPaidPopup({
                    show: true,
                    reason: 'Oops! It looks like you hit a limit on your free plan 😢. You can continue using your free account. 🎉 Upgrade to go back further than 30 in your reports.',
                    hideButtons: false,
                })
            }
        } else if (
            process.env.REACT_APP_PROFESSIONAL_SUBSCRIPTION_IDs.split(
                ' '
            ).includes(user.stripeProductId)
        ) {
            setStartDate(date)
        } else {
        }
    }

    const NewUserMessage = () => (
        <Box maxW={{ base: '360px', md: '720px' }} mb="18px">
            <Text fontSize="18px" fontWeight="bold">
                Welcome to your Performance Reports! These graphs will have
                example data until you have completed{' '}
                <span style={{ color: theme.colors.blue[500] }}>
                    {10 - completedTasks.length}
                </span>{' '}
                more
                <span> {completedTasks.length === 9 ? 'task' : 'tasks'}</span>.
                Download your Reports for events like 1:1s with your Managers,
                Performance Reviews, and to negotiate promotions or raises.
            </Text>
        </Box>
    )

    return (
        <>
            <ReportsLayout
                mobileMenu={<ReportsNavMobile />}
                isPdf={isPdf}
                main={
                    <>
                        {dataLoaded ? (
                            <>
                                {!isPdf ? (
                                    <>
                                        {isNewUser && <NewUserMessage />}
                                        <ReportsActionBar
                                            user={user}
                                            isNewUser={isNewUser}
                                            startDate={startDate}
                                            endDate={endDate}
                                            setStartDate={handleSetStartDate}
                                            setEndDate={setEndDate}
                                            mobile={mobile}
                                        />
                                        <UpgradePrompt
                                            isNewUser={isNewUser}
                                            subscription={subscription}
                                            setPaidPopup={setPaidPopup}
                                        />
                                        <Reports
                                            isNewUser={isNewUser}
                                            startDate={startDate}
                                            endDate={endDate}
                                            tasks={tasksInDateRange}
                                            completedTasks={
                                                completedTasksInRange
                                            }
                                            completedTodos={
                                                completedTodosInRange
                                            }
                                            labels={labels}
                                            mobile={mobile}
                                        />
                                    </>
                                ) : (
                                    <ReportsPDF
                                        user={user}
                                        startDate={startDate}
                                        endDate={endDate}
                                        tasks={tasksInDateRange}
                                        completedTasks={completedTasksInRange}
                                        completedTodos={completedTodosInRange}
                                        labels={labels}
                                    />
                                )}
                            </>
                        ) : (
                            <Center>
                                <Center
                                    height="52px"
                                    width="52px"
                                    shadow="base"
                                    fontSize="xl"
                                    padding="8px"
                                    borderRadius="8px"
                                >
                                    🦦
                                </Center>
                                <Text
                                    fontSize="xl"
                                    fontWeight="bold"
                                    letterSpacing="-0.75px"
                                    display={{ base: 'none', lg: 'flex' }}
                                    ml="8px"
                                >
                                    loading ...
                                </Text>
                            </Center>
                        )}
                    </>
                }
            />
            {isPopupOpen && (
                <ReportsPopup
                    isOpen={isPopupOpen}
                    showPopup={showPopup}
                    setIsOpen={setIsPopupOpen}
                    setShowPopup={setShowPopup}
                />
            )}
        </>
    )
}
