import React from 'react'
import { Flex, Button, Box, Text } from '@chakra-ui/react'
import { isOverDue } from '../Util/timeUtils'
import { format } from 'date-fns'

export default function InboxActionsBar({
    task,
    acceptTask,
    denyTask,
    requestedBy,
    due,
}) {
    return (
        <Flex justifyContent="space-between" marginTop="24px">
            <Flex alignItems="center" direction={{ base: 'column', sm: 'row' }}>
                <Flex alignItems="center">
                    <Button
                        ml="8px"
                        size="lg"
                        color="blue.500"
                        onClick={() => acceptTask(task)}
                    >
                        accept
                    </Button>
                    <Button
                        ml="8px"
                        size="lg"
                        color="red.500"
                        onClick={() => denyTask(task._id)}
                    >
                        deny
                    </Button>
                </Flex>
                <Box
                    ml={{ base: '8px', sm: '16px' }}
                    mt={{ base: '8px', sm: '0px' }}
                    alignSelf={{ base: 'start', sm: 'center' }}
                >
                    <Button variant="chip-blue">{requestedBy}</Button>
                </Box>
            </Flex>
            {due && (
                <Text
                    fontSize="sm"
                    alignSelf="center"
                    color={isOverDue(due) ? 'red.500' : 'colors.black'}
                    display={{
                        base: 'none',
                        md: 'flex',
                    }}
                    marginLeft="4px"
                    marginRight="4px"
                >
                    {format(new Date(due), 'MMM d, h:mma')}
                </Text>
            )}
        </Flex>
    )
}
