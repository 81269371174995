import { FormikProvider } from 'formik'
import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    Box,
    Text,
    CircularProgress,
} from '@chakra-ui/react'

export default function LoadingModal({ isOpen, message }) {
    return (
        <Modal isOpen={isOpen}>
            <ModalOverlay>
                <ModalContent h="200px" w="400px">
                    <Box
                        w="100%"
                        h="100%"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress isIndeterminate color="blue.500" />
                        <Text mt="24px" fontSize="18px" fontWeight="bold">
                            {message}
                        </Text>
                    </Box>
                </ModalContent>
            </ModalOverlay>
        </Modal>
    )
}
