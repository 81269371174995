import React from 'react'
import { Text } from '@chakra-ui/react'

export default function SubHeaderText({ children }) {
    return (
        <Text
            fontSize="18px"
            textAlign="center"
            marginTop="16px"
            fontWeight="400"
            pl="16px"
            pr="16px"
        >
            {children}
        </Text>
    )
}
