import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Box, VStack, Text } from '@chakra-ui/react'
import { apiCall } from '../Util/api'
import TemplateCard from './Cards/TemplateCard'
import DiscoverTemplateCard from './Cards/DiscoverTemplateCard'

export default function TemplatesList({
    user,
    myTemplates,
    setModalOpen,
    getMyTemplates,
    setMyTemplates,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    selectedSection,
    discoverTemplates,
}) {
    // dnd library needs styles to be in a separate function
    const containerStyle = (isDraggingOver) => ({
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 8,
        marginLeft: -8,
        marginRight: -8,
        cursor: isDraggingOver ? 'grabbing' : 'grab',
    })

    const onDragEnd = (result) => {
        // Initialize variables for function
        const { destination, source } = result
        const newIndex = destination ? destination.index : source.index
        let newTemplates = [...myTemplates]
        let newPosition = null

        if (newIndex === source.index) {
            return
        } else {
            // reorder to reflect dnd action
            const dragTemplate = newTemplates[source.index]
            newTemplates.splice(source.index, 1)
            newTemplates.splice(newIndex, 0, dragTemplate)

            // Calculate new position of template
            if (newIndex === 0) {
                newPosition = newTemplates[1].position - 100
            } else if (newIndex === newTemplates.length - 1) {
                newPosition =
                    newTemplates[newTemplates.length - 2].position + 100
            } else {
                const position1 = newTemplates[newIndex - 1].position
                const position2 = newTemplates[newIndex + 1].position

                newPosition = (position1 + position2) / 2
            }

            // update state
            newTemplates[newIndex].position = newPosition
            setMyTemplates(newTemplates)

            // update template position
            apiCall(`PUT`, `/users/${user._id}/templates/${dragTemplate._id}`, {
                position: newPosition,
            })
            return
        }
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={selectedSection.toString()}>
                {(provided, snapshot) => (
                    <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={containerStyle(snapshot.isDraggingOver)}
                        maxH="75vh"
                        overflow="auto"
                    >
                        <VStack id="templates-list">
                            {selectedSection === 0 && (
                                <Box w="100%">
                                    {myTemplates.map((template, index) => (
                                        <TemplateCard
                                            user={user}
                                            index={index}
                                            key={template._id}
                                            template={template}
                                            cards={myTemplates}
                                            setCards={setMyTemplates}
                                            setModalOpen={setModalOpen}
                                            updateTemplate={updateTemplate}
                                            deleteTemplate={deleteTemplate}
                                        />
                                    ))}
                                </Box>
                            )}
                            {selectedSection === 1 && (
                                <Box w="100%">
                                    <Text
                                        h="32px"
                                        lineHeight="32px"
                                        ml="16px"
                                        fontSize="24px"
                                        fontWeight="bold"
                                        display={{ base: 'none', md: 'block' }}
                                    >
                                        Find new templates and add them to your
                                        list!
                                    </Text>
                                    {discoverTemplates.map(
                                        (template, index) => (
                                            <DiscoverTemplateCard
                                                user={user}
                                                index={index}
                                                key={template._id}
                                                template={template}
                                                createTemplate={createTemplate}
                                                getMyTemplates={getMyTemplates}
                                            />
                                        )
                                    )}
                                </Box>
                            )}
                            {provided.placeholder}
                        </VStack>
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    )
}
