import React, { useContext, useState } from 'react'
import { Flex, Text, Kbd, Button, Box, Input } from '@chakra-ui/react'
import {
    HeartIcon,
    HeartIconFilled,
    CommentIcon,
    CommentFilledIcon,
} from '../ChakraDesign/Icons'
import { UserContext } from '../Contexts/UserContext'
import { apiCall } from '../Util/api'
import Comment, { CommentForm } from './Comment'
import { AVATARS } from './avatars/avatars'
import Avatar from './avatars/Avatar'
import { isPaidUser } from '../Util/permissions'
import { PaidPopUpContext } from '../Contexts/PaidPopupContext'

export default function Comments({ comments, likes, _id, posts, setPosts }) {
    const { user } = useContext(UserContext)

    const [viewAllComments, setViewAllComments] = useState(false)
    const [continueReading, setContinueReading] = useState(false)
    const [newComment, setNewComment] = useState('')
    const { setPaidPopup } = useContext(PaidPopUpContext)

    const handleViewAllComments = () => {
        setViewAllComments(!viewAllComments)
    }

    const likePost = async () => {
        if (isPaidUser(user)) {
            const res = await apiCall('PUT', `/users/${user._id}/post/${_id}`, {
                likes: likes.includes(user._id)
                    ? likes.filter((l) => l !== user._id)
                    : [...likes, user._id],
            })
            const newPosts = posts.map((p) =>
                p._id === _id
                    ? {
                          ...p,
                          likes: likes.includes(user._id)
                              ? likes.filter((l) => l !== user._id)
                              : [...likes, user._id],
                      }
                    : p
            )
            setPosts(newPosts)
        } else {
            setPaidPopup({
                show: true,
                reason: 'Oops! It looks like you hit a limit on your free plan 😢. Upgrade to like a post.',
            })
        }
    }

    const createComment = async (desc) => {
        if (isPaidUser(user)) {
            const createdComment = await apiCall(
                'POST',
                `/users/${user._id}/post/${_id}/comment`,
                {
                    description: desc,
                    user: user._id,
                }
            )

            const newPosts = posts.map((p) =>
                p._id === _id
                    ? { ...p, comments: [...p.comments, createdComment] }
                    : p
            )
            setPosts(newPosts)
            setNewComment('')

            return createdComment._id
        } else {
            setPaidPopup({
                show: true,
                reason: 'Oops! It looks like you hit a limit on your free plan 😢. Upgrade to comment on a post.',
            })
        }
    }

    return (
        <Flex flexDir="column" w="100%" p="16px">
            <Flex justifyContent="end">
                <Flex
                    mr="16px"
                    fontSize="18px"
                    alignItems="center"
                    onClick={() => likePost()}
                    cursor="pointer"
                >
                    {likes.includes(user._id) ? (
                        <HeartIconFilled />
                    ) : (
                        <HeartIcon />
                    )}
                    <Text ml="4px">{likes.length}</Text>
                </Flex>
                <Flex
                    fontSize="18px"
                    alignItems="center"
                    onClick={handleViewAllComments}
                    cursor="pointer"
                >
                    {viewAllComments ? <CommentFilledIcon /> : <CommentIcon />}
                    <Text ml="4px">{comments.length}</Text>
                </Flex>
            </Flex>
            {!viewAllComments && comments.length > 0 && (
                <>
                    <Flex
                        mt="12px"
                        background="gray.100"
                        p="16px"
                        borderRadius="16px"
                    >
                        <Avatar
                            size="48px"
                            img={
                                AVATARS.filter(
                                    (a) => a.name === comments[0].avatarAnimal
                                )[0]?.img
                            }
                            style={{
                                outline:
                                    user._id === comments[0].user &&
                                    '4px solid #fab6b2',
                                position: 'absolute',
                                marginLeft: 8,
                                marginTop: -44,
                            }}
                        ></Avatar>

                        <Text mt="8px">
                            {continueReading
                                ? comments[0].description.replace(
                                      /<\/?[^>]+(>|$)/g,
                                      ''
                                  )
                                : comments[0].description
                                      ?.substring(0, 300)
                                      .replace(/<\/?[^>]+(>|$)/g, '') + ' ...'}

                            {!continueReading && (
                                <Text
                                    as="span"
                                    ml="16px"
                                    color="pink.600"
                                    fontWeight="500"
                                    onClick={() => setViewAllComments(true)}
                                    cursor="pointer"
                                >
                                    Continue reading
                                </Text>
                            )}
                        </Text>
                    </Flex>
                    {comments.length > 1 && (
                        <Flex justifyContent="center" mt="16px">
                            <Text
                                color="pink.600"
                                fontWeight="500"
                                onClick={handleViewAllComments}
                                cursor="pointer"
                            >
                                View all {comments.length} comments
                            </Text>
                        </Flex>
                    )}
                </>
            )}
            <Flex flexDir="column">
                {viewAllComments &&
                    comments.map(
                        (c, i) =>
                            !c.parentCommentId && (
                                <Comment
                                    description={c.description}
                                    key={c._id}
                                    avatarAnimal={c.avatarAnimal}
                                    commentUserId={c.user}
                                    posts={posts}
                                    setPosts={setPosts}
                                    commentId={c._id}
                                    postId={_id}
                                    comments={c.childComments}
                                />
                            )
                    )}
                {(comments.length === 0 || viewAllComments) && (
                    <Flex alignItems="center" pl="16px" mt="16px">
                        <Flex alignItems="center" width="100%">
                            <Box w="100%" h="32px">
                                <CommentForm
                                    description={newComment}
                                    handleUpdate={(desc) => createComment(desc)}
                                    editable
                                />
                            </Box>
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </Flex>
    )
}
