import theme from '../../ChakraDesign/theme'
import React, { useState, useEffect } from 'react'
import {
    ComposedChart,
    Cell,
    Bar,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'
import ChartCard from './Utils/ChartCard'
import CustomTooltip from './Utils/CustomTooltip'
import { Flex, Text, Box } from '@chakra-ui/react'

export default function LabelsDistribution({
    startDate,
    endDate,
    completedTasks,
    labels,
    isNewUser,
    isPdf,
    mobile,
}) {
    const [filteredData, setFilteredData] = useState([])
    const [legendInfo, setLegendInfo] = useState([])
    const [activeIndex, setActiveIndex] = useState(null)
    const [mostProductiveLabel, setMostProductiveLabel] = useState({})
    const [numLabeledTasks, setNumLabeledTasks] = useState(0)
    const [numUnlabeledTasks, setNumUnlabeledTasks] = useState(0)
    const [colors] = useState([
        theme.colors.blue[500],
        theme.colors.teal[500],
        theme.colors.pink[500],
        theme.colors.grey[900],
        theme.colors.blue[300],
    ])

    useEffect(() => {
        let data = []
        if (isNewUser) {
            data = [
                {
                    labelName: 'work',
                    numCompTasks: 24,
                    index: 0,
                },
                {
                    labelName: '🦦 office otter',
                    numCompTasks: 21,
                    index: 1,
                },
                {
                    labelName: 'planning',
                    numCompTasks: 15,
                    index: 2,
                },
                {
                    labelName: 'HR',
                    numCompTasks: 12,
                    index: 2,
                },
                {
                    labelName: 'personal',
                    numCompTasks: 9,
                    index: 2,
                },
            ]

            setMostProductiveLabel(data[0])
            setNumLabeledTasks(7)
            setNumUnlabeledTasks(3)
            setFilteredData(data)
        } else {
            // create data array with proper object structure
            data = Array(labels.length).fill({})
            data = data.map((datum, index) => {
                return {
                    labelName: labels[index] && `${labels[index].name}`,
                    numCompTasks: 0,
                }
            })

            let labeledTasks = 0
            let unlabeledTasks = 0

            // increment numCompTasks in each dat object according to the number of occurences of its labelId
            completedTasks.forEach((task) => {
                if (task.labels.length > 0) {
                    labeledTasks++
                    task.labels.forEach((label) => {
                        data[
                            data.findIndex(
                                (datum) => datum.labelName === label.name
                            )
                        ].numCompTasks++
                    })
                } else {
                    unlabeledTasks++
                }
            })

            // Sort the array such from highest to lowest numComp tasks and grab the top five
            data.sort((a, b) => b.numCompTasks - a.numCompTasks)
            data.splice(5)

            // Once sorted, add index to each data object (for tooltip purposes)
            data.forEach((datum, index) => {
                datum.index = index
            })

            setMostProductiveLabel(data[0])
            setNumLabeledTasks(labeledTasks)
            setNumUnlabeledTasks(unlabeledTasks)
            setFilteredData(data)
        }

        // Create info to pass custom legend payload
        const legend = [
            {
                value: data[0] && `${data[0].labelName}`,
                color: colors[0],
                index: 0,
            },
            {
                value: data[1] && `${data[1].labelName}`,
                color: colors[1],
                index: 1,
            },
            {
                value: data[2] && `${data[2].labelName}`,
                color: colors[2],
                index: 2,
            },
            {
                value: data[3] && `${data[3].labelName}`,
                color: colors[3],
                index: 3,
            },
            {
                value: data[4] && `${data[4].labelName}`,
                color: colors[4],
                index: 4,
            },
        ]
        legend.splice(data.length)
        setLegendInfo(legend)
    }, [labels, completedTasks, isNewUser, colors])

    const stats = [
        {
            name: 'Most frequently used label',
            value: mostProductiveLabel.labelName,
            color: colors[0],
        },
        {
            name: 'Total number of labeled tasks',
            value: numLabeledTasks,
            color: colors[0],
        },
        {
            name: 'Total number of unlabeled tasks',
            value: numUnlabeledTasks,
            color: colors[0],
        },
    ]

    const unitLabels = [
        {
            singular: 'task completed for',
            plural: 'tasks completed for',
        },
    ]

    const Legend = ({ info }) => {
        return (
            <Flex
                align="center"
                justify={{
                    base: !isPdf ? 'start' : 'space-between',
                    sm: 'space-between',
                }}
                wrap="wrap"
            >
                {info.map((item) => (
                    <Text
                        style={{ cursor: 'default' }}
                        mb="8px"
                        mr="8px"
                        fontSize="14px"
                        display="flex"
                        alignItems="center"
                        onMouseOver={() => setActiveIndex(item.index)}
                        onMouseLeave={() => setActiveIndex(null)}
                    >
                        <Box
                            style={{
                                height: 16,
                                width: 16,
                                padding: 4,
                                backgroundColor: item.color,
                                marginRight: 8,
                                borderRadius: 8,
                            }}
                        />
                        {item.value}
                    </Text>
                ))}
            </Flex>
        )
    }

    return (
        <ChartCard
            title="Labels Distribution"
            description="Top labels used in completed tasks"
            Legend={<Legend info={legendInfo} />}
            stats={stats}
            isPdf={isPdf}
        >
            <ResponsiveContainer width={mobile ? '125%' : '105%'} height={320}>
                <ComposedChart
                    layout="vertical"
                    data={filteredData}
                    margin={{
                        top: 5,
                        right: mobile ? 95 : 56,
                        bottom: 30,
                    }}
                >
                    <Tooltip
                        content={
                            <CustomTooltip
                                startDate={startDate}
                                endDate={endDate}
                                colors={colors}
                                unitLabels={unitLabels}
                                dateLabelType={'static'}
                            />
                        }
                    />
                    <CartesianGrid stroke={theme.colors.grey[200]} />
                    <XAxis type="number" />
                    <YAxis type="category" hide="true" />
                    <Bar
                        dataKey="numCompTasks"
                        barSize={10}
                        radius={[0, 8, 8, 0]}
                        animationDuration={!isPdf && 3000}
                        onMouseOver={(data, index) => setActiveIndex(index)}
                        onMouseLeave={() => setActiveIndex(null)}
                    >
                        {filteredData.map((datum, index) => (
                            <Cell
                                key={index}
                                fill={colors[index]}
                                fillOpacity={index === activeIndex ? '1' : '.7'}
                            />
                        ))}
                    </Bar>
                </ComposedChart>
            </ResponsiveContainer>
        </ChartCard>
    )
}
