import React, { useContext, useState } from 'react'
import { UserContext } from '../Contexts/UserContext'
import {
    FormControl,
    Input,
    FormLabel,
    Switch,
    Button,
    Flex,
} from '@chakra-ui/react'
import { apiCall } from '../Util/api'

export default function AdminCreatePost({ posts, setPosts }) {
    const [description, setDescription] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [isPublished, setIsPublished] = useState(false)
    const [isPoll, setIsPoll] = useState(false)
    const [options, setOptions] = useState([
        { description: null },
        { description: null },
        { description: null },
        { description: null },
    ])

    const { user, setUser } = useContext(UserContext)

    const createPost = async () => {
        const createdPost = await apiCall('POST', `/users/${user._id}/post`, {
            description,
            image: imageUrl,
            isPoll,
            isPublished,
        })
        setPosts([...posts, createdPost])
        return createdPost._id
    }

    const createPoll = async () => {
        const postId = await createPost()
        for await (let o of options) {
            const createdOption = await apiCall(
                'POST',
                `/users/${user._id}/post/${postId}/option`,
                {
                    description: o.description,
                }
            )
        }
    }

    return (
        <FormControl isRequired>
            <FormLabel>Description</FormLabel>
            <Input
                placeholder="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
            <FormLabel>Image url</FormLabel>
            <Input
                placeholder="image url"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
            />
            <Flex flexDirection="column">
                <FormLabel>Is poll?</FormLabel>
                <Switch
                    isChecked={isPoll}
                    onChange={(e) => setIsPoll(!isPoll)}
                />
            </Flex>
            {isPoll && (
                <Flex flexDir="column">
                    {options.map((o, i) => (
                        <>
                            <FormLabel>option {i + 1}</FormLabel>
                            <Input
                                placeholder={`option ${i + 1}`}
                                value={o.description}
                                onChange={(e) =>
                                    setOptions(
                                        options.map((o, c) =>
                                            c === i
                                                ? {
                                                      ...o,
                                                      description:
                                                          e.target.value,
                                                  }
                                                : o
                                        )
                                    )
                                }
                            />
                        </>
                    ))}
                </Flex>
            )}
            <Flex flexDirection="column">
                <FormLabel>Published</FormLabel>
                <Switch
                    isChecked={isPublished}
                    onChange={(e) => setIsPublished(!isPublished)}
                />
            </Flex>
            <Button
                onClick={async () => {
                    if (isPoll) {
                        await createPoll()
                    } else {
                        await createPost()
                    }
                    window.location.reload()
                }}
            >
                Submit post
            </Button>
        </FormControl>
    )
}
