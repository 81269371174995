import React from 'react'
import classNames from 'classnames'
import theme from '../../../ChakraDesign/theme'
import { add, min } from 'date-fns'

export default function CustomTooltip({
    active,
    payload,
    unitLabels,
    startDate,
    endDate,
    colors,
    dateLabelType,
    isPercentage,
}) {
    if (active) {
        const newPayload = [...payload].reverse()
        const wrapperCN = classNames('recharts-default-tooltip')
        const finalStyle = {
            margin: 0,
            padding: 10,
            backgroundColor: theme.colors.white,
            border: `1px solid ${theme.colors.grey[700]}`,
            whiteSpace: 'nowrap',
            borderRadius: 4,
        }

        const tooltipYAxisContent = () => {
            const listStyle = { margin: 0, padding: 0 }
            const finalItemStyle = {
                display: 'block',
                paddingTop: 4,
                paddingBottom: 4,
                color: theme.colors.grey[800],
            }

            return (
                <ul className="recharts-tooltip-item-list" style={listStyle}>
                    <li
                        className="recharts-tooltip-item"
                        key={`tooltip-item-0`}
                        style={finalItemStyle}
                    >
                        {unitLabels.map((label, index) => (
                            <>
                                {index > 0 && <br />}
                                <span
                                    className="recharts-tooltip-item-value"
                                    style={{
                                        fontWeight: 'bold',
                                        color:
                                            colors[
                                                payload[index * 2].payload.index
                                            ] || colors[index],
                                    }}
                                >
                                    {newPayload && newPayload[index * 2].value}
                                    {isPercentage && '%'}
                                </span>
                                <span className="recharts-tooltip-item-name">
                                    {' '}
                                    {newPayload &&
                                    newPayload[index * 2].value === 1
                                        ? label.singular
                                        : label.plural}
                                    {newPayload[index * 2].payload.tag &&
                                        ` ${newPayload[index * 2].payload.tag}`}
                                </span>
                                {payload[index * 2].payload.labelName && (
                                    <span
                                        className="recharts-tooltip-item"
                                        style={{
                                            fontWeight: 'bold',
                                            color: colors[
                                                payload[0].payload.index
                                            ],
                                        }}
                                    >
                                        {' '}
                                        {payload[index * 2].payload.labelName}
                                    </span>
                                )}
                            </>
                        ))}
                    </li>
                </ul>
            )
        }

        const tooltipXAxisContent = () => {
            const labelCN = classNames('recharts-tooltip-label')
            const finalLabelStyle = { margin: 0, color: theme.colors.black }

            let date1
            let date2

            if (dateLabelType === 'dynamic') {
                // date label displays specific week depending on hover location
                date1 = new Date(newPayload && newPayload[0].payload.date)
                date2 = min([add(date1, { days: 6 }), endDate])
            } else if (dateLabelType === 'static') {
                // date label displays entire date range regardless of hover location
                date1 = startDate
                date2 = endDate
            } else if (dateLabelType === 'none') {
                return
            }

            const label = `${date1.getMonth() + 1}/${date1.getDate()}-${
                date2.getMonth() + 1
            }/${date2.getDate()}`

            return (
                <p className={labelCN} style={finalLabelStyle}>
                    {label}
                </p>
            )
        }

        return (
            <div className={wrapperCN} style={finalStyle}>
                {tooltipYAxisContent()}
                {tooltipXAxisContent()}
            </div>
        )
    }
    return null
}
