import React from 'react'
import { Text, Box } from '@chakra-ui/react'

export default function HeaderText({ children }) {
    return (
        <Box pl="24px" pr="24px">
            <Text fontSize="24px" textAlign="center" fontWeight="600">
                {children}
            </Text>
        </Box>
    )
}
