import React, { useState, useEffect } from 'react'
import {
    ComposedChart,
    Cell,
    Bar,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'
import ChartCard from './Utils/ChartCard'
import theme from '../../ChakraDesign/theme'
import CustomTooltip from './Utils/CustomTooltip'
import { getWeekNum } from './Utils/helperFunctions'
import { add, differenceInCalendarDays, format } from 'date-fns'

export default function ChecklistProductivity({
    startDate,
    endDate,
    completedTasks,
    completedTodos,
    isNewUser,
    isPdf,
    mobile,
}) {
    const [activeIndex, setActiveIndex] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [statValue, setStatValue] = useState(0)
    const colors = [theme.colors.blue[500]]

    useEffect(() => {
        if (isNewUser) {
            const dummyData = [
                {
                    date: startDate,
                    label: `${format(startDate, 'MMM d')}`,
                    numCompTodos: 8,
                },
                {
                    date: add(startDate, { weeks: 1 }),
                    label: `${format(add(startDate, { weeks: 1 }), 'MMM d')}`,
                    numCompTodos: 14,
                },
                {
                    date: add(startDate, { weeks: 2 }),
                    label: `${format(add(startDate, { weeks: 2 }), 'MMM d')}`,
                    numCompTodos: 12,
                },
                {
                    date: add(startDate, { weeks: 3 }),
                    label: `${format(add(startDate, { weeks: 3 }), 'MMM d')}`,
                    numCompTodos: 8,
                },
                {
                    date: add(startDate, { weeks: 4 }),
                    label: `${format(add(startDate, { weeks: 4 }), 'MMM d')}`,
                    numCompTodos: 14,
                },
                {
                    date: add(startDate, { weeks: 5 }),
                    label: `${format(add(startDate, { weeks: 5 }), 'MMM d')}`,
                    numCompTodos: 9,
                },
                {
                    date: add(startDate, { weeks: 6 }),
                    label: `${format(add(startDate, { weeks: 6 }), 'MMM d')}`,
                    numCompTodos: 11,
                },
                {
                    date: add(startDate, { weeks: 7 }),
                    label: `${format(add(startDate, { weeks: 7 }), 'MMM d')}`,
                    numCompTodos: 8,
                },
                {
                    date: add(startDate, { weeks: 8 }),
                    label: `${format(add(startDate, { weeks: 8 }), 'MMM d')}`,
                    numCompTodos: 13,
                },
            ]
            setFilteredData(dummyData)
        } else {
            const numWeeks = Math.ceil(
                (differenceInCalendarDays(endDate, startDate) + 1) / 7
            )

            let data = Array(numWeeks).fill({})
            data = data.map((datum, index) => {
                const date = add(startDate, { weeks: index })
                return {
                    date: date,
                    label: `${format(date, 'MMM d')}`,
                    numCompTodos: 0,
                }
            })

            completedTodos.forEach((todo) => {
                const weekNumber = getWeekNum(
                    new Date(todo.completionDate),
                    startDate
                )
                if (data[weekNumber] !== undefined) {
                    data[weekNumber].numCompTodos++
                }
            })

            let stat = completedTodos.length
            completedTasks.forEach((task) => {
                if (task.checklist.length > 0) {
                    task.checklist.forEach((todo) => {
                        if (!todo.complete) {
                            const weekNumber = getWeekNum(
                                new Date(task.completionDate),
                                startDate
                            )
                            if (data[weekNumber] !== undefined) {
                                data[weekNumber].numCompTodos++
                                stat++
                            }
                        }
                    })
                }
            })
            setFilteredData(data)
            setStatValue(stat)
        }
    }, [startDate, endDate, completedTodos, isNewUser, completedTasks])

    const stats = [
        {
            name: 'Total checklist items completed',
            value: isNewUser ? 93 : statValue,
            color: colors[0],
        },
    ]

    const unitLabels = [
        {
            singular: 'checklist item completed',
            plural: 'checklist items completed',
        },
    ]

    return (
        <ChartCard
            title="Checklist Productivity"
            description="How many checklist items are completed week over week"
            stats={stats}
            isPdf={isPdf}
        >
            <ResponsiveContainer width={mobile ? '125%' : '105%'} height={320}>
                {filteredData.length > 0 && (
                    <ComposedChart
                        data={filteredData}
                        margin={{
                            top: 5,
                            right: mobile ? 95 : 85,
                            bottom: 50,
                            left: mobile && -24,
                        }}
                    >
                        <Tooltip
                            content={
                                <CustomTooltip
                                    endDate={endDate}
                                    colors={colors}
                                    unitLabels={unitLabels}
                                    dateLabelType={'dynamic'}
                                />
                            }
                        />
                        <CartesianGrid stroke={theme.colors.grey[200]} />
                        <Bar
                            dataKey="numCompTodos"
                            fill={colors[0]}
                            barSize={40}
                            animationDuration={!isPdf && 3000}
                            radius={[8, 8, 0, 0]}
                            onMouseOver={(data, index) => setActiveIndex(index)}
                            onMouseLeave={() => setActiveIndex(null)}
                        >
                            {filteredData.map((entry, index) => (
                                <Cell
                                    fillOpacity={
                                        index === activeIndex ? '1' : '0.7'
                                    }
                                    key={index}
                                />
                            ))}
                        </Bar>
                        <XAxis
                            dataKey="label"
                            interval={{ base: 1, sm: 0 }}
                            angle={25}
                            dy={10}
                        ></XAxis>
                        <YAxis />
                    </ComposedChart>
                )}
            </ResponsiveContainer>
        </ChartCard>
    )
}
