import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../Contexts/UserContext'
import { analytics, apiCall } from '../Util/api'
import { Flex, Box } from '@chakra-ui/react'
import useLocalStorage from '../Hooks/UseLocalStorage'
import {
    sortFilesByDragAndDrop,
    sortFilesByCreationDate,
} from '../Files/FilesSortUtils'
import CreateFileInput from '../Files/CreateFileInput'
import FilesFilteringAndSorting from '../Files/FilesFilteringAndSorting'
import FilesList from '../Files/FilesList'

export default function FilesContainer() {
    const { user } = useContext(UserContext)

    const [files, setFiles] = useState([])
    const [sortBy, setSortBy] = useLocalStorage('sortFilesBy', 'dnd')

    useEffect(() => {
        getFiles()
        analytics.trigger({ name: 'Files Modal Viewed', user })
    }, [])

    //sort the users files whenever the sort by type changes
    useEffect(() => {
        let sortedFiles = []

        const filesCopy = [...files]
        sortedFiles = sortList(filesCopy)

        setFiles(sortedFiles)
    }, [sortBy])

    const getFiles = async () => {
        let allFiles = await apiCall('get', `/users/${user._id}/files`)

        setFiles(sortList(allFiles))
    }

    const sortList = (files) => {
        let sortedFiles = []

        switch (sortBy) {
            case 'dnd':
                sortedFiles = sortFilesByDragAndDrop(files)

                break
            case 'creationDateNewestFirst':
                sortedFiles = sortFilesByCreationDate(files, 'newestFirst')

                break
            case 'creationDateOldestFirst':
                sortedFiles = sortFilesByCreationDate(files, 'oldestFirst')

                break

            default:
                alert('sort selection not allowed')
                break
        }

        return sortedFiles
    }

    const createFile = async (formData) => {
        let newFile = await apiCall(
            'POST',
            `/users/${user._id}/files/create`,
            formData
        )
        console.log(newFile)

        let newFiles = [...files]
        newFiles.unshift(newFile)

        setFiles(newFiles)
    }

    const updateFile = async (fileId, updateData) => {
        let updatedFile = await apiCall(
            'put',
            `/users/${user._id}/files/${fileId}`,
            updateData
        )
        let updatedFiles = files.map((file) => {
            if (file._id === fileId) {
                return updatedFile
            } else {
                return file
            }
        })
        setFiles(updatedFiles)
    }

    const deleteFile = (fileId) => {
        apiCall('delete', `/users/${user._id}/files/${fileId}`)
        let updatedFiles = files.filter((file) => file._id !== fileId)
        setFiles(updatedFiles)
    }

    return (
        <Box>
            <Flex
                width="100%"
                alignItems="center"
                paddingLeft="16px"
                marginTop="11px"
                justifyContent="space-between"
            >
                <CreateFileInput createFile={createFile} />
                <FilesFilteringAndSorting
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                />
            </Flex>
            <FilesList
                files={files}
                setFiles={setFiles}
                user={user}
                sortBy={sortBy}
                updateFile={updateFile}
                deleteFile={deleteFile}
            />
        </Box>
    )
}
