import React, { useState, useEffect } from 'react'
import { apiCall } from '../../Util/api'
import {
    Text,
    Flex,
    Box,
    Input,
    Button,
    FormLabel,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Tooltip,
    useToast,
} from '@chakra-ui/react'
import { EyeIcon } from '../../ChakraDesign/Icons'
import ToastyBoi from '../../SharedComponents/ToastyBoi'
import { CopyIcon, CircleCheckIcon } from '../../ChakraDesign/Icons'

export default function InboxSettings({ user }) {
    const [vanityLink, setVanityLink] = useState('loading...')
    const toaster = useToast()

    useEffect(() => {
        getInboxLinkName()
    }, [])

    const submitForm = async () => {
        try {
            await apiCall('PUT', `/users/${user._id}/inbox`, { vanityLink })
            toaster({
                status: 'success',
                duration: 3000,
                isClosable: true,
                render: () => (
                    <ToastyBoi
                        message={'Link successfully saved!'}
                        icon={<CircleCheckIcon fill="white" />}
                        backgroundColor="blue.500"
                    />
                ),
            })
        } catch (error) {
            alert(error)
        }
    }

    const getInboxLinkName = async () => {
        try {
            const inbox = await apiCall('GET', `/inbox/${user._id}`)
            setVanityLink(inbox.vanityLink)
        } catch (error) {
            alert(error)
        }
    }

    return (
        <Box w={{ base: 232, md: 400 }}>
            <Text variant="profile-title">Inbox Settings</Text>

            <Box pl="16px" w="100%">
                <FormLabel mt="16px">Link Name</FormLabel>
                <InputGroup size="lg" width="135%">
                    <InputLeftAddon
                        children="inbox.officeotter.com/"
                        pr="4px"
                        overflow="hidden"
                        w={{ base: '96px', md: 216 }}
                    />
                    <Input
                        type="text"
                        value={vanityLink}
                        onChange={(e) => setVanityLink(e.target.value)}
                        placeholder="Type your link name..."
                        pl="4px"
                        borderTopRightRadius="0px"
                        borderBottomRightRadius="0px"
                    />
                    <Tooltip label="Copy link" shouldWrapChildren>
                        <InputRightAddon
                            children={
                                <CopyIcon
                                    height="24px"
                                    cursor="pointer"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            `${process.env.REACT_APP_INBOX_URL}/${vanityLink}`
                                        )
                                        // notify the user
                                        toaster({
                                            duration: 3000,
                                            render: () => (
                                                <ToastyBoi
                                                    message={'Link copied'}
                                                    icon={
                                                        <CircleCheckIcon fill="white" />
                                                    }
                                                    backgroundColor="blue.500"
                                                />
                                            ),
                                        })
                                    }}
                                />
                            }
                        />
                    </Tooltip>
                </InputGroup>
            </Box>
            <Flex w="135%" justify="space-between">
                <Button
                    ml="16px"
                    size="lg"
                    mt="16px"
                    onClick={() =>
                        window.open(
                            `${process.env.REACT_APP_INBOX_URL}/${vanityLink}`,
                            '_blank'
                        )
                    }
                    leftIcon={<EyeIcon />}
                >
                    preview
                </Button>
            </Flex>
            <Button variant="profile-save" onClick={submitForm}>
                Save changes
            </Button>
        </Box>
    )
}
