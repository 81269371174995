export const affirmations = [
    'Another one bites the dust!',
    'We 👀you! Great job!',
    "Let's get it 💪💯",
    'Wow, that was fast!',
    "Knock 'em back",
    'Thanks for doing that!',
    'Way to go!',
    'You made that look easy.',
    'You always find a way to get it done!',
    'Fantastic work!',
    'Thank you on behalf of your team!',
    "Is there anything you can't do?",
    'Your work never ceases to amaze me.',
    'Thanks for always being willing to lend a hand.',
    'Fantastic work ethic!',
    'You’re so great to work with.',
    "You're otter this world.",
    "You're so reliable!",
    'Kudos!',
    'You are invaluable!',
    'Wow! Nice work.',
    'Way to go above and beyond.',
    'You’re crushing it!',
]
