import React, { useState, useEffect } from 'react'
import {
    Box,
    FormControl,
    FormLabel,
    Button,
    RadioGroup,
    Radio,
    Select,
    Flex,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberDecrementStepper,
    NumberIncrementStepper,
} from '@chakra-ui/react'
import { RRule } from 'rrule'
import { format, getDate, getMonth } from 'date-fns'
import DatePicker from '../../SharedComponents/DatePicker'
import { apiCall } from '../../Util/api'
import converter from 'number-to-words'

export const DAYSOFWEEK = [
    RRule.MO,
    RRule.TU,
    RRule.WE,
    RRule.TH,
    RRule.FR,
    RRule.SA,
    RRule.SU,
]

const DAYSOFWEEKTEXT = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

export const blankForm = {
    dtstart: new Date(),
    freq: RRule.DAILY,
    interval: 1,
    byweekday: [],
    bymonthday: null,
    bymonth: null,
    bysetpos: null,
    until: null,
    tzid: 'America/Los_Angeles',
}

export default function RecurrenceForm({
    date,
    recurrenceId,
    userId,
    rrule,
    setRrule,
    addTasksOnDay,
    setAddtasksOnDay,
}) {
    const [selectedEndRadio, setSelectedEndRadio] = useState('never')
    const [selectedOnRadio, setSelectedOnRadio] = useState('on day')
    console.log('rendering')
    //grab recurrence information from backend if there is an id
    useEffect(() => {
        if (recurrenceId) {
            getRecurrence()
        }
    }, [recurrenceId])

    useEffect(() => {
        if (
            new Date(date).toISOString() !==
            new Date(rrule.dtstart).toISOString()
        ) {
            handleSetFreq({
                target: { value: rrule.freq },
                preventDefault: () => true,
            })
        }
    }, [date])

    //create RRule npm pkg object with lots of extra fun functions like future recurrences and natural language
    const generateRrule = () => {
        const fakeDateStart = setPartsToUTCDate(rrule.dtstart)

        let ruleParams = {
            ...rrule,
            dtstart: new Date(fakeDateStart),
        }
        for (let key in ruleParams) {
            if (ruleParams[key] === '') {
                delete ruleParams[key]
            }
        }

        const rule = new RRule(ruleParams)

        return rule
    }

    //get reuccrence from backend and format for frontend form
    const getRecurrence = async () => {
        const recurrence = await apiCall(
            'GET',
            `/users/${userId}/recurrence/${recurrenceId}`
        )

        let rrule = recurrence.rruleParams

        rrule = {
            ...blankForm,
            ...rrule,
            dtstart: new Date(rrule.dtstart),
            until: rrule.until && new Date(rrule.until),
            byweekday: rrule.byweekday.map((d) => DAYSOFWEEK[d.weekday]),
        }

        let radioSelection,
            endradioSelection = 'on'

        if (rrule.bysetpos !== null && rrule.byweekday.length > 0) {
            radioSelection = 'on the'
        } else if (rrule.monthday !== null) {
            radioSelection = 'on day'
        }

        //has monthday and not month
        if (rrule.until === undefined) {
            endradioSelection = 'never'
        }

        setSelectedOnRadio(radioSelection)
        setSelectedEndRadio(endradioSelection)
        setRrule(rrule)
        setAddtasksOnDay(recurrence.daysInAdvance)
    }

    const handleSetFreq = (e) => {
        e.preventDefault()
        const freq = e.target.value

        let newRules = nullOutUnusedFields(freq)

        //set default values based on repeat selection
        // YEARLY = 0,
        // MONTHLY = 1,
        // WEEKLY = 2,
        // DAILY = 3,
        // HOURLY = 4,
        // MINUTELY = 5,
        // SECONDLY = 6

        switch (Number(freq)) {
            case RRule.DAILY:
                setAddtasksOnDay(0)
                break
            case RRule.WEEKLY:
                newRules.byweekday = [DAYSOFWEEK[new Date().getDay() - 1]]
                break
            case RRule.MONTHLY:
                newRules.bymonthday = Number(getDate(new Date(date)))
                setSelectedOnRadio('on day')
                break
            case RRule.YEARLY:
                newRules.interval = 1
                newRules.bymonthday = Number(getDate(new Date(date)))
                newRules.bymonth = Number(getMonth(new Date(date))) + 1

                setSelectedOnRadio('on')
                break
            default:
                break
        }

        setRrule(newRules)
    }

    const handleSetOnRadio = (selection) => {
        let newRules = nullOutUnusedFields(rrule.freq)

        //set default values based on radio selection for monthly/yearly
        switch (selection) {
            case 'on day':
                newRules.bymonthday = Number(getDate(new Date(date)))
                newRules.byweekday = []
                break
            // case 'on the':
            //     newRules.bysetpos = getWeekOfMonth(new Date(date))
            //     newRules.byweekday = [DAYSOFWEEK[new Date().getDay() - 1]]
            //     break
            default:
                break
        }

        setRrule(newRules)
        setSelectedOnRadio(selection)
    }

    const handleSetEndsRadio = (selection) => {
        let until = null

        switch (selection) {
            case 'never':
                break
            case 'on':
                until = new Date()
                break
            default:
                break
        }

        setSelectedEndRadio(selection)
        setRrule({ ...rrule, until })
    }

    //null out fields based on the selected frequency to make clean rrule generation and submission to backend
    const nullOutUnusedFields = (freq) => {
        let newRules = { ...rrule, dtstart: new Date(date) }

        for (let key in newRules) {
            if (key !== 'dtstart' && key !== 'until' && key !== 'interval') {
                newRules[key] = ''
            }
        }

        newRules.byweekday = []
        newRules.freq = Number(freq)

        return newRules
    }

    const handleSelectedDay = (selectedDay) => {
        let newRrule = { ...rrule }
        //if rrule has this day, remove it
        if (containsObject(selectedDay, newRrule.byweekday)) {
            newRrule.byweekday = newRrule.byweekday.filter(
                (d) => d.weekday !== selectedDay.weekday
            )
        } else {
            //if rrule does not have this day, add it
            newRrule.byweekday.push(selectedDay)
        }

        setRrule(newRrule)
    }

    return (
        <Box>
            <FormControl marginTop="16px">
                <>
                    <FormLabel fontSize="small" fontWeight="bold">
                        <Box mt="16px" fontSize="sm" fontWeight="bold">
                            Repeat {' ' + generateRrule().toText()}
                        </Box>
                        <Box fontWeight="normal">
                            {generateRrule()
                                .all(function (date, i) {
                                    return i < 5
                                })
                                .map((r, i) => {
                                    return (
                                        <span
                                            style={{ marginRight: 4 }}
                                            key={i}
                                        >
                                            {format(
                                                setUTCPartsToDate(r),
                                                'MMM dd,'
                                            )}
                                        </span>
                                    )
                                })}
                            etc.
                        </Box>
                    </FormLabel>

                    <Flex>
                        {/* DAILY REPEAT */}
                        <NumberInput
                            value={rrule.interval}
                            onChange={(value) =>
                                setRrule({
                                    ...rrule,
                                    interval: Number(value),
                                })
                            }
                            mr="16px"
                            defaultValue={1}
                            min={0}
                            max={355}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>

                        <Select value={rrule.freq} onChange={handleSetFreq}>
                            <option value={3}>
                                {rrule.interval === 1 ? 'day' : 'days'}
                            </option>
                            <option value={2}>
                                {rrule.interval === 1 ? 'week' : 'weeks'}
                            </option>
                            <option value={1}>
                                {rrule.interval === 1 ? 'month' : 'months'}
                            </option>
                            <option value={0}>
                                {rrule.interval === 1 ? 'year' : 'years'}
                            </option>
                        </Select>
                    </Flex>
                    <Flex>
                        {/* DAY of wek SELECTION, ONLY SHOW FOR WEEKLY RECURRENCE */}
                        {rrule.freq === RRule.WEEKLY && (
                            <Box>
                                <FormLabel
                                    fontSize="small"
                                    mt="16px"
                                    fontWeight="bold"
                                >
                                    On
                                </FormLabel>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: 4,
                                    }}
                                >
                                    {DAYSOFWEEK.map((d, i) => (
                                        <Button
                                            variant={
                                                containsObject(
                                                    d,
                                                    rrule.byweekday
                                                )
                                                    ? 'solid'
                                                    : 'grey'
                                            }
                                            colorScheme={
                                                containsObject(
                                                    d,
                                                    rrule.byweekday
                                                ) && 'blue'
                                            }
                                            size="sm"
                                            onClick={() => handleSelectedDay(d)}
                                            mr="4px"
                                            ml="4px"
                                            key={i}
                                        >
                                            {DAYSOFWEEKTEXT[d.weekday]}
                                        </Button>
                                    ))}
                                </div>
                            </Box>
                        )}
                        {/* MONTHLY/YEARLY REPEAT */}
                        {rrule.freq < RRule.WEEKLY && (
                            <RadioGroup
                                name="on"
                                value={selectedOnRadio}
                                onChange={handleSetOnRadio}
                            >
                                {rrule.freq === RRule.MONTHLY && (
                                    <>
                                        <div style={{ marginTop: 16 }}>
                                            <Radio value="on day">
                                                On the{' '}
                                                {converter.toOrdinal(
                                                    getDate(new Date(date))
                                                )}
                                            </Radio>
                                        </div>
                                        {/* <div>
                                            <Radio value="on the" mt="16px">
                                                On the{' '}
                                                {converter.toWordsOrdinal(
                                                    getWeekOfMonth(
                                                        new Date(date)
                                                    )
                                                )}{' '}
                                                {format(new Date(date), 'EEEE')}
                                            </Radio>
                                        </div> */}
                                    </>
                                )}
                            </RadioGroup>
                        )}
                    </Flex>
                    <FormLabel
                        style={{ marginTop: 16 }}
                        fontSize="sm"
                        fontWeight="bold"
                    >
                        Ends
                    </FormLabel>
                    <RadioGroup
                        name="ends"
                        value={selectedEndRadio}
                        onChange={handleSetEndsRadio}
                    >
                        <Radio value="never">Never</Radio>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Radio value="on" mr="32px">
                                On
                            </Radio>{' '}
                            <DatePicker
                                date={rrule.until ? rrule.until : new Date()}
                                setDate={(date) =>
                                    setRrule({
                                        ...rrule,
                                        until: date,
                                    })
                                }
                                isDisabled={!Boolean(rrule.until)}
                            />
                        </div>
                    </RadioGroup>
                </>
                {Number(rrule.freq) < 3 && (
                    <>
                        <FormLabel fontSize="small" mt="16px" fontWeight="bold">
                            Add tasks to list
                        </FormLabel>
                        <Flex>
                            {/* DAILY REPEAT */}
                            <NumberInput
                                value={addTasksOnDay}
                                onChange={(value) => setAddtasksOnDay(value)}
                                mr="16px"
                                defaultValue={3}
                                min={0}
                                max={30}
                                width="80px"
                            >
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                            <Flex alignSelf="flex-end">
                                day{addTasksOnDay != 1 && 's'} in advance{' '}
                                {addTasksOnDay === 0 && '(morning of)'}
                            </Flex>
                        </Flex>
                    </>
                )}
            </FormControl>
        </Box>
    )
}

function containsObject(obj, list) {
    var i
    for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
            return true
        }
    }

    return false
}

function setPartsToUTCDate(d) {
    return new Date(
        Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
        )
    )
}

function setUTCPartsToDate(d) {
    return new Date(
        d.getUTCFullYear(),
        d.getUTCMonth(),
        d.getUTCDate(),
        d.getUTCHours(),
        d.getUTCMinutes(),
        d.getUTCSeconds()
    )
}
