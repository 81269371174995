import React, { useState, useEffect, useContext } from 'react'
import theme from '../ChakraDesign/theme'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import { Box } from '@chakra-ui/react'
import { LabelsContext } from '../Contexts/LabelsContext'

export default function HashtagDropdown({
    width,
    coordinates,
    description,
    setDescription,
    setDropdownOpen,
}) {
    const { labels } = useContext(LabelsContext)
    const [label, setLabel] = useState('#')
    const [options, setOptions] = useState([...labels])

    const submitLabel = (newLabel) => {
        setDescription(description + newLabel)
        setDropdownOpen(false)
    }

    useEffect(() => {
        if (label !== '#') {
            setOptions([{ name: label.substring(1), _id: 0 }, ...labels])
        } else setOptions([...labels])
    }, [label])

    return (
        <Box
            h={coordinates.h}
            w={width}
            position="relative"
            top={coordinates.y.toString() + 'px'}
            left={coordinates.x.toString() + 'px'}
            backgroundColor={theme.colors.white}
        >
            <AutoComplete
                openOnFocus
                rollNavigation
                onSelectOption={(obj) => submitLabel(obj.item.value)}
            >
                <AutoCompleteInput
                    autoFocus
                    h={coordinates.h}
                    w={width}
                    variant="flushed"
                    fontSize="lg"
                    value={label}
                    onChange={(e) => {
                        if (e.target.value.includes('#')) {
                            setLabel(e.target.value)
                        } else {
                            setDropdownOpen(false)
                        }
                    }}
                />
                <AutoCompleteList
                    w={width}
                    mt="8px"
                    onBlur={() => setDropdownOpen(false)}
                >
                    {options.map((option, index) => (
                        <AutoCompleteItem
                            key={option._id}
                            value={`#${option.name}`}
                            label={`#${option.name}`}
                        />
                    ))}
                </AutoCompleteList>
            </AutoComplete>
        </Box>
    )
}
