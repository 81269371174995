export const sortFilesByCreationDate = (unsortedArray, direction) => {
    switch (direction) {
        case 'newestFirst':
            unsortedArray.sort((a, b) => {
                const datea = new Date(a.createdDate)
                const dateb = new Date(b.createdDate)

                return dateb - datea
            })
            break
        case 'oldestFirst':
            unsortedArray.sort((a, b) => {
                const datea = new Date(a.createdDate)
                const dateb = new Date(b.createdDate)

                return datea - dateb
            })
            break
        default:
            alert('not a valid sort option')
            break
    }

    return unsortedArray
}

export const sortFilesByDragAndDrop = (unsortedArrayOfFiles) => {
    return unsortedArrayOfFiles.sort((a, b) => a.position - b.position)
}
