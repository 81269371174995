import React from 'react'
import {
    Box,
    Text,
    Flex,
    Modal,
    Button,
    Container,
    ModalContent,
} from '@chakra-ui/react'
import TemplatesContainer from '../Templates/TemplatesContainer'

export default function TemplatesModal({ isOpen, setIsOpen }) {
    return (
        <Modal isOpen={isOpen} size="full" onClose={() => setIsOpen(false)}>
            <ModalContent p={4}>
                <Container maxW="container.xl">
                    <Box>
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            pl="16px"
                            pt="4px"
                        >
                            <Text fontSize="xl" fontWeight="600">
                                Manage Templates
                            </Text>
                            <Box>
                                <Button
                                    onClick={() => {
                                        setIsOpen(false)
                                    }}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Flex>
                    </Box>
                    <Box padding="32px">
                        <TemplatesContainer setModalOpen={setIsOpen} />
                    </Box>
                </Container>
            </ModalContent>
        </Modal>
    )
}
