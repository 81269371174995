import React, { useState } from 'react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Flex,
    Button,
    Tooltip,
} from '@chakra-ui/react'

import { FiltersIcon, CloseIcon } from '../ChakraDesign/Icons'
const SORT_BY_FILTERS = [
    {
        name: 'drag and drop (default)',
        value: 'dnd',
    },
    {
        name: 'creation date (newest first)',
        value: 'creationDateNewestFirst',
    },
    {
        name: 'creation date (oldest first)',
        value: 'creationDateOldestFirst',
    },
]

export default function FilesFilteringAndSorting({ sortBy, setSortBy }) {
    const SortByButton = React.forwardRef(({ children, ...rest }, ref) => (
        <span ref={ref} {...rest}>
            <Button
                rightIcon={<FiltersIcon />}
                height="32px"
                display="flex"
                alignItems="center"
                fontSize="sm"
                color="black"
                justifyContent="space-between"
            >
                {children}
            </Button>
        </span>
    ))

    return (
        <Flex id="sort and filter" justifyContent="end" marginLeft="8px">
            {sortBy !== 'dnd' && (
                <Button
                    variant="chip-grey"
                    rightIcon={<CloseIcon height="16px" width="16px" />}
                    onClick={() => setSortBy('dnd')}
                >
                    {SORT_BY_FILTERS.filter((f) => f.value === sortBy)[0]?.name}
                </Button>
            )}
            <Menu isLazy>
                <Tooltip label="Sort files by a parameter">
                    <MenuButton as={SortByButton}>Sort by</MenuButton>
                </Tooltip>
                <MenuList>
                    {SORT_BY_FILTERS.map((f, i) => (
                        <MenuItem
                            onClick={() => setSortBy(f.value)}
                            value={f.value}
                            key={i}
                        >
                            {f.name}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Flex>
    )
}
