import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import logo from '../../Navbar/logo.png'
import theme from '../../ChakraDesign/theme'

export default function PDFLayout({ info, children }) {
    const PAGE_HEIGHT = 1056 - 32
    const HEADER_HEIGHT = 126
    const CONTENT_HEIGHT = PAGE_HEIGHT - HEADER_HEIGHT

    return (
        <>
            <Box padding="24px 24px 0px 24px">
                {' '}
                <Flex justify="space-between">
                    <Flex>
                        <Flex
                            alignSelf="center"
                            maxW="48px"
                            maxH="48px"
                            ml="-8px"
                        >
                            <img src={logo} style={{ maxWidth: '100%' }} />
                        </Flex>
                        <Text
                            display="flex"
                            fontFamily={theme.fonts.heading}
                            fontSize="30px"
                            fontWeight="bold"
                            letterSpacing="-0.75px"
                            pl="8px"
                            alignSelf="center"
                        >
                            office otter
                        </Text>
                    </Flex>
                    <Box fontSize="24px" fontWeight="bold">
                        {info.user.name}
                    </Box>
                </Flex>
                <Flex justify="space-between">
                    <Text fontSize="36px" fontWeight="bold">
                        Performance Report
                    </Text>
                    <Text
                        color={theme.colors.grey[900]}
                        fontSize="16px"
                        fontWeight="bold"
                    >
                        {format(info.startDate, 'MMM d y')} -{' '}
                        {format(info.endDate, 'MMM d y')}
                    </Text>
                </Flex>
            </Box>
            <Flex
                justify="start"
                align="center"
                direction="column"
                height={CONTENT_HEIGHT}
            >
                {children}
            </Flex>
        </>
    )
}
