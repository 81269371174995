import React, { useState } from 'react'
import { Input, Flex, useToast } from '@chakra-ui/react'
import OOModal from '../SharedComponents/OOModal'
import ToastyBoi from '../SharedComponents/ToastyBoi'
import { CopyIcon, CarrotIcon, CircleCheckIcon } from '../ChakraDesign/Icons'

export default function ShareFileDialogue({
    isOpen,
    onClose,
    fileLink,
    fileName,
}) {
    const toast = useToast()
    const copyToClipboard = () => {
        navigator.clipboard.writeText(fileLink)
        toast({
            duration: 3000,
            render: () => (
                <ToastyBoi
                    message="A link to the file has been copied"
                    icon={<CircleCheckIcon fill="white" />}
                    backgroundColor="blue.500"
                />
            ),
        })
    }

    return (
        <OOModal
            isOpen={isOpen}
            onClose={onClose}
            secondaryButton={{
                onClick: copyToClipboard,
                text: (
                    <>
                        <CopyIcon mt="6px" ml="-2px" />
                        <span>Copy Link</span>
                    </>
                ),
            }}
            title="Share file"
        >
            <Flex direction="column">{fileName}</Flex>
        </OOModal>
    )
}
