import React from 'react'
import { Box, VStack } from '@chakra-ui/react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { apiCall } from '../Util/api'
import FileCard from './FileCard'

export default function FilesList({
    files,
    setFiles,
    user,
    sortBy,
    updateFile,
    deleteFile,
}) {
    // dnd library needs styles to be in a separate function
    const containerStyle = (isDraggingOver) => ({
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 8,
        marginLeft: -8,
        marginRight: -8,
        cursor: isDraggingOver ? 'grabbing' : 'grab',
    })

    const onDragEnd = (result) => {
        // Initialize variables for function
        const { destination, source } = result
        const newIndex = destination ? destination.index : source.index
        let newSection = [...files]
        let newPosition = null

        if (newIndex === source.index) {
            return
        } else {
            // reorder the new section to reflect dnd
            let dragTask = newSection[source.index]
            newSection.splice(source.index, 1)
            newSection.splice(newIndex, 0, dragTask)

            // Calculate new position of task
            if (newIndex === 0) {
                newPosition = newSection[1].position - 100
            } else if (newIndex === newSection.length - 1) {
                newPosition = newSection[newSection.length - 2].position + 100
            } else {
                const position1 = newSection[newIndex - 1].position
                const position2 = newSection[newIndex + 1].position

                newPosition = (position1 + position2) / 2
            }

            // update state
            newSection[newIndex].position = newPosition
            setFiles(newSection)

            // update task position
            apiCall('PUT', `/users/${user._id}/files/${dragTask._id}`, {
                position: newPosition,
            })
            return
        }
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={69}>
                {(provided, snapshot) => (
                    <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={containerStyle(snapshot.isDraggingOver)}
                        maxH="75vh"
                        overflow="auto"
                        style={{
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            marginLeft: -8,
                            marginRight: -8,
                        }}
                    >
                        <VStack id="files-list">
                            {files?.map((f, i) => (
                                <>
                                    <FileCard
                                        index={i}
                                        key={f._id}
                                        file={f}
                                        updateFile={updateFile}
                                        deleteFile={deleteFile}
                                        files={files}
                                        setFiles={setFiles}
                                        user={user}
                                    />
                                </>
                            ))}
                            {provided.placeholder}
                        </VStack>
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    )
}
