import React from 'react'
import { Box } from '@chakra-ui/react'

export default function Avatar({ onClick, size, img, style, _hover }) {
    return (
        <Box
            height={size}
            width={size}
            backgroundSize={size}
            backgroundImage={`url(${img})`}
            marginTop="16px"
            backgroundRepeat={'no-repeat'}
            borderRadius="50%"
            marginLeft="8px"
            marginRight="8px"
            _hover={_hover}
            onClick={onClick}
            style={style}
        ></Box>
    )
}
