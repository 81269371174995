import React, { useState } from 'react'
import {
    Box,
    Flex,
    VStack,
    Progress,
    Text,
    Button,
    IconButton,
    Collapse,
    Spacer,
} from '@chakra-ui/react'
import { CarrotIcon } from '../ChakraDesign/Icons'
import { isOverDue } from '../Util/timeUtils'
import { format } from 'date-fns'
import Description from '../Tasks/TaskCard/Description'
import Notes from '../Tasks/TaskCard/Notes'
import Checklist from '../Tasks/TaskCard/Checklist'
import AttachmentsList from '../Tasks/TaskCard/AttachmentsList'
import InboxActionsBar from './InboxActionsBar'

export default function InboxCard({ task, acceptTask, denyTask }) {
    const [expanded, setExpanded] = useState(false)
    const progress = [
        task.checklist.filter((item) => item.complete).length,
        task.checklist.length,
    ]

    const handleClickTask = () => {
        if (!expanded) {
            setExpanded(true)
        }
    }

    const handleCloseTask = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setExpanded(false)
    }

    return (
        <Box
            w="100%"
            _hover={{
                boxShadow: '0 8px 16px 0 rgba(56, 96, 165, 0.15)',
                transition: '0.3s',
            }}
            _active={{
                cursor: 'grabbing',
            }}
            boxShadow={`${expanded && '0 8px 16px 0 rgba(56, 96, 165, 0.15)'}`}
            onClick={handleClickTask}
            backgroundColor="colors.white"
            cursor="pointer"
            mt="16px"
            p="16px"
            borderRadius="8px"
        >
            <Flex
                id="inbox-top-bar"
                justifyContent={{ base: 'start', md: 'space-between' }}
                width="100%"
                minHeight="48px"
            >
                <Box
                    style={{
                        paddingRight: '16px',
                        alignSelf: 'center',
                        marginLeft: '-4px',
                        marginTop: expanded && '11px',
                    }}
                    w={{
                        base: '90%',
                        md: !expanded ? '50%' : '100%',
                    }}
                >
                    <VStack flexDirection={expanded ? 'row' : 'column'}>
                        <Description
                            expanded={expanded}
                            disabled={true}
                            description={task.description}
                            handleUpdate={() => console.log('update desc')}
                            key={task._id}
                        />
                        {!expanded && progress[1] > 0 && (
                            <Flex
                                width="100%"
                                alignItems="center"
                                marginTop="0px !important"
                            >
                                <Progress
                                    value={(progress[0] / progress[1]) * 100}
                                    colorScheme="blue"
                                    borderRadius="16px"
                                    height="8px"
                                    width="100%"
                                    marginRight="16px"
                                    marginLeft="16px"
                                    marginTop="0px"
                                />
                                <Text
                                    fontSize="small"
                                    color="grey.900"
                                    fontWeight="bold"
                                    marginTop="0px"
                                >
                                    {progress[0]}/{progress[1]}
                                </Text>
                            </Flex>
                        )}
                    </VStack>
                </Box>
                {!expanded && (
                    <>
                        <Box
                            style={{
                                marginRight: 'auto',
                                alignSelf: 'center',
                            }}
                            display={{
                                base: 'none',
                                md: 'flex',
                            }}
                            w={{
                                md: '178px',
                            }}
                            flexWrap="wrap"
                        >
                            <Button variant="chip-blue" mt={'2px'} mb={'2px'}>
                                {task.requester.name}
                            </Button>
                        </Box>
                        <Text
                            fontSize="normal"
                            style={{
                                marginRight: 'auto',
                                alignSelf: 'center',
                            }}
                            w={{
                                base: 'none',
                                md: '136px',
                            }}
                            display={{
                                base: 'none',
                                md: 'flex',
                            }}
                            color={
                                isOverDue(task.due) ? 'red.500' : 'colors.black'
                            }
                        >
                            {task.due &&
                                format(new Date(task.due), 'MMM d, h:mma')}
                        </Text>
                        <Text
                            fontSize="normal"
                            style={{
                                alignSelf: 'center',
                            }}
                            w={{
                                md: '58px',
                            }}
                            display={{
                                base: 'none',
                                md: 'flex',
                            }}
                        >
                            {format(new Date(task.createdDate), 'MMM d')}
                        </Text>
                    </>
                )}
                {expanded && (
                    <IconButton
                        icon={<CarrotIcon />}
                        onClick={handleCloseTask}
                        size="lg"
                        borderRadius="xl"
                        backgroundColor="transparent"
                        color="grey.900"
                        alignSelf="flex-start"
                    />
                )}
            </Flex>
            <Collapse in={expanded} unmountOnExit>
                <Box
                    id="inbox-card-content"
                    marginLeft="32px"
                    marginRight="32px"
                    marginTop="8px"
                >
                    {task.notes.length > 0 && (
                        <>
                            <Notes
                                disabled={true}
                                notes={task.notes}
                                handleUpdate={() => console.log('update notes')}
                            />
                            <Spacer h="8px" />
                        </>
                    )}
                    {task.checklist.length > 0 && (
                        <>
                            <Checklist
                                disabled={true}
                                cantComplete={true}
                                items={task.checklist}
                                progress={progress}
                                expanded={expanded}
                                setExpanded={setExpanded}
                            />
                            <Spacer h="8px" />
                        </>
                    )}
                </Box>
                <AttachmentsList attachments={task.files} cantDelete={true} />
                <InboxActionsBar
                    task={task}
                    acceptTask={acceptTask}
                    denyTask={denyTask}
                    requestedBy={task.requester.name}
                    due={task.due}
                />
            </Collapse>
        </Box>
    )
}
