import React, { useState } from 'react'
import { getUTCHours } from '../../Util/timeUtils'
import {
    Text,
    Box,
    RadioGroup,
    Radio,
    Flex,
    Checkbox,
    Button,
} from '@chakra-ui/react'

export default function Notifications({ user, submit }) {
    // to make initializations cleaner
    const settings = user.notificationSettings

    // profile information
    const [type, setType] = useState(settings.type)
    const [BOD, setBOD] = useState(settings.beginningOfDayHour)
    const [EOD, setEOD] = useState(settings.endOfDayHour)
    const [weeklyReview, setWeeklyReview] = useState(settings.weeklyReviewHour)
    const [weeklyPreview, setWeeklyPreview] = useState(
        settings.weeklyPreviewHour
    )

    const handleWeeklyReviewClick = (isChecked) => {
        if (isChecked) {
            setWeeklyReview(getUTCHours(12))
        } else {
            setWeeklyReview(null)
        }
    }

    const handleWeeklyPreviewClick = (isChecked) => {
        if (isChecked) {
            setWeeklyPreview(getUTCHours(6))
        } else {
            setWeeklyPreview(null)
        }
    }

    const handleBODClick = (isChecked) => {
        if (isChecked) {
            setBOD(getUTCHours(7))
        } else {
            setBOD(null)
        }
    }

    const handleEODClick = (isChecked) => {
        if (isChecked) {
            setEOD(getUTCHours(16))
        } else {
            setEOD(null)
        }
    }

    const submitForm = async () => {
        await submit({
            notificationSettings: {
                type,
                endOfDayHour: EOD,
                beginningOfDayHour: BOD,
                weeklyReviewHour: weeklyReview,
                weeklyPreviewHour: weeklyPreview,
            },
        })
    }

    return (
        <>
            <Text variant="profile-title">Default notification type</Text>
            <Box pl="16px" w="100%">
                <RadioGroup value={type} onChange={setType}>
                    <Flex direction="column" align="start">
                        <Radio size="lg" mt="8px" mb="8px" value="desktop">
                            Desktop
                        </Radio>
                        <Radio size="lg" mt="8px" mb="8px" value="text">
                            Text
                        </Radio>
                        <Radio size="lg" mt="8px" mb="8px" value="email">
                            Email
                        </Radio>
                    </Flex>
                </RadioGroup>
            </Box>
            <Text variant="profile-title">Weekly review email</Text>
            <Flex justify="space-between" w="100%" pl="16px" mb="8px">
                <Text>Fridays at 12:00pm</Text>
                <Checkbox
                    value={weeklyReview}
                    isChecked={weeklyReview}
                    onChange={(e) => handleWeeklyReviewClick(e.target.checked)}
                    size="lg"
                />
            </Flex>
            <Text variant="profile-title">Weekly preview email</Text>
            <Flex justify="space-between" w="100%" pl="16px" mb="8px">
                <Text>Mondays at 6:00am</Text>
                <Checkbox
                    value={weeklyPreview}
                    isChecked={weeklyPreview}
                    onChange={(e) => handleWeeklyPreviewClick(e.target.checked)}
                    size="lg"
                />
            </Flex>
            <Text variant="profile-title">Daily summary email</Text>
            <Flex direction="column" w="100%">
                <Flex justify="space-between" w="100%" pl="16px" mb="8px">
                    <Text>Beginning of day</Text>
                    <Checkbox
                        value={BOD}
                        isChecked={BOD}
                        onChange={(e) => handleBODClick(e.target.checked)}
                        size="lg"
                    />
                </Flex>
                <Flex justify="space-between" w="100%" pl="16px" mb="16px">
                    <Text>End of day</Text>
                    <Checkbox
                        value={EOD}
                        isChecked={EOD}
                        onChange={(e) => handleEODClick(e.target.checked)}
                        size="lg"
                    />
                </Flex>
            </Flex>
            <Button variant="profile-save" onClick={() => submitForm()}>
                Save changes
            </Button>
        </>
    )
}
