import React, { useState, useContext } from 'react'
import { LabelsContext } from '../Contexts/LabelsContext'
import { Input, Box, Button, Flex } from '@chakra-ui/react'
import OOModal from '../SharedComponents/OOModal'

export default function LabelsFilterModal({ isOpen, onClose }) {
    const {
        labels,
        selectedLabels,
        unselectedLabels,
        setFilteredLabels,
        selectFilterLabel,
        unselectFilterLabel,
    } = useContext(LabelsContext)

    const [labelSearchString, setLabelSearchString] = useState('')

    const handleSelect = (label) => {
        selectFilterLabel(label)
        setLabelSearchString('')
    }

    const handleUnselect = (label) => {
        if (label.name !== 'All Labels') {
            unselectFilterLabel(label)
            setLabelSearchString('')
        } else {
            return
        }
    }

    const handleFilteredLabels = () => {
        if (selectedLabels[0].name === 'All Labels') {
            setFilteredLabels([...labels])
        } else {
            setFilteredLabels([...selectedLabels])
        }
    }

    return (
        <OOModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={() => handleFilteredLabels()}
            title="Select Labels"
        >
            <Flex direction="column">
                <Input
                    id="label-search"
                    mt="8px"
                    mb="16px"
                    value={labelSearchString}
                    placeholder="Search labels..."
                    autoComplete="off"
                    autoFocus
                    onChange={(e) => setLabelSearchString(e.target.value)}
                />
                <Box maxH="480px" overflowY="auto" overflowX="hidden">
                    <Box mt="0px" w="400px">
                        {selectedLabels.map((label) => (
                            <Button
                                mb="8px"
                                variant="chip-colored"
                                background={
                                    label.color === ''
                                        ? 'blue.500'
                                        : label.color
                                }
                                onClick={() => handleUnselect(label)}
                            >
                                {label.name}
                            </Button>
                        ))}
                    </Box>
                    <Box mt="0px" w="400px">
                        {unselectedLabels
                            .filter((label) =>
                                label.name
                                    .toLowerCase()
                                    .includes(labelSearchString.toLowerCase())
                            )
                            .map((label) => (
                                <Button
                                    mb="8px"
                                    variant="chip-grey"
                                    onClick={() => handleSelect(label)}
                                >
                                    <Box
                                        h="12px"
                                        w="12px"
                                        borderRadius="6px"
                                        bg={
                                            label.color === ''
                                                ? 'blue.500'
                                                : label.color
                                        }
                                        mr="4px"
                                        ml="-4px"
                                    ></Box>
                                    {label.name}
                                </Button>
                            ))}
                    </Box>
                </Box>
            </Flex>
        </OOModal>
    )
}
