import React, { useState, useEffect, useContext } from 'react'
import Poll from './Poll'
import Comments from './Comments'
import { Flex, Center, Switch } from '@chakra-ui/react'
import { UserContext } from '../Contexts/UserContext'
import { apiCall } from '../Util/api'

export default function Post({
    image: postimage,
    description: postdescription,
    likes,
    comments,
    isPoll,
    options,
    _id,
    isPublished,
    posts,
    setPosts,
    showUserView,
}) {
    const { user } = useContext(UserContext)
    const [description, setDescription] = useState(postdescription)
    const [image, setImage] = useState(postimage)

    const updatePost = async (updates) => {
        const createdPost = await apiCall(
            'PUT',
            `/users/${user._id}/post/${_id}`,
            updates
        )
        const newPosts = posts.map((p) =>
            p._id === createdPost._id ? createdPost : p
        )
        setPosts(newPosts)
        return createdPost._id
    }
    return (
        <Flex
            flexDir="column"
            alignItems="center"
            w={{ sm: '95%', md: '90%', lg: '80%' }}
            mb="32px"
        >
            {isPoll ? (
                <Poll
                    description={description}
                    options={options}
                    _id={_id}
                    posts={posts}
                    setPosts={setPosts}
                ></Poll>
            ) : (
                <Flex
                    mt="16px"
                    style={{
                        height: 240,
                        width: '100%',
                        backgroundPosition: 'center center',
                        padding: 12,
                        backgroundSize: 'cover',
                        display: 'flex',
                        borderRadius: '16px 16px 0px 0px ',
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.75)), url(${image})`,
                    }}
                    className="hover"
                    onClick={() => console.log('post title clicked')}
                >
                    <Center
                        style={{
                            color: 'white',
                            fontWeight: '600',
                            paddingLeft: 8,
                            paddingBottom: 8,
                            fontSize: '24px',
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        {description}
                    </Center>
                </Flex>
            )}
            {process.env.REACT_APP_ADMIN.split(' ').includes(user._id) &&
                !showUserView && (
                    <Flex w="100%" mt="16px">
                        <Switch
                            isChecked={isPublished}
                            onChange={() =>
                                updatePost({ isPublished: !isPublished })
                            }
                        >
                            Published
                        </Switch>
                    </Flex>
                )}
            <Comments
                posts={posts}
                setPosts={setPosts}
                comments={comments}
                likes={likes}
                _id={_id}
            ></Comments>
        </Flex>
    )
}
