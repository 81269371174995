import React, { useState, useEffect, useContext } from 'react'
import { apiCall } from './api'
import { UserContext } from '../Contexts/UserContext'
import Document from '@tiptap/extension-document'
import { useEditor, EditorContent } from '@tiptap/react'
import TextAlign from '@tiptap/extension-text-align'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import Bold from '@tiptap/extension-bold'
import Link from '@tiptap/extension-link'
import HardBreak from '@tiptap/extension-hard-break'
import { Text as ttText } from '@tiptap/extension-text'
import Paragraph from '@tiptap/extension-paragraph'
import {
    Box,
    Flex,
    Text,
    Modal,
    Button,
    ModalBody,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
} from '@chakra-ui/react'

export default function NewFeaturePopup({
    message,
    newFeature,
    setNewFeature,
}) {
    const { user } = useContext(UserContext)

    const closeModal = async () => {
        await apiCall(`PUT`, `/users/${user._id}`, {
            newFeature: false,
            newFeatureMessage: '',
        })

        setNewFeature(false)
    }

    return (
        <Modal
            size="2xl"
            isOpen={newFeature}
            onClose={closeModal}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent p="16px">
                <ModalCloseButton m="8px" />
                <ModalHeader>
                    <Text textAlign="center">
                        New Feature Alert{' '}
                        <span style={{ marginLeft: 2 }}> 🎉</span>
                    </Text>
                </ModalHeader>
                <ModalBody mt="-16px">
                    <Flex direction="column">
                        <Text textAlign="center" mb="16px">
                            We've been hard at work! Refresh your page to get
                            Office Otter's newest features!
                        </Text>
                        {message !== '' && <TextAreaForm message={message} />}
                    </Flex>
                    <Flex justifyContent="end">
                        <Button
                            mt="16px"
                            alignItems="flex-end"
                            colorScheme="blue"
                            onClick={() => closeModal()}
                        >
                            got it!
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

const TextAreaForm = ({ message }) => {
    const box = document.getElementById('new-feature-message')
    const [outline, setOutline] = useState('')

    let editor = useEditor({
        extensions: [
            Bold,
            ttText,
            Document,
            ListItem,
            Paragraph,
            HardBreak,
            Link.configure({ HTMLAttributes: { class: 'description-link' } }),
            TextAlign.configure({
                types: ['paragraph'],
            }),
            BulletList.configure({
                HTMLAttributes: { class: 'tiptap-bullet' },
            }),
        ],
        content: message,
        editable: false,
    })

    useEffect(() => {
        if (box && box.clientHeight > 32) {
            setOutline('1px solid #0a58ce')
        }
    })

    return (
        <Box
            id="new-feature-message"
            outline={outline}
            borderRadius="8px"
            p="16px"
            mt="8px"
        >
            <EditorContent editor={editor} />
        </Box>
    )
}
