import React, { useState, useEffect } from 'react'
import {
    ComposedChart,
    Area,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'
import CustomTooltip from './Utils/CustomTooltip'
import ChartCard from './Utils/ChartCard'
import theme from '../../ChakraDesign/theme'
import { add, differenceInCalendarDays, format } from 'date-fns'
import { getWeekNum } from './Utils/helperFunctions'

export default function CreatedVsCompleted({
    startDate,
    endDate,
    tasks,
    completedTasks,
    isNewUser,
    isPdf,
    mobile,
}) {
    const [filteredData, setFilteredData] = useState([])
    const colors = [theme.colors.grey[900], theme.colors.blue[500]]

    useEffect(() => {
        if (isNewUser) {
            const dummyData = [
                {
                    date: startDate,
                    label: `${format(startDate, 'MMM d')}`,
                    numCreatedTasks: 14,
                    numCompTasks: 11,
                },
                {
                    date: add(startDate, { weeks: 1 }),
                    label: `${format(add(startDate, { weeks: 1 }), 'MMM d')}`,
                    numCreatedTasks: 16,
                    numCompTasks: 10,
                },
                {
                    date: add(startDate, { weeks: 2 }),
                    label: `${format(add(startDate, { weeks: 2 }), 'MMM d')}`,
                    numCreatedTasks: 12,
                    numCompTasks: 17,
                },
                {
                    date: add(startDate, { weeks: 3 }),
                    label: `${format(add(startDate, { weeks: 3 }), 'MMM d')}`,
                    numCreatedTasks: 19,
                    numCompTasks: 13,
                },
                {
                    date: add(startDate, { weeks: 4 }),
                    label: `${format(add(startDate, { weeks: 4 }), 'MMM d')}`,
                    numCreatedTasks: 11,
                    numCompTasks: 15,
                },
                {
                    date: add(startDate, { weeks: 5 }),
                    label: `${format(add(startDate, { weeks: 5 }), 'MMM d')}`,
                    numCreatedTasks: 15,
                    numCompTasks: 13,
                },
                {
                    date: add(startDate, { weeks: 6 }),
                    label: `${format(add(startDate, { weeks: 6 }), 'MMM d')}`,
                    numCreatedTasks: 12,
                    numCompTasks: 10,
                },
                {
                    date: add(startDate, { weeks: 7 }),
                    label: `${format(add(startDate, { weeks: 7 }), 'MMM d')}`,
                    numCreatedTasks: 18,
                    numCompTasks: 13,
                },
                {
                    date: add(startDate, { weeks: 8 }),
                    label: `${format(add(startDate, { weeks: 8 }), 'MMM d')}`,
                    numCreatedTasks: 15,
                    numCompTasks: 12,
                },
            ]
            setFilteredData(dummyData)
        } else {
            //get whole number for total weeks in selected date range
            const numWeeks = Math.ceil(
                (differenceInCalendarDays(endDate, startDate) + 1) / 7
            )
            let data = Array(numWeeks).fill({})
            // Create an array which has a data object for each week in the given time interval
            data = data.map((week, index) => {
                const date = add(startDate, { weeks: index })
                return {
                    date: date,
                    label: `${format(date, 'MMM d')}`,
                    numCreatedTasks: 0,
                    numCompTasks: 0,
                }
            })

            //fill data array by week number
            completedTasks.forEach((task) => {
                const weekNumber = getWeekNum(
                    new Date(task.completionDate),
                    startDate
                )
                if (data[weekNumber] !== undefined) {
                    data[weekNumber].numCompTasks++
                }
            })

            // Extract created date from each task, filter through dates within the specified interval, and increment numCreatedTasks accordingly
            tasks.forEach((date) => {
                const weekNumber = getWeekNum(
                    new Date(date.createdDate),
                    startDate
                )

                if (data[weekNumber] !== undefined) {
                    data[weekNumber].numCreatedTasks++
                }
            })
            setFilteredData(data)
        }
    }, [startDate, endDate, tasks, isNewUser, completedTasks])

    const keys = [
        {
            color: colors[0],
            text: 'Created',
        },
        {
            color: colors[1],
            text: 'Completed',
        },
    ]

    const stats = [
        {
            name: 'Total tasks completed',
            value: isNewUser ? 114 : completedTasks.length,
            color: colors[1],
        },
    ]

    const unitLabels = [
        {
            singular: 'task created',
            plural: 'tasks created',
        },
        {
            singular: 'task completed',
            plural: 'tasks completed',
        },
    ]

    return (
        <ChartCard
            title="Created vs Completed"
            description="How many tasks are created and completed week over week"
            keys={keys}
            stats={stats}
            isPdf={isPdf}
        >
            <ResponsiveContainer width={mobile ? '125%' : '105%'} height={320}>
                {filteredData.length > 0 && (
                    <ComposedChart
                        width={600}
                        height={300}
                        data={filteredData}
                        margin={{
                            top: 5,
                            right: mobile ? 110 : 85,
                            bottom: 50,
                            left: mobile && -32,
                        }}
                    >
                        <Tooltip
                            content={
                                <CustomTooltip
                                    endDate={endDate}
                                    colors={colors}
                                    unitLabels={unitLabels}
                                    dateLabelType={'dynamic'}
                                />
                            }
                        />
                        <CartesianGrid stroke={theme.colors.grey[200]} />
                        <Line
                            type="monotone"
                            dataKey="numCompTasks"
                            stroke={colors[1]}
                            animationDuration={!isPdf && 3000}
                            strokeWidth={5}
                            dot={false}
                        />
                        <Area
                            type="monotone"
                            dataKey="numCompTasks"
                            fill={colors[1]}
                            animationDuration={!isPdf && 3000}
                            stroke="none"
                            fillOpacity=".7"
                        />
                        <Line
                            type="monotone"
                            dataKey="numCreatedTasks"
                            stroke={colors[0]}
                            animationDuration={!isPdf && 3000}
                            strokeWidth={3}
                            dot={false}
                        />
                        <Area
                            type="monotone"
                            dataKey="numCreatedTasks"
                            fill={colors[0]}
                            animationDuration={!isPdf && 3000}
                            stroke="none"
                            fillOpacity=".2"
                        />
                        <XAxis
                            dataKey="label"
                            color="red"
                            interval={{ base: 1, sm: 0 }}
                            angle={25}
                            scale="point"
                            dy={10}
                        ></XAxis>
                        <YAxis />
                    </ComposedChart>
                )}
            </ResponsiveContainer>
        </ChartCard>
    )
}
