import React from 'react'
import { Box, Text, VStack, Flex } from '@chakra-ui/react'

export default function Colors() {
    return (
        <VStack align="flex-start">
            <Text fontSize="3xl">Colors</Text>
            <Box>
                <Text fontSize="sm">Common</Text>
                <Flex>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="black"
                    ></Box>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="white"
                        border="1px solid black"
                    ></Box>
                </Flex>
            </Box>
            <Box>
                <Text fontSize="sm">blue</Text>
                <Flex>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="blue.400"
                    ></Box>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="blue.500"
                    ></Box>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="blue.600"
                    ></Box>
                </Flex>
            </Box>
            <Box>
                <Text fontSize="sm">grey</Text>
                <Flex>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="grey.400"
                    ></Box>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="grey.500"
                    ></Box>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="grey.600"
                    ></Box>
                </Flex>
            </Box>
            <Box>
                <Text fontSize="sm">teal</Text>
                <Flex>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="teal.400"
                    ></Box>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="teal.500"
                    ></Box>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="teal.600"
                    ></Box>
                </Flex>
            </Box>
            <Box>
                <Text fontSize="sm">Pink</Text>
                <Flex>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="pink.400"
                    ></Box>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="pink.500"
                    ></Box>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="pink.600"
                    ></Box>
                </Flex>
            </Box>
            <Box>
                <Text fontSize="sm">Red</Text>
                <Flex>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="red.400"
                    ></Box>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="red.500"
                    ></Box>
                    <Box
                        h="48px"
                        w="48px"
                        ml="4px"
                        mr="4px"
                        borderRadius="50%"
                        backgroundColor="red.600"
                    ></Box>
                </Flex>
            </Box>
        </VStack>
    )
}
