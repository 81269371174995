import React from 'react'
import { Flex } from '@chakra-ui/react'

export default function IntegrationCard({
    id,
    emoji,
    title,
    subtitle,
    onClick,
    style,
}) {
    return (
        <Flex
            id={id}
            _hover={{
                cursor: 'pointer',
                boxShadow: '0 8px 16px 0 rgba(56, 96, 165, 0.2)',
            }}
            borderRadius="16px"
            width="240px"
            margin="4px 4px"
            flexDir="column"
            alignItems="center"
            padding="16px"
            boxShadow="0 2px 4px 0 rgba(56, 96, 165, 0.2)"
            onClick={onClick}
            style={style}
            justifyContent="space-between"
        >
            <div style={{ fontSize: 48 }}>{emoji}</div>
            <div
                style={{
                    fontSize: 18,
                    lineHeight: 1.2,
                    textAlign: 'center',
                    fontWeight: 500,
                    letterSpacing: 0.1,
                }}
            >
                {title}
            </div>
            <div
                style={{
                    marginTop: 16,
                    fontSize: 14,
                    lineHeight: 1.1,
                    textAlign: 'center',
                    letterSpacing: 0.2,
                }}
            >
                {subtitle}
            </div>
        </Flex>
    )
}
