import React from 'react'
import { Box, Text, Flex } from '@chakra-ui/react'
import theme from '../../../ChakraDesign/theme'

export default function ChartCard({
    title,
    description,
    keys,
    children,
    Legend,
    stats,
    isPdf,
}) {
    const ChartHR = () => (
        <hr
            style={{
                border: '1px solid',
                borderColor: theme.colors.grey[600],
                opacity: 0.55,
                margin: 16,
                marginTop: -24,
                borderRadius: 25,
            }}
        />
    )

    const StatHR = () => (
        <span
            style={{
                fontWeight: 'bold',
                border: `1px solid`,
                borderColor: theme.colors.grey[300],
                borderRadius: 25,
                height: 1,
                width: '40%',
                marginLeft: 16,
                marginRight: 16,
            }}
        />
    )
    return (
        <Box
            w="100%"
            maxW={{ base: !isPdf ? '360px' : '720px', md: '720px' }}
            m="16px"
            borderRadius={theme.radii.base}
            padding="16px"
            boxShadow={isPdf ? 'none' : theme.shadows.base}
        >
            <Flex justify="space-between">
                <Box>
                    <Text fontSize="24px" fontWeight="bold">
                        {title}
                    </Text>
                </Box>
                {keys && (
                    <Flex
                        align="start"
                        display={{ base: !isPdf ? 'none' : 'flex', md: 'flex' }}
                    >
                        {keys.map((key) => (
                            <Text
                                fontSize="14px"
                                display="flex"
                                alignItems="center"
                            >
                                <Box
                                    style={{
                                        height: 16,
                                        width: 16,
                                        padding: 4,
                                        backgroundColor: key.color,
                                        marginRight: 8,
                                        marginLeft: 8,
                                        borderRadius: 8,
                                    }}
                                />
                                {key.text}
                            </Text>
                        ))}
                    </Flex>
                )}
            </Flex>
            <Text
                fontSize="16px"
                mb="16px"
                whiteSpace={{ base: '', md: 'pre' }}
            >
                {description}
            </Text>
            {keys && (
                <Flex
                    align="start"
                    mb="16px"
                    mt="0px"
                    display={{ base: !isPdf ? 'flex' : 'none', md: 'none' }}
                >
                    {keys.map((key) => (
                        <Text
                            fontSize="14px"
                            display="flex"
                            alignItems="center"
                            mr="8px"
                        >
                            <Box
                                style={{
                                    height: 16,
                                    width: 16,
                                    padding: 4,
                                    backgroundColor: key.color,
                                    marginRight: 8,
                                    borderRadius: 8,
                                }}
                            />
                            {key.text}
                        </Text>
                    ))}
                </Flex>
            )}
            {Legend}
            {children}
            {stats && (
                <>
                    {children && <ChartHR />}
                    {stats.map((stat) => (
                        <Text
                            style={{
                                fontWeight: 'bold',
                                margin: 8,
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                display: 'flex',
                                // whiteSpace: children && 'pre',
                            }}
                            fontSize={{
                                base: !isPdf ? '14px' : '20px',
                                md: '20px',
                            }}
                            whiteSpace={{
                                base: !isPdf && !children ? '' : 'pre',
                                md: 'pre',
                            }}
                        >
                            {stat.name}
                            <StatHR />
                            <Text style={{ color: stat.color }}>
                                {stat.value}
                            </Text>
                        </Text>
                    ))}
                </>
            )}
        </Box>
    )
}
