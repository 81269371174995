import React, { useState, useContext } from 'react'
import { URGENCIES } from '../../Util/urgencies'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Box,
    Flex,
    Button,
    Tooltip,
    Text,
} from '@chakra-ui/react'
import {
    ActivityIcon,
    CarrotIcon,
    ClockIcon,
    LabelIcon,
    CalendarIcon,
    KebabIcon,
    AttachmentIcon,
    SharingIcon,
    TemplateIcon,
    TrashIcon,
    InfoIcon,
    PushPinIcon,
    HourglassIcon,
} from '../../ChakraDesign/Icons'
import { format } from 'date-fns'
import { apiCall } from '../../Util/api'
import { isOverDue } from '../../Util/timeUtils'
import { analytics } from '../../Util/api'
import { UserContext } from '../../Contexts/UserContext'
import { TasksContext } from '../../Contexts/TasksContext'
import UploadButton from './UploadButton'
import LabelModal from '../LabelModal'
import NotificationModal from '../NotificationModal'
import DueDateModal from '../DueDateModal'
import SharingModal from '../SharingModal'
import TimeKeepingModal, {
    getTotalMinutesFromTimeLog,
} from '../TimeKeepingModal'

export default function ActionsBar({
    task,
    cards,
    setCards,
    taskUrgency,
    taskLabels,
    setTaskLabels,
    due,
    handleUpdateDueDate,
    updateCompletedTask,
    createGoogleCalendarEvent,
    createNotification,
    notifications,
    setNotifications,
    handleUploadSuccess,
    saveAsTemplate,
    handleDeleteNotification,
    shareLinkPermissionType,
    isCompleted,
    recurrenceId,
    urgency,
    isPinned,
}) {
    // stat variables
    const [isDueDateModalOpen, setIsDueDateModalOpen] = useState(false)
    const [isSharingModalOpen, setIsSharingModalOpen] = useState(false)
    const [isLabelsModalOpen, setIsLabelsModalOpen] = useState(false)
    const [isTimeKeepingModalOpen, setIsTimeKeepingModalOpen] = useState(false)
    const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
        useState(false)

    // context variables
    const { user } = useContext(UserContext)
    const { updateTask, deleteTask, changeTasksUrgency } =
        useContext(TasksContext)

    const ForwardRefWrapper = React.forwardRef(({ children, ...rest }, ref) => (
        <span ref={ref} {...rest}>
            {children}
        </span>
    ))

    const CustomTooltip = ({ label, children }) => (
        <Tooltip label={label}>
            <ForwardRefWrapper>{children}</ForwardRefWrapper>
        </Tooltip>
    )

    const UrgencySelector = React.forwardRef(({ children, ...rest }, ref) => (
        <span ref={ref} {...rest}>
            <Button
                rightIcon={<CarrotIcon />}
                minWidth="136px"
                height="40px"
                display="flex"
                alignItems="center"
                fontSize="md"
                color="blue.500"
                justifyContent="space-between"
            >
                {children}
            </Button>
        </span>
    ))

    const MoreActionsButton = React.forwardRef(({ children, ...rest }, ref) => (
        <span ref={ref} {...rest}>
            <Button variant="more-actions" leftIcon={<KebabIcon />}>
                {children}
            </Button>
        </span>
    ))

    const addFile = async (formData) => {
        const response = await apiCall(
            `POST`,
            `/users/${user._id}/files?taskId=${task._id}`,
            formData
        )
        return response
    }

    const updateTaskLabels = (newLabels) => {
        if (updateCompletedTask) {
            updateCompletedTask(task._id, { labels: newLabels })
        } else {
            updateTask(task._id, { labels: newLabels }, urgency)
        }
    }

    return (
        <Flex justifyContent="space-between" marginTop="24px">
            <Flex alignItems="center">
                <Menu isLazy>
                    <CustomTooltip label="Set urgency">
                        <MenuButton as={UrgencySelector}>
                            <span
                                style={{
                                    paddingRight: '12px',
                                    fontSize: '20px',
                                }}
                            >
                                {urgency === 4 || isCompleted
                                    ? '✅'
                                    : URGENCIES[taskUrgency].emoji}
                            </span>
                            {urgency === 4 || isCompleted
                                ? 'completed'
                                : URGENCIES[taskUrgency].text}
                        </MenuButton>
                    </CustomTooltip>
                    <MenuList>
                        {URGENCIES.map((urgency, i) => (
                            <MenuItem
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    changeTasksUrgency(task._id, taskUrgency, i)
                                }}
                                value={i}
                                key={i}
                            >
                                <span
                                    style={{
                                        paddingRight: '16px',
                                        fontSize: '20px',
                                    }}
                                >
                                    {urgency.emoji}
                                </span>
                                {urgency.text}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>

                {due && (
                    <CustomTooltip label="The urgency of this task is being ottermatically updated because it has a due date set.">
                        <span>
                            <InfoIcon
                                fill="pink.500"
                                h="16px"
                                w="16px"
                                marginLeft="4px"
                            />
                        </span>
                    </CustomTooltip>
                )}
                <Box marginLeft="16px">
                    {taskLabels.map((label) => (
                        <Button
                            variant="chip-colored"
                            background={
                                label.color === '' ? 'blue.500' : label.color
                            }
                            onClick={() => setIsLabelsModalOpen(true)}
                        >
                            {label.name}
                        </Button>
                    ))}
                </Box>
            </Flex>
            <Flex alignItems="center">
                <Flex
                    alignItems="center"
                    display={{ base: 'none', lg: 'flex' }}
                >
                    {isPinned && (
                        <CustomTooltip label="Unpin task">
                            <Button
                                variant="icon-button-text"
                                onClick={() =>
                                    updateTask(
                                        task._id,
                                        {
                                            isPinned: false,
                                        },
                                        urgency
                                    )
                                }
                                leftIcon={<PushPinIcon marginRight="0px" />}
                            >
                                Unpin
                            </Button>
                        </CustomTooltip>
                    )}
                    {getTotalMinutesFromTimeLog(task.timeLog) > 0 && (
                        <CustomTooltip label="Track time">
                            <Button
                                variant="icon-button-text"
                                onClick={() => setIsTimeKeepingModalOpen(true)}
                                leftIcon={<HourglassIcon marginRight="0px" />}
                            >
                                {Math.floor(
                                    getTotalMinutesFromTimeLog(task.timeLog) /
                                        60
                                )}
                                {''}:
                                {getTotalMinutesFromTimeLog(task.timeLog) %
                                    60 >=
                                    0 &&
                                    getTotalMinutesFromTimeLog(task.timeLog) %
                                        60 <
                                        10 &&
                                    '0'}
                                {getTotalMinutesFromTimeLog(task.timeLog) % 60}
                            </Button>
                        </CustomTooltip>
                    )}
                    <CustomTooltip label="Add and remove labels">
                        <Button
                            variant="icon-button-text"
                            onClick={() => setIsLabelsModalOpen(true)}
                            leftIcon={<LabelIcon marginRight="0px" />}
                        >
                            Label
                        </Button>
                    </CustomTooltip>
                    <CustomTooltip label="Set a due date & your task will auto-sort">
                        <Button
                            variant="icon-button-text"
                            onClick={() => setIsDueDateModalOpen(true)}
                            leftIcon={<ClockIcon />}
                            maxW="none"
                        >
                            {due ? (
                                <Text
                                    fontSize="sm"
                                    alignSelf="center"
                                    color={
                                        isOverDue(due)
                                            ? 'red.500'
                                            : 'colors.black'
                                    }
                                    display={{
                                        base: 'none',
                                        md: 'flex',
                                    }}
                                    marginLeft="4px"
                                    marginRight="4px"
                                >
                                    {format(new Date(due), 'M/d, h:mma')}
                                </Text>
                            ) : (
                                'Due'
                            )}
                        </Button>
                    </CustomTooltip>
                    <CustomTooltip label="Set a notification">
                        <Button
                            variant="icon-button-text"
                            onClick={() => setIsNotificationsModalOpen(true)}
                            leftIcon={<ActivityIcon />}
                        >
                            Remind
                        </Button>
                    </CustomTooltip>
                </Flex>
                <Menu isLazy placement="left">
                    <CustomTooltip label="More actions">
                        <MenuButton as={MoreActionsButton}>More</MenuButton>
                    </CustomTooltip>
                    <MenuList>
                        <MenuItem
                            display={{ base: 'block', md: 'none' }}
                            icon={<ClockIcon width="24px" />}
                            onClick={() => setIsDueDateModalOpen(true)}
                        >
                            Set Due Date
                        </MenuItem>
                        <MenuItem
                            display={{ base: 'block', md: 'none' }}
                            icon={<ActivityIcon width="24px" />}
                            onClick={() => setIsNotificationsModalOpen(true)}
                        >
                            Set Notification
                        </MenuItem>
                        <MenuItem
                            display={{ base: 'block', md: 'none' }}
                            icon={<LabelIcon width="24px" />}
                            onClick={() => setIsLabelsModalOpen(true)}
                        >
                            Add Label
                        </MenuItem>
                        <MenuItem
                            icon={<HourglassIcon width="24px" />}
                            onClick={() => setIsTimeKeepingModalOpen(true)}
                        >
                            Track Time
                        </MenuItem>
                        <MenuItem
                            icon={<PushPinIcon width="24px" />}
                            onClick={() => {
                                updateTask(
                                    task._id,
                                    {
                                        isPinned: isPinned ? false : true,
                                    },
                                    urgency
                                )
                                analytics.trigger({
                                    name: 'Task Pinned',
                                    user,
                                })
                            }}
                        >
                            {isPinned ? 'Unpin task' : 'Pin task'}
                        </MenuItem>
                        <UploadButton
                            addFile={addFile}
                            onUploadSuccess={handleUploadSuccess}
                        >
                            <MenuItem
                                icon={<AttachmentIcon width="24px" />}
                                closeOnSelect={false}
                            >
                                Add Attachment
                            </MenuItem>
                        </UploadButton>
                        <MenuItem
                            icon={<CalendarIcon width="24px" />}
                            onClick={createGoogleCalendarEvent}
                        >
                            Add to calendar
                        </MenuItem>
                        <MenuItem
                            icon={<SharingIcon width="24px" />}
                            onClick={() => setIsSharingModalOpen(true)}
                        >
                            Share Task
                        </MenuItem>
                        <MenuItem
                            icon={<TemplateIcon width="24px" />}
                            onClick={() => saveAsTemplate()}
                        >
                            Save as Template
                        </MenuItem>
                        {urgency !== 4 && (
                            <MenuItem
                                icon={<TrashIcon width="24px" />}
                                onClick={() => deleteTask(task._id)}
                            >
                                Delete
                            </MenuItem>
                        )}
                    </MenuList>
                </Menu>
            </Flex>
            {isDueDateModalOpen && (
                <DueDateModal
                    due={due}
                    submit={(data) => handleUpdateDueDate(data)}
                    isOpen={isDueDateModalOpen}
                    onClose={() => setIsDueDateModalOpen(false)}
                    urgencyDescription
                    urgency={urgency}
                    recurrenceId={recurrenceId}
                    taskId={task._id}
                />
            )}
            {isNotificationsModalOpen && (
                <NotificationModal
                    usersNotificationType={user.notificationSettings.type}
                    submit={createNotification}
                    notifications={notifications}
                    setNotifications={setNotifications}
                    handleDeleteNotification={handleDeleteNotification}
                    isOpen={isNotificationsModalOpen}
                    onClose={() => setIsNotificationsModalOpen(false)}
                />
            )}
            {isLabelsModalOpen && (
                <LabelModal
                    cards={cards}
                    setCards={setCards}
                    isOpen={isLabelsModalOpen}
                    selectedLabels={taskLabels}
                    setSelectedLabels={setTaskLabels}
                    updateCardLabels={updateTaskLabels}
                    onClose={() => setIsLabelsModalOpen(false)}
                />
            )}
            {isSharingModalOpen && (
                <SharingModal
                    taskId={task._id}
                    isOpen={isSharingModalOpen}
                    onClose={() => setIsSharingModalOpen(false)}
                    shareLinkPermissionType={shareLinkPermissionType}
                    urgency={urgency}
                />
            )}
            {isTimeKeepingModalOpen && (
                <TimeKeepingModal
                    task={task}
                    isOpen={isTimeKeepingModalOpen}
                    onClose={() => setIsTimeKeepingModalOpen(false)}
                />
            )}
        </Flex>
    )
}
