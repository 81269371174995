import React, { useContext } from 'react'
import { UserContext } from '../Contexts/UserContext'
import { TasksContext } from '../Contexts/TasksContext'
import { Box, Flex, Text, VStack } from '@chakra-ui/react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import TaskCard from './TaskCard/TaskCard'
import { URGENCIES } from '../Util/urgencies'
import EmptyTaskBoi from './EmptyTaskBoi'

export default function AllTasksList({ urgency, noHeaders }) {
    const { tasks, setTasks } = useContext(TasksContext)
    return (
        <Box
            maxHeight="calc(100vh - 136px)"
            overflow="auto"
            style={{
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 8,
                marginLeft: -8,
                marginRight: -8,
                marginTop: 8,
            }}
            width="100%"
        >
            <DragDropContext>
                <Droppable droppableId="all-tasks">
                    {() => (
                        <VStack id="tasks-list">
                            {tasks.filter((t) => t.isPinned).length > 0 && (
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Flex
                                        alignItems="center"
                                        marginRight="16px"
                                    >
                                        <span
                                            style={{
                                                paddingRight: 8,
                                                fontSize: 22,
                                            }}
                                        >
                                            📌
                                        </span>
                                        <Text
                                            color="blue.500"
                                            fontSize="md"
                                            fontWeight="bold"
                                            whiteSpace="nowrap"
                                            marginLeft="8px"
                                        >
                                            Pinned
                                        </Text>
                                    </Flex>
                                    <hr
                                        style={{
                                            height: 1,
                                            backgroundColor: '#E5ECF6',
                                            border: '1px solid #E5ECF6',
                                            width: '100%',
                                            borderRadius: 16,
                                        }}
                                    />
                                </Box>
                            )}
                            {tasks
                                .filter((t) => t.isPinned)
                                .map((t, i) => (
                                    <TaskCard
                                        task={t}
                                        index={i}
                                        key={t._id}
                                        cards={tasks}
                                        urgency={urgency}
                                        disableDrag={true}
                                        setCards={setTasks}
                                    />
                                ))}
                            {URGENCIES.map((urgency, i) => (
                                <Box width="100%">
                                    {!noHeaders && (
                                        <Box
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Flex
                                                alignItems="center"
                                                marginRight="16px"
                                            >
                                                <span
                                                    style={{
                                                        paddingRight: 8,
                                                        fontSize: 22,
                                                    }}
                                                >
                                                    {URGENCIES[i].emoji}
                                                </span>
                                                <Text
                                                    color="blue.500"
                                                    fontSize="md"
                                                    fontWeight="bold"
                                                    whiteSpace="nowrap"
                                                    marginLeft="8px"
                                                >
                                                    {URGENCIES[i].text}
                                                </Text>
                                            </Flex>
                                            <hr
                                                style={{
                                                    height: 1,
                                                    backgroundColor: '#E5ECF6',
                                                    border: '1px solid #E5ECF6',
                                                    width: '100%',
                                                    borderRadius: 16,
                                                }}
                                            />
                                        </Box>
                                    )}
                                    {tasks.map(
                                        (t, index) =>
                                            t.urgency === i &&
                                            !t.isPinned && (
                                                <TaskCard
                                                    task={t}
                                                    key={t._id}
                                                    index={index}
                                                    cards={tasks}
                                                    urgency={urgency}
                                                    disableDrag={true}
                                                    setCards={setTasks}
                                                />
                                            )
                                    )}
                                </Box>
                            ))}
                            )
                            {tasks.length === 0 && !noHeaders && (
                                <EmptyTaskBoi urgency={urgency} />
                            )}
                        </VStack>
                    )}
                </Droppable>
            </DragDropContext>
        </Box>
    )
}
