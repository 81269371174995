import bear from './bear.svg'
import browncat from './browncat.svg'
import browndog from './browndog.svg'
import bunny from './bunny.svg'
import elephant from './elephant.svg'
import fox from './fox.svg'
import horse from './horse.svg'
import monkey from './monkey.svg'
import orangecat from './orangecat.svg'
import orangecat2 from './orangecat2.svg'
import panda from './panda.svg'
import pig from './pig.svg'
import pointyDog from './pointyDog.svg'
import raccoon from './raccoon.svg'
import reindeer from './reindeer.svg'
import zebra from './zebra.svg'

export const AVATARS = [
    { img: bear, name: 'bear' },
    { img: browncat, name: 'browncat' },
    { img: browndog, name: 'browndog' },
    { img: bunny, name: 'bunny' },
    { img: elephant, name: 'elephant' },
    { img: fox, name: 'fox' },
    { img: horse, name: 'horse' },
    { img: monkey, name: 'monkey' },
    { img: orangecat, name: 'orangecat' },
    { img: orangecat2, name: 'orangecat2' },
    { img: panda, name: 'panda' },
    { img: pig, name: 'pig' },
    { img: pointyDog, name: 'pointyDog' },
    { img: raccoon, name: 'raccoon' },
    { img: reindeer, name: 'reindeer' },
    { img: zebra, name: 'zebra' },
]
