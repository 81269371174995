import React, { useState, useContext } from 'react'
import HeaderText from '../HeaderText'
import { Flex, Box, Button, Text, Input, Kbd, Checkbox } from '@chakra-ui/react'
import { apiCall } from '../../Util/api'
import { UserContext } from '../../Contexts/UserContext'
import { TasksContext } from '../../Contexts/TasksContext'
import { LabelsContext } from '../../Contexts/LabelsContext'
import CreateTaskInput from '../../Tasks/CreateTaskInput'

export default function Step3({ step, setStep }) {
    const [description, setDescription] = useState('')
    const [label, setLabel] = useState('')
    const { user, setUser } = useContext(UserContext)
    const { createTask } = useContext(TasksContext)
    const { labels, getUsersLabels } = useContext(LabelsContext)

    const handleCreateTask = async (data) => {
        let task = { ...data, openUp: true, position: 1000 }
        let checklistItem = {
            complete: false,
            completionDate: null,
            position: 1000,

            name: 'Use the action bar on the bottom right to set a due date, reminder, add to calendar, label and more!',
        }

        try {
            const createdChecklistItem = await apiCall(
                'POST',
                `/users/${user._id}/checklist`,
                checklistItem
            )

            task.checklist = [createdChecklistItem._id]
            await createTask(task, 1)
        } catch (error) {
            console.log(error)
        }
    }

    const createTaskWithNewLabel = async (labelName) => {
        const newLabel = await apiCall(`POST`, `/users/${user._id}/labels`, {
            name: labelName,
        })
        getUsersLabels()

        handleCreateTask({ description, labels: [newLabel] })

        setStep(step + 1)
    }

    const createTaskWithExistingLabel = (label) => {
        handleCreateTask({ description, labels: [label] })

        setStep(step + 1)
    }

    return (
        <Box marginBottom="48px">
            {description === '' ? (
                <>
                    <Box pl="24px" pr="24px">
                        <HeaderText>
                            What else do you want to work on today?
                        </HeaderText>
                    </Box>
                    <Flex
                        marginLeft="32px"
                        marginTop="16px"
                        height="48px"
                        marginRight="32px"
                    >
                        <CreateTaskInput
                            setWalkthroughDescription={(description) =>
                                setDescription(description)
                            }
                        />
                    </Flex>{' '}
                </>
            ) : (
                <Flex direction="column" align="center">
                    <HeaderText>Add a label to categorize your task</HeaderText>
                    <Flex
                        p="24px"
                        width="90%"
                        alignItems="center"
                        justifyContent="space-between"
                        boxShadow="base"
                        borderRadius="8px"
                        mt="16px"
                    >
                        <Checkbox size="lg" isChecked={false} />
                        <Text
                            fontSize="lg"
                            fontWeight="400"
                            style={{
                                textAlign: 'center',
                                marginLeft: 16,
                                marginRight: 32,
                                overflowWrap: 'break-word',
                                marginRight: 'auto',
                            }}
                        >
                            {description.length > 35
                                ? `${description.substring(0, 35)}...`
                                : description}
                        </Text>
                    </Flex>

                    <Flex justify="center" mt="16px">
                        {labels.map((label, index) => (
                            <Button
                                id={`label${index}`}
                                variant="chip-colored"
                                background={
                                    label.color === ''
                                        ? 'blue.500'
                                        : label.color
                                }
                                key={label.name}
                                mt="2px"
                                mb="2px"
                                onClick={() =>
                                    createTaskWithExistingLabel(label)
                                }
                            >
                                {label.name}
                            </Button>
                        ))}
                    </Flex>
                    <Flex
                        w={label.length > 0 ? '80%' : '70%'}
                        mt="16px"
                        align="center"
                    >
                        <Input
                            id="walkghrough-label-input"
                            placeholder="Create your own label..."
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                            onKeyDown={(e) =>
                                e.keyCode === 13 &&
                                createTaskWithNewLabel(e.target.value)
                            }
                        />
                        {label.length > 0 && (
                            <Button
                                colorScheme="blue"
                                variant="solid"
                                size="md"
                                marginLeft="16px"
                                onClick={() => createTaskWithNewLabel(label)}
                                display="flex"
                                minW="120px"
                            >
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    submit{' '}
                                    <Kbd
                                        color="black"
                                        marginLeft="4px"
                                        borderColor="grey.800"
                                    >
                                        enter
                                    </Kbd>
                                </span>
                            </Button>
                        )}
                    </Flex>
                </Flex>
            )}
        </Box>
    )
}
