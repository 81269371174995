import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Input, useToast } from '@chakra-ui/react'
import {
    CircleCheckIcon,
    InfoIcon,
    WarningIcon,
} from '../../ChakraDesign/Icons'
import ToastyBoi from '../../SharedComponents/ToastyBoi'

const STATES = {
    notUploading: 'not uploading',
    uploading: 'uploading',
    error: 'error',
    success: 'success',
}

export default function UploadButton({ addFile, onUploadSuccess, children }) {
    const [uploadState, setUploadState] = useState(STATES.notUploading)
    const inputFileRef = useRef(null)
    const toaster = useToast()

    useEffect(() => {
        switch (uploadState) {
            case STATES.uploading:
                toaster({
                    title: 'Uploading',
                    duration: 3000,
                    isClosable: true,
                    render: () => (
                        <ToastyBoi
                            message={'Uploading...'}
                            icon={<WarningIcon fill="white" />}
                            backgroundColor="grey.900"
                        />
                    ),
                })
                break
            case STATES.error:
                toaster({
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    render: () => (
                        <ToastyBoi
                            message={'Something went wrong'}
                            icon={<WarningIcon fill="white" />}
                            backgroundColor="red.500"
                        />
                    ),
                })
                break
            case STATES.success:
                toaster({
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    render: () => (
                        <ToastyBoi
                            message={'Upload successful!'}
                            icon={<CircleCheckIcon fill="white" />}
                            backgroundColor="blue.500"
                        />
                    ),
                })
                break
            case STATES.notUploading:
                break
            default:
                break
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadState])

    const onFileChange = async (e) => {
        e.stopPropagation()
        setUploadState(STATES.uploading)

        const file = e.target.files['0']
        const formattedFile = new File([file], `${file.name}`, {
            type: file.type,
        })

        try {
            let formData = new FormData()
            formData.append('files', formattedFile)

            const response = await addFile(formData)

            onUploadSuccess(response)
            setUploadState(STATES.success)
        } catch (error) {
            setUploadState(STATES.error)
            setTimeout(() => {
                setUploadState(STATES.notUploading)
            }, 3000)
            console.log(error)
        }

        setTimeout(() => {
            setUploadState(STATES.notUploading)
        }, 3000)
    }

    const handleButtonClick = (e) => {
        /*Collecting node-element and performing click*/
        e.stopPropagation()
        inputFileRef.current.click()
    }

    return (
        <>
            <Input
                style={{ display: 'none' }}
                type="file"
                ref={inputFileRef}
                onChange={onFileChange}
            />
            <span
                onClick={(e) => {
                    e.stopPropagation()
                    handleButtonClick(e)
                }}
            >
                {children}
            </span>
        </>
    )
}
