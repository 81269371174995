import React, { useState, useEffect } from 'react'
import { apiCall } from '../../Util/api'
import { AddTaskIcon } from '../../ChakraDesign/Icons'
import UploadButton from '../../Tasks/TaskCard/UploadButton'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import {
    Text,
    Link,
    Flex,
    Box,
    Image,
    Input,
    Button,
    Avatar,
    FormLabel,
} from '@chakra-ui/react'

export default function CompanyInfo({ user, submit }) {
    // company info
    const [company, setCompany] = useState({ name: '', logo: '', url: '' })
    const [companyList, setCompanyList] = useState([])
    const [disableLogo, setDisableLogo] = useState(false)
    const [disableUrl, setDisableUrl] = useState(false)

    useEffect(() => {
        getUserCompany()
        getAllCompanies()
    }, [])

    const createCompany = async (data) => {
        const createdCompany = await apiCall(
            `POST`,
            `/users/${user._id}/company`,
            data
        )
        return createdCompany
        // set state for company and user.company
    }

    const getAllCompanies = async () => {
        const allCompanies = await apiCall(`GET`, `/users/${user._id}/company`)
        allCompanies.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
            return 0
        })
        setCompanyList(allCompanies)
    }

    const getUserCompany = async () => {
        const userCompany = await apiCall(
            `GET`,
            `/users/${user._id}/company/${user.company}`
        )

        if (userCompany) {
            setCompany(userCompany)

            if (userCompany.logo !== '') {
                setDisableLogo(true)
            }
            if (userCompany.url !== '') {
                setDisableUrl(true)
            }
        }
    }

    const updateCompany = async (updates) => {
        const updatedCompany = await apiCall(
            `PUT`,
            `/users/${user._id}/company/${company._id}`,
            updates
        )
        return updatedCompany
    }

    const uploadLogo = async (formData) => {
        if (![...formData][0][1].type.includes('image')) {
            alert(
                'File must be an image. Accepted file types are jpeg, gif, png, svg, bmp, and ico'
            )
            throw formData
        } else {
            try {
                const newLogo = await apiCall(
                    `POST`,
                    `/users/${user._id}/files/create`,
                    formData
                )

                return newLogo
            } catch (error) {
                console.log(error)
            }
        }
    }

    const logoUploadSuccess = (newFile) => {
        setCompany({ ...company, logo: newFile.url })
        setDisableLogo(true)
    }

    const handleCompanyChange = (name) => {
        const status = getCompanyStatus(name)

        if (status === 'existing') {
            const index = companyList
                .map((company) => company.name.toLowerCase())
                .indexOf(name.toLowerCase())

            if (companyList[index].logo !== '') {
                setDisableLogo(true)
            } else {
                setDisableLogo(false)
            }
            if (companyList[index].url !== '') {
                setDisableUrl(true)
            } else {
                setDisableUrl(false)
            }

            setCompany(companyList[index])
        } else {
            setCompany({ name: name, logo: '', url: '' })
            setDisableLogo(false)
            setDisableUrl(false)
        }
    }

    const getCompanyStatus = (name) => {
        if (name === '') {
            return 'empty'
        } else if (
            companyList
                .map((company) => company.name.toLowerCase())
                .includes(name.toLowerCase())
        ) {
            return 'existing'
        } else {
            return 'new'
        }
    }

    const submitForm = async () => {
        // update company info
        let companyId = ''
        const status = getCompanyStatus(company.name)
        if (status === 'new') {
            const createdCompany = await createCompany({
                name: company.name,
                logo: company.logo,
                url: company.url,
            })
            companyId = createdCompany._id

            if (createdCompany.logo !== '') {
                setDisableLogo(true)
            } else {
                setDisableLogo(false)
            }
            if (createdCompany.url !== '') {
                setDisableUrl(true)
            } else {
                setDisableUrl(false)
            }

            let newCompanyList = [...companyList]
            newCompanyList.push(createdCompany)
            setCompanyList(newCompanyList)
            setCompany(createdCompany)
        } else if (status === 'existing') {
            const updatedCompany = await updateCompany({
                logo: company.logo,
                url: company.url,
            })
            companyId = updatedCompany._id

            if (updatedCompany.logo !== '') {
                setDisableLogo(true)
            } else {
                setDisableLogo(false)
            }
            if (updatedCompany.url !== '') {
                setDisableUrl(true)
            } else {
                setDisableUrl(false)
            }

            let newCompanyList = [...companyList]
            newCompanyList = newCompanyList.map((company) => {
                if (company._id === updatedCompany._id) {
                    return updatedCompany
                } else {
                    return company
                }
            })
            setCompanyList(newCompanyList)
        } else if (status === 'empty') {
            companyId = null
        }
        await submit({ company: companyId })
    }

    return (
        <>
            <Text variant="profile-title" mt="32px">
                Company info
            </Text>
            <Box pl="16px" w="100%">
                <Label text="Company Name" />
                <Flex>
                    <AutoComplete
                        rollNavigation
                        listAllValuesOnFocus
                        suggestWhenEmpty={true}
                        openOnFocus
                        onSelectOption={(obj) =>
                            handleCompanyChange(obj.item.label)
                        }
                    >
                        <AutoCompleteInput
                            type="text"
                            size="lg"
                            variant="filled"
                            textTransform="capitalize"
                            value={company.name}
                            onBlur={(e) => handleCompanyChange(e.target.value)}
                            onChange={(e) =>
                                setCompany({
                                    ...company,
                                    name: e.target.value,
                                })
                            }
                            placeholder="Type your company name..."
                        />
                        <AutoCompleteList mt="4px" maxH="240px">
                            {companyList.map((c, index) => (
                                <>
                                    {index === 0 && company.name !== '' && (
                                        <AutoCompleteItem
                                            key={0}
                                            value={company}
                                            label={company.name}
                                        >
                                            <Flex justify="start">
                                                <Avatar
                                                    name={company.name}
                                                    bg="blue.500"
                                                    size="xs"
                                                    color="white"
                                                    mr="16px"
                                                />

                                                <Text textTransform="capitalize">
                                                    {company.name}
                                                </Text>
                                            </Flex>
                                        </AutoCompleteItem>
                                    )}
                                    <AutoCompleteItem
                                        key={c._id}
                                        value={c}
                                        label={c.name}
                                    >
                                        <Flex justify="start">
                                            {c.logo !== '' ? (
                                                <Image
                                                    src={c.logo}
                                                    w="24px"
                                                    maxH="24px"
                                                    mr="16px"
                                                />
                                            ) : (
                                                <Avatar
                                                    name={c.name}
                                                    bg="blue.500"
                                                    size="xs"
                                                    color="white"
                                                    mr="16px"
                                                />
                                            )}

                                            <Text textTransform="capitalize">
                                                {c.name}
                                            </Text>
                                        </Flex>
                                    </AutoCompleteItem>
                                </>
                            ))}
                        </AutoCompleteList>
                    </AutoComplete>
                </Flex>
                <Label text="Company Logo" />
                {disableLogo ? (
                    <Image src={company.logo} h="52px" ml="16px" />
                ) : (
                    <UploadButton
                        addFile={uploadLogo}
                        onUploadSuccess={logoUploadSuccess}
                    >
                        <Button
                            bg="transparent"
                            w="100%"
                            h="48px"
                            justifyContent="flex-start"
                            size="lg"
                            pl="16px"
                            disabled={company.name === ''}
                        >
                            <AddTaskIcon color="blue.500" />
                            <Text ml="8px" fontWeight={600} fontSize="16px">
                                Add Logo
                            </Text>
                        </Button>
                    </UploadButton>
                )}
                <Label text="Company Website" />
                {disableUrl ? (
                    <Link
                        isExternal
                        ml="16px"
                        color="blue.500"
                        textDecoration="underline"
                        href={company.url}
                    >
                        {company.url.length > 40
                            ? `${company.url.substring(0, 40)}...`
                            : company.url}
                    </Link>
                ) : (
                    <InfoInput
                        value={company.url}
                        onChange={(e) =>
                            setCompany({ ...company, url: e.target.value })
                        }
                        placeholder="Paste your company URL here..."
                        disabled={company.name === ''}
                    />
                )}
            </Box>
            <Button variant="profile-save" onClick={() => submitForm()}>
                Save changes
            </Button>
        </>
    )
}

const InfoInput = ({
    value,
    onFocus,
    onBlur,
    onChange,
    placeholder,
    isRequired,
    disabled,
}) => (
    <Input
        type="text"
        size="lg"
        variant="filled"
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        isRequired={isRequired}
        disabled={disabled}
        autoComplete="off"
    />
)

const Label = ({ text }) => <FormLabel mt="16px">{text}</FormLabel>
