import React, { useState } from 'react'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import { Box, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { times, validateTime } from '../Util/timeUtils'
import theme from '../ChakraDesign/theme'
import { ClockIcon } from '../ChakraDesign/Icons'

export default function TimePicker({
    time,
    setTime,
    invalidTime,
    setInvalidTime,
}) {
    const [scopedTime, setScopedTime] = useState(time)

    const updateTime = (updatedTime) => {
        setScopedTime(updatedTime)

        const validatedTime = validateTime(updatedTime)

        if (validatedTime) {
            setTime(validatedTime)
            setInvalidTime(false)
        } else {
            setInvalidTime(true)
        }
    }

    return (
        <Box cursor="pointer">
            <AutoComplete
                rollNavigation
                openOnFocus
                onSelectOption={(obj) => updateTime(obj.item.value)}
            >
                <InputGroup>
                    <InputLeftElement>
                        <ClockIcon
                            color={!invalidTime ? 'grey.500' : 'red.500'}
                        />
                    </InputLeftElement>
                    <AutoCompleteInput
                        placeholder="10:00AM"
                        value={scopedTime}
                        onChange={(e) => updateTime(e.target.value)}
                        border="none"
                        borderLeftRadius="0px"
                        lefticon={<ClockIcon />}
                    />
                </InputGroup>

                <AutoCompleteList>
                    {times.map((option, index) => (
                        <AutoCompleteItem
                            key={`option-${index}`}
                            value={option}
                            label={option}
                        />
                    ))}
                </AutoCompleteList>
            </AutoComplete>
            {invalidTime && (
                <span
                    style={{
                        position: 'absolute',
                        top: -24,
                        color: theme.colors.red[500],
                    }}
                >
                    *Invalid time
                </span>
            )}
        </Box>
    )
}
