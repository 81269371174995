import React, { useEffect, useContext, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { analytics, apiCall } from '../../Util/api'
import {
    Box,
    Flex,
    useToast,
    Tooltip,
    Text,
    Button,
    IconButton,
    Spacer,
    Progress,
    VStack,
    Collapse,
} from '@chakra-ui/react'
import {
    CarrotIcon,
    AddTaskIcon,
    CircleCheckIcon,
} from '../../ChakraDesign/Icons'
import ToastyBoi from '../../SharedComponents/ToastyBoi'
import TemplateActionBar from './TemplateActionBar'
import AttachmentsList from '../../Tasks/TaskCard/AttachmentsList'
import Description from '../../Tasks/TaskCard/Description'
import Checklist from '../../Tasks/TaskCard/Checklist'
import Notes from '../../Tasks/TaskCard/Notes'
import LoadingModal from './LoadingModal'
import { TasksContext } from '../../Contexts/TasksContext'

export default function TemplateCard({
    user,
    index,
    cards,
    setCards,
    template,
    setModalOpen,
    updateTemplate,
    deleteTemplate,
}) {
    const toaster = useToast()
    const [expanded, setExpanded] = useState(template.expanded)
    const [description, setDescription] = useState(template.description)
    const [templateLabels, setTemplateLabels] = useState(template.labels)
    const [notes, setNotes] = useState(template.notes.join('\n\n'))
    const [checklist, setChecklist] = useState(
        [...template.checklist].sort((a, b) => a.position - b.position)
    )
    const [progress, setProgress] = useState([0, checklist.length])
    const [attachments, setAttachments] = useState(template.files)
    const [isLoading, setIsLoading] = useState(false)

    const { setTasks } = useContext(TasksContext)

    useEffect(() => {
        setProgress([0, checklist.length])
    }, [checklist])

    useEffect(() => {
        if (template.openUp) {
            setExpanded(true)
        }
    }, [template])

    const templateCardStyle = (draggableStyle) => {
        let transform = draggableStyle.transform

        if (transform) {
            transform =
                'translate(0px' +
                transform.slice(transform.indexOf(','), transform.length)
        }
        return {
            ...draggableStyle,
            transform: transform,
            cursor: expanded && 'pointer',
            marginTop: '16px',
            padding: '16px',
            borderRadius: '8px',
            backgroundColor: 'gray.200',
        }
    }

    const handleUpdate = async (newData, field) => {
        switch (field) {
            case 'description':
                setDescription(newData.description)
                break

            case 'checklist':
                break
            case 'notes':
                setNotes(newData.notes)
                break
            default:
                alert('not a valid update field')
                break
        }
        updateTemplate(template._id, newData)
    }

    const convertToTask = async (event) => {
        event.stopPropagation()
        setIsLoading(true)

        const taskData = {
            description,
            notes,
            urgency: 1,
            files: attachments,
            labels: templateLabels,
        }

        const tasks = await apiCall(`GET`, `/users/${user._id}/tasks`)
        analytics.trigger({ name: 'Task Created From Template', user })

        const nowSection = tasks
            .filter((task) => task.urgency === 1)
            .sort((a, b) => a.position - b.position)

        if (nowSection) {
            try {
                if (nowSection.length === 0) {
                    taskData.position = 1000
                } else {
                    taskData.position = nowSection[0].position - 100
                }

                const newTask = await apiCall(
                    `POST`,
                    `/users/${user._id}/tasks`,
                    taskData
                )
                console.log('new task created')

                for await (let item of checklist) {
                    const newItem = {
                        name: item.name,
                        complete: false,
                        completionDate: null,
                        position: item.position,
                    }

                    try {
                        await apiCall(
                            `POST`,
                            `/users/${user._id}/checklist/${newTask._id}?template=false`,
                            newItem
                        )
                    } catch (error) {
                        console.log(error)
                    }
                }
                let newTasks = [...tasks]

                newTask.openUp = true
                newTasks.push(newTask)

                newTasks = newTasks.sort((a, b) => a.position - b.position)
                newTasks = newTasks.sort((a, b) => a.urgency - b.urgency)

                setIsLoading(false)
                setModalOpen(false)
                setTasks(newTasks)

                toaster({
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    render: () => (
                        <ToastyBoi
                            message="Task created!"
                            icon={<CircleCheckIcon fill="white" />}
                            backgroundColor="blue.500"
                        />
                    ),
                })
            } catch (error) {
                console.log(error)
            }
        }
    }

    // API's to pass to checklist component
    const createItem = async ({ name }) => {
        const newChecklist = [...checklist]
        const newItem = {
            name,
            position:
                newChecklist.length === 0
                    ? 1000
                    : newChecklist[newChecklist.length - 1].position + 10,
        }
        newChecklist.push(newItem)
        setChecklist(newChecklist)

        try {
            const createdItem = await apiCall(
                `POST`,
                `/users/${user._id}/checklist/${template._id}?template=true`,
                newItem
            )
            newChecklist[newChecklist.length - 1] = createdItem
            setChecklist(newChecklist)
            updateTemplate(template._id, { checklist: newChecklist })
        } catch (error) {
            console.log(error)
        }
    }

    const updateItem = async (itemId, updates) => {
        try {
            const updatedItem = await apiCall(
                `PUT`,
                `/users/${user._id}/checklist/${template._id}/${itemId}?template=true`,
                updates
            )
            return updatedItem
        } catch (error) {
            console.log(error)
        }
    }

    const deleteItem = (itemId) => {
        const newChecklist = checklist.filter((item) => item._id !== itemId)
        setChecklist(newChecklist)
        updateTemplate(template._id, { checklist: newChecklist })

        try {
            apiCall(
                `DELETE`,
                `/users/${user._id}/checklist/${template._id}/${itemId}?template=true`
            )
        } catch (error) {
            console.log(error)
        }
    }

    // API to delete attachment
    const deleteAttachment = (fileId) => {
        let newAttachments = [...attachments].filter(
            (attachment) => attachment._id !== fileId
        )
        setAttachments(newAttachments)
        updateTemplate(template._id, { files: newAttachments })

        try {
            apiCall(
                `DELETE`,
                `/users/${user._id}/tasks/${template._id}/files/${fileId}?template="true"`
            )
        } catch (error) {
            console.log(error)
        }
    }

    const handleUploadSuccess = (newFile) => {
        let newAttachments = [...attachments]
        newAttachments.push(newFile)

        updateTemplate(template._id, { files: newAttachments })
        setAttachments(newAttachments)
    }

    useEffect(() => {
        setTemplateLabels([...template.labels])
    }, [template.labels])

    return (
        <>
            <Draggable
                key={template._id}
                draggableId={template._id}
                index={index}
                isDragDisabled={expanded}
            >
                {(provided) => (
                    <Box
                        w="100%"
                        _hover={{
                            boxShadow: '0 8px 16px 0 rgba(56, 96, 165, 0.15)',
                            transition: '0.3s',
                        }}
                        _active={{
                            cursor: 'grabbing',
                        }}
                        boxShadow={`${
                            expanded && '0 8px 16px 0 rgba(56, 96, 165, 0.15)'
                        }`}
                        backgroundColor="colors.white"
                        onClick={() => !expanded && setExpanded(true)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={templateCardStyle(provided.draggableProps.style)}
                    >
                        <Flex
                            justifyContent={{
                                base: 'start',
                                md: 'space-between',
                            }}
                            w="100%"
                            minHeight="48px"
                        >
                            <Tooltip label="Add to Task List">
                                <Flex alignItems="center">
                                    <AddTaskIcon
                                        mr="-4px"
                                        color="blue.500"
                                        cursor="pointer"
                                        alignSelf="flex-start"
                                        mt="12px"
                                        onClick={(e) => convertToTask(e)}
                                    />
                                </Flex>
                            </Tooltip>
                            <Box
                                pr="16px"
                                ml="-4px"
                                alignSelf="center"
                                mt={expanded && '11px'}
                                w={{
                                    base: '90%',
                                    md: !expanded ? '50%' : '100%',
                                }}
                            >
                                <VStack
                                    flexDirection={expanded ? 'row' : 'column'}
                                >
                                    <Box
                                        w="100%"
                                        mb={
                                            !expanded && progress[1] === 0
                                                ? '11px'
                                                : '10px'
                                        }
                                    >
                                        <Description
                                            expanded={expanded}
                                            description={description}
                                            handleUpdate={handleUpdate}
                                            key={template._id}
                                        />
                                    </Box>
                                    {!expanded && progress[1] > 0 && (
                                        <Flex
                                            w="100%"
                                            alignItems="center"
                                            mt="0px !important"
                                        >
                                            <Progress
                                                h="8px"
                                                w="100%"
                                                mr="16px"
                                                ml="16px"
                                                value={0}
                                                colorScheme="blue"
                                                borderRadius="16px"
                                                mt="0px"
                                            />
                                            <Text
                                                fontSize="small"
                                                fontWeight="bold"
                                                color="grey.900"
                                                mt="0px"
                                            >
                                                {progress[0]}/{progress[1]}
                                            </Text>
                                        </Flex>
                                    )}
                                </VStack>
                            </Box>
                            {!expanded && (
                                <>
                                    <Box
                                        mr="auto"
                                        alignSelf="center"
                                        display={{ base: 'none', md: 'flex' }}
                                        w={{
                                            md: '178px',
                                        }}
                                        flexWrap="wrap"
                                    >
                                        {templateLabels.map((label, index) => (
                                            <Button
                                                mt="2px"
                                                mb="2px"
                                                key={index}
                                                variant="chip-colored"
                                                background={
                                                    label.color === ''
                                                        ? 'blue.500'
                                                        : label.color
                                                }
                                            >
                                                {label.name}
                                            </Button>
                                        ))}
                                    </Box>
                                </>
                            )}
                            {expanded && (
                                <IconButton
                                    icon={<CarrotIcon />}
                                    onClick={() => {
                                        setExpanded(false)
                                        template.openUp = false
                                    }}
                                    size="lg"
                                    borderRadius="xl"
                                    backgroundColor="transparent"
                                    color="grey.900"
                                    alignSelf="flex-start"
                                />
                            )}
                        </Flex>
                        <Collapse in={expanded} unmountOnExit>
                            <Box ml="32px" mr="32px" mt="8px">
                                <Notes
                                    notes={notes}
                                    handleUpdate={handleUpdate}
                                />
                                <Spacer h="8px" />
                                <Checklist
                                    user={user}
                                    items={checklist}
                                    setItems={setChecklist}
                                    expanded={expanded}
                                    progress={progress}
                                    createItem={createItem}
                                    updateItem={updateItem}
                                    deleteItem={deleteItem}
                                    cantComplete={true}
                                />
                            </Box>
                            <AttachmentsList
                                section={0}
                                attachments={attachments}
                                deleteAttachment={deleteAttachment}
                            />
                            <TemplateActionBar
                                section={0}
                                cards={cards}
                                setCards={setCards}
                                userId={user._id}
                                templateId={template._id}
                                templateLabels={templateLabels}
                                setTemplateLabels={setTemplateLabels}
                                updateTemplate={updateTemplate}
                                deleteTemplate={deleteTemplate}
                                handleUploadSuccess={handleUploadSuccess}
                                actionButtonText="Add to Task List"
                                actionButtonFunction={convertToTask}
                            />
                        </Collapse>
                    </Box>
                )}
            </Draggable>
            {isLoading && (
                <LoadingModal
                    isOpen={isLoading}
                    message="Adding to Task List..."
                />
            )}
        </>
    )
}
