import React from 'react'
import { Box, Text, Flex, Center, Button } from '@chakra-ui/react'

export default function UpgradePrompt({
    isNewUser,
    subscription,
    setPaidPopup,
}) {
    const numDays = subscription === 'personal' ? 30 : 7

    return (
        <>
            {subscription !== 'professional' && !isNewUser && (
                <Flex justifyContent="center" mt="32px">
                    <Box>
                        <Text fontSize="lg">
                            You could see past <b>{numDays}</b> days in your
                            reports if you upgrade your subscription.
                        </Text>
                        <Center>
                            <Button
                                colorScheme="blue"
                                onClick={() =>
                                    setPaidPopup({
                                        show: true,
                                        reason: 'Upgrade your subscription.',
                                        hideButtons: false,
                                    })
                                }
                                mt="8px"
                            >
                                upgrade now
                            </Button>
                        </Center>
                    </Box>
                </Flex>
            )}
        </>
    )
}
