import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Box, VStack, Flex, Text } from '@chakra-ui/react'
import { apiCall } from '../Util/api'
import RecurringTaskCard from './RecurrenceTaskCard'
import { RRule } from 'rrule'
import { blankForm, DAYSOFWEEK } from '../Tasks/TaskCard/RecurrenceForm'

export default function RecurringTasksList({
    user,
    recurringTasks,
    setRecurringTasks,
    updateRecurringTask,
}) {
    // dnd library needs styles to be in a separate function
    const containerStyle = (isDraggingOver) => ({
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 8,
        marginLeft: -8,
        marginRight: -8,
        cursor: isDraggingOver ? 'grabbing' : 'grab',
    })

    const onDragEnd = (result) => {
        // Initialize variables for function
        const { destination, source } = result
        const newIndex = destination ? destination.index : source.index
        let newRecurringTasks = [...recurringTasks]
        let newPosition = null

        if (newIndex === source.index) {
            return
        } else {
            // reorder to reflect dnd action
            const dragRecurringTask = newRecurringTasks[source.index]
            newRecurringTasks.splice(source.index, 1)
            newRecurringTasks.splice(newIndex, 0, dragRecurringTask)

            if (newIndex === 0) {
                newPosition = newRecurringTasks[1].position - 100
            } else if (newIndex === newRecurringTasks.length - 1) {
                newPosition =
                    newRecurringTasks[newRecurringTasks.length - 2].position +
                    100
            } else {
                const position1 = newRecurringTasks[newIndex - 1].position
                const position2 = newRecurringTasks[newIndex + 1].position

                newPosition = (position1 + position2) / 2
            }

            // update state
            newRecurringTasks[newIndex].position = newPosition
            setRecurringTasks(newRecurringTasks)

            apiCall(
                `PUT`,
                `/users/${user._id}/recurrence/${dragRecurringTask._id}`,
                {
                    position: newPosition,
                }
            )
            return
        }
    }

    const generateRrule = (recurrence) => {
        const fakeDateStart = setPartsToUTCDate(
            new Date(recurrence.rruleParams.dtstart)
        )

        let rrule = recurrence.rruleParams

        let ruleParams = {
            ...blankForm,
            ...rrule,
            dtstart: new Date(fakeDateStart),
            until: rrule.until && new Date(rrule.until),
            byweekday: rrule.byweekday.map((d) => DAYSOFWEEK[d.weekday]),
        }
        for (let key in ruleParams) {
            if (ruleParams[key] === '') {
                delete ruleParams[key]
            }
        }

        const rule = new RRule(ruleParams)

        return rule
    }

    const sortRecurrencesByText = () => {
        let texts = []
        recurringTasks.map((recurrence) => {
            let text = generateRrule(recurrence).toText()
            if (!texts.includes(text)) {
                texts.push(text)
            }
        })

        return texts
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="3">
                {(provided, snapshot) => (
                    <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={containerStyle(snapshot.isDraggingOver)}
                        maxH="75vh"
                        overflow="auto"
                    >
                        <VStack id="recurrence-list">
                            {sortRecurrencesByText().map((text, index) => (
                                <>
                                    <Box
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Flex
                                            alignItems="center"
                                            marginRight="16px"
                                        >
                                            <Text
                                                color="blue.500"
                                                fontSize="md"
                                                fontWeight="bold"
                                                whiteSpace="nowrap"
                                                marginLeft="8px"
                                            >
                                                {text}
                                            </Text>
                                        </Flex>
                                        <hr
                                            style={{
                                                height: 1,
                                                backgroundColor: '#E5ECF6',
                                                border: '1px solid #E5ECF6',
                                                width: '100%',
                                                borderRadius: 16,
                                            }}
                                        />
                                    </Box>
                                    {recurringTasks.map(
                                        (recurrence, index) =>
                                            generateRrule(
                                                recurrence
                                            ).toText() === text && (
                                                <RecurringTaskCard
                                                    user={user}
                                                    index={index}
                                                    key={recurrence._id}
                                                    recurrence={recurrence}
                                                    cards={recurringTasks}
                                                    setCards={setRecurringTasks}
                                                    updateRecurringTask={
                                                        updateRecurringTask
                                                    }
                                                    recurringTasks={
                                                        recurringTasks
                                                    }
                                                    setRecurringTasks={
                                                        setRecurringTasks
                                                    }
                                                />
                                            )
                                    )}
                                </>
                            ))}

                            {provided.placeholder}
                        </VStack>
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    )
}

function setPartsToUTCDate(d) {
    return new Date(
        Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
        )
    )
}

function setUTCPartsToDate(d) {
    return new Date(
        d.getUTCFullYear(),
        d.getUTCMonth(),
        d.getUTCDate(),
        d.getUTCHours(),
        d.getUTCMinutes(),
        d.getUTCSeconds()
    )
}
