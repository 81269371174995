import React, { useState, useEffect, useContext, useRef } from 'react'
import TasksList from './TasksList'
import TasksNavLeft from './TasksNavLeft'
import TasksNavMobile from './TasksNavMobile'
import TasksFilteringAndSorting from './TasksFilteringAndSorting'
import { UserContext } from '../Contexts/UserContext'
import { apiCall } from '../Util/api'
import CreateTaskInput from './CreateTaskInput'
import { Flex, Button, VStack, Tooltip, IconButton } from '@chakra-ui/react'
import CompletedSection from './CompletedSection'
import useLocalStorage from '../Hooks/UseLocalStorage'
import AllTasksList from './AllTasksList'
import io from 'socket.io-client'
import WalkthroughContainer from '../Walkthrough/WalkthroughContainer'
import IntegrationsHelper from './IntegrationsHelper'
import SearchTasksList from './SearchTasksList'
import SearchInput from '../Navbar/SearchInput'
import LabelsTaskList from './LabelsTaskList'
import { useParams, useNavigate } from 'react-router-dom'
import PaymentStatus from '../Stripe/PaymentStatus'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import LabelsFilterModal from './LabelsFilterModal'
import InboxHeader from '../Inbox/InboxHeader'
import InboxList from '../Inbox/InboxList'
import { TasksContext } from '../Contexts/TasksContext'
import { LabelsContext } from '../Contexts/LabelsContext'
import { LeftArrowIcon, RightArrowIcon } from '../ChakraDesign/Icons'
import MoreInfoModal from '../Auth/MoreInfoModal'
import TasksLayout from '../Layouts/TasksLayout'
import NewFeaturePopup from '../Util/NewFeaturePopup'
import TemplatesModal from './TemplatesModal'
const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY)

export default function TasksContainer(props) {
    const { user, setUser } = useContext(UserContext)

    let urlParams = useParams()
    const navigate = useNavigate()
    const [urgency, setUrgency] = useState(1)
    const [inbox, setInbox] = useState([])
    const [vanityLink, setVanityLink] = useState('')
    const [isZenMode, setIsZenMode] = useLocalStorage('zenMode', false)
    const [filterLabelsModalOpen, setFilterLabelsModalOpen] = useState(false)
    const [isSearching, setIsSearching] = useState(false)
    const [isLoading, setIsLoading] = useState(null)
    const [unreadInboxTask, setUnreadInboxTask] = useLocalStorage(
        'unreadInboxTask',
        false
    )
    const [isInitialLoadDone, setIsInitialLoadDone] = useState(false)
    const [isSetupGuideOpen, setIsSetupGuideOpen] = useState(false)
    const [newFeature, setNewFeature] = useState(user.newFeature)
    const [featureMessage, setFeatureMessage] = useState(user.newFeatureMessage)
    const [isTemplatesOpen, setIsTemplatesOpen] = useState(false)
    const { selectedLabels, getUsersLabels } = useContext(LabelsContext)
    const {
        tasks,
        setTasks,
        tasksRef,
        numCompletedTasks,
        setNumCompletedTasks,
        isSearchActive,
        sortBy,
    } = useContext(TasksContext)

    // ref so socket.io can access current state
    const urgencyRef = useRef(urgency)

    //grab all the users tasks on load
    useEffect(() => {
        Promise.all([getTasks()]).then((d) => {
            setIsInitialLoadDone(true)
        })
        getNumCompletedTasks()
        getUsersLabels()
        getInbox()
    }, [])

    useEffect(() => {
        console.log(urgency)
    }, [urgency])

    useEffect(() => {
        const exampleTaskId = '63a0e99023d3d2a5cd3bc5a8'
        if (urlParams.taskId && isLoading === false) {
            if (urlParams.taskId === 'completed') {
                navigate('/tasks')
                setUrgency(4)
            } else if (urlParams.taskId.length === exampleTaskId.length) {
                console.log('checking shared')
                checkForSharedTask()
            }
        }
    }, [isLoading, urlParams])

    const checkForSharedTask = async () => {
        console.log(isLoading)
        //sharing task stuff
        let taskId = urlParams.taskId
        let task

        if (taskId) {
            let allTasks = await apiCall('get', `/users/${user._id}/tasks`)
            //check for id in users task list
            if (allTasks.map((t) => t._id.toString()).includes(taskId)) {
                console.log('found task id on users list')
                task = allTasks.filter((t) => t._id === taskId)[0]

                let tasks = tasks.map((t) => {
                    if (t._id === taskId) {
                        t.openUp = true
                        return t
                    }
                    return t
                })
                setTasks(tasks)
                setUrgency(Number(task.urgency))
            } else {
                //not on users task list first, need to add
                console.log('adding shared task to users list')
                //endpoint will push task onto users list and return full task
                try {
                    task = await apiCall(
                        'GET',
                        `/users/${user._id}/tasks/shared/${taskId}`
                    )

                    task.openUp = true

                    // setTasks([...tasks].unshift(task))
                } catch (error) {
                    alert('You do not have access to this task')
                }
            }
        }
    }

    //listen for tasks from integrations, notifications, and god mode refresh
    useEffect(() => {
        const socket = io.connect(process.env.REACT_APP_BACKEND_SERVER)

        // let backend know which user just connected
        socket.on('connect', () => {
            console.log('connected')
            socket.emit('connected', user._id)
        })

        socket.on('newTask', (data) => {
            if (data === user._id.toString()) {
                console.log('new task socket firing')
                getTasks()
                apiCall('GET', `/users/${user._id}`)
                    .then((data) => setUser(data))
                    .catch((err) => alert(err))
            }
        })

        socket.on('newLabels', (data) => {
            if (data === user._id.toString()) {
                console.log('new labels socket firing')
                getUsersLabels()
                // setTasks([])
                getTasks()
            }
        })

        socket.on('newInboxTask', (data) => {
            if (data === user._id.toString()) {
                console.log('inbox task socket firing')
                getInbox()
                if (urgencyRef.current !== 'inbox') {
                    setUnreadInboxTask(true)
                }
                if (!user.isInboxIntegrated) {
                    const updatedUser = { ...user, isInboxIntegrated: true }
                    setUser(updatedUser)
                }
                alert('You have a new requested task in your inbox')
            }
        })

        socket.on('task notification', (data) => {
            if (data.userId === user._id.toString()) {
                console.log('task notification socket firing')
                apiCall(
                    'GET',
                    `/users/${data.userId}/tasks/taskId/${data.taskId}`
                )
                    .then((data) => {
                        alert(
                            `This is a friendly reminder to do "${data.description
                                .replace(/<[^>]*>?/gm, '')
                                .substr(0, 200)}"`
                        )
                    })
                    .catch((err) => {
                        alert(err)
                    })
            }
        })

        socket.on('checklist notification', (data) => {
            if (data.userId === user._id.toString()) {
                console.log('checklist notification socket firing')
                apiCall(`GET`, `/users/${data.userId}/checklist/${data.itemId}`)
                    .then((data) => {
                        alert(
                            `This is a friendly reminder to do checklist item "${data.name.substr(
                                0,
                                200
                            )}"`
                        )
                    })
                    .catch((err) => {
                        alert(err)
                    })
            }
        })

        socket.on('community notification', (data) => {
            if (data.userId === user._id.toString()) {
                alert(data.message)
            }
        })

        socket.on('notification sent', async (data) => {
            if (data.userId === user._id.toString()) {
                console.log('notification sent socket firing')
                const newTask = await apiCall(
                    'GET',
                    `/users/${user._id}/tasks/taskId/${data.taskId}`
                )

                let newTasks = [...tasksRef.current]
                newTasks = newTasks.map((task) => {
                    if (task._id === data.taskId) {
                        return newTask
                    } else {
                        return task
                    }
                })
                setTasks(newTasks)
            }
        })

        socket.on('refresh', () => {
            window.location.reload()
        })

        socket.on('newFeature', async () => {
            const newUser = await apiCall(`GET`, `/users/${user._id}`)

            setUser(newUser)
            setNewFeature(newUser.newFeature)
            setFeatureMessage(newUser.newFeatureMessage)
        })

        return () => {
            socket.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        urgencyRef.current = urgency
        if (urgency === 'inbox' && unreadInboxTask) {
            setUnreadInboxTask(false)
        }
    }, [urgency])

    const getTasks = async () => {
        console.log('running get tasks')
        setIsLoading(true)
        let tasks = await apiCall('get', `/users/${user._id}/tasks`)

        // make tasks array be in the proper order for dnd purposes
        tasks = tasks.sort((a, b) => a.position - b.position)
        tasks = tasks.sort((a, b) => a.urgency - b.urgency)

        setTasks(tasks)

        setIsLoading(false)
    }

    const getInbox = async () => {
        // get list of pending inbox tasks
        const inboxList = await apiCall(
            `GET`,
            `/requesters/${user._id}/tasks/all`
        )
        setInbox(inboxList)

        // get user's vanity link
        const inbox = await apiCall('GET', `/inbox/${user._id}`)
        setVanityLink(inbox?.vanityLink)
    }

    const getNumCompletedTasks = async () => {
        const num = await apiCall(
            'get',
            `/users/${user._id}/tasks/numCompleted`
        )

        setNumCompletedTasks(num)
    }

    const getSectionTotals = () => {
        const totals = [
            tasks.filter((t) => t.urgency === 0).length,
            tasks.filter((t) => t.urgency === 1).length,
            tasks.filter((t) => t.urgency === 2).length,
            tasks.filter((t) => t.urgency === 3).length,
            numCompletedTasks,
            inbox.length,
        ]

        return totals
    }

    return (
        <TasksLayout
            isZenMode={isZenMode}
            setIsZenMode={setIsZenMode}
            setIsTemplatesOpen={setIsTemplatesOpen}
            setIsSetupGuideOpen={setIsSetupGuideOpen}
            searchInput={<SearchInput setIsSearching={setIsSearching} />}
            mobileMenu={
                <TasksNavMobile
                    urgency={urgency}
                    setUrgency={setUrgency}
                    sectionTotals={getSectionTotals}
                    unreadInboxTask={unreadInboxTask}
                />
            }
            left={
                <VStack
                    width="100%"
                    filter={
                        (isSearchActive || sortBy === 'label') && 'blur(3px)'
                    }
                >
                    <TasksNavLeft
                        urgency={urgency}
                        setUrgency={setUrgency}
                        sectionTotals={getSectionTotals}
                        unreadInboxTask={unreadInboxTask}
                        isZenMode={isZenMode}
                    />
                    <IntegrationsHelper
                        isOpen={isSetupGuideOpen}
                        setIsOpen={setIsSetupGuideOpen}
                        isZenMode={isZenMode}
                    />
                    <Tooltip
                        label={`${isZenMode ? 'Expand' : 'Collapse'} left side`}
                    >
                        <IconButton
                            color="grey.500"
                            backgroundColor="white"
                            icon={
                                isZenMode ? (
                                    <RightArrowIcon />
                                ) : (
                                    <LeftArrowIcon />
                                )
                            }
                            onClick={() => setIsZenMode(!isZenMode)}
                            alignSelf={!isZenMode && 'flex-start'}
                            display={{ base: 'none', lg: 'block' }}
                            style={{
                                width: '54px',
                                height: 54,
                            }}
                        />
                    </Tooltip>
                </VStack>
            }
            main={
                <>
                    {!user.isWalkthroughComplete &&
                        !props.paymentStatus &&
                        !user.needsMoreInfo && (
                            <StripeWrapper user={user}>
                                <WalkthroughContainer />
                            </StripeWrapper>
                        )}
                    {user.needsMoreInfo && (
                        <MoreInfoModal
                            isOpen={user.needsMoreInfo}
                            name={user.name}
                        />
                    )}
                    {urgency !== 4 && !isSearchActive && (
                        <Flex
                            width="100%"
                            alignItems="center"
                            paddingLeft="16px"
                            marginTop="11px"
                            justifyContent={
                                urgency !== 'all tasks' && sortBy !== 'label'
                                    ? 'space-between'
                                    : 'flex-end'
                            }
                            flexDirection={{ base: 'column', sm: 'row' }}
                        >
                            {urgency !== 'inbox' ? (
                                <>
                                    {sortBy !== 'label' ? (
                                        <CreateTaskInput
                                            urgency={urgency}
                                            setIsTemplatesOpen={
                                                setIsTemplatesOpen
                                            }
                                        />
                                    ) : (
                                        <Flex
                                            wrap="wrap"
                                            justify="end"
                                            display={{
                                                base: 'none',
                                                md: 'flex',
                                            }}
                                        >
                                            {selectedLabels.map((label) => (
                                                <Button
                                                    variant="chip-colored"
                                                    background={
                                                        label.color === ''
                                                            ? 'blue.500'
                                                            : label.color
                                                    }
                                                    mb="8px"
                                                >
                                                    {label.name}
                                                </Button>
                                            ))}
                                            <Button
                                                variant="chip-grey"
                                                mb="8px"
                                                mr="-8px"
                                                onClick={() =>
                                                    setFilterLabelsModalOpen(
                                                        true
                                                    )
                                                }
                                            >
                                                Select Labels
                                            </Button>
                                        </Flex>
                                    )}
                                    <TasksFilteringAndSorting />
                                </>
                            ) : (
                                <InboxHeader vanityLink={vanityLink} />
                            )}
                        </Flex>
                    )}
                    {props.paymentStatus && (
                        <StripeWrapper user={user}>
                            <PaymentStatus />
                        </StripeWrapper>
                    )}
                    {urgency < 4 &&
                        !isSearchActive &&
                        sortBy !== 'label' &&
                        isInitialLoadDone && <TasksList urgency={urgency} />}
                    {urgency === 4 && !isSearchActive && (
                        <CompletedSection
                            urgency={4}
                            numCompletedTasks={numCompletedTasks}
                        />
                    )}
                    {urgency === 'all tasks' &&
                        !isSearchActive &&
                        sortBy !== 'label' && (
                            <AllTasksList urgency={urgency} />
                        )}
                    {urgency === 'inbox' && (
                        <InboxList inbox={inbox} setInbox={setInbox} />
                    )}
                    {isSearchActive && (
                        <SearchTasksList
                            urgency={urgency}
                            isSearching={isSearching}
                        />
                    )}
                    {sortBy === 'label' && <LabelsTaskList urgency={urgency} />}
                    {filterLabelsModalOpen && (
                        <LabelsFilterModal
                            isOpen={filterLabelsModalOpen}
                            onClose={() => setFilterLabelsModalOpen(false)}
                        />
                    )}
                    {newFeature && (
                        <NewFeaturePopup
                            newFeature={newFeature}
                            message={featureMessage}
                            setNewFeature={setNewFeature}
                        />
                    )}
                    {isTemplatesOpen && (
                        <TemplatesModal
                            isOpen={isTemplatesOpen}
                            setIsOpen={setIsTemplatesOpen}
                        />
                    )}
                </>
            }
        />
    )
}

const StripeWrapper = (props) => {
    const [stripeIntentSecret, setStripeIntentSecret] = useState(null)

    useEffect(() => {
        if (
            !props.user?.stripeProductId ||
            (props.user.stripeProductId && props.user.isFromTeamAddition)
        ) {
            getIntent()
        }
    }, [])

    const getIntent = async () => {
        try {
            const data = await apiCall('POST', `/stripe/intent`, {
                stripeCustomerId: props.user?.stripeCustomerId,
            })

            setStripeIntentSecret(data.secret)
        } catch (error) {
            alert(error)
        }
    }

    return stripeIntentSecret !== null ? (
        <Elements
            stripe={stripePromise}
            options={{
                clientSecret: stripeIntentSecret,
            }}
        >
            {props.children}
        </Elements>
    ) : (
        props.children
    )
}
