import React, { useContext, useEffect, useState } from 'react'
import {
    Text,
    Flex,
    Center,
    Box,
    Input,
    FormControl,
    Button,
    useToast,
    CircularProgress,
    Link as ChakraLink,
    Select,
    VStack,
    FormHelperText,
} from '@chakra-ui/react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { apiCall, setTokenHeader } from '../Util/api'
import jwtDecode from 'jwt-decode'
import { UserContext } from '../Contexts/UserContext'
import { Formik, Form } from 'formik'
import { getUTCHours } from '../Util/timeUtils'
import { useMsal } from '@azure/msal-react'
import ReactRotatingText from 'react-rotating-text'
import { useGoogleLogin } from '@react-oauth/google'
import { FcGoogle } from 'react-icons/fc'
import { CgMicrosoft } from 'react-icons/cg'
import blueWave from './blueWave.svg'
import PhoneInput from '../UserProfile/UserForms/PhoneInputs/PhoneInputComponent'
import { PhoneErrorMessage } from '../UserProfile/UserForms/YourInfo'
import { isValidPhoneNumber } from 'react-phone-number-input'
import burrows from './img/burrows.webp'
import theAssist from './img/theAssist.png'
import snackNation from './img/snackNation.webp'
import missExcel from './img/missExcel.svg'
import ima from './img/ima.jpeg'
import waa from './img/waa.png'
import wholeass from './img/wholeass.png'

const SOURCES = [
    {
        image: burrows,
        title: 'Jeremy Burrows',
    },
    {
        image: snackNation,
        title: 'Snack Nation',
    },
    {
        image: theAssist,
        title: 'The Assist',
    },

    {
        image: missExcel,
        title: 'Miss Excel',
    },
    {
        image: missExcel,
        title: 'An email from Shayanne',
    },
    {
        image: wholeass,
        title: 'Whole Assistant',
    },
    {
        image: ima,
        title: 'IMA',
    },

    {
        image: waa,
        title: 'WAA',
    },
]

export default function Signup() {
    const { user, setUser } = useContext(UserContext)
    const toast = useToast()
    const navigate = useNavigate()
    const location = useLocation()
    const { instance } = useMsal()

    const [showFormInputs, setShowFormInputs] = useState(false)
    const [phone, setPhone] = useState('')
    const [validPhone, setValidPhone] = useState(true)
    const [tempName, setTempName] = useState('')
    const [showOtherInput, setShowOtherInput] = useState(false)
    const notificationSettings = {
        weeklyReviewHour: getUTCHours(12),
        weeklyPreviewHour: getUTCHours(6),
    }

    useEffect(() => {
        if (user !== null) {
            navigate('/tasks')
        }
    }, [user])

    const signInUser = async (userFormData) => {
        //make sign in call to backend
        try {
            const data = await apiCall('POST', '/auth/signin', userFormData)
            setTokenAndUser(data.token)
        } catch (err) {
            throwAuthError(err)
        }
    }

    const login = useGoogleLogin({
        onSuccess: async (response) => {
            response.notificationSettings = notificationSettings

            try {
                const data = await apiCall(
                    'POST',
                    '/auth/signinWithGoogle',
                    response
                )

                setTokenAndUser(data.token)
            } catch (err) {
                throwAuthError(err)
            }
        },
        flow: 'auth-code',
    })

    const msftLogin = async () => {
        instance
            .loginPopup({
                scopes: ['email'],
            })
            .then(async (result) => {
                try {
                    const data = await apiCall('POST', '/auth/signinWithMsft', {
                        jwt: result.idToken,
                        notificationSettings,
                    })
                    setTokenAndUser(data.token)
                } catch (err) {
                    throwAuthError(err)
                }
            })
    }

    const setTokenAndUser = async (token) => {
        localStorage.setItem('jwtToken', token)
        setTokenHeader(token)
        const decoded = jwtDecode(token)
        const newUserData = await apiCall('GET', `/users/${decoded._id}`)

        console.log(newUserData)
        setUser(newUserData)
    }

    const throwAuthError = (err) => {
        toast({
            title: 'Error',
            description: err.data.error.message,
            status: 'error',
            duration: 4000,
            isClosable: true,
        })
    }

    const signUpUser = async (userFormData) => {
        //make sign up call to backend
        try {
            userFormData.notificationSettings = notificationSettings
            const data = await apiCall('POST', '/auth/signup', userFormData)

            setTokenAndUser(data.token)
            localStorage.setItem('walkthroughStep', 1)
        } catch (err) {
            toast({
                title: 'Error',
                description: 'There was an error creating your account',
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
        }
    }

    return (
        <Flex
            justifyContent="start"
            alignItems="start"
            backgroundRepeat="no-repeat"
            backgroundPosition="right top"
            backgroundImage={{ base: 'none', lg: `url(${blueWave})` }}
            height="100vh"
        >
            <Flex maxWidth="480px" p="36px 48px">
                <Box w={{ base: '312px', md: '420px' }}>
                    <Flex alignItems="center">
                        <Center
                            height="52px"
                            width="52px"
                            shadow="base"
                            fontSize="xl"
                            padding="8px"
                            borderRadius="8px"
                        >
                            🦦
                        </Center>
                        <Text
                            fontSize="xl"
                            fontWeight="bold"
                            letterSpacing="-0.75px"
                            ml="8px"
                        >
                            office otter
                        </Text>
                    </Flex>
                    <Flex mt="24px" flexDir="column">
                        <Text fontSize="26px">
                            the administrative <br />
                            <ReactRotatingText
                                items={[
                                    'software company.',
                                    'community.',
                                    'support system.',
                                    'all-in-one partner.',
                                ]}
                            />
                        </Text>
                        <Text fontSize="18px" color="gray.500" mt="16px">
                            {location.pathname === '/signup' ? (
                                <span>
                                    Get started on your <b>free account</b>.
                                </span>
                            ) : (
                                'Sign in'
                            )}
                        </Text>
                    </Flex>

                    {/* {!showFormInputs ? ( */}
                    <Flex mb="32px" mt="16px" flexDir="column">
                        {location.pathname === '/signin' && (
                            <>
                                <Formik
                                    initialValues={{ email: '' }}
                                    onSubmit={async (values, actions) => {
                                        await signInUser(values)
                                    }}
                                >
                                    {({
                                        values,
                                        handleChange,
                                        isSubmitting,
                                    }) => (
                                        <Form style={{ width: '100%' }}>
                                            <FormControl
                                                id="email"
                                                isRequired
                                                mb="16px"
                                            >
                                                <Input
                                                    type="email"
                                                    size="lg"
                                                    variant="filled"
                                                    placeholder="work email"
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    name="email"
                                                />
                                            </FormControl>
                                            <FormControl
                                                id="password"
                                                isRequired
                                            >
                                                <Input
                                                    type="password"
                                                    size="lg"
                                                    variant="filled"
                                                    placeholder="password"
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    name="password"
                                                />
                                            </FormControl>
                                            <Button
                                                colorScheme="blue"
                                                variant="solid"
                                                size="lg"
                                                width="100%"
                                                marginTop="24px !important"
                                                marginBottom="24px !important"
                                                type="submit"
                                                fontWeight="500"
                                            >
                                                {isSubmitting ? (
                                                    <CircularProgress
                                                        isIndeterminate
                                                        size="32px"
                                                    />
                                                ) : (
                                                    'Sign in'
                                                )}
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    mb="24px"
                                >
                                    <Text color="gray.500" fontSize="lg">
                                        or
                                    </Text>
                                </Flex>

                                <Button
                                    onClick={() => login()}
                                    textTransform="none"
                                    leftIcon={<FcGoogle fontSize="24px" />}
                                    size="lg"
                                    fontWeight="500"
                                    mb="16px"
                                    colorScheme="blue"
                                >
                                    Sign{' '}
                                    {location.pathname === '/signin'
                                        ? 'in'
                                        : 'up'}{' '}
                                    with Google
                                </Button>
                                <Button
                                    onClick={() => msftLogin()}
                                    textTransform="none"
                                    leftIcon={<CgMicrosoft fontSize="24px" />}
                                    size="lg"
                                    fontWeight="500"
                                    mb="16px"
                                    colorScheme="gray"
                                >
                                    Sign{' '}
                                    {location.pathname === '/signin'
                                        ? 'in'
                                        : 'up'}{' '}
                                    with Microsoft
                                </Button>
                            </>
                        )}

                        {location.pathname === '/signup' && (
                            <VStack w="100%" alignContent="center">
                                <Formik
                                    initialValues={{
                                        name: '',
                                        email: '',
                                        password: '',
                                        phone: '',
                                        jobTitle: '',
                                        cameFrom: '',
                                    }}
                                    onSubmit={async (values, actions) => {
                                        if (isValidPhoneNumber(phone)) {
                                            values.phone = phone
                                            setValidPhone(true)
                                            await signUpUser(values)
                                        } else {
                                            setValidPhone(false)
                                        }
                                    }}
                                >
                                    {({
                                        values,
                                        handleChange,
                                        isSubmitting,
                                    }) => (
                                        <Form
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <FormControl
                                                id="name"
                                                isRequired
                                                mb="16px"
                                                mt="8px"
                                            >
                                                <Input
                                                    type="text"
                                                    size="lg"
                                                    variant="filled"
                                                    placeholder="name"
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    name="name"
                                                />
                                            </FormControl>
                                            <FormControl
                                                id="job-title"
                                                isRequired
                                                mb="16px"
                                            >
                                                <Input
                                                    type="text"
                                                    size="lg"
                                                    variant="filled"
                                                    placeholder="job title"
                                                    onChange={handleChange}
                                                    value={values.jobTitle}
                                                    name="jobTitle"
                                                />
                                            </FormControl>
                                            <FormControl
                                                id="email"
                                                isRequired
                                                mb="16px"
                                            >
                                                <Input
                                                    type="email"
                                                    size="lg"
                                                    variant="filled"
                                                    placeholder="work email"
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    name="email"
                                                />
                                            </FormControl>
                                            <FormControl
                                                id="password"
                                                isRequired
                                                mb="16px"
                                            >
                                                <Input
                                                    type="password"
                                                    size="lg"
                                                    variant="filled"
                                                    placeholder="password"
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    name="password"
                                                />
                                            </FormControl>
                                            <FormControl id="phone" isRequired>
                                                <PhoneInput
                                                    placeholder="phone number"
                                                    value={phone}
                                                    onChange={(num) =>
                                                        setPhone(num)
                                                    }
                                                />
                                                {!validPhone && (
                                                    <PhoneErrorMessage
                                                        validPhone={validPhone}
                                                    />
                                                )}
                                                <FormHelperText ml="16px">
                                                    For our text integration
                                                </FormHelperText>
                                            </FormControl>
                                            {!showOtherInput ? (
                                                <Select
                                                    placeholder="How did you hear about us?"
                                                    type="text"
                                                    size="lg"
                                                    variant="filled"
                                                    mt="12px"
                                                    value={values.cameFrom}
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.value ===
                                                            'Other'
                                                        ) {
                                                            setShowOtherInput(
                                                                true
                                                            )
                                                        } else {
                                                            handleChange(e)
                                                        }
                                                    }}
                                                    name="cameFrom"
                                                    required
                                                >
                                                    {SOURCES.map((s) => (
                                                        <option value={s.title}>
                                                            {s.title}
                                                        </option>
                                                    ))}
                                                    <option>Other</option>
                                                </Select>
                                            ) : (
                                                <FormControl
                                                    id="job-title"
                                                    isRequired
                                                    mt="12px"
                                                >
                                                    <Input
                                                        type="text"
                                                        size="lg"
                                                        variant="filled"
                                                        placeholder="How did you hear about us?"
                                                        onChange={handleChange}
                                                        value={values.cameFrom}
                                                        name="cameFrom"
                                                        autoFocus
                                                    />
                                                </FormControl>
                                            )}

                                            <Button
                                                id="signup-submit"
                                                colorScheme="blue"
                                                variant="solid"
                                                size="lg"
                                                width="100%"
                                                marginTop="16px !important"
                                                marginBottom="16px !important"
                                                type="submit"
                                            >
                                                {isSubmitting ? (
                                                    <CircularProgress
                                                        isIndeterminate
                                                        size="32px"
                                                    />
                                                ) : (
                                                    `Create Account`
                                                )}
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </VStack>
                        )}
                    </Flex>
                    {/* )} */}
                    <Box>
                        {location.pathname === '/signin' ? (
                            <>
                                <Flex>
                                    <Text fontSize="normal">
                                        Don't have an account?{' '}
                                    </Text>
                                    <Link to="/signup">
                                        <Text color="blue.500" ml="2px">
                                            {' '}
                                            Go to sign up
                                        </Text>
                                    </Link>
                                </Flex>
                                <ChakraLink
                                    href={`${process.env.REACT_APP_BACKEND_SERVER}/passwordreset`}
                                    color="blue.500"
                                    textDecor="underline"
                                >
                                    Forgot Password?
                                </ChakraLink>
                            </>
                        ) : (
                            <>
                                <Flex>
                                    <Text fontSize="normal">
                                        Already signed up?{' '}
                                    </Text>
                                    <Link to="/signin">
                                        <Text color="blue.500" ml="2px">
                                            {' '}
                                            Go to sign in
                                        </Text>
                                    </Link>
                                </Flex>
                                <Text fontSize="xs">
                                    By signing up, you agree to our{' '}
                                    <a
                                        style={{
                                            color: '#0a58ce',
                                            textDecoration: 'none',
                                        }}
                                        target="_blank"
                                        href="https://app.officeotter.com/tos"
                                        rel="noopener noreferrer"
                                    >
                                        terms of service and privacy policy
                                    </a>
                                    .
                                </Text>
                            </>
                        )}
                    </Box>
                </Box>
            </Flex>
        </Flex>
    )
}
