import React, { useContext, useState } from 'react'
import OOModal from '../SharedComponents/OOModal'
import theme from '../ChakraDesign/theme'
import { TasksContext } from '../Contexts/TasksContext'
import {
    Text,
    Input,
    Flex,
    FormControl,
    FormLabel,
    Box,
    Tooltip,
    Button,
} from '@chakra-ui/react'
import { apiCall } from '../Util/api'
import { UserContext } from '../Contexts/UserContext'
import { format } from 'date-fns'
import { TrashIcon, HourglassIcon } from '../ChakraDesign/Icons'

export default function TimeKeepingModal({ isOpen, onClose, task }) {
    const { user } = useContext(UserContext)
    const { tasks, setTasks } = useContext(TasksContext)
    const [hours, setHours] = useState(null)
    const [minutes, setMinutes] = useState(null)

    const handleSubmit = async (operation) => {
        let h = hours
        let m = minutes

        if (h === null) {
            h = 0
        }
        if (m === null) {
            m = 0
        }
        let timeToAdd = parseFloat(h) * 60 + parseFloat(m)

        if (timeToAdd > 0 || operation === 'clear') {
            try {
                let newTask

                switch (operation) {
                    case 'track':
                        newTask = await apiCall(
                            'PUT',
                            `/users/${user._id}/tasks/${task._id}/timeSpent`,
                            { timeToAdd }
                        )

                        break

                    case 'clear':
                        newTask = await apiCall(
                            'DELETE',
                            `/users/${user._id}/tasks/${task._id}/timeSpent`
                        )
                        break

                    default:
                        break
                }

                let tasksCopy = [...tasks]
                let newTasks = tasksCopy.map((t) => {
                    if (t._id === task._id) {
                        return newTask
                    } else {
                        return t
                    }
                })
                setTasks(newTasks)
            } catch (error) {
                alert(error)
            }
        }
    }

    const handleRemoveLog = async (dateLogged) => {
        try {
            let newTask = await apiCall(
                'PUT',
                `/users/${user._id}/tasks/${task._id}/timeSpent/log`,
                { dateLogged }
            )
            let tasksCopy = [...tasks]
            let newTasks = tasksCopy.map((t) => {
                if (t._id === task._id) {
                    return newTask
                } else {
                    return t
                }
            })
            setTasks(newTasks)
        } catch (error) {
            alert(error)
        }
    }

    return (
        <OOModal
            title="Track your time"
            onSubmit={() => handleSubmit('track')}
            secondaryButton={{
                onClick: () => handleSubmit('clear'),
                text: 'Clear all',
                style: {
                    backgroundColor: theme.colors.red[500],
                    color: 'white',
                },
            }}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Flex direction="column">
                <Text fontSize="lg">
                    You have spent{' '}
                    <span style={{ fontWeight: 'bold' }}>
                        {Math.floor(
                            getTotalMinutesFromTimeLog(task.timeLog) / 60
                        )}{' '}
                        hours
                    </span>{' '}
                    and{' '}
                    <span style={{ fontWeight: 'bold' }}>
                        {getTotalMinutesFromTimeLog(task.timeLog) % 60} minutes
                    </span>{' '}
                    on this task
                </Text>
                <Box mt="16px">
                    {task.timeLog.length > 0 && <b>Log</b>}
                    <Box mt="4px">
                        {task.timeLog.map((l) => (
                            <Flex
                                align="end"
                                width="100%"
                                justifyContent="space-between"
                            >
                                <Flex alignSelf="flex-start" width="104px">
                                    {format(new Date(l.dateLogged), 'MMM d')}
                                </Flex>
                                <Flex alignSelf="flex-start" width="104px">
                                    {Math.floor(l.timeSpent / 60) > 0 ? (
                                        <Box>
                                            {Math.floor(l.timeSpent / 60)}{' '}
                                            {Math.floor(l.timeSpent / 60) > 1
                                                ? 'hrs'
                                                : 'hr'}
                                        </Box>
                                    ) : (
                                        <span> - </span>
                                    )}
                                </Flex>

                                <Flex alignSelf="flex-start" width="104px">
                                    {l.timeSpent % 60 > 0 ? (
                                        <Box>
                                            {l.timeSpent % 60}{' '}
                                            {l.timeSpent % 60 > 1
                                                ? 'mins'
                                                : 'min'}
                                        </Box>
                                    ) : (
                                        <span> - </span>
                                    )}
                                </Flex>
                                <Tooltip label="Remove log">
                                    <Flex
                                        onClick={() =>
                                            handleRemoveLog(l.dateLogged)
                                        }
                                    >
                                        <TrashIcon
                                            _hover={{
                                                color: '#ff3d71',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </Flex>
                                </Tooltip>
                            </Flex>
                        ))}
                    </Box>
                </Box>
                <Flex paddingTop="16px" align="end">
                    <FormControl mr="8px">
                        <FormLabel>
                            <b>Add time</b>
                        </FormLabel>
                        <Input
                            placeholder="Hours..."
                            value={hours}
                            onChange={(e) => setHours(e.target.value)}
                            type="number"
                        />
                    </FormControl>
                    <Text pb="10px">:</Text>
                    <FormControl ml="8px">
                        {/* <FormLabel>Minutes</FormLabel> */}
                        <Input
                            placeholder="Minutes..."
                            value={minutes}
                            onChange={(e) => setMinutes(e.target.value)}
                            type="number"
                        />
                    </FormControl>
                </Flex>
            </Flex>
        </OOModal>
    )
}

export const getTotalMinutesFromTimeLog = (timeLog) =>
    timeLog.reduce((prev, cur) => prev + cur.timeSpent, 0)

export const getHoursMinutesFormattedFromTimeLog = (timeLog) => {
    return `
            ${Math.floor(getTotalMinutesFromTimeLog(timeLog) / 60)}
            ${''}:
            ${
                getTotalMinutesFromTimeLog(timeLog) % 60 >= 0 &&
                getTotalMinutesFromTimeLog(timeLog) % 60 < 10
                    ? '0'
                    : ''
            }
            ${getTotalMinutesFromTimeLog(timeLog) % 60}
`
}
