import React, { useState, useEffect, useContext } from 'react'
import CommunityLayout from './CommunityLayout'
import { Flex, Center, Switch } from '@chakra-ui/react'
import { analytics, apiCall } from '../Util/api'
import { UserContext } from '../Contexts/UserContext'
import AdminCreatePost from './AdminCreatePost'
import Post from './Post'
import CommunityFirstTimePopup from './CommunityFirstTimePopup'
import useLocalStorage from '../Hooks/UseLocalStorage'

export default function CommunityContainer() {
    const { user } = useContext(UserContext)
    const [posts, setPosts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showUserView, setShowUserView] = useState(false)
    const [showPopup, setShowPopup] = useLocalStorage(
        'showCommunityPopup',
        true
    )
    const [isPopupOpen, setIsPopupOpen] = useState(showPopup)

    useEffect(() => {
        setIsLoading(true)

        getAllPostsAndPolls().then(() => setIsLoading(false))
        analytics.trigger({
            name: 'Community Viewed',
            user,
        })
    }, [])

    const getAllPostsAndPolls = async () => {
        const posts = await apiCall('GET', `/users/${user._id}/post`)
        setPosts(posts)
    }

    return (
        <CommunityLayout
            main={
                <Flex
                    flexDir="column"
                    w="100vw"
                    maxW="1440px"
                    alignItems="center"
                >
                    {process.env.REACT_APP_ADMIN.split(' ').includes(
                        user._id
                    ) &&
                        !showUserView && (
                            <AdminCreatePost
                                posts={posts}
                                setPosts={setPosts}
                            />
                        )}
                    {process.env.REACT_APP_ADMIN.split(' ').includes(
                        user._id
                    ) && (
                        <Switch
                            isChecked={showUserView}
                            onChange={() => setShowUserView(!showUserView)}
                        >
                            showUserView
                        </Switch>
                    )}
                    {!isLoading &&
                        posts.map((p, i) =>
                            showUserView ||
                            !process.env.REACT_APP_ADMIN.split(' ').includes(
                                user._id
                            ) ? (
                                p.isPublished && (
                                    <Post
                                        description={p.description}
                                        comments={p.comments}
                                        likes={p.likes}
                                        image={p.image}
                                        isPoll={p.isPoll}
                                        options={p.options}
                                        isPublished={p.isPublished}
                                        _id={p._id}
                                        i={i}
                                        posts={posts}
                                        setPosts={setPosts}
                                        showUserView={showUserView}
                                        key={p._id}
                                    />
                                )
                            ) : (
                                <Post
                                    description={p.description}
                                    comments={p.comments}
                                    likes={p.likes}
                                    image={p.image}
                                    isPoll={p.isPoll}
                                    options={p.options}
                                    isPublished={p.isPublished}
                                    _id={p._id}
                                    i={i}
                                    posts={posts}
                                    setPosts={setPosts}
                                    showUserView={showUserView}
                                    key={p._id}
                                />
                            )
                        )}
                    {isPopupOpen && (
                        <CommunityFirstTimePopup
                            isOpen={isPopupOpen}
                            showPopup={showPopup}
                            setIsOpen={setIsPopupOpen}
                            setShowPopup={setShowPopup}
                            user={user}
                        />
                    )}
                </Flex>
            }
        />
    )
}
