export default [
    // {
    //     left: '',
    //     text: 'All Tasks',
    //     right: '100',
    // },
    {
        left: '🤷‍♀️',
        text: 'Pending',
        right: '0',
    },
    {
        left: '🏃‍♀️',
        text: 'Now',
        right: '2',
    },
    {
        left: '🚶‍♀️',
        text: 'Soon',
        right: '33',
    },
    {
        left: '🥱',
        text: 'Later',
        right: '7',
    },
    {
        left: '✅',
        text: 'Completed',
        right: '700',
    },
]
